import { Controller } from "@hotwired/stimulus";

/**
 * Utility helpers for the Connect experience.
 */
export default class extends Controller {
  static targets = ["extraFilter"];
  declare readonly extraFilterTargets: HTMLElement[];

  /**
   * Toggles a hidden class on any element with a data-connect-targets="extra-filter" attribute
   */
  toggleExtraFilters(event: Event) {
    event.preventDefault();

    this.extraFilterTargets.forEach((filter) => {
      filter.classList.toggle("hidden");
    });
  }
}
