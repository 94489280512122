import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const MyCalmingBoxScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    likeToSmell: "",
    likeToSee: "",
    likeToFeel: "",
    likeToHave: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [likeToSmell, setLikeToSmell] = React.useState(
    initialState.likeToSmell
  );
  const [likeToSee, setLikeToSee] = React.useState(initialState.likeToSee);
  const [likeToFeel, setLikeToFeel] = React.useState(initialState.likeToFeel);
  const [likeToHave, setLikeToHave] = React.useState(initialState.likeToHave);

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      likeToSmell,
      likeToSee,
      likeToFeel,
      likeToHave,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>My Calming Box</EE.Heading>
          <EE.HR />
          <EE.Paragraph className="my-4">
            Big feelings can come on when we least expect it. Sometimes more
            than one big feeling can happen in a very short amount of time.
          </EE.Paragraph>
          <EE.Paragraph className="my-4">
            While talking about our feelings can help, sometimes we first need
            to remove ourselves from the situation causing our big feelings,
            take some deep breaths, and open our calming box to help us relax.
          </EE.Paragraph>
          <EE.Paragraph className="my-4">
            So what is a calming box, you might ask? A calming box is a
            container, like a shoe box, that holds a bunch of different objects,
            items, and/or snacks that help you calm down when you are feeling
            big feelings. A calming box can be: a shoe box, basket, backpack, or
            storage tub. It’s up to you! Make it your favorite colors, put
            stickers on it, glitter, paint, whatever you like!
          </EE.Paragraph>
          <EE.Paragraph className="my-4">
            Make sure to place it in a room or spot that you go to often when
            you are feeling big feelings, such as your bedroom or playroom.
          </EE.Paragraph>
          <EE.Paragraph className="my-4">
            Now let’s talk about what <i>you</i> would like to put in your
            calming box!
          </EE.Paragraph>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>Some things I like to smell, chew, or eat...</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLikeToSmell(e.target.value)
              }
              value={likeToSmell}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>
                Some things I like to look at, listen to, or read...
              </EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLikeToSee(e.target.value)
              }
              value={likeToSee}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>
                Some things I like to squeeze, squish, or hug...
              </EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLikeToFeel(e.target.value)
              }
              value={likeToFeel}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>In my calming box I would like to have...</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLikeToHave(e.target.value)
              }
              value={likeToHave}
            />
          </EE.QuestionLabel>

          <EE.Subheading>Caregivers...</EE.Subheading>

          <EE.Paragraph className="mt-4">
            The next time your child is experiencing a “big feeling” or strong
            emotion acknowledge what you’re observing in your child's physical
            state, so they feel seen (i.e. I see you’re crying, do you want a
            hug or should we pick something out of the calming box to help
            you?), and offer choices for self-soothing. Wait until your child’s
            energy level has become more regulated before asking additional
            questions on “what happened.”
          </EE.Paragraph>

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default MyCalmingBoxScreens;
