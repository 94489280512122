import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useStressManagementTools } from "./Context";
import InputWithText from "./InputWithText";

const Calm = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const {
    panicThoughts,
    panicPhysicalFeelings,
    panicBehaviors,
    worryThoughts,
    worryPhysicalFeelings,
    worryBehaviors,
    calmThoughts,
    calmPhysicalFeelings,
    calmBehaviors,
    setCalmThoughts,
    setCalmPhysicalFeelings,
    setCalmBehaviors,
    assignedExerciseId,
    completedExercise,
  } = useStressManagementTools();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      panicThoughts,
      panicPhysicalFeelings,
      panicBehaviors,
      worryThoughts,
      worryPhysicalFeelings,
      worryBehaviors,
      calmThoughts,
      calmPhysicalFeelings,
      calmBehaviors,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.SubmitableForm submitExercise={submitExercise}>
      <EE.StepIndicator numSteps={numSteps} currentStep={currentStep} />

      <p className="mt-6 mb-8">
        When you feel <EE.Bold>calm...</EE.Bold>
      </p>

      <InputWithText
        label={
          <>
            ...what <EE.Bold>thoughts</EE.Bold> do you have?
          </>
        }
        id="thoughts"
        value={calmThoughts}
        setValue={setCalmThoughts}
      />

      <InputWithText
        label={
          <>
            ...what <EE.Bold>physical feelings</EE.Bold> do you have?
          </>
        }
        id="physical_feelings"
        value={calmPhysicalFeelings}
        setValue={setCalmPhysicalFeelings}
      />

      <InputWithText
        label={
          <>
            ...what <EE.Bold>behaviors</EE.Bold> do you have?
          </>
        }
        id="behaviors"
        value={calmBehaviors}
        setValue={setCalmBehaviors}
      />

      <EE.ButtonGroup>
        <EE.ExerciseCallToActionLink to={nextPage}>
          Next
        </EE.ExerciseCallToActionLink>
      </EE.ButtonGroup>
    </EE.SubmitableForm>
  );
};

export default Calm;
