import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useGetToKnowYourself } from "./Context";

const Summary = () => {
  const {
    familyRelationships,
    friendsAndRelationships,
    fitness,
    diet,
    socialLife,
    senseOfBelonging,
    hobbiesSports,
    assignedExerciseId,
    completedExercise,
  } = useGetToKnowYourself();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      familyRelationships,
      friendsAndRelationships,
      fitness,
      diet,
      socialLife,
      senseOfBelonging,
      hobbiesSports,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  const textValues = [
    "",
    "Very dissatisfied",
    "Somewhat dissatisfied",
    "Neutral",
    "Somewhat satisfied",
    "Very satisfied",
  ];

  return (
    <EE.SubmitableForm submitExercise={submitExercise}>
      <div className="text-center">Here's what you selected:</div>

      <EE.FiveStarRatingResults
        title="family relationships"
        textValue={textValues[familyRelationships]}
        value={familyRelationships}
      />

      <EE.FiveStarRatingResults
        title="friends and relationships"
        textValue={textValues[friendsAndRelationships]}
        value={friendsAndRelationships}
      />

      <EE.FiveStarRatingResults
        title="fitness"
        textValue={textValues[fitness]}
        value={fitness}
      />

      <EE.FiveStarRatingResults
        title="diet"
        textValue={textValues[diet]}
        value={diet}
      />

      <EE.FiveStarRatingResults
        title="social life"
        textValue={textValues[socialLife]}
        value={socialLife}
      />

      <EE.FiveStarRatingResults
        title="sense of belonging"
        textValue={textValues[senseOfBelonging]}
        value={senseOfBelonging}
      />

      <EE.FiveStarRatingResults
        title="hobbies and/or sports"
        textValue={textValues[hobbiesSports]}
        value={hobbiesSports}
      />

      <EE.ButtonGroup>
        <EE.ResumableExerciseActions
          submitButtonText="I'm done"
          setExerciseComplete={setExerciseComplete}
        />
      </EE.ButtonGroup>
    </EE.SubmitableForm>
  );
};
export default Summary;
