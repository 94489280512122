import WheelOfLifeIcon from "@js/images/icons/WheelOfLife.svg";
import React from "react";
import { Route } from "react-router-dom";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { WheelOfLifeProvider } from "./Context";
import CurrentSelfWorth from "./CurrentSelfWorth";
import DoubleGraph from "./DoubleGraph";
import IdealSelfWorth from "./IdealSelfWorth";
import SingleGraph from "./SingleGraph";

const Screens: React.FC<ExerciseScreenProps> = (props) => {
  const title = "Wheel of Life";

  const intro = (
    <EE.GenericExerciseIntroPage
      imageSrc={WheelOfLifeIcon}
      imageAltText="A person in a wheel."
      title={title}
      introText="Where does your self-worth come from? Where would you like it to come from? Find out how to close that gap."
      nextPage="/current_self_worth"
    />
  );

  return (
    <WheelOfLifeProvider {...props}>
      <EE.WrapperWithIntro intro={intro}>
        <EE.ExerciseNavigation title={title} />

        <Route path="/current_self_worth">
          <CurrentSelfWorth nextPage="/graph1" />
        </Route>

        <Route path="/graph1">
          <SingleGraph nextPage="/ideal_self_worth" />
        </Route>

        <Route path="/ideal_self_worth">
          <IdealSelfWorth nextPage="/graph2" />
        </Route>

        <Route path="/graph2">
          <DoubleGraph nextPage="/" />
        </Route>
      </EE.WrapperWithIntro>
    </WheelOfLifeProvider>
  );
};

export default Screens;
