import SelfInventoryIcon from "@js/images/icons/Self-inventory.svg";
import React from "react";
import { Route } from "react-router-dom";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { MySelfInventoryProvider } from "./Context";
import SelfEsteem from "./SelfEsteem";
import StillGrowing from "./StillGrowing";
import Strengths from "./Strengths";
import Summary from "./Summary";
import Values from "./Values";

const Screens: React.FC<ExerciseScreenProps> = (props) => {
  const introText = (
    <div className="pt-8">
      Who are you at this very moment? What are you really good at? What do you
      wish you could change? Let's take stock of what makes you
      <span className="italic"> you</span>.
    </div>
  );
  const title = "My Self-Inventory";
  const numSteps = 4;
  let currentStep = 1;

  const intro = (
    <EE.GenericExerciseIntroPage
      imageSrc={SelfInventoryIcon}
      imageAltText="A star, a heart, and a person."
      title={title}
      introText={introText}
      nextPage="/strengths"
    />
  );

  return (
    <MySelfInventoryProvider {...props}>
      <EE.WrapperWithIntro intro={intro}>
        <EE.ExerciseNavigation title={title} />

        <Route path="/strengths">
          <Strengths
            numSteps={numSteps}
            currentStep={currentStep++}
            nextPage="/values"
          />
        </Route>

        <Route path="/values">
          <Values
            numSteps={numSteps}
            currentStep={currentStep++}
            nextPage="/self_esteem"
          />
        </Route>

        <Route path="/self_esteem">
          <SelfEsteem
            numSteps={numSteps}
            currentStep={currentStep++}
            nextPage="/still_growing"
          />
        </Route>

        <Route path="/still_growing">
          <StillGrowing
            numSteps={numSteps}
            currentStep={currentStep++}
            nextPage="/summary"
          />
        </Route>

        <Route path="/summary">
          <Summary />
        </Route>
      </EE.WrapperWithIntro>
    </MySelfInventoryProvider>
  );
};

export default Screens;
