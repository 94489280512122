import { Controller } from "@hotwired/stimulus";

type ActionEvent = Event & { params: { expandDefault?: boolean } };

export default class extends Controller {
  declare collapsedValue: boolean;
  declare readonly shownOnExpandTargets: HTMLElement[];
  declare readonly buttonTarget: HTMLElement;

  static values = {
    collapsed: {
      type: Boolean,
      default: false,
    },
  };

  static targets = ["button", "shownOnExpand"];

  toggle(event: ActionEvent) {
    if (this.collapsedValue) {
      this.expand(event);
    } else {
      this.collapse();
    }
  }

  expand(event: ActionEvent) {
    if (!this.collapsedValue) {
      return;
    }

    this.element.classList.remove("collapsed");
    this.collapsedValue = false;

    if (event.params.expandDefault) {
      $(this.shownOnExpandTargets).collapse("show");
    }
  }

  collapse() {
    if (this.collapsedValue) {
      return;
    }

    this.element.classList.add("collapsed");
    this.collapsedValue = true;
    $(this.element).find("[aria-expanded]").collapse("hide");
  }
}
