import clone from "lodash/clone";
import isEmpty from "lodash/isEmpty";
import pullAt from "lodash/pullAt";
import React from "react";

import { ExerciseElements as EE, Icons } from "../shared/Elements";
import { useThermometerImage } from "../shared/Hooks";
import FearThermometerElements from "../Thermometers/elements/FearThermometer";
import { useFearLadder } from "./Context";

interface Fear {
  name: string;
  value: number;
  graduatedAt: Date;
}

const defaultFear: Fear = { name: "", value: 5, graduatedAt: null };

const FearLadderHeader: React.FC = (props) => {
  return (
    <EE.RoundedExerciseCard className="pb-4">
      <EE.Title>Fear Ladder</EE.Title>
      <Icons.ExerciseFearLadderIcon className="block mx-auto mb-4" />
    </EE.RoundedExerciseCard>
  );
};

const FearSection: React.FC<{
  fear: Fear;
  index: number;
}> = (props) => {
  const { fear, index } = props;
  const { setFears, fears } = useFearLadder();

  const [expanded, setExpanded] = React.useState(false);
  const [editableName, setEditableName] = React.useState("");

  const { ThermometerImage } = useThermometerImage(
    FearThermometerElements.ImageMapping,
    fear.value
  );

  const onThermometerValueUpdate = (value) => {
    let graduatedAt = null;
    if (value === 0) {
      graduatedAt = new Date().toISOString();
    }
    const newFear: Fear = { ...fear, value, graduatedAt };
    const tmpFears = clone(fears);
    tmpFears[index] = newFear;
    setFears(tmpFears);
  };

  const onFearNameUpdate = (event) => {
    const newFear: Fear = { ...fear, name: editableName };
    const tmpFears = clone(fears);
    tmpFears[index] = newFear;
    setFears(tmpFears);
    event.preventDefault();
  };

  const removeNewFear = () => {
    const tmpFears = clone(fears);
    pullAt(tmpFears, index);
    setFears(tmpFears);
  };

  return (
    <div className="w-full my-2 rounded-2xl group">
      {isEmpty(fear.name) ? (
        <div className="p-4 md:p-8">
          <form onSubmit={onFearNameUpdate} className="flex justify-between">
            <EE.TextInput
              placeholder="New fear"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEditableName(e.target.value)
              }
              value={editableName}
            />
            <button className="px-5 ml-2 font-sans font-medium text-white rounded-full shadow-lg btn bg-green-700 md:ml-8">
              Add
            </button>
            <button
              onClick={removeNewFear}
              className="block ml-2 fill-current text-green-700 md:ml-8"
              aria-label="Remove Fear"
            >
              <span className="text-gray-700 material-icons">close</span>
            </button>
          </form>
        </div>
      ) : (
        <div className="p-4 md:p-8">
          <div
            className="flex justify-between cursor-pointer group"
            onClick={() => setExpanded((expanded) => !expanded)}
            onKeyUp={(event) =>
              event.key === "Enter" && setExpanded((expanded) => !expanded)
            }
            role="button"
            tabIndex={0}
          >
            <div className="flex justify-between flex-grow">
              <div className="self-center font-sans font-bold text-gray-700">
                <span>{fear.name}</span>
              </div>
              <ThermometerImage className="h-24" />
            </div>
            <div className="self-center font-sans text-gray-700">
              <div className="group-hover:opacity-50">
                {expanded ? (
                  <span className="text-gray-700 material-icons">
                    expand_more
                  </span>
                ) : (
                  <span className="text-gray-700 material-icons">
                    navigate_next
                  </span>
                )}
              </div>
            </div>
          </div>
          <div>
            {expanded && (
              <>
                <EE.ThermometerRating
                  thermometerValue={fear.value}
                  setThermometerValue={onThermometerValueUpdate}
                  elements={FearThermometerElements}
                  showThermometerImage={false}
                  id="fear-ladder-thermometer"
                />
                {fear.value === 0 && (
                  <span className="font-sans font-bold text-gray-700">
                    This fear has shrunk to 0! We can move it off the ladder!
                  </span>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export { Fear, defaultFear, FearSection, FearLadderHeader };
