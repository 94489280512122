import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const PersonalSafetyPlanScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    patientName: `${props.patientFirstName} ${props.patientLastName}`,
    date: new Date().toLocaleDateString("en-US"),
    warningSign1: "",
    warningSign2: "",
    warningSign3: "",
    internalCopingStrategy1: "",
    internalCopingStrategy2: "",
    internalCopingStrategy3: "",
    externalDistraction1: "",
    externalDistraction2: "",
    whyToFindHelp1: "",
    whyToFindHelp2: "",
    whoToAskForHelp1: "",
    whoToAskForHelp2: "",
    whoToAskForHelp3: "",
    mitigateRisk1: "",
    mitigateRisk2: "",
    mitigateRisk3: "",
    additionalDetails: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [patientName, setPatientName] = React.useState(
    initialState.patientName
  );
  const [date, setDate] = React.useState(initialState.date);
  const [warningSign1, setWarningSign1] = React.useState(
    initialState.warningSign1
  );
  const [warningSign2, setWarningSign2] = React.useState(
    initialState.warningSign2
  );
  const [warningSign3, setWarningSign3] = React.useState(
    initialState.warningSign3
  );
  const [internalCopingStrategy1, setInternalCopingStrategy1] = React.useState(
    initialState.internalCopingStrategy1
  );
  const [internalCopingStrategy2, setInternalCopingStrategy2] = React.useState(
    initialState.internalCopingStrategy2
  );
  const [internalCopingStrategy3, setInternalCopingStrategy3] = React.useState(
    initialState.internalCopingStrategy3
  );
  const [externalDistraction1, setExternalDistraction1] = React.useState(
    initialState.externalDistraction1
  );
  const [externalDistraction2, setExternalDistraction2] = React.useState(
    initialState.externalDistraction2
  );
  const [whyToFindHelp1, setWhyToFindHelp1] = React.useState(
    initialState.whyToFindHelp1
  );
  const [whyToFindHelp2, setWhyToFindHelp2] = React.useState(
    initialState.whyToFindHelp2
  );
  const [whoToAskForHelp1, setWhoToAskForHelp1] = React.useState(
    initialState.whoToAskForHelp1
  );
  const [whoToAskForHelp2, setWhoToAskForHelp2] = React.useState(
    initialState.whoToAskForHelp2
  );
  const [whoToAskForHelp3, setWhoToAskForHelp3] = React.useState(
    initialState.whoToAskForHelp3
  );
  const [mitigateRisk1, setMitigateRisk1] = React.useState(
    initialState.mitigateRisk1
  );
  const [mitigateRisk2, setMitigateRisk2] = React.useState(
    initialState.mitigateRisk2
  );
  const [mitigateRisk3, setMitigateRisk3] = React.useState(
    initialState.mitigateRisk3
  );
  const [additionalDetails, setAdditionalDetails] = React.useState(
    initialState.additionalDetails
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      patientName,
      date,
      warningSign1,
      warningSign2,
      warningSign3,
      internalCopingStrategy1,
      internalCopingStrategy2,
      internalCopingStrategy3,
      externalDistraction1,
      externalDistraction2,
      whyToFindHelp1,
      whyToFindHelp2,
      whoToAskForHelp1,
      whoToAskForHelp2,
      whoToAskForHelp3,
      mitigateRisk1,
      mitigateRisk2,
      mitigateRisk3,
      additionalDetails,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Personal Safety Plan</EE.Heading>
          <EE.HR />

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>Patient Name</EE.Bold>
            </EE.Paragraph>
            <EE.TextInput
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPatientName(e.target.value)
              }
              value={patientName}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>Date</EE.Bold>
            </EE.Paragraph>
            <EE.TextInput
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDate(e.target.value)
              }
              value={date}
            />
          </EE.QuestionLabel>

          <EE.Subheading className="text-green-700">
            Education and Professional Resources
          </EE.Subheading>

          <table className="w-full table-fixed mt-2 mb-8">
            <thead className="md:border md:border-green-700">
              <tr className="hidden h-12 font-sans text-lg font-medium text-left text-white bg-green-700 md:table-row">
                <th className="md:w-1/2 md:p-2 md:border-r md:border-grey-300">
                  Organization
                </th>
                <th className="md:w-1/2 md:p-2">Phone Number</th>
              </tr>
            </thead>
            <tbody>
              <tr className={`md:hover:bg-gray-100 py-10`}>
                <td className="block w-full py-4 align-top md:table-cell md:p-4 md:border md:w-auto md:border-green-700 font-bold">
                  National Suicide Prevention Lifeline
                </td>
                <td className="block w-full py-4 align-top md:table-cell md:p-4 md:border md:w-auto md:border-green-700">
                  988
                </td>
              </tr>
              <tr className={`md:hover:bg-gray-100 py-10`}>
                <td className="block w-full py-4 align-top md:table-cell md:p-4 md:border md:w-auto md:border-green-700 font-bold">
                  Crisis Text Line
                </td>
                <td className="block w-full py-4 align-top md:table-cell md:p-4 md:border md:w-auto md:border-green-700">
                  Text "HOME" to 741-741
                </td>
              </tr>
              <tr className={`md:hover:bg-gray-100 py-10`}>
                <td
                  className="block w-full colspan-2 py-4 align-top md:table-cell md:p-4 md:border md:border-green-700 text-orange-700 font-bold"
                  colSpan={2}
                >
                  You should always call 911 if you need immediate help to stay
                  safe
                </td>
              </tr>
            </tbody>
          </table>

          <EE.Subheading className="text-green-700">
            STEP 1: Know WHEN you need help
          </EE.Subheading>

          <EE.QuestionLabel>
            <EE.Paragraph className="mb-4 bg-green-300 p-4">
              What are the warning signs to know that I’m not doing well (e.g.,
              start to think about death/suicide, feel down/depressed)?
            </EE.Paragraph>
            <EE.TextArea
              id="warning-sign-1"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWarningSign1(e.target.value)
              }
              value={warningSign1}
            />
            <EE.TextArea
              id="warning-sign-2"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWarningSign2(e.target.value)
              }
              value={warningSign2}
            />
            <EE.TextArea
              id="warning-sign-3"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWarningSign3(e.target.value)
              }
              value={warningSign3}
            />
          </EE.QuestionLabel>

          <EE.Subheading className="text-green-700">
            STEP 2: Know HOW to help yourself (internal coping strategies)
          </EE.Subheading>

          <EE.QuestionLabel>
            <EE.Paragraph className="mb-4 bg-green-300 p-4">
              What things can I can do on my own to feel better?
            </EE.Paragraph>
            <EE.TextArea
              id="internal-coping-strategy-1"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInternalCopingStrategy1(e.target.value)
              }
              value={internalCopingStrategy1}
            />
            <EE.TextArea
              id="internal-coping-strategy-2"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInternalCopingStrategy2(e.target.value)
              }
              value={internalCopingStrategy2}
            />
            <EE.TextArea
              id="internal-coping-strategy-3"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInternalCopingStrategy3(e.target.value)
              }
              value={internalCopingStrategy3}
            />
          </EE.QuestionLabel>

          <EE.Subheading className="text-green-700">
            STEP 3: Know HOW to help yourself (external distractions)
          </EE.Subheading>

          <EE.QuestionLabel>
            <EE.Paragraph className="mb-4 bg-green-300 p-4">
              What people or places can take my mind off of my problems—at least
              for a little bit?
            </EE.Paragraph>
            <EE.TextArea
              id="external-distraction-1"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setExternalDistraction1(e.target.value)
              }
              value={externalDistraction1}
            />
            <EE.TextArea
              id="external-distraction-2"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setExternalDistraction2(e.target.value)
              }
              value={externalDistraction2}
            />
          </EE.QuestionLabel>

          <EE.Subheading className="text-green-700">
            STEP 4: Know WHY you are finding help
          </EE.Subheading>

          <EE.QuestionLabel>
            <EE.Paragraph className="mb-4 bg-green-300 p-4">
              List two things that are very important to me and worth living
              for:
            </EE.Paragraph>
            <EE.TextArea
              id="why-to-find-help-1"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWhyToFindHelp1(e.target.value)
              }
              value={whyToFindHelp1}
            />
            <EE.TextArea
              id="why-to-find-help-2"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWhyToFindHelp2(e.target.value)
              }
              value={whyToFindHelp2}
            />
          </EE.QuestionLabel>

          <EE.Subheading className="text-green-700">
            STEP 5: Know WHO to ask for help
          </EE.Subheading>

          <EE.QuestionLabel>
            <EE.Paragraph className="mb-4 bg-green-300 p-4">
              Who can I contact who will help me during a crisis? (Must be above
              the age of 21 years old)
            </EE.Paragraph>
            <EE.TextArea
              id="who-to-ask-for-help-1"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWhoToAskForHelp1(e.target.value)
              }
              value={whoToAskForHelp1}
            />
            <EE.TextArea
              id="who-to-ask-for-help-2"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWhoToAskForHelp2(e.target.value)
              }
              value={whoToAskForHelp2}
            />
            <EE.TextArea
              id="who-to-ask-for-help-3"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWhoToAskForHelp3(e.target.value)
              }
              value={whoToAskForHelp3}
            />
          </EE.QuestionLabel>

          <EE.Subheading className="text-green-700">
            STEP 6: Mitigate Risk to lethal means
          </EE.Subheading>

          <EE.QuestionLabel>
            <EE.Paragraph className="mb-4 bg-green-300 p-4">
              How can you limit your access to lethal means and keep yourself
              safe during a crisis at home, school, or in the community?
            </EE.Paragraph>
            <EE.TextArea
              id="mitigate-risk-1"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setMitigateRisk1(e.target.value)
              }
              value={mitigateRisk1}
            />
            <EE.TextArea
              id="mitigate-risk-2"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setMitigateRisk2(e.target.value)
              }
              value={mitigateRisk2}
            />
            <EE.TextArea
              id="mitigate-risk-3"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setMitigateRisk3(e.target.value)
              }
              value={mitigateRisk3}
            />
          </EE.QuestionLabel>

          <EE.Subheading className="text-green-700">
            Additional Details
          </EE.Subheading>

          <EE.QuestionLabel>
            <EE.Paragraph className="mb-4 bg-green-300 p-4">
              Are there any other steps you can take to help maintain your
              safety?
            </EE.Paragraph>
            <EE.TextArea
              id="additional-details"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAdditionalDetails(e.target.value)
              }
              value={additionalDetails}
            />
          </EE.QuestionLabel>

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
            showSaveForLaterButton={true}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default PersonalSafetyPlanScreens;
