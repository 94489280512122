import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import React from "react";

import { ExerciseScreenProps } from "../shared/Elements";
import { Fear, defaultFear } from "./Shared";

const FearLadderContext = React.createContext(undefined);

const FearLadderProvider: React.FC<ExerciseScreenProps> = (props) => {
  const {
    assignedExerciseId,
    patientFirstName,
    careLink,
    chatLink,
    completedExercise,
    readOnly,
    additionalData = {},
  } = props;

  const previousFears = additionalData?.fears;
  let graduatedFears = [];

  const defaultState = {
    fears: [defaultFear],
    completedBy: "",
    thoughts: "",
  };

  if (previousFears) {
    if (!isEmpty(previousFears.inProgress)) {
      const previousInProgressFears = previousFears.inProgress.map((fear) => {
        return {
          ...pick(defaultFear, ["value"]),
          ...pick(fear, ["name", "graduatedAt"]),
        };
      });
      defaultState.fears = previousInProgressFears;
    }

    graduatedFears = previousFears.graduated.map((fear) => {
      return pick(fear, ["name", "value", "graduatedAt"]);
    });
  }

  const initialState = {
    ...defaultState,
    ...previousFears,
    ...props.completedExercise?.data,
  };
  const [fears, setFears] = React.useState<Fear[]>(initialState.fears);
  const [completedBy, setCompletedBy] = React.useState(
    initialState.completedBy
  );
  const [thoughts, setThoughts] = React.useState<string>(initialState.thoughts);

  // TODO: Pass in the clinician's first name (if we do indeed have that data)
  const clinicianFirstName = "your therapist";

  const value = {
    assignedExerciseId,
    careLink,
    chatLink,
    clinicianFirstName,
    completedBy,
    completedExercise,
    fears,
    graduatedFears,
    patientFirstName,
    readOnly,
    setCompletedBy,
    setFears,
    setThoughts,
    thoughts,
  };

  return <FearLadderContext.Provider value={value} {...props} />;
};

function useFearLadder() {
  const context = React.useContext(FearLadderContext);
  if (!context) {
    throw new Error("useFearLadder must be used within a FearLadderProvider");
  }
  return context;
}

export { FearLadderProvider, FearLadderContext, useFearLadder };
