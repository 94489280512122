import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useWheelOfLife } from "./Context";
import SelfWorthGraph from "./SelfWorthGraph";

const SingleGraph = (props: { nextPage: string }) => {
  const { nextPage } = props;

  const { currentSources, assignedExerciseId, completedExercise } =
    useWheelOfLife();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      currentSources,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.SubmitableForm submitExercise={submitExercise}>
      <SelfWorthGraph
        headerText="You currently get your self-worth from:"
        sources={currentSources}
      />

      <EE.ButtonGroup>
        <EE.ExerciseCallToActionLink to={nextPage}>
          Next
        </EE.ExerciseCallToActionLink>
      </EE.ButtonGroup>
    </EE.SubmitableForm>
  );
};

export default SingleGraph;
