import Image from "@js/components/shared/Image";
import FeelingImages from "@js/images/feelings/";
import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useMyFeelingsScale } from "./Context";

const MyFeelingsScale = () => {
  const { feeling, setFeeling, careLink } = useMyFeelingsScale();

  const Feeling: React.FC<{ feelingName: string }> = ({ feelingName }) => {
    const borderColor = feeling == feelingName ? "gray-700" : "transparent";
    const hoverClasses = "hover:bg-almond hover:bg-opacity-100";

    return (
      <div
        className={`border border-${borderColor} cursor-pointer ${hoverClasses} rounded-lg`}
        onClick={() => setFeeling(feelingName)}
        onKeyUp={(event) => event.key === "Enter" && setFeeling(feelingName)}
        role="button"
        tabIndex={0}
      >
        <div className="mx-4 my-2">
          <Image
            data-testid="feeling-scale-img"
            src={FeelingImages[feelingName]}
          />
        </div>
        <p className="capitalize mt-0.5 mb-1 text-center text-xs sm:text-base">
          {feelingName}
        </p>
      </div>
    );
  };

  return (
    <>
      <div className="grid">
        <EE.Heading>How are you feeling today?</EE.Heading>
        <div className="w-full mt-8 place-self-center">
          <div className="grid grid-flow-row grid-cols-4 grid-rows-4 gap-x-1 gap-y-5">
            {Object.keys(FeelingImages).map((f) => (
              <Feeling feelingName={f} key={f} />
            ))}
          </div>

          <EE.LinkGroup>
            <EE.ExerciseCallToActionLink
              disabled={!feeling}
              to="/feeling_strength"
            >
              Next
            </EE.ExerciseCallToActionLink>
            <EE.SecondaryActionLink text="Cancel" href={careLink} />
            <hr className="mt-10 mb-6" />
          </EE.LinkGroup>
        </div>
      </div>
    </>
  );
};

export default MyFeelingsScale;
