// Populates an input from a select element
//
import { Controller } from "@hotwired/stimulus";

export default class AutopopulatableController extends Controller {
  declare readonly inputTarget: HTMLElement;
  declare readonly populatorTarget: HTMLSelectElement;
  declare readonly defaultsValue: { [key: string]: string };

  static targets = ["input", "populator"];
  static values = { defaults: Object };

  populate() {
    const populatorValue =
      this.populatorTarget.options[this.populatorTarget.selectedIndex].value;

    this.inputTarget.innerHTML = this.defaultsValue[populatorValue] || "";
  }
}
