// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@js/helpers/stimulus";

const application = Application.start();
const context = require.context("controllers", true, /_controller\.j|ts$/);
// load component-specific stimulus controllers in sidecar
const contextComponents = require.context(
  "../../components",
  true,
  /_controller\.j|ts$/
);
application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  )
);
