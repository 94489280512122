/*
Purpose: This controller ties the values of a group of multiselect checkboxes
to the visibility of other dom elements. It checks the state of the checkboxes
to ensure the visibility is set correctly on load and then will toggle as the
user interacts with each checkbox.

Targets:
- block: dom elements that you wish to control the visibilitity of
- state: the checkboxes where you wish to control the visibility of the dom elements

Attributes:
- data-name: This is what binds an individual checkbox to the dom elements you
  want to control. Thus a single checkbox can control the visibility of
  multiple block elements that are of the same data-name to the checkbox value.
- data-inverse-visbility: This inverts the visibility to the state of the checkboxes
- data-multiselect-checkbox-toggle-limit-value: A stimulus style value (limitValue) that
  allows you to limit how many of the checkboxes can be checked.

Example:
<div data-controller="multiselect-checkbox-toggle">
  <form>
    <label>
      <input class="hidden" data-action="multiselect-checkbox-toggle#toggle" data-multiselect-checkbox-toggle-target="state" type="checkbox" value="authenticity"> Authenticity
    </label>
    <label>
      <input class="hidden" data-action="multiselect-checkbox-toggle#toggle" data-multiselect-checkbox-toggle-target="state" type="checkbox" value="peace"> Peace
    </label>
  </form>

  <div class="hidden" data-multiselect-checkbox-toggle-target="block" data-name="authenticity">
    Stuff in one section where we want to toggle visibility for authenticity.
  </div>
  <div class="hidden" data-multiselect-checkbox-toggle-target="block" data-name="authenticity">
    Stuff in another section where we want to toggle visibility for authenticity.
  </div>

  <div class="hidden" data-multiselect-checkbox-toggle-target="block" data-name="peace">
    Stuff in one section where we want to toggle visibility for peace.
  </div>
  <div class="hidden" data-multiselect-checkbox-toggle-target="block" data-name="peace">
    Stuff in another section where we want to toggle visibility for peace.
  </div>
</div>
 */

import { Controller } from "@hotwired/stimulus";
import Brightline from "@js/helpers/dom";

export default class extends Controller {
  declare readonly blockTargets: HTMLElement[];
  declare readonly stateTargets: HTMLInputElement[];
  declare limitValue: number;

  static get values() {
    return {
      limit: {
        type: Number,
        default: 0,
      },
    };
  }

  static get targets() {
    return ["block", "state"];
  }

  connect() {
    this.stateTargets.forEach((element, index) => {
      this.updateVisibility(
        element.dataset.name || element.value,
        element.checked
      );
    });
  }

  countOfCheckedStateTargets(): number {
    var count = 0;
    this.stateTargets.forEach((element, index) => {
      if (element.checked) {
        count++;
      }
    });
    return count;
  }

  hitCheckedLimit() {
    return (
      this.limitValue > 0 && this.countOfCheckedStateTargets() > this.limitValue
    );
  }

  toggle(event: any) {
    if (this.hitCheckedLimit()) {
      event.target.checked = false;
    } else {
      this.updateVisibility(
        event.target.dataset.name || event.target.value,
        event.target.checked
      );
    }
  }

  updateVisibility(name: string, visible: boolean = true) {
    this.blockTargets.forEach((element, index) => {
      if (element.dataset.name === name) {
        if (visible) {
          if (
            element.dataset.inverseVisibility === "false" ||
            element.dataset.inverseVisibility === undefined
          ) {
            element.classList.remove("hidden");
          } else {
            element.classList.add("hidden");
          }
        } else {
          if (
            element.dataset.inverseVisibility === "false" ||
            element.dataset.inverseVisibility === undefined
          ) {
            element.classList.add("hidden");
          } else {
            element.classList.remove("hidden");
          }
        }
      }
    });
  }
}
