import { Controller } from "@hotwired/stimulus";
import { LiveRegionAnnouncer } from "@js/models/LiveRegionAnnouncer";

export default class extends Controller {
  static targets = ["content", "loading"];
  declare readonly contentTarget: HTMLElement;
  declare readonly loadingTarget: HTMLElement;
  liveRegionAnnouncer = new LiveRegionAnnouncer();

  connect(): void {
    this.liveRegionAnnouncer.ensureLiveRegionInDocument();
    this.announceContentToScreenReader();
    this.dispatch("connect");
  }

  showLoading(event: StimulusEvent): void {
    const hiddenClass = event.params.hiddenClass || "hidden";
    // TODO: Remove 'd-none' once everything is on Tailwind
    this.contentTarget.classList.add(hiddenClass, "d-none");
    this.loadingTarget.classList.remove(hiddenClass, "d-none");
    this.liveRegionAnnouncer.announce(
      this.loadingTarget.innerText || "Loading..."
    );
  }

  hideLoading(event: StimulusEvent): void {
    const hiddenClass = event.params.hiddenClass || "hidden";
    // TODO: Remove 'd-none' once everything is on Tailwind
    this.contentTarget.classList.remove(hiddenClass, "d-none");
    this.loadingTarget.classList.add(hiddenClass, "d-none");
    this.announceContentToScreenReader();
  }

  announceContentToScreenReader() {
    this.liveRegionAnnouncer.announce(this.contentTarget.innerText);
  }
}

interface StimulusEvent extends Event {
  params: Record<any, any>;
}
