import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useFixedVsGrowthMindsets } from "./Context";

const Practice = (props: { currentStep: number; numSteps: number }) => {
  const { numSteps, currentStep } = props;
  const {
    dontThink,
    setDontThink,
    doThink,
    setDoThink,
    assignedExerciseId,
    completedExercise,
  } = useFixedVsGrowthMindsets();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      dontThink,
      doThink,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.SubmitableForm submitExercise={submitExercise}>
      <EE.StepIndicator numSteps={numSteps} currentStep={currentStep} />

      <p className="pb-4 text-lg">Now you try it:</p>

      <div className="flex flex-col items-center px-4 py-6 bg-blue-300 rounded-2xl">
        <p className="font-bold px-4 py-0 text-xs pt-0.5 bg-white rounded-full">
          Fixed mindset
        </p>
        <label className="py-4 text-sm block" htmlFor="instead_of_thinking">
          Instead of thinking...
        </label>
        <EE.TextArea
          className="z-10 w-full h-16 border border-gray-400 rounded-md resize-none md:h-12"
          id="instead_of_thinking"
          value={dontThink}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setDontThink(e.target.value);
          }}
        ></EE.TextArea>
      </div>

      <div className="z-0 w-full mx-auto mt-1 text-5xl text-center text-gray-400 transform rotate-90 material-icons">
        forward
      </div>

      <div className="flex flex-col items-center px-4 py-6 bg-yellow-300 rounded-2xl">
        <p className="font-bold px-4 py-0 text-xs pt-0.5 bg-white rounded-full">
          Growth mindset
        </p>
        <label className="py-4 text-sm block" htmlFor="think_this">
          Think this...
        </label>
        <EE.TextArea
          className="z-10 w-full h-16 border border-gray-400 rounded-md resize-none md:h-12"
          id="think_this"
          value={doThink}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setDoThink(e.target.value);
          }}
        ></EE.TextArea>
      </div>

      <EE.ButtonGroup>
        <EE.ResumableExerciseActions
          submitButtonText="I'm done"
          setExerciseComplete={setExerciseComplete}
        />
      </EE.ButtonGroup>
    </EE.SubmitableForm>
  );
};

export default Practice;
