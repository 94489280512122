import React from "react";
import { Route } from "react-router-dom";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { BodyMapProvider } from "./Context";
import Exercise from "./Exercise";
import Introduction from "./Introduction";
import PostExercise from "./PostExercise";
import PreExercise from "./PreExercise";

const Screens = (props: ExerciseScreenProps) => {
  return (
    <BodyMapProvider {...props}>
      <EE.Wrapper>
        <Route exact path="/">
          <Introduction />
        </Route>
        <Route path="/pre-exercise">
          <PreExercise />
        </Route>
        <Route path="/exercise">
          <Exercise />
        </Route>
        <Route path="/post-exercise">
          <PostExercise />
        </Route>
      </EE.Wrapper>
    </BodyMapProvider>
  );
};

export default Screens;
