import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  declare readonly blockTargets: HTMLElement[];
  declare readonly stateTarget: HTMLInputElement;
  declare readonly stateTargets: HTMLInputElement[];
  declare readonly stateToBlockMappingValue: Object;

  static values = {
    stateToBlockMapping: Object,
  };

  static targets = ["block", "state"];

  connect() {
    this.startHidden();
  }

  updateBlockVisibility() {
    if (
      this.stateTargets.length === 1 &&
      !this.shouldUseStateToBlockMapping()
    ) {
      const state = this.stateTargets[0];

      this.blockTargets.forEach((blockTarget) => {
        this.toggleBlockVisibility(state, blockTarget);
      });
    }

    // We can likely do away with StateToBlockMapping by adding a param that can point to the correct block and being specific with the mapping in the markup
    if (this.shouldUseStateToBlockMapping()) {
      for (const state in this.stateToBlockMappingValue) {
        const blocksForState = this.blockTargets.filter((el) =>
          this.stateToBlockMappingValue[state].includes(el.dataset.checkboxId)
        );

        this.stateTargets.forEach((stateElement) => {
          if (stateElement.value === state) {
            blocksForState.forEach((blockTarget) => {
              this.toggleBlockVisibility(stateElement, blockTarget);
            });
          }
        });
      }
    }
  }

  shouldUseStateToBlockMapping() {
    return Object.keys(this.stateToBlockMappingValue).length > 0;
  }

  startHidden() {
    if (
      this.stateTargets.length === 1 &&
      !this.shouldUseStateToBlockMapping()
    ) {
      const startHidden = !this.stateTargets[0].checked;

      if (startHidden) {
        this.blockTargets.forEach(this.hideBlock);
      }
    }

    if (this.shouldUseStateToBlockMapping()) {
      for (const state in this.stateToBlockMappingValue) {
        const blocksForState = this.blockTargets.filter((el) =>
          this.stateToBlockMappingValue[state].includes(el.dataset.checkboxId)
        );
        blocksForState.forEach(this.hideBlock);
      }
    }
  }

  toggleBlockVisibility(state, block) {
    if (state.checked) {
      this.showBlock(block);
      if (state.value == "other") {
        this.addRequiredAttribute(block);
      }
    } else {
      this.hideBlock(block);
      if (state.value == "other") {
        this.removeRequiredAttribute(block);
      }
    }
  }

  hideBlock(block) {
    block.classList.add("hidden");
  }

  showBlock(block) {
    block.classList.remove("hidden");
  }

  addRequiredAttribute(block) {
    block.setAttribute("required", "true");
  }

  removeRequiredAttribute(block) {
    block.removeAttribute("required");
  }
}
