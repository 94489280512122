import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // scrollTarget is what element is scrolled into view
  // focusTarget is what element is given focus after scrolling
  static targets = ["scroll", "focus", "top"];

  static values = {
    behavior: { type: String, default: "auto" },
  };

  scroll(event) {
    if (this.hasFocusTarget) {
      this.focusTarget.focus({ preventScroll: true });
    }
    this.scrollTarget.scrollIntoView({ behavior: this.behaviorValue });
  }

  scrollToTop(event) {
    this.topTarget.focus({ preventScroll: true });
    this.topTarget.scrollIntoView({ behavior: this.behaviorValue });
  }
}
