import { ApolloError, useMutation } from "@apollo/client";
import Image from "@js/components/shared/Image";
import { SAVE_EXERCISE } from "@js/graphql/queries";
import { SaveExerciseInput } from "@js/graphql/types";
import { useMotionValue, useTransform } from "framer-motion";
import React from "react";

import { useExerciseForm } from "./Context";

const useThermometerColor = (thermometerValue: number) => {
  const x = useMotionValue(0);
  const input = [0, 2, 4, 5, 6, 8, 10];
  const output = [
    "#573978",
    "#8E7D9B",
    "#A0AFC7",
    "#D8D8D8",
    "#B0CEFA",
    "#9EDCD0",
    "#F6AF5B",
  ];

  const opacity = [0.6, 0.8, 0.8, 0.6, 0.6, 0.6, 0.8];

  const thermometerColor = useTransform(x, input, output);
  const thermometerOpacity = useTransform(x, input, opacity);

  React.useEffect(() => {
    x.set(thermometerValue);
  }, [thermometerValue]);

  return { thermometerColor, thermometerOpacity };
};

const useThermometerImage = (
  thermometerImageMapping: any[],
  thermometerValue: number,
  minValue = 0
) => {
  const [thermometerImage, setThermometerImage] = React.useState<string>();
  const thermometerImageIndex = thermometerValue - minValue;

  React.useEffect(() => {
    setThermometerImage(thermometerImageMapping[thermometerImageIndex]);
  }, [thermometerValue]);

  // These images are loaded on the fly. At some point we should consider preloading them.
  const ThermometerImage: React.FC<{ className?: string }> = ({
    className,
  }): JSX.Element => {
    return <Image src={thermometerImage} className={`pt-2 ${className}`} />;
  };

  return { thermometerImage, ThermometerImage };
};

const useSubmitExercise = (
  data: any,
  assignedExerciseId: string,
  completedExerciseId?: number
) => {
  const [exerciseComplete, setExerciseComplete] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);

  const { careLink } = useExerciseForm();
  const [saveExercise] = useMutation(SAVE_EXERCISE, {
    onCompleted({ saveExercise: { errors } }): void {
      setIsSaving(false);
      if (errors.length) {
        // TODO: Terrible. Horrible. No good. Very bad.
        // But this might save clinicians a bit of pain until
        // we come up with a better solution.
        alert(
          "There was an error saving this exercise. Please make sure all fields are filled in and try again."
        );
      } else {
        window.location.href = careLink;
      }
    },
    onError(error: ApolloError): void {
      setIsSaving(false);
      console.error(
        "There was an error submitting the exercise!",
        error.name,
        error.graphQLErrors,
        error.message
      );
    },
  });

  const exerciseInput: SaveExerciseInput = {
    data: JSON.stringify(data),
    completed: exerciseComplete,
    assignedExerciseId,
    completedExerciseId,
  };

  const onSubmit = async () => {
    setIsSaving(true);
    saveExercise({
      variables: { input: exerciseInput },
    });
  };

  const submitExercise = (e: React.ChangeEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return { submitExercise, setExerciseComplete, isSaving };
};

export { useThermometerImage, useThermometerColor, useSubmitExercise };
