import React from "react";

const Mo = (): JSX.Element => {
  return (
    <>
      <defs>
        <path
          d="M116.764055,97.1352853 C117.998686,108.629088 118.645689,119.595698 118.984748,129 L0.0344147476,129 C0.307282214,121.431621 0.779595196,112.851384 1.59713331,103.798823 C1.06446208,104.333039 0.731579264,104.533431 0.598484848,104.4 C-4.99600361e-16,103.8 2.99242424,93.6 2.39393939,94.2 C1.79545455,94.8 1.05263021e-14,99 0.598484848,94.8 C0.970065935,92.1923474 2.37981243,86.6357794 4.82772433,78.130296 C5.16705241,76.0883566 5.52866651,74.0474517 5.9140303,72.0129983 C5.91050851,72.0085542 5.90692915,72.0042214 5.90329221,72 C5.38636364,71.4 3.59090909,74.4 2.99242424,74.4 C2.63531846,74.4 4.67535113,68.7925166 9.11252225,57.5775499 C13.1260212,41.9802088 18.8438316,27.5977289 26.9896807,17.1086386 C25.9942278,17.3132155 25.0815433,17.6198365 25.1363636,17.4 C25.2451285,16.9638391 26.3333333,16.8 28.1287879,15 C28.8486738,14.2782916 29.6647723,13.6530393 30.3456253,13.1629171 C30.708071,12.7751703 31.074798,12.3946287 31.4458567,12.0214792 C30.6052778,12.1232343 28.7968149,12.5302818 29.3257576,12 C29.887839,11.4364955 31.5057052,11.4022199 33.6835732,9.90902113 C40.7314402,3.67612092 49.2392958,0 59.5095813,0 C80.7738379,0 94.4828721,15.758873 103.321066,37.3416685 C109.151438,49.6472228 111.876861,55.8 111.497333,55.8 C110.898848,55.8 109.620322,53.4 109.103394,54 C109.060155,54.0501874 109.031131,54.1303901 109.016321,54.2406083 C109.588813,56.3105747 110.129613,58.4059309 110.640475,60.520184 C110.685518,60.6514917 110.731491,60.7847637 110.778394,60.92 C113.651626,70.7066667 115.287737,77 115.686727,79.8 C116.258095,83.8097021 115.193023,81.2570732 114.574571,80.4954442 C114.828141,82.1017441 115.068269,83.7073899 115.295668,85.3097445 C116.324955,90.3251887 117.860587,97.4206372 117.482182,97.8 C117.397475,97.8849211 117.158099,97.6633495 116.764055,97.1352853 L116.764055,97.1352853 Z"
          id="path-1"
        ></path>
        <path
          d="M2.01298951,13.8415742 C-0.0259453325,12.6614144 0.0095352299,8.77297056 2.09223757,5.15649177 C4.1749399,1.54001298 7.51618701,-0.435013799 9.55512186,0.745145984 C10.7358307,1.42855436 11.5614682,4.29235523 14.0283075,7.74926298 C15.8213876,10.2619975 19.9013951,13.2156969 18.702323,15.2519136 C17.5433813,17.2199825 12.6052094,14.7178253 9.74284735,14.3357577 C5.56946659,13.7786955 3.22242137,14.5416078 2.01298951,13.8415742 Z M115.243545,13.5358118 C114.034114,14.2358453 111.687068,13.4729331 107.513688,14.0299953 C104.651326,14.4120629 99.7131537,16.9142201 98.5542119,14.9461511 C97.3551398,12.9099344 101.435147,9.95623503 103.228227,7.44350055 C105.695067,3.9865928 106.520704,1.12279193 107.701413,0.439383556 C109.740348,-0.740776228 113.081595,1.23425056 115.164297,4.85072934 C117.247,8.46720813 117.28248,12.355652 115.243545,13.5358118 Z"
          id="path-3"
        ></path>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="What-happened-1" transform="translate(-108.000000, -195.000000)">
          <g
            id="Body-Placeholder"
            transform="translate(108.000000, 195.000000)"
          >
            <g id="Body" transform="translate(19.750000, 39.000000)">
              <mask id="mask-2" fill="white">
                <use href="#path-1"></use>
              </mask>
              <use fill="#9EDCD0" href="#path-1"></use>
              <g id="↳Color" opacity="0.7" mask="url(#mask-2)" fill="#9EDCD0">
                <g transform="translate(-19.750000, -39.000000)" id="Color">
                  <rect x="0" y="0" width="158" height="168"></rect>
                </g>
              </g>
            </g>
            <g id="Ears" transform="translate(20.946970, 56.400000)">
              <mask id="mask-4" fill="white">
                <use href="#path-3"></use>
              </mask>
              <use fill="#9EDCD0" href="#path-3"></use>
              <path
                d="M2.60020612,11.7548938 C1.34547698,11.0286417 1.57623914,8.27296314 3.11562783,5.5999136 C4.65501651,2.92686406 6.92009654,1.34867298 8.17482568,2.07492515 C9.42955481,2.80117733 9.19879265,5.55685584 7.65940397,8.22990538 C6.12001529,10.9029549 3.85493525,12.481146 2.60020612,11.7548938 Z"
                id="Oval-2-Copy"
                fillOpacity="0.2"
                fill="#000000"
                mask="url(#mask-4)"
              ></path>
              <path
                d="M114.380718,11.4951893 C113.125989,12.2214415 110.860909,10.6432504 109.32152,7.97020086 C107.782132,5.29715132 107.551369,2.54147281 108.806099,1.81522064 C110.060828,1.08896846 112.325908,2.66715955 113.865296,5.34020909 C115.404685,8.01325863 115.635447,10.7689371 114.380718,11.4951893 Z"
                id="Oval-2-Copy-2"
                fillOpacity="0.2"
                fill="#000000"
                mask="url(#mask-4)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </>
  );
};

export default Mo;
