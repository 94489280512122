import { Controller } from "@hotwired/stimulus";

const disabledTextClasses = ["text-gray-500", "bg-gray-100"];
const enabledTextClasses = ["text-blue-700", "bg-blue-100"];

export default class extends Controller {
  static targets = [
    "form",
    "childrenCount",
    "childrenCountDisplay",
    "ageSelectLabel",
    "childrenAge",
    "childrenAgeSelect",
    "decrementButton",
    "requiredField",
  ];

  connect() {
    this.childrenCountDisplayTarget.textContent = this.numChildren();
    this.toggleDecrement();
  }

  incrementChild(e) {
    e.preventDefault();
    this.incrementNumChildren();
    this.displayAgeSelectLabel();
    this.addAgeSelect();
    this.enableSubmit();
  }

  decrementChild(e) {
    e.preventDefault();
    this.decrementNumChildren();
    this.removeAgeSelect();
    this.enableSubmit();
  }

  numChildren() {
    return parseInt(this.formTarget.dataset["numChildren"] || "0");
  }

  incrementNumChildren() {
    this.formTarget.dataset["numChildren"] = `${this.numChildren() + 1}`;
    this.setChildrenCount();
    this.toggleDecrement();
  }

  decrementNumChildren() {
    this.formTarget.dataset["numChildren"] = `${this.numChildren() - 1}`;
    this.setChildrenCount();
    this.toggleDecrement();
  }

  setChildrenCount() {
    this.childrenCountTarget.value = this.numChildren();
    this.childrenCountDisplayTarget.textContent = this.numChildren();
  }

  displayAgeSelectLabel() {
    if (this.ageSelectLabelTarget.classList.contains("hidden")) {
      this.ageSelectLabelTarget.classList.remove("hidden");
    }
  }

  addAgeSelect() {
    const newAgeSelect = this.childrenAgeSelectTarget.content
      .cloneNode(true)
      .querySelector("select");
    const labelString = newAgeSelect.options[0].text;
    newAgeSelect.options[0] = new Option(
      labelString.replace(1, this.numChildren()),
      ""
    );
    newAgeSelect.options[0].selected = true;
    newAgeSelect.classList.add("mt-2");
    this.childrenAgeTarget.appendChild(newAgeSelect);
  }

  removeAgeSelect() {
    this.childrenAgeTarget.removeChild(this.childrenAgeTarget.lastElementChild);
  }

  enableSubmit() {
    const valid = (element) =>
      element !== null &&
      !(
        element.value === "" ||
        element.value === "0" ||
        (element.type === "checkbox" && !element.checked)
      );
    if (this.requiredFieldTargets.every(valid)) {
      document.getElementById("primary-button").classList.remove("hidden");
      document.getElementById("disabled-button").classList.add("hidden");
    } else {
      document.getElementById("primary-button").classList.add("hidden");
      document.getElementById("disabled-button").classList.remove("hidden");
    }
  }

  toggleDecrement() {
    if (this.numChildren() > 1) {
      this.decrementButtonTarget.disabled = false;
      this.decrementButtonTarget.classList.remove(...disabledTextClasses);
      this.decrementButtonTarget.classList.add(...enabledTextClasses);
    } else if (this.numChildren() < 2) {
      this.decrementButtonTarget.disabled = true;
      this.decrementButtonTarget.classList.add(...disabledTextClasses);
      this.decrementButtonTarget.classList.remove(...enabledTextClasses);
    }
  }
}
