import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useWhoIAm } from "./Context";

const Lived = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const {
    name,
    birthday,
    graduatingClass,
    ethnicity,
    placesIHaveLived1,
    placesIHaveLived2,
    placesIHaveLived3,
    placesIHaveLived4,
    placesIHaveLived5,
    placesIHaveLived6,
    supportSystem,
    hobbies1,
    hobbies2,
    hobbies3,
    hobbies4,
    hobbies5,
    hobbies6,
    setHobbies1,
    setHobbies2,
    setHobbies3,
    setHobbies4,
    setHobbies5,
    setHobbies6,
    assignedExerciseId,
    completedExercise,
  } = useWhoIAm();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      name,
      birthday,
      graduatingClass,
      ethnicity,
      placesIHaveLived1,
      placesIHaveLived2,
      placesIHaveLived3,
      placesIHaveLived4,
      placesIHaveLived5,
      placesIHaveLived6,
      supportSystem,
      hobbies1,
      hobbies2,
      hobbies3,
      hobbies4,
      hobbies5,
      hobbies6,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <>
      <EE.StepIndicator numSteps={numSteps} currentStep={currentStep} />
      <EE.SubmitableForm submitExercise={submitExercise}>
        <EE.Subheading id="hobbies-label">
          My hobbies/sports/extra-curricular:
        </EE.Subheading>
        <EE.TextInput
          aria-labelledby="hobbies-label"
          className="mb-4"
          value={hobbies1}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setHobbies1(e.target.value)
          }
        />
        <EE.TextInput
          aria-labelledby="hobbies-label"
          className="mb-4"
          value={hobbies2}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setHobbies2(e.target.value)
          }
        />
        <EE.TextInput
          aria-labelledby="hobbies-label"
          className="mb-4"
          value={hobbies3}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setHobbies3(e.target.value)
          }
        />
        <EE.TextInput
          aria-labelledby="hobbies-label"
          className="mb-4"
          value={hobbies4}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setHobbies4(e.target.value)
          }
        />
        <EE.TextInput
          aria-labelledby="hobbies-label"
          className="mb-4"
          value={hobbies5}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setHobbies5(e.target.value)
          }
        />
        <EE.TextInput
          aria-labelledby="hobbies-label"
          className="mb-4"
          value={hobbies6}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setHobbies6(e.target.value)
          }
        />
        <EE.ButtonGroup>
          <EE.ExerciseCallToActionLink to={nextPage}>
            Next
          </EE.ExerciseCallToActionLink>

          <EE.SaveForLaterButtonGreen
            text="Save for Later"
            setExerciseComplete={setExerciseComplete}
          />
        </EE.ButtonGroup>
      </EE.SubmitableForm>
    </>
  );
};

export default Lived;
