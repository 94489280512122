import Image from "@js/components/shared/Image";
import MyStrengthsTeenIcon from "@js/images/exercises/MyStrengths-teen.png";
import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const MyStrengthsScreen: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    strength1: "",
    strength2: "",
    strength3: "",
    strength4: "",
    strength5: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [strength1, setStrength1] = React.useState(initialState.strength1);
  const [strength2, setStrength2] = React.useState(initialState.strength2);
  const [strength3, setStrength3] = React.useState(initialState.strength3);
  const [strength4, setStrength4] = React.useState(initialState.strength4);
  const [strength5, setStrength5] = React.useState(initialState.strength5);

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      strength1,
      strength2,
      strength3,
      strength4,
      strength5,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <Image src={MyStrengthsTeenIcon} className="mx-auto" />

          <EE.HeadingCenterBlack>My Strengths</EE.HeadingCenterBlack>

          {EE.TextInputPromptSingle("Strength #1", strength1, setStrength1)}

          {EE.TextInputPromptSingle("Strength #2", strength2, setStrength2)}

          {EE.TextInputPromptSingle("Strength #3", strength3, setStrength3)}

          {EE.TextInputPromptSingle("Strength #4", strength4, setStrength4)}

          {EE.TextInputPromptSingle("Strength #5", strength5, setStrength5)}

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default MyStrengthsScreen;
