import { Controller } from "@hotwired/stimulus";
import { setCookie } from "@js/helpers/cookies";
import * as Sentry from "@sentry/react";
import VimeoPlayer from "@vimeo/player";
import ahoy from "ahoy.js";
import * as focusTrap from "focus-trap";

// the values should be integers corresponding to the days until the cookie should expire
const MODAL_DISMISSAL_COOKIE_DURATION_IN_DAYS = {
  "chat-help-center-modal": 15,
  "check-in-modal": 7,
  "welcome-to-messages-modal": 30,
};

export default class extends Controller {
  static targets = ["modal", "focus", "heading", "player"];
  static values = {
    name: String,
    source: String,
    displayLanguageId: String,
    disableBackgroundClick: { type: String, default: "false" },
  };

  modalTargetDisconnected(target) {
    document.body.classList.remove("overflow-y-hidden");
    if (this.trap) {
      this.trap.deactivate();
    }
  }

  modalTargetConnected(target) {
    if (!this.isClosed) {
      this.trackEvent("MemberModal:Opened");
      document.body.classList.add("overflow-y-hidden");
      this.sendBreadcrumbToSentry("Activating focus trap");
      this.trap = focusTrap.createFocusTrap(this.modalTarget, {
        fallbackFocus: this.modalTarget,
      });
      this.trap.activate();
    }
  }

  show(event) {
    this.sendBreadcrumbToSentry("Opening");

    if (event) {
      if (event.target.dataset.heading) {
        this.headingTarget.innerText = event.target.dataset.heading;
      }
      if (
        this.modalTarget.dataset.eventName &&
        this.modalTarget.dataset.eventProperties
      ) {
        ahoy.track(
          this.modalTarget.dataset.eventName,
          JSON.parse(this.modalTarget.dataset.eventProperties)
        );
      }
    }

    this.modalTarget.classList.remove("hidden");
    document.body.classList.add("overflow-y-hidden");
    this.focusFirstElement();
    this.dispatch("show");
    this.trackEvent("MemberModal:Opened");
    this.toggleVideo();
  }

  hide(event) {
    if (this.isClosed) {
      return;
    }

    if (
      event &&
      event.type == "keyup" &&
      !["Enter", "Space"].includes(event.code)
    ) {
      return;
    }

    this.close();
    this.trackEvent("MemberModalCloseButton:Clicked");
  }

  close() {
    this.sendBreadcrumbToSentry("Closing");
    this.modalTarget.classList.add("hidden");
    document.body.classList.remove("overflow-y-hidden");
    this.setModalDismissalCookie();
    this.toggleVideo();
    if (this.trap) {
      this.trap.deactivate();
    }
    this.dispatch("closed");
  }

  closeOtherModals() {
    // this is only being used specifically for the exit intent modal
    // when any modal opens, it closes all modals except the exit intent modal
    this.close();
  }

  backgroundClick() {
    const disableBackgroundClick = this.disableBackgroundClickValue === "true";

    if (disableBackgroundClick || this.isClosed) {
      return;
    } else {
      this.close();
      this.trackEvent("MemberModalBackground:Clicked");
    }
  }

  toggle(event) {
    this.isClosed ? this.show(event) : this.hide();
  }

  toggleVideo() {
    if (this.hasPlayerTarget) {
      this.player = new VimeoPlayer(this.playerTarget);
      this.isClosed ? this.player.pause() : this.player.play();
    }
  }

  trackEvent(eventName) {
    // Don't track event unless name specified
    if (this.nameValue) {
      ahoy.track(eventName, this.getModalDetails());
    }
  }

  getModalDetails() {
    return {
      modalName: this.nameValue,
      source: this.sourceValue,
      page: window.location.pathname,
      displayLanguageId: this.displayLanguageIdValue,
    };
  }

  // Maybe put this somewhere else someday
  focusFirstElement() {
    const implicitFocusTarget = this.modalTarget.querySelector(
      "img, a, button, href, input, select, textarea"
    );

    const focusTarget = this.explicitFocusTarget() || implicitFocusTarget;

    if (focusTarget) {
      focusTarget.focus();
    }
  }

  explicitFocusTarget() {
    const autoFocusTarget = this.modalTarget.querySelector("[autofocus]");
    if (autoFocusTarget) {
      return autoFocusTarget;
    }
    if (this.hasFocusTarget) {
      return this.focusTarget;
    }
  }

  setModalDismissalCookie() {
    if (this.shouldSetModalDismissalCookie()) {
      setCookie(
        `has_dismissed_${this.nameValue}`,
        "true",
        this.modalCookieDismissalDuration()
      );
    }
  }

  shouldSetModalDismissalCookie() {
    return MODAL_DISMISSAL_COOKIE_DURATION_IN_DAYS.hasOwnProperty(
      this.nameValue
    );
  }

  modalCookieDismissalDuration() {
    return MODAL_DISMISSAL_COOKIE_DURATION_IN_DAYS[this.nameValue];
  }

  get isClosed() {
    return this.modalTarget.classList.contains("hidden");
  }

  sendBreadcrumbToSentry(message) {
    const payload = {
      category: "modals",
      message: message,
      level: "info",
      data: {
        ...this.getModalDetails(),
        element: JSON.stringify(this.modalTarget.outerHTML),
      },
    };
    Sentry.addBreadcrumb(payload);
  }
}
