import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import ahoy from "ahoy.js";

export default class extends Controller {
  static targets = ["expander", "expandable", "hideable"];
  static values = {
    eventData: { type: Object, default: {} },
  };

  declare readonly expandableTarget: HTMLElement;
  declare readonly expanderTarget: HTMLElement;
  declare readonly hideableTarget: HTMLElement;
  declare readonly hasExpanderTarget: boolean;
  declare readonly hasExpandableTarget: boolean;
  declare readonly eventDataValue: object;

  initialize() {
    if (this.hasExpanderTarget && this.hasExpandableTarget) {
      // Bind the click event on the expander element
      this.expanderTarget.addEventListener("click", this.expand.bind(this));
    }
  }

  hideWhenExpanded() {
    this.hideableTarget.classList.add("hidden");
  }

  private expand() {
    this.expanderTarget.classList.add("hidden");
    this.expandableTarget.classList.remove("hidden");

    ahoy.track("CareNavigationOtherPrograms:Expanded", this.getEventDetails());
    this.logCostShare();
  }

  private logCostShare() {
    const forms = Array.from(
      document.querySelectorAll("[data-form=cost-share]")
    ).map((element) => element as HTMLFormElement);
    forms.forEach((form) => {
      Rails.ajax({
        type: "POST",
        url: form.action,
        data: Rails.serializeElement(form, []),
      });
    });
  }

  private getEventDetails() {
    return {
      page: window.location.pathname,
      ...this.eventDataValue,
    };
  }
}
