import {
  ThermometerContext,
  ThermometerProvider,
  useThermometer,
} from "./Context";
import { useThermometerColor, useThermometerImage } from "./Hooks";

export {
  ThermometerProvider,
  ThermometerContext,
  useThermometer,
  useThermometerColor,
  useThermometerImage,
};
