import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useWhoIAm } from "./Context";

const FreeTime = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const {
    name,
    birthday,
    graduatingClass,
    ethnicity,
    placesIHaveLived1,
    placesIHaveLived2,
    placesIHaveLived3,
    placesIHaveLived4,
    placesIHaveLived5,
    placesIHaveLived6,
    supportSystem,
    hobbies1,
    hobbies2,
    hobbies3,
    hobbies4,
    hobbies5,
    hobbies6,
    favoriteMovieOrShow,
    favoriteColor,
    favoriteBookOrMagazine,
    favoriteFood,
    favoriteClassOrSubject,
    favoriteMusicGenre,
    pets,
    goodFriend,
    freeTime,
    setFreeTime,
    assignedExerciseId,
    completedExercise,
  } = useWhoIAm();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      name,
      birthday,
      graduatingClass,
      ethnicity,
      placesIHaveLived1,
      placesIHaveLived2,
      placesIHaveLived3,
      placesIHaveLived4,
      placesIHaveLived5,
      placesIHaveLived6,
      supportSystem,
      hobbies1,
      hobbies2,
      hobbies3,
      hobbies4,
      hobbies5,
      hobbies6,
      favoriteMovieOrShow,
      favoriteColor,
      favoriteBookOrMagazine,
      favoriteFood,
      favoriteClassOrSubject,
      favoriteMusicGenre,
      pets,
      goodFriend,
      freeTime,
      setFreeTime,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.GenericTextAreaPromptPage
      currentStep={currentStep}
      numSteps={numSteps}
      nextPage={nextPage}
      textPrompt="Where I spend my free time:"
      textAreaValue={freeTime}
      setTextAreaValue={setFreeTime}
      submitExercise={submitExercise}
      showSaveForLater={true}
      setExerciseComplete={setExerciseComplete}
    />
  );
};

export default FreeTime;
