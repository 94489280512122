import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  fallbackExecCommandCopy(text: string) {
    var el = document.createElement("textarea");
    el.value = text;

    el.style.top = "0";
    el.style.left = "0";
    el.style.position = "fixed";

    document.body.appendChild(el);
    el.focus();
    el.select();

    try {
      document.execCommand("copy");
    } catch (ex) {
      console.error("Could not copy text: ", ex);
    }

    document.body.removeChild(el);
  }

  async copy({ params: { text } }) {
    if (!navigator.clipboard) {
      this.fallbackExecCommandCopy(text);
      return;
    }

    await navigator.clipboard.writeText(text);
  }
}
