import React from "react";

export default function InputField(props: PatientRelationshipSelectProps) {
  const { onChange, value, labelTitle, labelName, error } = props;
  return (
    <div className="pt-4">
      <label htmlFor={labelName}>{labelTitle}</label>
      <div className="relative">
        <select
          name={labelName}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          className="block w-full px-4 py-3 pr-8 leading-tight border rounded border-lightBrown "
        >
          <option value="">Select an option</option>
          <option value="Child">Child</option>
          <option value="Parent">Parent</option>
          <option value="Spouse or Partner">Spouse or Partner</option>
          <option value="Other">Other</option>
        </select>
      </div>
      {error && <div className="mt-2 error">{error}</div>}
    </div>
  );
}

interface PatientRelationshipSelectProps {
  onChange: (value: string) => void;
  value: string;
  labelTitle: string;
  labelName: string;
  error: string;
}
