import { Controller } from "@hotwired/stimulus";

// Sets input
export default class extends Controller {
  declare readonly prefixValue: string;
  declare readonly overflowLimitValue: number;
  static values = {
    prefix: { type: String, default: "selected-checkbox-option" },
    overflowLimit: { type: Number, default: 2 },
  };

  updateSelected(event: any) {
    const checked = event.target.value;
    const element = this.element.querySelector(
      "#" + this.prefixValue + "-" + checked
    );

    if (element?.getAttribute("data-checked") == "true") {
      element?.setAttribute("data-checked", "false");
    } else {
      element?.setAttribute("data-checked", "true");
    }

    const overflowElement = this.element.querySelector(
      "#" + this.prefixValue + "-overflow"
    );

    const allLabels = this.element.querySelectorAll(
      '[id^="' + this.prefixValue + '-"][data-checked]'
    );

    var nowVisible = 0;
    var totalChecked = 0;
    allLabels.forEach((element: any, index: number) => {
      if (element.getAttribute("data-checked") == "true") {
        totalChecked++;
      }
      if (
        element.getAttribute("data-checked") == "true" &&
        nowVisible < this.overflowLimitValue
      ) {
        this.makeVisible(element);
        nowVisible++;
      } else {
        this.makeHidden(element);
      }
    });

    if (totalChecked > this.overflowLimitValue && overflowElement) {
      overflowElement.innerHTML =
        "+ " + (totalChecked - this.overflowLimitValue) + " more";
      this.makeVisible(overflowElement);
    } else {
      this.makeHidden(overflowElement);
    }
  }

  makeVisible(element: any) {
    if (element.classList.contains("hidden")) {
      element.classList.remove("hidden");
    }
  }

  makeHidden(element: any) {
    if (!element.classList.contains("hidden")) {
      element.classList.add("hidden");
    }
  }
}
