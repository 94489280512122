import React, { ChangeEvent } from "react";

import { useThermometerImage } from "../../shared";
import {
  ExerciseElements as EE,
  ExerciseErrorMessage,
} from "../../shared/Elements";
import { useDoubleBubble } from "../Context";
import { ImageMapping } from "../Shared";

const ExerciseSteps: React.FC<{ step: number }> = ({ step }): JSX.Element => {
  const [error, setError] = React.useState<string>("");

  const MAX_CHARACTER_LENGTH = 90;

  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    updateStateSetterHook: any
  ) => {
    const thought = e.target.value;
    if (thought.length <= MAX_CHARACTER_LENGTH) {
      updateStateSetterHook(thought);
      setError("");
    } else {
      setError("Please describe the issue in 90 characters or less");
    }
  };

  const {
    initialThoughts,
    finalThoughts,
    initialThermometerValue,
    finalThermometerValue,
    differenceNoticed,
    setInitialThoughts,
    setFinalThoughts,
    setInitialThermometerValue,
    setFinalThermometerValue,
    setDifferenceNoticed,
  } = useDoubleBubble();

  const { ThermometerImage: InitialThermometerImage } = useThermometerImage(
    ImageMapping,
    initialThermometerValue
  );
  const { ThermometerImage: FinalThermometerImage } = useThermometerImage(
    ImageMapping,
    finalThermometerValue
  );

  return (
    <>
      {step === 1 && (
        <>
          <EE.CenteredExerciseText
            className="my-6"
            htmlFor="initial-thoughts-input"
          >
            Think of a time recently when you were stuck in a bad mood. What was
            the situation? What was the thought you had?
          </EE.CenteredExerciseText>

          <EE.TextArea
            id="initial-thoughts-input"
            data-testid="initial-thoughts"
            value={initialThoughts}
            onChange={(e) => {
              onChange(e, setInitialThoughts);
            }}
            placeholder=""
          />
          <ExerciseErrorMessage>{error}</ExerciseErrorMessage>
        </>
      )}
      {step === 2 && (
        <>
          <EE.CenteredExerciseText className="my-6" id="slider-label">
            How did this thought feel?
          </EE.CenteredExerciseText>
          <InitialThermometerImage className="h-16 pb-4 mx-auto" />
          <EE.ThermometerNumber>{initialThermometerValue}</EE.ThermometerNumber>
          <EE.ThermometerSlider
            thermometerValue={initialThermometerValue}
            onChange={setInitialThermometerValue}
            ariaLabelledByForHandle="slider-label"
          />
          <EE.ExerciseAnchors>
            <EE.ExerciseAnchor className="text-left">
              Very Sad
            </EE.ExerciseAnchor>
            <EE.ExerciseAnchor className="text-right">
              Very Happy
            </EE.ExerciseAnchor>
          </EE.ExerciseAnchors>
        </>
      )}
      {step === 3 && (
        <>
          <EE.CenteredExerciseText className="my-6">
            Is there another way to look at this?
          </EE.CenteredExerciseText>

          <EE.TextArea
            id="final-thoughts-input"
            data-testid="final-thoughts"
            value={finalThoughts}
            onChange={(e) => {
              onChange(e, setFinalThoughts);
            }}
            placeholder="What would you tell a friend who had this happen?"
          />
          <ExerciseErrorMessage>{error}</ExerciseErrorMessage>
        </>
      )}
      {step === 4 && (
        <>
          <EE.CenteredExerciseText className="my-6" id="slider-label">
            How did this new thought make you feel?
          </EE.CenteredExerciseText>
          <FinalThermometerImage className="h-16 pb-4 mx-auto" />
          <EE.ThermometerNumber>{finalThermometerValue}</EE.ThermometerNumber>
          <EE.ThermometerSlider
            thermometerValue={finalThermometerValue}
            onChange={setFinalThermometerValue}
            ariaLabelledByForHandle="slider-label"
          />
          <EE.ExerciseAnchors>
            <EE.ExerciseAnchor className="text-left">
              Very Sad
            </EE.ExerciseAnchor>
            <EE.ExerciseAnchor className="text-right">
              Very Happy
            </EE.ExerciseAnchor>
          </EE.ExerciseAnchors>
        </>
      )}
      {step === 5 && (
        <>
          <EE.CenteredExerciseText
            className="my-6"
            htmlFor="difference-noticed-input"
          >
            What do you notice about the difference between how these two
            thoughts feel?{" "}
          </EE.CenteredExerciseText>
          <EE.TextArea
            id="difference-noticed-input"
            data-testid="text-area-difference-noticed"
            value={differenceNoticed}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              onChange(e, setDifferenceNoticed);
            }}
            placeholder=""
          />
        </>
      )}
    </>
  );
};

export default ExerciseSteps;
