import React from "react";

import { ExerciseScreenProps } from "../shared/Elements";

const MappingYourNeedsContext = React.createContext(undefined);

const MappingYourNeedsProvider: React.FC<ExerciseScreenProps> = (props) => {
  const defaultState = {
    need1: "",
    need2: "",
    need3: "",
    need4: "",
    need5: "",
  };

  const {
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  } = props;

  const initialState = { ...defaultState, ...completedExercise?.data };

  const [need1, setNeed1] = React.useState<string>(initialState.need1);
  const [need2, setNeed2] = React.useState<string>(initialState.need2);
  const [need3, setNeed3] = React.useState<string>(initialState.need3);
  const [need4, setNeed4] = React.useState<string>(initialState.need4);
  const [need5, setNeed5] = React.useState<string>(initialState.need5);

  const value = {
    need1,
    setNeed1,
    need2,
    setNeed2,
    need3,
    setNeed3,
    need4,
    setNeed4,
    need5,
    setNeed5,
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  };

  return <MappingYourNeedsContext.Provider value={value} {...props} />;
};

function useMappingYourNeeds() {
  const context = React.useContext(MappingYourNeedsContext);
  if (!context) {
    throw new Error(
      "useMappingYourNeeds must be used within a MappingYourNeedsProvider"
    );
  }
  return context;
}

export {
  MappingYourNeedsProvider,
  MappingYourNeedsContext,
  useMappingYourNeeds,
};
