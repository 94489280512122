// the purpose of the controller is to preview the photo before uploading it
// the controller has 4 targets: div, preview, input, fields
// the div target is the container for the preview
// the preview target is the image tag that will display the preview
// the input target is the file input
// the fields target is the container for the file input, in the event of surrounding elements fields
// example:
// <div data-controller="photo-preview">
//   <div data-photo-preview-target="div" class="hidden">
//     <img data-photo-preview-target="preview" />
//     <button data-action="click->photo-preview#dismiss">Remove</button>
//   </div>
//
//   <div data-photo-preview-target="fields">
//     <%= f.file_field :photo %>
//   </div>
// </div>
// the preview method is called when the file input changes

import { Controller } from "@hotwired/stimulus";
import heic2any from "heic2any";

export default class extends Controller {
  static targets = ["div", "preview", "input", "fields", "error"];

  preview(event) {
    const input = this.inputTarget.files[0];
    if (input) {
      if (input.type === "image/heic") {
        this.errorTarget.classList.add("hidden");
        heic2any({
          blob: input,
          toType: "image/jpeg",
          quality: 0.8,
        })
          .then((jpegBlob) => {
            this.displayImage(jpegBlob);
          })
          .catch((error) => {
            console.error("Error converting HEIC image:", error);
          });
      } else if (input.type.startsWith("image/")) {
        this.errorTarget.classList.add("hidden");
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewTarget.src = e.target.result;
          this.divTarget.classList.remove("hidden");
        };
        reader.readAsDataURL(input);
      } else {
        this.inputTarget.value = null;
        this.errorTarget.classList.remove("hidden");
      }
    }
  }

  convertHEICtoJPEG(file) {
    return heic2any({
      blob: file,
      toType: "image/jpeg",
      quality: 0.8,
    });
  }

  displayImage(blob) {
    const url = URL.createObjectURL(blob);
    this.previewTarget.src = url;
    this.divTarget.classList.remove("hidden");

    this.previewTarget.onload = () => {
      URL.revokeObjectURL(url);
    };
  }

  handleDisplayError(event) {
    this.dismiss();
    this.surfaceError();
  }

  surfaceError() {
    this.errorTarget.classList.remove("hidden");
  }

  dismiss() {
    this.inputTarget.value = null;

    // Set src to transparent gif rather than null or "" so it is valid
    this.previewTarget.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
    this.divTarget.classList.add("hidden");
    this.fieldsTarget.classList.remove("hidden");
  }
}
