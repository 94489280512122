import { ActionEvent, Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "preview"];
  declare readonly selectTarget: HTMLSelectElement;
  declare readonly previewTarget: HTMLSelectElement;

  optionSelected() {
    this.previewTarget.setAttribute("src", this.selectedUrl());
  }

  selectedUrl() {
    return this.selectTarget.selectedOptions[0].dataset.previewUrlParam || "";
  }
}
