import { Controller } from "@hotwired/stimulus";

// Sets input value to specified value when action is triggered
export default class extends Controller {
  declare readonly inputTarget: HTMLInputElement;
  declare readonly dataValue: string;

  static targets = ["input"];
  static values = { data: String };

  setValue(_event) {
    const target = this.inputTarget;

    if (target) {
      target.value = this.dataValue;
    }
  }
}
