import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";

const Draw = (props: GenericMultiPageExerciseScreenProps) => {
  const { nextPage, numSteps, currentStep } = props;
  return (
    <>
      <EE.StepIndicator numSteps={numSteps} currentStep={currentStep} />
      <EE.Subheading className="mb-48">
        Draw how conflict makes you feel. You can draw on a piece of paper or
        directly on the computer while sharing your screen.
      </EE.Subheading>

      <EE.ExerciseCallToActionLink to={nextPage}>
        Next
      </EE.ExerciseCallToActionLink>
    </>
  );
};

export default Draw;
