export const timeZoneName = (date: Date = new Date()): string => {
  // This returns the time zone name such as `Mountain` or `Eastern`
  const dtf = Intl.DateTimeFormat("en-US", { timeZoneName: "long" });
  return dtf
    .formatToParts(date)
    .find((part) => part.type == "timeZoneName")
    .value.split(" ")[0];
};

export const currentCanonicalTimeZoneName = () => {
  // This returns the canonical time zone name such as `America/Los_Angeles`
  return Intl.DateTimeFormat("en-US", {
    timeZoneName: "long",
  }).resolvedOptions().timeZone;
};

export const timeZoneLongName = (date: Date, timeZone: string): string => {
  const longDtf = Intl.DateTimeFormat("en-US", {
    timeZone,
    timeZoneName: "long",
  });
  const shortDtf = Intl.DateTimeFormat("en-US", {
    timeZone,
    timeZoneName: "short",
  });
  const toName = (dtf: Intl.DateTimeFormat) =>
    dtf.formatToParts(date).find((part) => part.type == "timeZoneName").value;

  return `${toName(longDtf)} (${toName(shortDtf)})`;
};

export const getFullFormatDate = (dateOrString: Date | string): string => {
  const date =
    typeof dateOrString === "string" ? new Date(dateOrString) : dateOrString;
  const day = Intl.DateTimeFormat("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);
  const time = Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
    timeZoneName: "short",
  }).format(date);

  return `${day} at ${time}`;
};
