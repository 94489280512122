import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  declare readonly sliderTarget: HTMLElement;
  declare readonly sliderWrapperTarget: HTMLElement;
  declare readonly tabTargets: HTMLElement[];
  declare readonly scrollLeftButtonTarget: HTMLElement;
  declare readonly scrollRightButtonTarget: HTMLElement;
  declare readonly defaultTabValue: String;
  declare avgTabWidth: number;

  static targets = [
    "slider",
    "sliderWrapper",
    "tab",
    "scrollLeftButton",
    "scrollRightButton",
  ];
  static values = {
    defaultTab: String,
  };

  initialize(): void {
    this.sliderTarget.addEventListener("scroll", this.onScroll);

    // handle window/slider element resize
    const resizeObserver = new ResizeObserver(() => {
      if (this.isScrollable()) {
        this.addLeftScrollable();
      } else {
        this.removeLeftScrollable();
      }

      this.setAverageWidth();
    });
    resizeObserver.observe(this.sliderTarget);

    // initial render
    if (!this.isScrollable()) {
      this.removeLeftScrollable();
      return;
    }

    $(`#${this.defaultTabValue}`)[0].scrollIntoView({
      behavior: "smooth",
      inline: "center",
      block: "end",
    });

    this.setAverageWidth();
  }

  handleScroll(event) {
    if (event.type === "max-left-scroll") {
      this.removeLeftScrollable();
    } else if (event.type === "left-scroll") {
      this.addRightScrollable();
      this.addLeftScrollable();
    } else if (event.type === "max-right-scroll") {
      this.removeRightScrollable();
    }
  }

  onScroll(event) {
    const element = event.target;
    const scrollLeft = element.scrollLeft;

    const maxScrollAmount = element.scrollWidth - element.offsetWidth - 1;
    if (scrollLeft >= maxScrollAmount) {
      event.target.dispatchEvent(new CustomEvent("max-left-scroll"));
    } else if (scrollLeft > 0) {
      event.target.dispatchEvent(new CustomEvent("left-scroll"));
    } else if (scrollLeft === 0) {
      event.target.dispatchEvent(new CustomEvent("max-right-scroll"));
    }
  }

  handleScrollClick(event) {
    if (event.target.id == "left-scroll-button") {
      this.sliderTarget.scrollBy({
        left: this.avgTabWidth * 2,
        behavior: "smooth",
      });
    } else {
      this.sliderTarget.scrollBy({
        left: -(this.avgTabWidth * 2),
        behavior: "smooth",
      });
    }
  }

  isScrollable() {
    return this.sliderTarget.scrollWidth > this.sliderTarget.clientWidth;
  }

  addLeftScrollable() {
    this.sliderWrapperTarget.classList.add("left-scrollable");
    this.scrollLeftButtonTarget.classList.remove("hidden");
  }

  removeLeftScrollable() {
    this.sliderWrapperTarget.classList.remove("left-scrollable");
    this.scrollLeftButtonTarget.classList.add("hidden");
  }

  addRightScrollable() {
    this.sliderWrapperTarget.classList.add("right-scrollable");
    this.scrollRightButtonTarget.classList.remove("hidden");
  }

  removeRightScrollable() {
    this.sliderWrapperTarget.classList.remove("right-scrollable");
    this.scrollRightButtonTarget.classList.add("hidden");
  }

  setAverageWidth() {
    this.avgTabWidth =
      this.tabTargets.map((tab) => tab.clientWidth).reduce((a, b) => a + b) /
      this.tabTargets.length;
  }
}
