import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["search"];
  static values = {
    debounceMs: { type: Number, default: 200 },
  };

  initialize() {
    this.search = debounce(this.apply, this.debounceMsValue);
  }

  connect() {
    this.element.addEventListener("ajax:success", (e) => {
      const [data, status, xhr] = e.detail;
      window.history.replaceState(null, null, xhr.responseURL);
    });
    //TODO: Make this a dedicated controller
    if (this.hasSearchTarget) {
      // For the cursor to be move to the end, the input has to have focus first, then when the value is changed it will goto the end. If you set .value to the same, it won't change in chrome.
      var value = this.searchTarget.value;
      this.searchTarget.focus();
      this.searchTarget.value = "";
      this.searchTarget.value = value;
    }
  }

  apply() {
    if (this.element.dataset["remote"] == "true") {
      Rails.fire(this.element, "submit");
    } else {
      this.element.submit();
    }
  }
}
