/* 
Purpose: This controller is designed to allow creating editable lists for
exercises. It can handle lists with multiple attributes but, does not make any
assumptions about how the data is saved. It creates a live binding between the
form and the rendered list handling the updating client side as a preview. It
also allows for arbitrarily adding additional items client side that can be
saved to the server.

Targets:
- listView: Outer container for the list
- list: Container where the actual list lives. This allows other elements to be
  in the listView without getting disrupted by adding new list items.
- listItem: This is where each individual list item lives. The construct allows
  for multiple fields to be rendered per list item.
- formView: Outer container for the form
- form: Container where the actual form fields live. This allows other elements to be
  in the formView without getting disrupted by adding new form items.
- formItem: This is where each individual form item lives. The construct allows
  for multiple fields to be rendered within each form item.

Attributes:
- data-bind-id: This needs to be a unique integer that connects a formItem to a
  listItem so that the values can be reflected live clientside. An index from a
  server side array is sufficient.
- data-attribute: This is the name of the field and must be on both the
  listItem and formItem for binding to work. For each field in a listItem and
  formItem this must be unique.
- data-label: In order to make the form accessible there has to be a label with
  a unique name. The code will add an index to this and set as the label
  content.
- label[for] & input[id] must be unique for screen readers (and capybara) to be
  able to find the input from the label. The code will add an `_{index}` where
  index is the next integer in the bind-id sequence when a new formItem is
  created.

Single field example:
<div data-controller="editable-list">
  <div data-editable-list-target="listView">
    <ul data-editable-list-target="list">
      <li data-editable-list-target="listItem" data-bind-id="0" data-attribute="name">User Entered Content</li>
    </ul>
    <div>
      <a data-action="editable-list#showFormView" href="#">Edit my list</a>
    </div>
  </div>

  <div class="hidden" data-editable-list-target="formView">
    <div data-editable-list-target="form">
      <div data-editable-list-target="formItem" data-bind-id="0">
        <label class="hidden" data-label="Favorite" for="calming_favs_name_0">Favorite 1</label>
        <input data-attribute="name" id="calming_favs_name_0" type="text" value="User Entered Content" name="completed_exercise[data][calming_favs][][name]">
      </div>
    </div>
    <a data-action="editable-list#addItem" href="#">Add another item+</a>
    <a data-action="editable-list#showListView" href="#">Done</a>
  </div>
</div>
 */

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  declare readonly formItemTarget: HTMLElement;
  declare readonly formItemTargets: Array<HTMLElement>;
  declare readonly formTarget: HTMLElement;
  declare readonly formViewTarget: HTMLElement;
  declare readonly listItemTarget: HTMLElement;
  declare readonly listItemTargets: Array<HTMLElement>;
  declare readonly listTarget: HTMLElement;
  declare readonly listViewTarget: HTMLElement;

  static targets = [
    "formItem",
    "form",
    "formView",
    "listItem",
    "list",
    "listView",
  ];

  observables: object = {};
  elementMap: ElementListMap;
  bindings: Bindings;

  connect() {
    this.bindAll();
  }

  addItem(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    let id = this.newId();

    let newFormItem = this.cloneItem(this.formItemTarget, id);
    this.formTarget.appendChild(newFormItem);

    let newListItem = this.cloneItem(this.listItemTarget, id);
    this.listTarget.appendChild(newListItem);

    this.elementMap.update(id, newFormItem, newListItem);
  }

  toggleView(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.listViewTarget.classList.toggle("hidden");
    this.formViewTarget.classList.toggle("hidden");
  }

  showFormView(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.listViewTarget.classList.add("hidden");
    this.formViewTarget.classList.remove("hidden");
  }

  showListView(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.listViewTarget.classList.remove("hidden");
    this.formViewTarget.classList.add("hidden");
  }

  private bindAll() {
    this.bindings = new Bindings();
    this.elementMap = new ElementListMap(
      this.formItemTargets,
      this.listItemTargets
    );
    this.elementMap.onUpdate((id, sourceRef, viewerRef) => {
      this.bindings.bind(id, sourceRef, viewerRef);
    });
    this.elementMap.index();
  }

  private cloneItem(item: HTMLElement, id: string): HTMLElement {
    let newItem = item.cloneNode(true) as HTMLElement;

    newItem.setAttribute("data-bind-id", id);

    const attributes = this.attributesFromItem(newItem);
    Array.from(attributes).forEach((attribute, index) => {
      if (["input", "textarea"].includes(attribute.tagName.toLowerCase())) {
        (attribute as HTMLInputElement).value = "";
      } else {
        attribute.innerHTML = "";
      }
      // ensure `id` is unique and matched to label's `for` to help with accessibility
      attribute.id = [attribute.id, id].join("_");
    });
    // update the labels so that they are more accessible
    Array.from(newItem.querySelectorAll("[data-label]")).forEach((label) => {
      // Make 1 indexed so more human friendly
      let labelId = parseInt(id) + 1;
      let labelVal = (label as HTMLElement).dataset.label;
      label.innerHTML = [labelVal, labelId].join(" ");
      // ensure `for` is unique and matched to input's `id` to help with accessibility
      label.setAttribute("for", [label.getAttribute("for"), id].join("_"));
    });

    return newItem;
  }

  private attributesFromItem(node: HTMLElement): Array<HTMLElement> {
    if (node.hasAttribute("data-attribute")) {
      return [node];
    } else {
      return Array.from(node.querySelectorAll("[data-attribute]"));
    }
  }

  // we are relying on the bind ids in rails to be the index of the list items
  // so that we don't need to provide an id from rails land as it changes how
  // fields_for sends back the data
  private newId(): string {
    let elements = Array.from(
      this.formTarget.querySelectorAll("[data-bind-id]")
    );
    var nextId = 0;
    elements.forEach((element) => {
      let el = element as HTMLElement;
      let id = parseInt(el.dataset.bindId);
      if (id >= nextId) {
        nextId = ++id;
      }
    });
    return nextId.toString();
  }
}

// Below are helper classes to find and map inputs to viewable elements such as <li>
// binding them via an observer pattern so that there is a seemless client side experience

type ElementReference = {
  node: HTMLElement;
  attributes: Array<HTMLElement>;
};

class ElementListMap {
  sources: Array<HTMLElement>;
  viewers: Array<HTMLElement>;
  _index: object = {};
  _callback: (id, source: ElementReference, viewer: ElementReference) => void;

  constructor(sources: Array<HTMLElement>, viewers: Array<HTMLElement>) {
    this.sources = sources;
    this.viewers = viewers;
  }

  index() {
    this.sources.forEach((source) => {
      let id = source.dataset.bindId;
      this.addTo(id, "source", this.buildReference(source));
    });
    this.viewers.forEach((viewer) => {
      let id = viewer.dataset.bindId;
      this.addTo(id, "viewer", this.buildReference(viewer));
    });

    this.forEach((id, sourceRef, viewerRef) => {
      this._callback(id, sourceRef, viewerRef);
    });
  }

  update(id, source: HTMLElement, viewer: HTMLElement) {
    this.addTo(id, "source", this.buildReference(source));
    this.addTo(id, "viewer", this.buildReference(viewer));

    this._callback(id, this._index[id].source, this._index[id].viewer);
  }

  onUpdate(
    callback: (id, source: ElementReference, viewer: ElementReference) => void
  ) {
    this._callback = callback;
  }

  forEach(
    iterator: (
      id: string,
      source: ElementReference,
      viewer: ElementReference
    ) => void
  ) {
    Object.entries(this._index).forEach(([id, ref]) => {
      iterator(id, ref["source"], ref["viewer"]);
    });
  }

  findAttributes(node: HTMLElement): Array<HTMLElement> {
    if (node.hasAttribute("data-attribute")) {
      return [node];
    } else {
      return Array.from(node.querySelectorAll("[data-attribute]"));
    }
  }

  private addTo(id: string, key: string, value: any) {
    if (!this._index.hasOwnProperty(id)) {
      this._index[id] = {};
    }
    this._index[id][key] = value;
  }

  private buildReference(node: HTMLElement): ElementReference {
    let reference: ElementReference = {
      node: node,
      attributes: this.findAttributes(node),
    };
    return reference;
  }
}

type Binding = {
  source: ElementReference;
  viewer: ElementReference;
  observers: object;
};

class Bindings {
  _index: { [key: string]: Binding } = {};

  bind(id: string, source: ElementReference, viewer: ElementReference) {
    if (this._index.hasOwnProperty(id)) {
      delete this._index[id];
    }

    let observers = this.observe(source);
    this.subscribe(observers, viewer.attributes);

    let binding: Binding = {
      source: source,
      viewer: viewer,
      observers: observers,
    };
    this._index[id] = binding;
  }

  observe(source: ElementReference): object {
    var observers = {};
    source.attributes.forEach((attribute) => {
      const input = attribute as HTMLInputElement;
      const name = input.dataset.attribute;
      var observer = new Observable(input.value);
      input.onchange = () => (observer.value = input.value);
      input.onkeyup = () => (observer.value = input.value);
      observers[name] = observer;
    });
    return observers;
  }

  subscribe(observers, attributes: Array<HTMLElement>) {
    attributes.forEach((attribute) => {
      const name = attribute.dataset.attribute;
      observers[name].subscribe((value) => {
        attribute.innerHTML = value;
      });
    });
  }
}

class Observable {
  _listeners: Array<any>;
  _value: any;

  constructor(value) {
    this._listeners = [];
    this._value = value;
  }

  notify() {
    this._listeners.forEach((listener) => listener(this._value));
  }

  subscribe(listener: any) {
    this._listeners.push(listener);
  }

  get value(): any {
    return this._value;
  }

  set value(val: any) {
    if (val !== this._value) {
      this._value = val;
      this.notify();
    }
  }
}
