import { Controller } from "@hotwired/stimulus";
import { LiveRegionAnnouncer } from "@js/models/LiveRegionAnnouncer";

/**
 * This controller is used to manage ARIA live region notifications. These are
 * used to notify the user of the current state of the page and are only used
 * by the screen reader.
 *
 * You can trigger a notification with data-action="click->live-region#announce",
 * which will trigger the notification using the validationMessage of the event target.
 *
 * Alternatively, you can use data-live-region-target="announceable" to notify the screen
 * reader when the decorated element changes.
 *
 * For more information see:
 * https://dequeuniversity.com/class/dynamic-updates/notify-users/aria-live
 * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions
 */

export default class extends Controller {
  static targets = ["announceable"];
  liveRegionAnnouncer = new LiveRegionAnnouncer();

  connect() {
    this.liveRegionAnnouncer.ensureLiveRegionInDocument();
    this.liveRegionAnnouncer.announceChangesTo(this.announceableTargets);
  }

  disconnect() {
    this.liveRegionAnnouncer.disconnect();
  }

  announce(eventOrMessage) {
    this.liveRegionAnnouncer.announce(eventOrMessage);
  }
}
