import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";

const Example = (props: {
  dontThink: string;
  doThink: string;
  currentStep: number;
  numSteps: number;
  nextPage: string;
}) => {
  const { dontThink, doThink, numSteps, currentStep, nextPage } = props;

  return (
    <>
      <EE.StepIndicator numSteps={numSteps} currentStep={currentStep} />

      <div className="flex flex-col items-center px-4 py-6 bg-blue-300 rounded-2xl">
        <p className="px-4 font-bold py-0 text-xs pt-0.5 bg-white rounded-full">
          Fixed mindset
        </p>
        <p className="py-4 text-sm">Instead of thinking...</p>
        <p className="font-sans text-2xl italic font-semibold text-center">
          &quot;{dontThink}&quot;
        </p>
      </div>

      <div className="w-full mx-auto mt-1 text-5xl text-center text-gray-400 transform rotate-90 material-icons">
        forward
      </div>

      <div className="flex flex-col items-center px-4 py-6 bg-yellow-300 rounded-2xl">
        <p className="px-4 font-bold py-0 text-xs pt-0.5 bg-white rounded-full">
          Growth mindset
        </p>
        <p className="py-4 text-sm">Think this...</p>
        <p className="font-sans text-2xl italic font-semibold text-center">
          &quot;{doThink}&quot;
        </p>
      </div>

      <EE.ButtonGroup>
        <EE.ExerciseCallToActionLink to={nextPage}>
          Next
        </EE.ExerciseCallToActionLink>
      </EE.ButtonGroup>
    </>
  );
};

export default Example;
