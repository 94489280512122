import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useHowDoesConflictMakeYouFeel } from "./Context";

const Feelings = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const {
    feeling1,
    feeling2,
    feeling3,
    setFeeling1,
    setFeeling2,
    setFeeling3,
    assignedExerciseId,
    completedExercise,
  } = useHowDoesConflictMakeYouFeel();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      feeling1,
      feeling2,
      feeling3,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <>
      <EE.StepIndicator numSteps={numSteps} currentStep={currentStep} />
      <EE.SubmitableForm submitExercise={submitExercise}>
        <EE.Subheading className="mb-4" id="feeling-label">
          When you sense that feeling, name 1-3 things or words that you think
          of immediately.
        </EE.Subheading>
        <EE.TextInput
          aria-labelledby="feeling-label"
          className="mb-4"
          value={feeling1}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFeeling1(e.target.value)
          }
        />
        <EE.TextInput
          aria-labelledby="feeling-label"
          className="mb-4"
          value={feeling2}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFeeling2(e.target.value)
          }
        />
        <EE.TextInput
          aria-labelledby="feeling-label"
          className="mb-4"
          value={feeling3}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFeeling3(e.target.value)
          }
        />

        <EE.ButtonGroup>
          <EE.ExerciseCallToActionLink to={nextPage}>
            Next
          </EE.ExerciseCallToActionLink>

          <EE.SaveForLaterButtonGreen
            text="Save for Later"
            setExerciseComplete={setExerciseComplete}
          />
        </EE.ButtonGroup>
      </EE.SubmitableForm>
    </>
  );
};

export default Feelings;
