import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { DoubleBubbleProvider, ExerciseParamsProvider } from "./Context";
import Exercise from "./Exercise";
import Introduction from "./Introduction";
import Reflection from "./Reflection";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Screens: React.FC<ExerciseScreenProps> = (props) => {
  return (
    <DoubleBubbleProvider {...props}>
      <EE.Wrapper>
        <div className="max-w-2xl mx-auto select-none">
          <ScrollToTop />
          <Route exact path="/">
            <Introduction />
          </Route>
          <Route path="/exercise/:step">
            <ExerciseParamsProvider>
              <Exercise />
            </ExerciseParamsProvider>
          </Route>
          <Route path="/reflection">
            <Reflection />
          </Route>
        </div>
      </EE.Wrapper>
    </DoubleBubbleProvider>
  );
};

export default Screens;
