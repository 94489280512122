import { Controller } from "@hotwired/stimulus";

// Class for enabling use of "pattern" attribute validation on textarea elements.
// Can be used to validate that the pattern is matched when submitting parent form.
// Optionally can be enabed/disabled via toggle method, and initial state can be set via data-textarea-pattern-initially-enabled-value
export default class extends Controller {
  static targets = ["textarea"];

  static values = {
    message: { type: String, default: "Invalid input" },
    initiallyEnabled: { type: Boolean, default: true },
  };

  connect() {
    this.handleChange = this.onChange.bind(this);
    this.handleSubmit = this.onSubmit.bind(this);
    if (this.initiallyEnabledValue) {
      this.addListeners();
      this.enabled = true;
    } else {
      this.enabled = false;
    }
  }

  toggle() {
    if (this.enabled) {
      this.removeListeners();
      this.textareaTarget.setCustomValidity("");
      this.enabled = false;
    } else {
      this.addListeners();
      this.enabled = true;
    }
  }

  disconnect() {
    this.removeListeners();
  }

  addListeners() {
    this.textareaTarget.addEventListener("change", this.handleChange);
    this.textareaTarget.form.addEventListener("submit", this.handleSubmit);
  }

  removeListeners() {
    this.textareaTarget.removeEventListener("change", this.handleChange);
    this.textareaTarget.form.removeEventListener("submit", this.handleSubmit);
  }

  matchesRegex(textarea) {
    var patternRegex = new RegExp(textarea.getAttribute("pattern"), "s");
    return textarea.value.match(patternRegex);
  }

  onSubmit(event) {
    const textarea = this.textareaTarget;
    if (!this.matchesRegex(textarea)) {
      event.preventDefault();
      textarea.setCustomValidity(this.messageValue);
      textarea.reportValidity();
    }
  }

  onChange(event) {
    const textarea = event.target;
    if (this.matchesRegex(textarea)) {
      textarea.setCustomValidity("");
    }
  }
}
