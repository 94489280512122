import React from "react";

import { ExerciseScreenProps } from "../shared/Elements";

const GetToKnowYourselfContext = React.createContext(undefined);

const GetToKnowYourselfProvider: React.FC<ExerciseScreenProps> = (props) => {
  const defaultState = {
    familyRelationships: null,
    friendsAndRelationships: null,
    fitness: null,
    diet: null,
    socialLife: null,
    senseOfBelonging: null,
    hobbiesSports: null,
  };

  const {
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  } = props;

  const initialState = { ...defaultState, ...completedExercise?.data };

  const [familyRelationships, setFamilyRelationships] = React.useState<string>(
    initialState.familyRelationships
  );
  const [friendsAndRelationships, setFriendsAndRelationships] =
    React.useState<string>(initialState.friendsAndRelationships);
  const [fitness, setFitness] = React.useState<string>(initialState.fitness);
  const [diet, setDiet] = React.useState<string>(initialState.diet);
  const [socialLife, setSocialLife] = React.useState<string>(
    initialState.socialLife
  );
  const [senseOfBelonging, setSenseOfBelonging] = React.useState<string>(
    initialState.senseOfBelonging
  );
  const [hobbiesSports, setHobbiesSports] = React.useState<string>(
    initialState.hobbiesSports
  );

  const value = {
    familyRelationships,
    setFamilyRelationships,
    friendsAndRelationships,
    setFriendsAndRelationships,
    fitness,
    setFitness,
    diet,
    setDiet,
    socialLife,
    setSocialLife,
    senseOfBelonging,
    setSenseOfBelonging,
    hobbiesSports,
    setHobbiesSports,
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  };

  return <GetToKnowYourselfContext.Provider value={value} {...props} />;
};

function useGetToKnowYourself() {
  const context = React.useContext(GetToKnowYourselfContext);
  if (!context) {
    throw new Error(
      "useGetToKnowYourself must be used within a GetToKnowYourselfProvider"
    );
  }
  return context;
}

export {
  GetToKnowYourselfProvider,
  GetToKnowYourselfContext,
  useGetToKnowYourself,
};
