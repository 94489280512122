import React from "react";

import { ExerciseScreenProps } from "../shared/Elements";

const FixedVsGrowthMindsetsContext = React.createContext(undefined);

const FixedVsGrowthMindsetsProvider: React.FC<ExerciseScreenProps> = (
  props
) => {
  const defaultState = {
    dontThink: "",
    doThink: "",
  };

  const {
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  } = props;

  const initialState = { ...defaultState, ...completedExercise?.data };

  const [dontThink, setDontThink] = React.useState<string>(
    initialState.dontThink
  );
  const [doThink, setDoThink] = React.useState<string>(initialState.doThink);

  const value = {
    dontThink,
    setDontThink,
    doThink,
    setDoThink,
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  };

  return <FixedVsGrowthMindsetsContext.Provider value={value} {...props} />;
};

function useFixedVsGrowthMindsets() {
  const context = React.useContext(FixedVsGrowthMindsetsContext);
  if (!context) {
    throw new Error(
      "useFixedVsGrowthMindsets must be used within a FixedVsGrowthMindsetsProvider"
    );
  }
  return context;
}

export {
  FixedVsGrowthMindsetsProvider,
  FixedVsGrowthMindsetsContext,
  useFixedVsGrowthMindsets,
};
