import React from "react";
import { useParams } from "react-router-dom";

import { ExerciseScreenProps } from "../shared/Elements";

const DoubleBubbleContext = React.createContext(undefined);

const DoubleBubbleProvider: React.FC<ExerciseScreenProps> = (props) => {
  const defaultState = {
    initialThoughts: "",
    finalThoughts: "",
    reflectionThoughts: "",
    differenceNoticed: "",
    initialThermometerValue: 5,
    finalThermometerValue: 5,
    reflectionThermometerValue: 5,
  };

  const {
    assignedExerciseId,
    patientFirstName,
    careLink,
    chatLink,
    completedExercise,
    readOnly,
  } = props;

  const initialState = { ...defaultState, ...completedExercise?.data };

  const [initialThoughts, setInitialThoughts] = React.useState<string>(
    initialState.initialThoughts
  );
  const [finalThoughts, setFinalThoughts] = React.useState<string>(
    initialState.finalThoughts
  );
  const [reflectionThoughts, setReflectionThoughts] = React.useState<string>(
    initialState.reflectionThoughts
  );
  const [differenceNoticed, setDifferenceNoticed] = React.useState<string>(
    initialState.differenceNoticed
  );
  const [initialThermometerValue, setInitialThermometerValue] =
    React.useState<number>(initialState.initialThermometerValue);
  const [finalThermometerValue, setFinalThermometerValue] =
    React.useState<number>(initialState.finalThermometerValue);
  const [reflectionThermometerValue, setReflectionThermometerValue] =
    React.useState<number>(initialState.reflectionThermometerValue);

  const value = {
    setInitialThoughts,
    setFinalThoughts,
    setReflectionThoughts,
    setDifferenceNoticed,
    setInitialThermometerValue,
    setFinalThermometerValue,
    setReflectionThermometerValue,
    initialThoughts,
    finalThoughts,
    reflectionThoughts,
    differenceNoticed,
    initialThermometerValue,
    finalThermometerValue,
    reflectionThermometerValue,
    assignedExerciseId,
    patientFirstName,
    careLink,
    chatLink,
    completedExercise,
    readOnly,
  };

  return <DoubleBubbleContext.Provider value={value} {...props} />;
};

function useDoubleBubble() {
  const context = React.useContext(DoubleBubbleContext);
  if (!context) {
    throw new Error(
      "useDoubleBubble must be used within a DoubleBubbleProvider"
    );
  }
  return context;
}

interface ExerciseParamsContextValues {
  step: number;
  nextPath: string;
}

const ExerciseParams = React.createContext({} as ExerciseParamsContextValues);

function ExerciseParamsProvider(props: React.PropsWithChildren<{}>) {
  const { step: stepString } = useParams<{ step: string }>();

  const step = parseInt(stepString);

  let nextPath: string;

  if (step === 5) {
    nextPath = "/reflection";
  } else {
    nextPath = `/exercise/${step + 1}`;
  }

  return (
    <ExerciseParams.Provider
      value={{
        step,
        nextPath,
      }}
      {...props}
    />
  );
}

function useExerciseParams() {
  const context = React.useContext(ExerciseParams);
  if (!context) {
    throw new Error(
      "useExerciseParams must be used within a ExerciseParamsProvider"
    );
  }
  return context;
}

export {
  DoubleBubbleProvider,
  DoubleBubbleContext,
  useDoubleBubble,
  ExerciseParamsProvider,
  ExerciseParams,
  useExerciseParams,
};
