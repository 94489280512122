import React from "react";

import { ExerciseScreenProps } from "../shared/Elements";

const HowDoesConflictMakeYouFeelContext = React.createContext(undefined);

const HowDoesConflictMakeYouFeelProvider: React.FC<ExerciseScreenProps> = (
  props
) => {
  const defaultState = {
    feeling1: "",
    feeling2: "",
    feeling3: "",
    blobs: [],
  };

  const {
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  } = props;

  const initialState = { ...defaultState, ...completedExercise?.data };

  const [feeling1, setFeeling1] = React.useState<string>(initialState.feeling1);
  const [feeling2, setFeeling2] = React.useState<string>(initialState.feeling2);
  const [feeling3, setFeeling3] = React.useState<string>(initialState.feeling3);
  const [blobs, setBlobs] = React.useState(initialState.blobs);

  const value = {
    feeling1,
    setFeeling1,
    feeling2,
    setFeeling2,
    feeling3,
    setFeeling3,
    blobs,
    setBlobs,
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  };

  return (
    <HowDoesConflictMakeYouFeelContext.Provider value={value} {...props} />
  );
};

function useHowDoesConflictMakeYouFeel() {
  const context = React.useContext(HowDoesConflictMakeYouFeelContext);
  if (!context) {
    throw new Error(
      "useHowDoesConflictMakeYouFeel must be used within a HowDoesConflictMakeYouFeelProvider"
    );
  }
  return context;
}

export {
  HowDoesConflictMakeYouFeelProvider,
  HowDoesConflictMakeYouFeelContext,
  useHowDoesConflictMakeYouFeel,
};
