import { Controller } from "@hotwired/stimulus";
import { AsYouType } from "libphonenumber-js";

export default class extends Controller {
  static targets = ["input"];
  declare readonly inputTarget: HTMLInputElement;

  connect() {
    this.format();
  }

  format() {
    const value = this.inputTarget.value;

    if (value.includes("(") && !value.includes(")")) {
      const withoutParenthesis = value.replace("(", "");
      const withoutLastDigit = withoutParenthesis.slice(
        0,
        withoutParenthesis.length - 1
      );
      this.inputTarget.value = withoutLastDigit;
    } else {
      this.inputTarget.value = new AsYouType("US").input(value);
    }
  }
}
