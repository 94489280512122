import { Controller } from "@hotwired/stimulus";

/*
The purpose of this controller is to be able to use vh in CSS and take
Android Chrome into account. Android Chrome considers 100vh to include the
UI elements in the height calculation, which means setting 100vh on
Android is bigger than 100vh on iOS/desktop. This controller sets a CSS variable
that can be used in CSS instead of 100vh, and it will be calculated based
on the actual viewport height. Quite a sadness.
*/

export default class extends Controller {
  connect() {
    this.adjustViewHeight();
    window.addEventListener("resize", this.adjustViewHeight);
  }

  disconnect() {
    window.removeEventListener("resize", this.adjustViewHeight);
  }

  adjustViewHeight = () => {
    // Use window.innerHeight to get the actual visible height
    // Set the property on the root document
    document.documentElement.style.setProperty(
      "--custom-100vh-in-pixels",
      `${window.innerHeight}px`
    );
  };
}
