import { Controller } from "@hotwired/stimulus";
import tailwindConfig from "@js/../../tailwind.config";

const rangeSliderColor = tailwindConfig.theme.colors.green[300];
const unfilledSliderColor = tailwindConfig.theme.colors.gray[400];
const nullStateSliderColor = tailwindConfig.theme.colors.gray[300];

export default class extends Controller {
  static get targets() {
    return ["output", "input", "leftDecoration", "rightDecoration"];
  }
  declare outputTarget: HTMLOutputElement;
  declare inputTarget: any;
  declare hasLeftDecorationTarget: boolean;
  declare leftDecorationTarget: HTMLOutputElement;
  declare hasRightDecorationTarget: boolean;
  declare rightDecorationTarget: HTMLOutputElement;

  initialize() {
    if (
      this.inputTarget.getAttribute("data-range-slider-is-null-state") == "true"
    ) {
      this.updateNullStateStyles();
    } else {
      this.updateStyles();
    }
  }

  updateValue() {
    this.updateStyles();
  }

  private updateStyles() {
    const { value, max, min }: { value: number; max: number; min: number } =
      this.inputTarget;
    const numerator = value - min;
    const denominator = max - min;
    const percentage = (numerator / denominator) * 100;
    this.outputTarget.innerHTML = value.toString();
    // Set styles based on percentage of bar
    this.inputTarget.style.background = `linear-gradient(to right, ${rangeSliderColor} 0%, ${rangeSliderColor} ${percentage}%, ${unfilledSliderColor} ${percentage}%, ${unfilledSliderColor} 100%)`;
    // Set styles for null state to update web slider thumb
    this.inputTarget.className = this.inputTarget.className.replace(
      "null-state",
      ""
    );
    if (this.hasLeftDecorationTarget) {
      this.leftDecorationTarget.style.color = unfilledSliderColor;
    }
    if (this.hasRightDecorationTarget) {
      this.rightDecorationTarget.style.color = unfilledSliderColor;
    }
  }

  private updateNullStateStyles() {
    this.outputTarget.innerHTML = "Drag to select";
    // Set styles based on percentage of bar
    this.inputTarget.style.background = `linear-gradient(to right, ${nullStateSliderColor} 0%, ${nullStateSliderColor} 50%, ${nullStateSliderColor} 50%, ${nullStateSliderColor} 100%)`;
    if (this.hasLeftDecorationTarget) {
      this.leftDecorationTarget.style.color = nullStateSliderColor;
    }
    if (this.hasRightDecorationTarget) {
      this.rightDecorationTarget.style.color = nullStateSliderColor;
    }
  }
}
