import { Controller } from "@hotwired/stimulus";
import Brightline from "@js/helpers/dom";

export default class extends Controller {
  updateContent(event: any) {
    // gets target name, then updates innerHTML on reflection page with input value
    const target = event.target.attributes["data-reflection-element"].value;
    Brightline.setHTMLForId(target, event.target.value);
    document
      .querySelectorAll(`[data-reflection-container='${target}'`)
      .forEach((elt) => (elt.innerHTML = event.target.value));
  }

  updateValue(event: any) {
    // gets target name, then updates value on reflection page with input value
    const target = event.target.attributes["data-reflection-element"].value;
    Brightline.setValueForId(target, event.target.value);
  }
}
