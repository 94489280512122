import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";

import { useExerciseParams } from "../Context";
import Mo from "./Mo";
import ThoughtBubble from "./ThoughtBubble";

const ExerciseGraphic: React.FC<{
  initialThought: string;
  initialThermometerValue: number;
  finalThought: string;
  finalThermometerValue: number;
}> = ({
  initialThought,
  initialThermometerValue,
  finalThought,
  finalThermometerValue,
}): JSX.Element => {
  const { step } = useExerciseParams();

  const initialThoughtBubbleControls = useAnimation();
  const finalThoughtBubbleControls = useAnimation();

  const initialThoughtBubbleVariants = {
    1: { opacity: 1, x: 70, y: 40 },
    2: { opacity: 1, x: 70, y: 40 },
    3: { opacity: 1, x: -60, y: 0, transition: { duration: 3 } },
    4: { opacity: 1, x: -60, y: 0, transition: { duration: 3 } },
    5: { opacity: 1, x: 70, y: 40, transition: { duration: 3 } },
  };

  const finalThoughtBubbleVariants = {
    1: { opacity: 0, x: 280, y: 40 },
    2: { opacity: 0, x: 280, y: 40 },
    3: { opacity: 1, x: 280, y: 40, transition: { delay: 2.5 } },
    4: { opacity: 1, x: 280, y: 40 },
    5: { opacity: 1, x: 280, y: 40 },
  };

  useEffect(() => {
    initialThoughtBubbleControls.start(step.toString());
    finalThoughtBubbleControls.start(step.toString());
  }, [step]);

  return (
    <svg
      height="278"
      width="345"
      className="mx-auto text-center shadow bg-cream align-center rounded-2xl text-lightBrown"
    >
      <g transform="translate(100 120)">
        <Mo />
      </g>
      <motion.g
        animate={initialThoughtBubbleControls}
        variants={initialThoughtBubbleVariants}
        initial="1"
      >
        <ThoughtBubble
          key="initialThoughtBubble"
          thermometerValue={initialThermometerValue}
          text={initialThought}
          direction="left"
        />
      </motion.g>
      <motion.g
        animate={finalThoughtBubbleControls}
        variants={finalThoughtBubbleVariants}
        initial="1"
      >
        <ThoughtBubble
          thermometerValue={finalThermometerValue}
          text={finalThought}
          key="finalThoughtBubble"
          direction="right"
        />
      </motion.g>
    </svg>
  );
};

export default ExerciseGraphic;
