import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link", "param"];

  static values = {
    url: String,
    params: Object,
  };

  setLinkUrl(event) {
    let [selectedUrl, paramString] = this.determineUrlAndParams(event);
    const urlParams = this.buildUrlParams(paramString);
    const finalUrl = `${selectedUrl}?${urlParams.toString()}`;

    this.linkTarget.href = finalUrl;
  }

  determineUrlAndParams(event) {
    // If a specific URL has been specified, use that.
    // Otherwise, grab the URL from the selected option,
    // and then fall back to the href attached to the link target
    return [
      this.urlValue,
      event.target.selectedOptions?.[0].dataset.url,
      this.linkTarget.href,
    ]
      .find((url) => url?.length > 0)
      .split("?");
  }

  buildUrlParams(paramString) {
    // Combine any existing params with the new params
    const passedParams = Object.entries(this.paramsValue).map(
      ([key, value]) => ({ name: key, value: value })
    );

    const startingParams = new URLSearchParams(paramString ?? "");
    return [...passedParams, ...this.paramTargets].reduce(
      (params, paramTarget) => {
        const name = paramTarget.dataset?.paramName || paramTarget.name;
        const value = paramTarget.value;

        if (params.has(name)) {
          params.set(name, value);
        } else {
          params.append(name, value);
        }

        return params;
      },
      startingParams
    );
  }
}
