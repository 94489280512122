import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get values() {
    return {
      steps: {
        step_1: { type: String },
        step_2: { type: String },
        step_3: { type: String },
        step_4: { type: String },
        step_5: { type: String },
        step_6: { type: String },
        step_7: { type: String },
        step_8: { type: String },
      },
    };
  }

  // steps are stored in state (string values of chosen dropdown values)
  declare stepsValue: object;

  initialize() {
    // loop through to build initial value of steps state
    for (let i: number = 1; i < 9; i++) {
      // get existing state if available
      let stepValue = (
        document.getElementById(
          `completed_exercise_data_step_${i}`
        ) as HTMLSelectElement
      ).value;
      // set internal stimulus controller state
      this.stepsValue[`step_${i}`] =
        stepValue === "Choose an activity..." ? null : stepValue;
      // add relevant images below dropdowns that have available state
      stepValue !== "Choose an activity..." &&
        this.addImageToDOM(stepValue, `step_${i}-image-wrapper`);
    }
  }

  // fired when dropdown value is selected
  addRoutineStep(event: any) {
    // pull the step that is modified -- string in format "step_1" etc.
    const step: string = event.target.ariaLabel;
    // update internal state
    this.stepsValue[step] =
      event.target.value === "Choose an activity..."
        ? null
        : event.target.value;
    // remove old image if one existed
    this.removeImageFromDOM(`${step}-image-wrapper`);
    // add new image if there is a value present
    event.target.value !== "Choose an activity..." &&
      this.addImageToDOM(event.target.value, `${step}-image-wrapper`);
  }

  // triggered when next button is hit
  formatReflectionPage() {
    // move to top of page
    $("html,body").scrollTop(0);
    // set all divs to hidden and remove images (to refresh state in case previous button was hit)
    for (let i = 1; i < 9; i++) {
      document.getElementById(`step-${i}-reflection`).style.display = "none";
      document
        .getElementById(`step-${i}-reflection-image-wrapper-image`)
        ?.remove();
    }
    // filter steps down to those with an actual value
    const filteredSteps = Object.values(this.stepsValue).filter(
      (value) => value != null
    );
    // loop through steps to build reflection page, first showing step in DOM and then filling in image and text
    filteredSteps.forEach((step_value: string, i: number) => {
      const index = i + 1; // steps need to start at one, not zero
      document.getElementById(`step-${index}-reflection`).style.display =
        "flex";
      this.addImageToDOM(step_value, `step-${index}-reflection-image-wrapper`);
      document.getElementById(`step-${index}-text`).innerText = step_value;
    });
  }

  // given parent, remove stale child image from DOM
  private removeImageFromDOM(parent) {
    let oldImage = document.getElementById(`${parent}-image`);
    // null check because this function is called in loop for reflection page
    oldImage && oldImage.remove();
  }

  // add image of given name to parent in DOM
  private addImageToDOM(name: string, parent: string) {
    // format name from dropdown values (spaces replaced with "-" and all lowercase) to what exists in pack library
    const newImageName: string = name.toLowerCase().replace(/ /g, "-");
    // clone existing hidden version from the DOM
    let newImage: HTMLImageElement = document
      .getElementById(newImageName)
      .cloneNode(true) as HTMLImageElement;
    // update ID to allow easy removal
    newImage.id = `${parent}-image`;
    newImage.style.display = "flex";
    // add image to provided parent
    document.getElementById(parent).appendChild(newImage);
  }
}
