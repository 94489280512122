import MappingNeedsIcon from "@js/images/icons/MappingNeeds.svg";
import React from "react";
import { Route } from "react-router-dom";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { MappingYourNeedsProvider } from "./Context";
import Instructions from "./Instructions";
import Map from "./Map";
import Summary from "./Summary";

const Screens: React.FC<ExerciseScreenProps> = (props) => {
  const title = "Mapping Your Needs";
  const totalPages = 8;
  let pageNumber = 1;

  const intro = (
    <EE.GenericExerciseIntroPage
      imageSrc={MappingNeedsIcon}
      imageAltText="A pyramid."
      title={title}
      introText="What matters the most to you? Mapping that out can help you know where to fit in."
      nextPage="/instructions"
    />
  );

  return (
    <MappingYourNeedsProvider {...props}>
      <EE.WrapperWithIntro intro={intro}>
        <EE.ExerciseNavigation title={title} />

        <Route path="/instructions">
          <Instructions nextPage="/map" />
        </Route>

        <Route path="/map">
          <Map nextPage="/summary" />
        </Route>

        <Route path="/summary">
          <Summary />
        </Route>
      </EE.WrapperWithIntro>
    </MappingYourNeedsProvider>
  );
};

export default Screens;
