import { Controller } from "@hotwired/stimulus";
import { ONE_SECOND } from "@js/helpers/constants";
import Rails from "@rails/ujs";
import debounce from "lodash/debounce";

/**
 * Adds display behavior to a multiselect UI element.
 *
 * Applies a `multiselect--open` class to menu button
 * when its menu is open.
 */
// TODO: This is getting large and should be decomposed into multiple controllers
export default class extends Controller {
  static targets = ["label"];
  static values = { defaults: Object };
  declare readonly labelTargets: HTMLElement[];
  declare readonly defaultsValue: Record<string, string>;
  currentRequest: any = null;

  initialize() {
    this.submitForm = debounce(this.submitForm, ONE_SECOND).bind(this);
  }

  connect() {
    this.setOuterLabel();
    this.element.addEventListener("ajax:success", () => this.setReady());

    this.element.addEventListener("ajax:send", (event) => {
      this.setLoading();

      this.abortInProgressXHRRequest(event);
    });
    this.element.addEventListener("ajax:complete", () => {
      this.currentRequest = null;
    });
  }

  setOuterLabel() {
    if (!this.isHTMLFormElement(this.element)) return;

    const formData = new FormData(this.element);

    const fields = new Fields(this.defaultsValue);
    for (var [key, label] of formData.entries()) {
      const match = /\[(?<field>.*?)\]/.exec(key);
      const fieldName = match?.groups?.field;
      if (fieldName) {
        fields.appendLabelToField({
          label: label.toString().replace(/_/g, " "),
          field: fieldName,
        });
      }
    }

    fields.forEach((field, labelParts) => {
      const labelText = this.defaultsValue[field];
      const label = new Label(labelText);
      label.updateFromParts(labelParts);
    });
  }

  submitForm() {
    Rails.fire(this.element, "submit");
  }

  unselectAll(event: Event) {
    if (!this.isElement(event.target)) return;

    event.preventDefault();
    event.target.parentNode
      ?.querySelectorAll('[type="checkbox"]')
      .forEach((checkbox) => {
        if (this.isInput(checkbox)) {
          checkbox.checked = false;
        }
      });
    this.setOuterLabel();

    if (this.isHTMLFormElement(this.element)) {
      this.element.submit();
    }
  }

  private abortInProgressXHRRequest(event: any) {
    if (this.currentRequest) {
      this.currentRequest.abort();
    }
    this.currentRequest = event.detail[0];
    return true;
  }

  private setLoading() {
    this.element.classList.add("multiselect--loading");
    document
      .querySelectorAll(".multiselect__content--ready")
      .forEach((element) => element.classList.add("hidden"));
    document
      .querySelectorAll(".multiselect__content--loading")
      .forEach((element) => {
        element.classList.remove("hidden");
        element.classList.add("flex");
      });
  }

  private setReady() {
    this.element.classList.remove("multiselect--loading");
    document
      .querySelectorAll(".multiselect__content--ready")
      .forEach((element) => element.classList.remove("hidden"));
    document
      .querySelectorAll(".multiselect__content--loading")
      .forEach((element) => {
        element.classList.add("hidden");
        element.classList.remove("flex");
      });
  }

  private isHTMLFormElement(value: any): value is HTMLFormElement {
    return !!value.acceptCharset;
  }

  private isElement(target: any): target is Element {
    return !!target.parentNode;
  }

  private isInput(element: any): element is HTMLInputElement {
    return !!element.value || !!element.type;
  }
}

class Label {
  private element: Element;
  private MAX_LABELS_TO_SHOW: number = 3;

  constructor(private fieldName: string) {
    this.element = this.getLabelElementForField(fieldName);
  }

  private getLabelElementForField(field: string) {
    const element = document.querySelector(`[data-label-for=${field}]`);
    if (!element)
      throw new Error(`No element found with data-label-for=${field}`);
    return element;
  }

  updateFromParts(parts: string[]) {
    if (parts.length > this.MAX_LABELS_TO_SHOW) {
      const labelParts = parts.slice(0, this.MAX_LABELS_TO_SHOW).join(", ");
      this.element.innerHTML = `${labelParts}, +${
        parts.length - this.MAX_LABELS_TO_SHOW
      }`;
      this.element.classList.add("multiselect--active");
    } else if (parts.length === 0) {
      this.element.innerHTML = this.fieldName;
      this.element.classList.remove("multiselect--active");
    } else {
      this.element.innerHTML = parts.join(", ");
      this.element.classList.add("multiselect--active");
    }
  }

  toggleOpen() {
    this.element.classList.toggle("multiselect--open");
  }
}

class Fields {
  private fields: Record<string, string[]> = {};

  constructor(private defaultFieldLabels: Record<string, string>) {
    this.initializeFields();
  }

  private initializeFields() {
    Object.keys(this.defaultFieldLabels).forEach(
      (fieldName) => (this.fields[fieldName] = [])
    );
  }

  appendLabelToField({ label, field }: { label: string; field: string }) {
    this.fields[field] ||= [];
    this.fields[field].push(label);
  }

  forEach(callback: (field: string, labelParts: string[]) => void) {
    for (const field in this.fields) {
      const labelParts = this.fields[field];
      callback(field, labelParts);
    }
  }
}
