class ScrollController {
  constructor(element) {
    this.element = element;
    // scrollers have to be in sibling trees for now to allow
    // for multiple scrollers on the same page, like in Library
    this.leftScroller = this.element.parentElement.querySelector(
      `[data-scrollable-direction=left]`
    );
    this.rightScroller = this.element.parentElement.querySelector(
      `[data-scrollable-direction=right]`
    );

    this.onScroll();
  }

  canScroll = () => this.element.scrollWidth > this.element.offsetWidth;
  canScrollLeft = () => this.element.scrollLeft > 0;
  canScrollRight = () => {
    const rightEdge = this.element.scrollWidth - this.element.offsetWidth;
    return this.element.scrollLeft < rightEdge - 1;
  };

  scroll = (directionCoefficient) =>
    this.element.scrollBy({
      left: (this.element.offsetWidth + 4) * directionCoefficient,
      behavior: "smooth",
    });

  onScroll = () => {
    if (this.canScroll() && this.canScrollLeft()) {
      this.leftScroller.classList.remove("hidden");
      this.element.classList.add("fade-left");
    } else {
      this.leftScroller.classList.add("hidden");
      this.element.classList.remove("fade-left");
    }

    if (this.canScroll() && this.canScrollRight()) {
      this.rightScroller.classList.remove("hidden");
      this.element.classList.add("fade-right");
    } else {
      this.rightScroller.classList.add("hidden");
      this.element.classList.remove("fade-right");
    }
  };
}

function scrollOnClick() {
  for (const scrollContainer of document.querySelectorAll(
    "[data-scrollable]"
  )) {
    const scrollController = new ScrollController(scrollContainer);

    scrollController.leftScroller.addEventListener("click", () =>
      scrollController.scroll(-1)
    );
    scrollController.rightScroller.addEventListener("click", () =>
      scrollController.scroll(1)
    );

    scrollController.element.addEventListener(
      "scroll",
      scrollController.onScroll
    );
  }
}

document.addEventListener("turbolinks:load", () => {
  scrollOnClick();
});
