import { Controller } from "@hotwired/stimulus";

// When applied to the element, will prevent the page from scrolling to the top
// when a Turbo visit is completed.
export default class extends Controller {
  connect() {
    document.addEventListener("turbo:render", this.preventScroll);
  }

  disconnect() {
    document.removeEventListener("turbo:render", this.preventScroll);
  }

  preventScroll = () => {
    window.Turbo.navigator.currentVisit.scrolled = true;
  };
}
