import React from "react";
import { Link } from "react-router-dom";

import { useThermometerImage } from "../shared";
import { useThermometer } from "../shared/Context";
import {
  ExerciseElements as EE,
  ThermometerElementsProps,
} from "../shared/Elements";

const Exercise = (thermometerElements: ThermometerElementsProps) => {
  const {
    minValue,
    minValueLabel,
    maxValue,
    maxValueLabel,
    exerciseQuestion,
    imageIsFace,
    ImageMapping,
    IntroductionText,
    InstructionsText,
  } = thermometerElements;

  const {
    thermometerValue,
    setThermometerValue,
    patientFirstName,
    chatLink,
    careLink,
  } = useThermometer();

  const { ThermometerImage } = useThermometerImage(
    ImageMapping,
    thermometerValue,
    minValue
  );

  const imageStyles = (imageIsFace = false) => {
    if (imageIsFace) {
      return "h-40 mx-auto pt-6 pb-4";
    } else {
      return "h-40 mx-auto";
    }
  };

  return (
    <div className="p-2">
      <div className="flex-grow-0">
        <EE.RoundedExerciseCard className="py-2">
          <ThermometerImage className={imageStyles(imageIsFace)} />
          <div className="mt-4">
            <EE.ThermometerNumber>{thermometerValue}</EE.ThermometerNumber>
          </div>
          <div className="px-4 sm:px-12 pt-10 pb-4">
            <EE.ThermometerSlider
              minValue={minValue}
              maxValue={maxValue}
              thermometerValue={thermometerValue}
              onChange={setThermometerValue}
              ariaLabelledByForHandle="slider-label"
            />
            <EE.ExerciseAnchors>
              <EE.ExerciseAnchor className="text-left">
                {minValueLabel}
              </EE.ExerciseAnchor>
              <EE.ExerciseAnchor className="text-right">
                {maxValueLabel}
              </EE.ExerciseAnchor>
            </EE.ExerciseAnchors>
          </div>
          <p className="font-sans text-mediumBrown pb-10" id={"slider-label"}>
            Rate {patientFirstName}'s {exerciseQuestion}.
          </p>
        </EE.RoundedExerciseCard>
        <div className="flex flex-col justify-center">
          <EE.ExerciseCallToActionLink to="/comments">
            Next
          </EE.ExerciseCallToActionLink>

          <EE.SecondaryActionLink text="Cancel" to={careLink} />
          <hr className="mt-10 mb-6" />
        </div>
      </div>

      <EE.Header>Introduction</EE.Header>
      <IntroductionText />
      <EE.Header>Instructions</EE.Header>
      <InstructionsText />
      <EE.HaveQuestions chatLink={chatLink} />
    </div>
  );
};

export default Exercise;
