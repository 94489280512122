import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["appointmentDatetime", "stafferId"];

  selectValues(event) {
    const radio = event.target;

    const appointmentDatetime = radio.getAttribute("data-appointment-datetime");
    const stafferId = radio.getAttribute("data-staffer-id");

    this.appointmentDatetimeTarget.value = appointmentDatetime;
    this.stafferIdTarget.value = stafferId;
  }
}
