import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import BodyMap from "./BodyMap";
import { useHowDoesConflictMakeYouFeel } from "./Context";

const FindOnBody = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const {
    feeling1,
    feeling2,
    feeling3,
    blobs,
    assignedExerciseId,
    completedExercise,
    readOnly,
  } = useHowDoesConflictMakeYouFeel();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      feeling1,
      feeling2,
      feeling3,
      blobs,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <>
      <EE.StepIndicator numSteps={numSteps} currentStep={currentStep} />
      <EE.SubmitableForm submitExercise={submitExercise}>
        <EE.Subheading>
          Where on your body do you feel conflict or feel upset? Click on the
          figure below
        </EE.Subheading>
        <BodyMap propsReadOnly={readOnly} />
        <EE.ButtonGroup>
          <EE.ExerciseCallToActionLink to={nextPage}>
            Next
          </EE.ExerciseCallToActionLink>

          <EE.SaveForLaterButtonGreen
            text="Save for Later"
            setExerciseComplete={setExerciseComplete}
          />
        </EE.ButtonGroup>
      </EE.SubmitableForm>
    </>
  );
};

export default FindOnBody;
