/*
Purpose: This controller is intended to be applied to <details> elements as a supplement to the native `toggle` event, adding specific events for when toggling the details results in an `opened` state or a `closed` state.

Example:
<details data-controller="details-events ahoy" data-action="opened->ahoy#track" data-ahoy-event="MyDetailsInformation:Viewed">
  <!-- information that is initially hidden within the details element -->
</details>
*/

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("toggle", (event) => {
      if (this.element.open) {
        this.dispatch("opened", { detail: event, prefix: false });
      } else {
        this.dispatch("closed", { detail: event, prefix: false });
      }
    });
  }
}
