import { Controller } from "@hotwired/stimulus";

import { readyForTest } from "./mixins/readyForTest";

export default class extends Controller {
  static targets = ["element"];
  static values = { className: String };

  toggle(event) {
    event.preventDefault();

    this.elementTargets.forEach((target) => {
      const toggleClassName = target.dataset.toggleableClassName;
      target.classList.toggle(toggleClassName ?? this.classNameValue);
    });
  }

  toggleClickedElement(event) {
    event.preventDefault();

    const target = event.target;
    const toggleClassName = target.dataset.toggleableClassName;
    target.classList.remove(toggleClassName ?? this.classNameValue);
  }

  connect() {
    readyForTest(this);
  }
}
