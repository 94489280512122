import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["completedQuestions"];
  }
  declare readonly completedQuestionsTarget: { value: number };
  seeAnswer(event: any) {
    const seeAnswerButton = document.getElementById(event.target.id);
    const answerBlock = document.getElementById(`${event.target.id}-answer`);
    // don't love this but it seemed the cleanest way to keep tailwind styles simple
    answerBlock.style.opacity = "1";
    answerBlock.style.marginTop = "0px";
    // check if button should become "I'm done" or "Next"
    switch (event.target.getAttribute("data-quiz-location-param")) {
      case "conclusion":
        // prevent auto-submit of form
        setTimeout(() => {
          seeAnswerButton.innerText = "I'm done";
          seeAnswerButton.removeAttribute("data-action");
          seeAnswerButton.setAttribute("type", "submit");
          seeAnswerButton.setAttribute("name", "commit");
          seeAnswerButton.setAttribute("value", "Complete");
          seeAnswerButton.setAttribute("id", "footer-complete");
        }, 20);
        this.completedQuestionsTarget.value++;
        break;
      case "step":
        seeAnswerButton.innerText = "Next";
        seeAnswerButton.setAttribute("data-action", "exercise-navigation#next");
        this.completedQuestionsTarget.value++;
        break;
      default:
        // hide answer button
        seeAnswerButton.style.display = "none";
        // add in back to care button
        const backToCareButton: HTMLButtonElement = document.getElementById(
          "back-to-care"
        ) as HTMLButtonElement;
        backToCareButton.style.display = "flex";
        seeAnswerButton.parentElement.replaceChild(
          backToCareButton,
          seeAnswerButton
        );
        break;
    }
  }
}
