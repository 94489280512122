import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useWheelOfLife } from "./Context";
import { Source, SourceSection, defaultSource } from "./Shared";

const IdealSelfWorth = (props: { nextPage: string }) => {
  const { nextPage } = props;

  const {
    currentSources,
    idealSources,
    setIdealSources,
    assignedExerciseId,
    completedExercise,
  } = useWheelOfLife();

  const addNewSource = (event) => {
    event.preventDefault();

    if (maxSources()) return false;

    const tmpSources = [...idealSources, defaultSource];
    setIdealSources(tmpSources);
  };

  const maxSources = () => {
    return idealSources.length == 10;
  };

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      currentSources,
      idealSources,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  const totalPercent = () => {
    let total = 0;
    idealSources.forEach((source) => {
      if (parseInt(source.percent)) {
        total += parseInt(source.percent);
      }
    });
    return total;
  };

  return (
    <EE.SubmitableForm submitExercise={submitExercise}>
      <h2 className="text-xl md:mt-12">
        Where do you <span className="italic">ideally</span> want to get your
        self-worth?
      </h2>

      <EE.Paragraph className="mt-6">
        List your ideal sources of self-worth and how much importance you would
        like to give each. Make sure your percentages add up to 100%
      </EE.Paragraph>

      {idealSources.map((_source: Source, index: number) =>
        SourceSection(index, idealSources, setIdealSources)
      )}

      <button
        className="mt-8 text-green-700"
        onClick={addNewSource}
        hidden={maxSources()}
      >
        + Add another source
      </button>

      <p className="mt-8 font-bold">
        Your percentages add up to: {totalPercent()}%
      </p>

      <EE.ButtonGroup>
        <EE.ExerciseCallToActionLink
          to={nextPage}
          disabled={totalPercent() != 100}
        >
          Next
        </EE.ExerciseCallToActionLink>
      </EE.ButtonGroup>
    </EE.SubmitableForm>
  );
};

export default IdealSelfWorth;
