import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  observer: MutationObserver;

  connect() {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        [...mutation.addedNodes]
          .filter((node) => node.nodeType === Node.ELEMENT_NODE)
          .forEach((node) => {
            this.removeLinks([...(node as HTMLElement).querySelectorAll("a")]);
          });
      });
    });

    this.observer.observe(this.element, { childList: true, subtree: true });

    this.removeLinks([...this.element.querySelectorAll("a")]);
  }

  disconnect() {
    this.observer.disconnect();
  }

  removeLinks(links: HTMLAnchorElement[]) {
    links.forEach((a) => {
      if (!a.href.includes("#")) {
        a.remove();
      }
    });
  }
}
