import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import React from "react";
import { HashRouter as Router, Switch } from "react-router-dom";

import AllAboutMeScreens from "./AllAboutMe";
import BehaviorObservationChartScreens from "./BehaviorObservationChart";
import BigFeelingsScreens from "./BigFeelings";
import BodyMapScreens from "./BodyMap";
import BucketFillingAndBucketDippingScreens from "./BucketFillingAndBucketDipping";
import ChecklistBuilderScreens from "./ChecklistBuilder";
import DoingSomethingFunToFeelBetterScreens from "./DoingSomethingFunToFeelBetter";
import DoubleBubbleScreens from "./DoubleBubble";
import FearLadderScreens from "./FearLadder";
import FiveStepsScreens from "./FiveSteps";
import FixedVsGrowthMindsetsScreens from "./FixedVsGrowthMindsets";
import FollowingMyPlansScreens from "./FollowingMyPlans";
import FourFactorsScreens from "./FourFactors";
import GetToKnowYourselfScreens from "./GetToKnowYourself";
import HowDoesConflictMakeYouFeel from "./HowDoesConflictMakeYouFeel";
import HowIShowMyFeelingsScreens from "./HowIShowMyFeelings";
import IMessagesAndYouMessagesScreens from "./IMessagesAndYouMessages";
import IStatementsScreens from "./IStatements";
import LearningAboutAnxietyScreens from "./LearningAboutAnxiety";
import LearningToRelaxScreens from "./LearningToRelax";
import LearningYourAnxiousFeelingsScreens from "./LearningYourAnxiousFeelings";
import MakeAPlanScreens from "./MakeAPlan";
import MakingAPlanScreens from "./MakingAPlan";
import MappingYourNeedsScreens from "./MappingYourNeeds";
import MyCalmingBoxScreens from "./MyCalmingBox";
import MyFavoriteSkillsForFeelingGoodScreens from "./MyFavoriteSkillsForFeelingGood";
import MyFeelingsScaleScreens from "./MyFeelingsScale";
import MyNegativeSelfMyPositiveSelfScreens from "./MyNegativeSelfMyPositiveSelf";
import MyProgramScreen from "./MyProgram";
import MyRelaxingPlaceScreens from "./MyRelaxingPlace";
import MySelfInventoryScreens from "./MySelfInventory";
import MySocialMapKidsScreen from "./MySocialMapKids";
import MySocialMapTeensScreen from "./MySocialMapTeens";
import MyStrengthsScreen from "./MyStrengths";
import ParentObservationRecordScreens from "./ParentObservationRecord";
import PersonalSafetyPlanScreens from "./PersonalSafetyPlan";
import PracticingMyNewSkillsScreens from "./PracticingMyNewSkills";
import SchedulingTimeForFunScreens from "./SchedulingTimeForFun";
import SelfEsteemJournalScreens from "./SelfEsteemJournal";
import SessionSummaryScreen from "./SessionSummary";
import { ExerciseFormProvider } from "./shared/Context";
import { ExerciseScreenProps } from "./shared/Elements";
import ShowingYouCareScreens from "./ShowingYouCare";
import SlothSharkOwlScreens from "./SlothSharkOwl";
import SLPEvaluationSummaryScreen from "./SLPEvaluationSummary";
import StopWorksheetScreens from "./StopWorksheet";
import StrategiesToRelieveStressScreens from "./StrategiesToRelieveStress";
import StressManagementToolsScreens from "./StressManagementTools";
import TenThingsICanDoToFeelGoodScreens from "./TenThingsICanDoToFeelGood";
import ThinkingAheadScreens from "./ThinkingAhead";
import ThinkingFeelingDoingScreens from "./ThinkingFeelingDoing";
import TimeOutScreens from "./TimeOut";
import WhatILoveAboutYouScreen from "./WhatILoveAboutYou";
import WhatsNewScreens from "./WhatsNew";
import WheelOfLifeScreens from "./WheelOfLife";
import WhoIAmScreens from "./WhoIAm";

const EXERCISE_SCREENS = {
  AllAboutMe: AllAboutMeScreens,
  BehaviorObservationChart: BehaviorObservationChartScreens,
  BigFeelings: BigFeelingsScreens,
  BodyMap: BodyMapScreens,
  BucketFillingAndBucketDipping: BucketFillingAndBucketDippingScreens,
  ChecklistBuilder: ChecklistBuilderScreens,
  DoingSomethingFunToFeelBetter: DoingSomethingFunToFeelBetterScreens,
  DoubleBubble: DoubleBubbleScreens,
  FearLadder: FearLadderScreens,
  FiveSteps: FiveStepsScreens,
  FixedVsGrowthMindsets: FixedVsGrowthMindsetsScreens,
  FollowingMyPlans: FollowingMyPlansScreens,
  FourFactors: FourFactorsScreens,
  GetToKnowYourself: GetToKnowYourselfScreens,
  HowIShowMyFeelings: HowIShowMyFeelingsScreens,
  HowDoesConflictMakeYouFeel: HowDoesConflictMakeYouFeel,
  IMessagesAndYouMessages: IMessagesAndYouMessagesScreens,
  IStatements: IStatementsScreens,
  LearningAboutAnxiety: LearningAboutAnxietyScreens,
  LearningToRelax: LearningToRelaxScreens,
  LearningYourAnxiousFeelings: LearningYourAnxiousFeelingsScreens,
  MakeAPlan: MakeAPlanScreens,
  MakingAPlan: MakingAPlanScreens,
  MappingYourNeeds: MappingYourNeedsScreens,
  MyCalmingBox: MyCalmingBoxScreens,
  MyFavoriteSkillsForFeelingGood: MyFavoriteSkillsForFeelingGoodScreens,
  MyFeelingsScale: MyFeelingsScaleScreens,
  MyNegativeSelfMyPositiveSelf: MyNegativeSelfMyPositiveSelfScreens,
  MyProgram: MyProgramScreen,
  MyRelaxingPlace: MyRelaxingPlaceScreens,
  MySelfInventory: MySelfInventoryScreens,
  MySocialMapKids: MySocialMapKidsScreen,
  MySocialMapTeens: MySocialMapTeensScreen,
  MyStrengths: MyStrengthsScreen,
  ParentObservationRecord: ParentObservationRecordScreens,
  PersonalSafetyPlan: PersonalSafetyPlanScreens,
  PracticingMyNewSkills: PracticingMyNewSkillsScreens,
  SchedulingTimeForFun: SchedulingTimeForFunScreens,
  SelfEsteemJournal: SelfEsteemJournalScreens,
  SessionSummary: SessionSummaryScreen,
  ShowingYouCare: ShowingYouCareScreens,
  SlothSharkOwl: SlothSharkOwlScreens,
  SLPEvaluationSummary: SLPEvaluationSummaryScreen,
  StopWorksheet: StopWorksheetScreens,
  StrategiesToRelieveStress: StrategiesToRelieveStressScreens,
  StressManagementTools: StressManagementToolsScreens,
  TenThingsICanDoToFeelGood: TenThingsICanDoToFeelGoodScreens,
  ThinkingAhead: ThinkingAheadScreens,
  ThinkingFeelingDoing: ThinkingFeelingDoingScreens,
  TimeOut: TimeOutScreens,
  WhatILoveAboutYou: WhatILoveAboutYouScreen,
  WhatsNew: WhatsNewScreens,
  WheelOfLife: WheelOfLifeScreens,
  WhoIAm: WhoIAmScreens,
};

const App = (props: ExerciseScreenProps) => {
  const apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
      uri: "/graphql",
      headers: {
        "X-CSRF-Token": props.csrfToken,
      },
    }),
  });

  const ExerciseScreenComponent = EXERCISE_SCREENS[props.componentName];
  if (ExerciseScreenComponent === undefined) {
    throw new Error(
      `The exercise component does not exist ${props.componentName}`
    );
  }
  return (
    <div>
      <Router basename="">
        <ApolloProvider client={apolloClient}>
          <Switch>
            <ExerciseFormProvider {...props}>
              <ExerciseScreenComponent {...props} />
            </ExerciseFormProvider>
          </Switch>
        </ApolloProvider>
      </Router>
    </div>
  );
};

export default App;
