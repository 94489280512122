import React from "react";

import { useThermometer, useThermometerImage } from "../shared";
import {
  ExerciseElements as EE,
  ThermometerElementsProps,
} from "../shared/Elements";

const Introduction = (thermometerElements: ThermometerElementsProps) => {
  const {
    Title,
    IntroductionText,
    initialThermometerValue,
    introductionImageValue,
    imageIsFace,
    ImageMapping,
    minValue,
  } = thermometerElements;
  const { ThermometerImage } = useThermometerImage(
    ImageMapping,
    introductionImageValue || initialThermometerValue,
    minValue
  );

  const { careLink } = useThermometer();

  const imageStyles = (imageIsFace = false) => {
    if (imageIsFace) {
      return "w-1/4 pt-8 mx-auto";
    } else {
      return "w-1/2 mx-auto";
    }
  };

  return (
    <div className="p-2">
      <div className="flex-grow-0">
        <EE.RoundedExerciseCard>
          <ThermometerImage className={imageStyles(imageIsFace)} />
          <Title />
        </EE.RoundedExerciseCard>
        <div className="pt-8 text-mediumBrown">
          <IntroductionText />
        </div>

        <div className="flex flex-col justify-center">
          <EE.ExerciseCallToActionLink to="/exercise">
            Start
          </EE.ExerciseCallToActionLink>
          <EE.SecondaryActionLink text="Cancel" href={careLink} />
          <hr className="mt-10 mb-6" />
        </div>
      </div>
    </div>
  );
};

export default Introduction;
