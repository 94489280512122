import forEach from "lodash/forEach";
import split from "lodash/split";

const splitParagraphIntoLines = (text: string, maxLettersPerLine = 26) => {
  const words = split(text, " ");
  let lettersOnLine = 0;
  let line: string[] = [];
  let array = [];
  forEach(words, (currentWord) => {
    lettersOnLine = lettersOnLine + currentWord.length + 1;
    if (lettersOnLine >= maxLettersPerLine) {
      array.push(line);
      line = [currentWord];
      lettersOnLine = currentWord.length;
    } else {
      line.push(currentWord);
    }
  });
  array.push(line);
  return array.map((line) => line.join(" "));
};

const formatPhoneNumber = (rawPhone: string): string => {
  const onlyDigits = rawPhone.replace(/\D/g, "");
  const digitsArray = onlyDigits.split("");
  const firstThreeDigits = digitsArray.slice(0, 3).join("");
  const middleThreeDigits = digitsArray.slice(3, 6).join("");
  const lastFourDigits = digitsArray.slice(6, 10).join("");
  let phoneNumber = "";

  if (firstThreeDigits.length === 3) {
    phoneNumber += `(${firstThreeDigits}`;
  } else {
    phoneNumber += firstThreeDigits;
  }

  if (middleThreeDigits.length) phoneNumber += `) ${middleThreeDigits}`;
  if (lastFourDigits.length) phoneNumber += `-${lastFourDigits}`;

  return phoneNumber;
};

export { splitParagraphIntoLines, formatPhoneNumber };
