// Adds the contents of `template` to the end of `container`
//
import { Controller } from "@hotwired/stimulus";

export default class ActOnLoadController extends Controller {
  declare readonly targetTarget: HTMLElement | HTMLFormElement;
  declare readonly hasTargetTarget: boolean;
  declare readonly actionValue: "click" | "submit";

  static targets = ["target"];
  static values = { action: { type: String, default: "click" } };

  // On load, perform the action on the target
  connect() {
    this.performAction();
  }

  // Perform the action on the target
  performAction() {
    if (!this.hasTargetTarget) {
      return;
    }

    if (this.actionValue == "click") {
      this.targetTarget.click();
      return;
    }

    if (this.actionValue == "submit") {
      (this.targetTarget as HTMLFormElement).submit();
      return;
    }
  }
}
