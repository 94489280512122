import { Controller } from "@hotwired/stimulus";
import { ONE_SECOND } from "@js/helpers/constants";
import throttle from "lodash/throttle";

export default class extends Controller {
  static targets = ["progressBar", "back"];
  static values = {
    steps: Number,
    currentStep: Number,
    throttle: { type: Number, default: ONE_SECOND },
  };
  step = this.currentStepValue || 0;

  connect() {
    this.forward = throttle(this.forward, this.throttleValue);
    this.backward = throttle(this.backward, this.throttleValue);
    this.updateProgressBar();
    this.updateBackButtonVisibility();
  }

  forward(event) {
    if (event.key && event.key !== "Enter") return;

    this.step += 1;
    this.updateBackButtonVisibility();
    this.updateProgressBar();
  }

  backward() {
    if (this.step <= 0) return;

    this.step -= 1;
    this.updateBackButtonVisibility();
    this.updateProgressBar();
  }

  updateProgressBar() {
    this.progressBarTarget.value = this.percentComplete();
    this.progressBarTarget.innerText = `${this.percentComplete()}%`;
  }

  percentComplete() {
    return Math.round((this.step / this.stepsValue) * 100);
  }

  updateBackButtonVisibility() {
    if (this.step <= 0) {
      this.hideBackButton();
    } else {
      this.showBackButton();
    }
  }

  hideBackButton() {
    if (this.hasBackTarget) {
      this.backTarget.classList.add("invisible");
    }
  }

  showBackButton() {
    if (this.hasBackTarget) {
      this.backTarget.classList.remove("invisible");
    }
  }
}
