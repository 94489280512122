import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const MakeAPlanScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    statusQuo: "",
    newPlan: "",
    whenToTry: "",
    who: "",
    newBehavior: "",
  };
  const initialState = { ...defaultState, ...props.completedExercise?.data };

  const [statusQuo, setStatusQuo] = React.useState(initialState.statusQuo);
  const [newPlan, setNewPlan] = React.useState(initialState.newPlan);
  const [whenToTry, setWhenToTry] = React.useState(initialState.whenToTry);
  const [who, setWho] = React.useState(initialState.who);
  const [newBehavior, setNewBehavior] = React.useState(
    initialState.newBehavior
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      statusQuo,
      newPlan,
      whenToTry,
      who,
      newBehavior,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Make a Plan</EE.Heading>
          <EE.HR />
          <EE.Caption>
            This worksheet will help you make a plan for how to replace your
            accommodating behaviors with supportive ones. Take a look at the
            example animations that illustrate how parents can make a plan for
            how to decrease their accommodating behaviors with their child. The
            last example is yours to fill in!
          </EE.Caption>
          <div className="mt-8">
            <EE.Subheading>Example #1</EE.Subheading>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video className="w-full my-4" autoPlay loop>
              <source
                src="/video/reassuring-things-are-ok.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <EE.Paragraph>
              <EE.Bold>The status quo:</EE.Bold> Mom reassures child by
              returning all of her frequent text messages when she’s requesting
              reassurance
            </EE.Paragraph>
            <EE.Paragraph>
              <EE.Bold>The new plan:</EE.Bold> When child texts mom for
              reassurance, mom will only respond to the first 3 text messages
              each afternoon (unless it’s an emergency)
            </EE.Paragraph>
            <EE.Paragraph>
              <EE.Bold>When to try this:</EE.Bold> While mom is at work—from 3pm
              to 6pm
            </EE.Paragraph>
            <EE.Paragraph>
              <EE.Bold>Who's trying this:</EE.Bold> Mom
            </EE.Paragraph>
            <EE.Paragraph>
              <EE.Bold>The new behavior:</EE.Bold> On the last (3rd) message,
              mom will remind her child of the plan—that she will not respond to
              any more text messages—with a supportive statement, and then stop
              responding.
            </EE.Paragraph>
          </div>

          <div className="mt-8">
            <EE.Subheading>Example #2</EE.Subheading>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video className="w-full my-4" autoPlay loop>
              <source
                src="/video/sleeping-in-childs-room.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
            <EE.Paragraph>
              <EE.Bold>The status quo:</EE.Bold> Parents sleep in child’s bed at
              night because she is too anxious to sleep alone
            </EE.Paragraph>
            <EE.Paragraph>
              <EE.Bold>The new plan:</EE.Bold> Neither parent will sleep in
              child’s bed
            </EE.Paragraph>
            <EE.Paragraph>
              <EE.Bold>When to try this:</EE.Bold> Bedtime
            </EE.Paragraph>
            <EE.Paragraph>
              <EE.Bold>Who's trying this:</EE.Bold> Both parents
            </EE.Paragraph>
            <EE.Paragraph>
              <EE.Bold>The new behavior:</EE.Bold> Both parents will read a
              story for 15 minutes in a chair near the bed and then leave the
              child's room. Both parents will return to the child's room and
              check on the child every 10 minutes until she is asleep. If the
              child leaves her bed, parents will return her to bed without
              engaging—even if child is upset.
            </EE.Paragraph>
          </div>

          <div className="mt-8">
            <EE.Subheading>Your turn!</EE.Subheading>
            <EE.QuestionLabel>
              <EE.Bold>The status quo:</EE.Bold>
              <EE.TextArea
                value={statusQuo}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setStatusQuo(e.target.value)
                }
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel>
              <EE.Bold>The new plan:</EE.Bold>
              <EE.TextArea
                value={newPlan}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setNewPlan(e.target.value)
                }
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel>
              <EE.Bold>When to try this:</EE.Bold>
              <EE.TextInput
                value={whenToTry}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setWhenToTry(e.target.value)
                }
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel>
              <EE.Bold>Who's trying this:</EE.Bold>
              <EE.TextInput
                value={who}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setWho(e.target.value)
                }
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel>
              <EE.Bold>The new behavior:</EE.Bold>
              <EE.TextArea
                value={newBehavior}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setNewBehavior(e.target.value)
                }
              />
            </EE.QuestionLabel>
          </div>

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default MakeAPlanScreens;
