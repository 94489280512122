import Triangle from "@js/images/exercises/exercise-triangle-nolabel.svg";
import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useMappingYourNeeds } from "./Context";

const input = (value: string, setValue: Function) => {
  const { readOnly } = useMappingYourNeeds();

  return (
    <>
      <input
        className="w-full h-8 text-center bg-transparent border-none outline-none focus:outline-none disabled:opacity-50"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
        autoComplete="off"
        value={value}
        placeholder="Need"
        disabled={readOnly}
        maxLength={35}
      ></input>
      <span className="w-32 h-0 border-b border-black"></span>
    </>
  );
};

const Map = (props: { nextPage: string }) => {
  const {
    need1,
    setNeed1,
    need2,
    setNeed2,
    need3,
    setNeed3,
    need4,
    setNeed4,
    need5,
    setNeed5,
  } = useMappingYourNeeds();

  return (
    <EE.SubmitableForm>
      <p className="md:mt-12">
        Map your needs on the triangle. Add basic needs at the bottom and needs
        for feeling fulfilled at the top.
      </p>

      <p className="table mx-auto mb-4 text-sm text-gray-600">
        Fulfilment needs
      </p>
      <div
        className="flex flex-col items-center justify-end bg-center bg-no-repeat bg-auto bg-cover h-60"
        style={{
          backgroundImage: `url(${Triangle})`,
          backgroundSize: "100% 100%",
        }}
      >
        {input(need1, setNeed1)}
        {input(need2, setNeed2)}
        {input(need3, setNeed3)}
        {input(need4, setNeed4)}
        {input(need5, setNeed5)}
        <span className="h-3">&nbsp;</span>
      </div>
      <p className="table mx-auto mt-2 text-sm text-gray-600">Basic needs</p>

      <EE.ButtonGroup>
        <EE.ExerciseCallToActionLink to={props.nextPage}>
          Next
        </EE.ExerciseCallToActionLink>
      </EE.ButtonGroup>
    </EE.SubmitableForm>
  );
};

export default Map;
