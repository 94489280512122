import tailwindConfig from "@js/../../tailwind.config";
import React from "react";

import PieChartWithLabels from "./PieChartWithLabels";
import { Source } from "./Shared";

const SelfWorthGraph = (props: {
  headerText: string;
  sources: Array<Source>;
}) => {
  const { headerText, sources } = props;

  const data = sources.map((source: Source) => {
    return { name: source.name, value: source.percent };
  });

  const twColors = tailwindConfig.theme.colors;
  const PieChartColors = [
    twColors.blue[500],
    twColors.yellow[300],
    twColors.green[300],
    twColors.red[400],
    twColors.purple[400],
    twColors.blue[400],
    twColors.yellow[200],
    twColors.green[200],
    twColors.red[300],
    "#A685C2",
  ];

  const PieChartLabelColor = twColors.gray[700];

  return (
    <>
      <h2 className="text-xl font-medium md:mt-12">{headerText}</h2>

      <div className="flex justify-center block w-full sm:hidden">
        <PieChartWithLabels
          data={data}
          colors={PieChartColors}
          labelFontSize={12}
          labelColor={PieChartLabelColor}
          outerRadius={70}
          height={225}
          textSpace={50}
        />
      </div>

      <div className="flex justify-center hidden w-full sm:block">
        <PieChartWithLabels
          data={data}
          colors={PieChartColors}
          labelFontSize={14}
          labelColor={PieChartLabelColor}
          outerRadius={120}
          height={325}
          textSpace={100}
        />
      </div>
    </>
  );
};

export default SelfWorthGraph;
