import DOMPurify from "dompurify";

export const SENDBIRD_USER_MESSAGE_CLASS = "sendbird-text-message-item-body";
export const SENDBIRD_ADMIN_MESSAGE_CLASS = "sendbird-admin-message__text";
export const SENDBIRD_FILE_ATTACHMENT_CLASS = "sendbird-file-message__tooltip";
export const SENDBIRD_FILE_ATTACHMENT_AVATAR_CLASS =
  "sendbird-fileviewer__header__left__avatar";
export const SENDBIRD_FILE_ATTACHMENT_DELETE_CLASS =
  "sendbird-fileviewer__header__right__actions__delete";
export const SENDBIRD_FILE_ATTACHMENT_CLOSE_CLASS =
  "sendbird-fileviewer__header__right__actions__close";
export const SENDBIRD_FILE_VIEWER_HEADER_CLASS = "sendbird-fileviewer__header";
export const SENDBIRD_FILE_VIEWER_HEADER_CONTROLS_CLASS =
  "sendbird-fileviewer__header__right";
export const SENDBIRD_FILE_VIEWER_DOWNLOAD_LINK_CLASS =
  "sendbird-fileviewer__header__right__actions__download";
export const SENDBIRD_CHAT_HEADER_CLASS = "sendbird-chat-header";
export const SENDBIRD_EMOJI_REACTION_BUTTON_CLASS =
  "sendbird-message-item-reaction-menu__trigger__icon";
export const SENDBIRD_MORE_OPTIONS_CLASS =
  "sendbird-message-item-menu__trigger__icon";
export const SENDBIRD_MESSAGE_STATUS_ICON_CLASS =
  "sendbird-message-status__icon";
export const SENDBIRD_DATE_CLASS = "sendbird-label--caption-2";
export const SENDBIRD_TIMESTAMP_CLASS = "sendbird-message-status__text";
export const SENDBIRD_MESSAGE_CLASS = "sendbird-text-message-item-body";
export const SENDBIRD_IMAGE_MESSAGE_CLASS =
  "sendbird-thumbnail-message-item-body__thumbnail";
export const SENDBIRD_SPINNER_SELECTOR =
  '[d="M32 61.333C48.2 61.333 61.333 48.2 61.333 32S48.2 2.667 32 2.667 2.667 15.8 2.667 32c0 1.568 1.27 2.839 2.838 2.839s2.84-1.271 2.84-2.839C8.344 18.935 18.934 8.344 32 8.344c13.065 0 23.656 10.591 23.656 23.656S45.065 55.656 32 55.656c-1.568 0-2.839 1.27-2.839 2.839 0 1.567 1.271 2.838 2.839 2.838z"]';

const decodeMap = {
  "amp": "&",
  "apos": "'",
  "#x27": "'",
  "#x2F": "/",
  "#39": "'",
  "#47": "/",
  "lt": "<",
  "gt": ">",
  "nbsp": " ",
  "quot": '"',
};

function decodeHtml(text: string): string {
  return text.replace(
    /&([^;]+);/gm,
    (match, entity) => decodeMap[entity] || match
  );
}

export const containsLink = (text: string) => {
  return text.search(/<a[^>]* href="([^"]*)/) !== -1;
};

// adapted from https://stackoverflow.com/a/8943487
export const linkify = (text: string) => {
  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  const decodedText = decodeHtml(text);
  if (containsLink(decodedText)) return decodedText;
  return text.replace(urlRegex, (url) => {
    return `<a href="${url}?utm_content=chat-link&ahoy%5B_event_name%5D=ChatLink%3AClicked" target="_blank">${url}</a>`;
  });
};

export const sanitizeHtml = (text: string) => {
  // Allow target attributes and prevent data attributes
  return DOMPurify.sanitize(text, {
    ALLOW_DATA_ATTR: false,
    ADD_ATTR: ["target"],
  });
};

export const safeLinkifyMessage = (message: Element) => {
  const linkedHtml = linkify(message.innerHTML);
  message.innerHTML = sanitizeHtml(linkedHtml);
  //mark the message as having been sanitized
  message.setAttribute("__s", "true");
};

function messageSanitized(message: Element): boolean {
  return message.hasAttribute("__s");
}

// adapted from https://stackoverflow.com/a/8943487
export const rewriteSendbirdUrl = (text: string) => {
  return text.replace(
    new RegExp("https://sendbird-us.*"),
    "Attachment from Brightline"
  );
};

/*
  We want to make links obvious and easy to click, but Sendbird doesn't
  do link unfurling yet. It's supposedly coming in Q3: https://community.sendbird.com/t/showing-links-within-usermessages-in-react-uikit/348

  For now, let's do a bad thing and periodically check to see if React
  has rendered any messages with links. If so, we'll reach in and overwrite
  the DOM.
*/
export const linkifySendbirdMessages = () => {
  let userMessages = document.getElementsByClassName(
    SENDBIRD_USER_MESSAGE_CLASS
  );
  let adminMessages = document.getElementsByClassName(
    SENDBIRD_ADMIN_MESSAGE_CLASS
  );
  let allMessages = [...userMessages, ...adminMessages];
  for (const message of allMessages) {
    if (messageSanitized(message)) {
      continue;
    }
    safeLinkifyMessage(message);
  }
};

export const rewriteSendbirdAttachmentLinks = () => {
  let attachments = document.getElementsByClassName(
    SENDBIRD_FILE_ATTACHMENT_CLASS
  );
  for (const attachment of attachments) {
    const element = attachment.querySelector(".sendbird-label");
    element.innerHTML = rewriteSendbirdUrl(element.innerHTML);
  }
};

export const tabifyFilePreviewControls = () => {
  const controlClasses = [
    SENDBIRD_FILE_ATTACHMENT_AVATAR_CLASS,
    SENDBIRD_FILE_ATTACHMENT_DELETE_CLASS,
    SENDBIRD_FILE_ATTACHMENT_CLOSE_CLASS,
  ];

  for (const controlClass of controlClasses) {
    const controls = document.getElementsByClassName(controlClass);
    for (const control of controls) {
      control.setAttribute("tabindex", "0");
    }
  }
};

export const makeFileHeaderLinksVisibleToScreenReaders = () => {
  const downloadLink = document.getElementsByClassName(
    SENDBIRD_FILE_VIEWER_DOWNLOAD_LINK_CLASS
  );

  for (const link of downloadLink) {
    link.setAttribute("aria-label", "Download");
  }

  const deleteButton = document.getElementsByClassName(
    SENDBIRD_FILE_ATTACHMENT_DELETE_CLASS
  );

  for (const link of deleteButton) {
    link.setAttribute("aria-label", "Delete");
    link.setAttribute("role", "button");
  }

  const closeButton = document.getElementsByClassName(
    SENDBIRD_FILE_ATTACHMENT_CLOSE_CLASS
  );

  for (const link of closeButton) {
    link.setAttribute("aria-label", "Close");
    link.setAttribute("role", "button");
  }
};

export const removeChatHeader = () => {
  const chatHeaders = document.getElementsByClassName(
    SENDBIRD_CHAT_HEADER_CLASS
  );
  for (const header of chatHeaders) {
    header.remove();
  }
};

export const makeMessageButtonsVisibleToScreenReaders = () => {
  const reactionMenuButtons = document.getElementsByClassName(
    SENDBIRD_EMOJI_REACTION_BUTTON_CLASS
  );

  for (const button of reactionMenuButtons) {
    button.setAttribute("aria-label", "Open emoji reaction menu");
  }

  const moreOptionsButtons = document.getElementsByClassName(
    SENDBIRD_MORE_OPTIONS_CLASS
  );

  for (const button of moreOptionsButtons) {
    button.setAttribute("aria-label", "Show more message options");
  }

  const messageStatusIcons = document.getElementsByClassName(
    SENDBIRD_MESSAGE_STATUS_ICON_CLASS
  );

  for (const icon of messageStatusIcons) {
    icon.removeAttribute("role");
  }
};

export const addLiveRegionToAnnouncements = () => {
  const elements = [
    ...Array.from(document.getElementsByClassName(SENDBIRD_DATE_CLASS)),
    ...Array.from(document.getElementsByClassName(SENDBIRD_TIMESTAMP_CLASS)),
    ...Array.from(document.getElementsByClassName(SENDBIRD_MESSAGE_CLASS)),
    ...Array.from(
      document.getElementsByClassName(SENDBIRD_IMAGE_MESSAGE_CLASS)
    ),
  ];

  for (const element of elements) {
    element.setAttribute("aria-live", "assertive");
  }
};
