import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  declare readonly pillTargets: HTMLElement[];
  declare readonly morePillTarget: HTMLElement;
  declare readonly pillListTarget: HTMLElement;
  declare readonly startCollapsedValue: Boolean;
  declare collapsed: Boolean;

  static targets = ["pill", "morePill", "pillList"];

  static values = {
    startCollapsed: Boolean,
  };

  MORE_TEXT = {
    text: "More",
    icon: "expand_more",
  };
  FEWER_TEXT = {
    text: "Fewer",
    icon: "expand_less",
  };

  HIDDEN_CLASS = "hidden";
  EXPANDED_CLASS = "expanded";

  initialize(): void {
    this.collapsed = this.startCollapsedValue;

    if (this.collapsed) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  handleCollapse() {
    this.toggleCollapsed();

    if (this.collapsed) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  collapse() {
    this.pillTargets.forEach((pill) => pill.classList.add(this.HIDDEN_CLASS));
    this.pillListTarget.classList.remove(this.EXPANDED_CLASS);
    this.replaceMorePillText();
  }

  expand() {
    this.pillTargets.forEach((pill) =>
      pill.classList.remove(this.HIDDEN_CLASS)
    );
    this.pillListTarget.classList.add(this.EXPANDED_CLASS);
    this.replaceMorePillText();
  }

  replaceMorePillText() {
    const replacement = this.collapsed ? this.MORE_TEXT : this.FEWER_TEXT;
    const existing = this.collapsed ? this.FEWER_TEXT : this.MORE_TEXT;

    this.morePillTarget.innerHTML = this.morePillTarget.innerHTML
      .replace(existing.text, replacement.text)
      .replace(existing.icon, replacement.icon);
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }
}
