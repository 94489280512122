import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import FeelingsThermometerElements from "../Thermometers/elements/FeelingsThermometer";

const MobileOnlyLabel = (props) => {
  return (
    <span className="my-4 font-sans text-lg font-bold text-green-700 md:hidden md:py-4">
      {props.children}
    </span>
  );
};

const TableCell = (props) => {
  return (
    <td className="block w-full py-4 align-top md:table-cell md:p-4 md:border md:w-auto md:border-gray-300">
      {props.children}
    </td>
  );
};

const TableRow = (props) => {
  const { children, className = "" } = props;
  return (
    <tr className={`md:hover:bg-gray-100 py-10 ${className}`}>{children}</tr>
  );
};

const PracticingMyNewSkillsScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultPlan = {
    plan: "",
    helps: "",
    tried: null,
    doneTrying: null,
    thermometerValue: 5,
  };

  const defaultState = {
    whatsMakingMeFeelBad: "",
    currentThermometerValue: 5,
    idealThermometerValue: 5,
    planA: defaultPlan,
    planB: defaultPlan,
    planC: defaultPlan,
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };

  const [whatsMakingMeFeelBad, setWhatsMakingMeFeelBad] = React.useState(
    initialState.whatsMakingMeFeelBad
  );

  const [planA, setPlanA] = React.useState(initialState.planA.plan);
  const [planAHelps, setPlanAHelps] = React.useState(initialState.planA.helps);
  const [planATried, setPlanATried] = React.useState(initialState.planA.tried);
  const [planADoneTrying, setPlanADoneTrying] = React.useState(
    initialState.planA.doneTrying
  );
  const [planAThermometerValue, setPlanAThermometerValue] = React.useState(
    initialState.planA.thermometerValue
  );

  const [planB, setPlanB] = React.useState(initialState.planB.plan);
  const [planBHelps, setPlanBHelps] = React.useState(initialState.planB.helps);
  const [planBTried, setPlanBTried] = React.useState(initialState.planB.tried);
  const [planBDoneTrying, setPlanBDoneTrying] = React.useState(
    initialState.planB.doneTrying
  );
  const [planBThermometerValue, setPlanBThermometerValue] = React.useState(
    initialState.planB.thermometerValue
  );

  const [planC, setPlanC] = React.useState(initialState.planC.plan);
  const [planCHelps, setPlanCHelps] = React.useState(initialState.planC.helps);
  const [planCTried, setPlanCTried] = React.useState(initialState.planC.tried);
  const [planCDoneTrying, setPlanCDoneTrying] = React.useState(
    initialState.planC.doneTrying
  );
  const [planCThermometerValue, setPlanCThermometerValue] = React.useState(
    initialState.planC.thermometerValue
  );

  const [currentThermometerValue, setCurrentThermometerValue] = React.useState(
    initialState.currentThermometerValue
  );
  const [idealThermometerValue, setIdealThermometerValue] = React.useState(
    initialState.idealThermometerValue
  );
  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      whatsMakingMeFeelBad,
      currentThermometerValue,
      idealThermometerValue,
      planA: {
        plan: planA,
        helps: planAHelps,
        tried: planATried,
        doneTrying: planADoneTrying,
        thermometerValue: planAThermometerValue,
      },
      planB: {
        plan: planB,
        helps: planBHelps,
        tried: planBTried,
        doneTrying: planBDoneTrying,
        thermometerValue: planBThermometerValue,
      },
      planC: {
        plan: planC,
        helps: planCHelps,
        tried: planCTried,
        doneTrying: planCDoneTrying,
        thermometerValue: planCThermometerValue,
      },
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );
  return (
    <>
      <EE.Wrapper className="max-w-6xl">
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Practicing My New Skills</EE.Heading>
          <EE.HR />
          <EE.Paragraph className="py-4">
            When you feel bad, try using the skills you have learned. Write a
            skill for Plan A, Plan B, and Plan C below. Then write how you think
            it will help. After you try each one, write down that you did it and
            give a <EE.Bold>Feelings Thermometer</EE.Bold> rating. Remember:
            don't give up—keep trying until you feel better!
          </EE.Paragraph>

          <EE.QuestionLabel>
            What's making me feel bad:
            <EE.TextInput
              className="rounded-lg"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWhatsMakingMeFeelBad(e.target.value)
              }
              value={whatsMakingMeFeelBad}
            />
          </EE.QuestionLabel>

          <div className="flex flex-col my-4 md:flex-row md:my-16">
            <EE.ThermometerRating
              thermometerValue={currentThermometerValue}
              setThermometerValue={setCurrentThermometerValue}
              className="w-full md:px-4"
              elements={FeelingsThermometerElements}
              id="current-thermometer"
            >
              My <EE.Bold>Feelings Thermometer</EE.Bold> rating right now:
            </EE.ThermometerRating>

            <EE.ThermometerRating
              thermometerValue={idealThermometerValue}
              setThermometerValue={setIdealThermometerValue}
              className="w-full md:px-4"
              elements={FeelingsThermometerElements}
              id="ideal-thermometer"
            >
              What I would like my rating to be:
            </EE.ThermometerRating>
          </div>
          <table className="w-full table-fixed">
            <thead>
              <tr className="hidden h-12 font-sans text-lg font-medium leading-8 text-left text-green-700 rounded-lg md:table-row">
                <th className="md:w-1/2 md:p-2 md:border md:border-gray-300">
                  New Skill I Will Try
                </th>
                <th className="md:w-1/2 md:p-2 md:border md:border-gray-300">
                  How Did It Go?
                </th>
              </tr>
            </thead>
            <tbody>
              <TableRow>
                <TableCell>
                  <MobileOnlyLabel>New Skill I Will Try</MobileOnlyLabel>
                  <EE.QuestionLabel>
                    <EE.Bold>Plan A</EE.Bold>:
                    <EE.TextInput
                      className="rounded-lg"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPlanA(e.target.value)
                      }
                      value={planA}
                    />
                  </EE.QuestionLabel>
                  <EE.QuestionLabel>
                    <EE.Bold>How It Will Help</EE.Bold>:
                    <EE.TextArea
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPlanAHelps(e.target.value)
                      }
                      value={planAHelps}
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <MobileOnlyLabel>How Did It Go?</MobileOnlyLabel>
                  <EE.QuestionLabel className="flex justify-between">
                    <span>
                      Did I try <EE.Bold>Plan A</EE.Bold>?
                    </span>
                    <EE.YesNoRadio
                      radioName="planATried"
                      checked={planATried}
                      setChecked={setPlanATried}
                    />
                  </EE.QuestionLabel>
                  <EE.ThermometerRating
                    thermometerValue={planAThermometerValue}
                    setThermometerValue={setPlanAThermometerValue}
                    showThermometerImage={false}
                    elements={FeelingsThermometerElements}
                    id="plan-a-thermometer"
                  >
                    My rating after <EE.Bold>Plan A</EE.Bold>:
                  </EE.ThermometerRating>

                  <EE.QuestionLabel className="flex justify-between">
                    <span>Am I done trying?</span>
                    <EE.YesNoRadio
                      radioName="planADoneTrying"
                      checked={planADoneTrying}
                      setChecked={setPlanADoneTrying}
                    />
                  </EE.QuestionLabel>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <MobileOnlyLabel>New Skill I Will Try</MobileOnlyLabel>
                  <EE.QuestionLabel>
                    <EE.Bold>Plan B</EE.Bold>:
                    <EE.TextInput
                      className="rounded-lg"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPlanB(e.target.value)
                      }
                      value={planB}
                    />
                  </EE.QuestionLabel>
                  <EE.QuestionLabel>
                    <EE.Bold>How It Will Help</EE.Bold>:
                    <EE.TextArea
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPlanBHelps(e.target.value)
                      }
                      value={planBHelps}
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <MobileOnlyLabel>How Did It Go?</MobileOnlyLabel>
                  <EE.QuestionLabel className="flex justify-between">
                    <span>
                      Did I try <EE.Bold>Plan B</EE.Bold>?
                    </span>
                    <EE.YesNoRadio
                      radioName="planBTried"
                      checked={planBTried}
                      setChecked={setPlanBTried}
                    />
                  </EE.QuestionLabel>
                  <EE.ThermometerRating
                    thermometerValue={planBThermometerValue}
                    setThermometerValue={setPlanBThermometerValue}
                    showThermometerImage={false}
                    elements={FeelingsThermometerElements}
                    id="plan-b-thermometer"
                  >
                    My rating after <EE.Bold>Plan B</EE.Bold>:
                  </EE.ThermometerRating>

                  <EE.QuestionLabel className="flex justify-between">
                    <span>Am I done trying?</span>
                    <EE.YesNoRadio
                      radioName="planBDoneTrying"
                      checked={planBDoneTrying}
                      setChecked={setPlanBDoneTrying}
                    />
                  </EE.QuestionLabel>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <MobileOnlyLabel>New Skill I Will Try</MobileOnlyLabel>
                  <EE.QuestionLabel>
                    <EE.Bold>Plan C</EE.Bold>:
                    <EE.TextInput
                      className="rounded-lg"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPlanC(e.target.value)
                      }
                      value={planC}
                    />
                  </EE.QuestionLabel>
                  <EE.QuestionLabel>
                    <EE.Bold>How It Will Help</EE.Bold>:
                    <EE.TextArea
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPlanCHelps(e.target.value)
                      }
                      value={planCHelps}
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <MobileOnlyLabel>How Did It Go?</MobileOnlyLabel>
                  <EE.QuestionLabel className="flex justify-between">
                    <span>
                      Did I try <EE.Bold>Plan C</EE.Bold>?
                    </span>
                    <EE.YesNoRadio
                      radioName="planCTried"
                      checked={planCTried}
                      setChecked={setPlanCTried}
                    />
                  </EE.QuestionLabel>
                  <EE.ThermometerRating
                    thermometerValue={planCThermometerValue}
                    setThermometerValue={setPlanCThermometerValue}
                    showThermometerImage={false}
                    elements={FeelingsThermometerElements}
                    id="plan-c-thermometer"
                  >
                    My rating after <EE.Bold>Plan C</EE.Bold>:
                  </EE.ThermometerRating>

                  <EE.QuestionLabel className="flex justify-between">
                    <span>Am I done trying?</span>
                    <EE.YesNoRadio
                      radioName="planCDoneTrying"
                      checked={planCDoneTrying}
                      setChecked={setPlanCDoneTrying}
                    />
                  </EE.QuestionLabel>
                </TableCell>
              </TableRow>
            </tbody>
          </table>
          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default PracticingMyNewSkillsScreens;
