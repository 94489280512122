import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    visible: { type: String, default: "true" },
  };

  static targets = [
    "input",
    "min",
    "lowercase",
    "uppercase",
    "number",
    "special",
    "confirm",
    "visicon",
    "vislabel",
    "hide",
    "show",
  ];

  passwordVisible?: boolean;

  declare readonly visibleValue: string;
  declare readonly inputTarget: HTMLInputElement;
  declare readonly minTarget: Element;
  declare readonly lowercaseTarget: Element;
  declare readonly uppercaseTarget: Element;
  declare readonly numberTarget: Element;
  declare readonly specialTarget: Element;
  declare readonly visiconTarget: HTMLSpanElement;
  declare readonly vislabelTarget: HTMLSpanElement;
  declare readonly vislabelTargets: HTMLSpanElement[];
  declare readonly hideTarget: HTMLSpanElement;
  declare readonly showTarget: HTMLSpanElement;

  get hasVislabelTarget(): boolean {
    return this.vislabelTargets.length > 0;
  }

  connect() {
    const isVisible = this.visibleValue.toLowerCase() !== "false";
    this.toggleByVisibility(isVisible);
  }

  toggleVisibility(e: Event) {
    e.preventDefault();
    this.toggleByVisibility(this.passwordVisible);
    this.passwordVisible = !this.passwordVisible;
  }

  toggleByVisibility(isVisible) {
    this.inputTarget.type = isVisible ? "text" : "password";
    if (this.hasVislabelTarget) {
      this.visiconTarget.innerText = isVisible
        ? "visibility_off"
        : "visibility";
      this.vislabelTarget.innerText = isVisible
        ? this.hideTarget.innerText
        : this.showTarget.innerText;
    } else {
      this.visiconTarget.innerText = isVisible
        ? "visibility"
        : "visibility_off";
    }
  }

  updateValidations() {
    if (/[0-9]/.test(this.inputTarget.value)) {
      this.markAsValid(this.numberTarget);
    } else {
      this.markAsInvalid(this.numberTarget);
    }

    if (/[A-Z]/.test(this.inputTarget.value)) {
      this.markAsValid(this.uppercaseTarget);
    } else {
      this.markAsInvalid(this.uppercaseTarget);
    }

    if (/[a-z]/.test(this.inputTarget.value)) {
      this.markAsValid(this.lowercaseTarget);
    } else {
      this.markAsInvalid(this.lowercaseTarget);
    }

    if (/[!@#$%^&*\-]/.test(this.inputTarget.value)) {
      this.markAsValid(this.specialTarget);
    } else {
      this.markAsInvalid(this.specialTarget);
    }

    if (this.inputTarget.value.length >= 8) {
      this.markAsValid(this.minTarget);
    } else {
      this.markAsInvalid(this.minTarget);
    }
  }

  private markAsValid(element: Element) {
    element.classList.add("valid");
    element.classList.remove("invalid");
  }

  private markAsInvalid(element: Element) {
    element.classList.add("invalid");
    element.classList.remove("valid");
  }
}
