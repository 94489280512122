import { Controller } from "@hotwired/stimulus";

// TODO: Find a better name... paginateInline?
export default class extends Controller {
  static values = { currentPage: Number };
  static targets = ["page", "form"];
  declare currentPageValue: number;
  declare readonly pageTargets: Element[];

  initialize() {
    // TODO: Stimulus 2 would automatically initialize this value AFAIK
    this.currentPageValue = 0;
  }

  back() {
    this.currentPageValue--;
    this.showCurrentPage();
  }

  next() {
    this.currentPageValue++;
    this.showCurrentPage();
  }

  // TODO: Stimulus 2 gives us callbacks so we could remove this.showCurrentPage() from the other methods
  // currentPageValueChanged() {
  //   this.showCurrentPage();
  // }

  showCurrentPage() {
    this.pageTargets.forEach((element, index) => {
      if (index != this.currentPageValue) {
        element.classList.add("hidden");
      } else {
        element.classList.remove("hidden");
      }
    });
  }
}
