import React from "react";

import { ExerciseScreenProps } from "../shared/Elements";

const SelfEsteemJournalContext = React.createContext(undefined);

const SelfEsteemJournalProvider: React.FC<ExerciseScreenProps> = (props) => {
  const defaultState = {
    goodEvent: "",
    positiveWords: "",
    selfCompliment: "",
    positiveFeeling: "",
    positiveAction: "",
    negativeThought: "",
    differentThought: "",
    makeTomorrowBetter: "",
  };

  const {
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  } = props;

  const initialState = { ...defaultState, ...completedExercise?.data };

  const [goodEvent, setGoodEvent] = React.useState<string>(
    initialState.goodEvent
  );
  const [positiveWords, setPositiveWords] = React.useState<string>(
    initialState.positiveWords
  );
  const [selfCompliment, setSelfCompliment] = React.useState<string>(
    initialState.selfCompliment
  );
  const [positiveFeeling, setPositiveFeeling] = React.useState<string>(
    initialState.positiveFeeling
  );
  const [positiveAction, setPositiveAction] = React.useState<string>(
    initialState.positiveAction
  );
  const [negativeThought, setNegativeThought] = React.useState<string>(
    initialState.negativeThought
  );
  const [differentThought, setDifferentThought] = React.useState<string>(
    initialState.differentThought
  );
  const [makeTomorrowBetter, setMakeTomorrowBetter] = React.useState<string>(
    initialState.makeTomorrowBetter
  );

  const value = {
    goodEvent,
    setGoodEvent,
    positiveWords,
    setPositiveWords,
    selfCompliment,
    setSelfCompliment,
    positiveFeeling,
    setPositiveFeeling,
    positiveAction,
    setPositiveAction,
    negativeThought,
    setNegativeThought,
    differentThought,
    setDifferentThought,
    makeTomorrowBetter,
    setMakeTomorrowBetter,
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  };

  return <SelfEsteemJournalContext.Provider value={value} {...props} />;
};

function useSelfEsteemJournal() {
  const context = React.useContext(SelfEsteemJournalContext);
  if (!context) {
    throw new Error(
      "useSelfEsteemJournal must be used within a SelfEsteemJournalProvider"
    );
  }
  return context;
}

export {
  SelfEsteemJournalProvider,
  SelfEsteemJournalContext,
  useSelfEsteemJournal,
};
