import Fuse from "fuse.js";
import { Autocomplete } from "stimulus-autocomplete";

export default class extends Autocomplete {
  static targets = ["input", "result", "results"];

  fetchResults = (query) => {
    const options = {
      // Search in `name` and in `abbreviation`
      keys: ["name", "abbreviation"],
      distance: 175,
    };
    const fuse = new Fuse(this.entries(), options);
    const results = fuse.search(query, { limit: 5 });
    this.replaceResults(results);
  };

  replaceResults(results) {
    this.resultsTarget.innerHTML = null;
    results.forEach((result) => {
      this.resultsTarget.appendChild(this.resultWrapper(result.item.name));
    });

    this.identifyOptions();
    if (!!this.options) {
      this.open();
      this.resultsTarget.classList.remove("hidden");
    } else {
      this.close();
    }
  }

  resultWrapper(resultBody) {
    const result = this.resultTarget.cloneNode(true);
    result.style.display = "block";
    result.classList.remove("hidden");
    result.innerHTML = resultBody;
    return result;
  }

  entries() {
    const parsedEntries = JSON.parse(this.element.dataset.entries);
    return parsedEntries.map((entry) => {
      return {
        name: entry.name,
        abbreviation: this.abbreviation(entry.name),
      };
    });
  }

  abbreviation(name) {
    return name
      .split(" ")
      .map((word) => word.charAt(0))
      .join("")
      .toLowerCase();
  }

  close() {
    if (!this.resultsShown) return;

    this.resultsShown = false;
    this.inputTarget.removeAttribute("aria-activedescendant");
    this.element.dispatchEvent(
      new CustomEvent("toggle", {
        detail: {
          action: "close",
          inputTarget: this.inputTarget,
          resultsTarget: this.resultsTarget,
        },
      })
    );
    this.resultsTarget.classList.add("hidden");
  }
}
