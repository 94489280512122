class Animation {
  /*
   Purpose: allows manipulating a value via a sine wave oscillation.

   Example, oscillate the width between 75 -> 125% at a slow speed.
    ```js
    const animation = new Animation();
    animation.oscillator(25, 100, 0.01, (value) => {
      blob.setAttribute( "style", `width:${value}%;`);
    });
    ```
   */
  oscillator(
    rangeVal: number,
    midVal: number,
    speed: number = 0.2,
    callback: (currentValue: number) => void
  ) {
    let i = 4.9; //start at the smallest val sin(4.9) == -.98

    let loop = () => {
      let current = Math.sin(i) * rangeVal + midVal;
      i = i + speed;
      callback(current);
      requestAnimationFrame(loop);
    };

    requestAnimationFrame(loop);
  }
}

export default Animation;
