import { Controller } from "@hotwired/stimulus";
import * as focusTrap from "focus-trap"; // ESM

export default class extends Controller {
  static targets = ["trap"];

  activate() {
    this.trap = focusTrap.createFocusTrap(this.trapTarget);
    this.trap.activate();
  }

  deactivate() {
    if (!this.trap) return;

    this.trap.deactivate();
  }
}
