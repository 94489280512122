import Images from "@js/images/blobs";
import React from "react";

import { useThermometer } from "../../shared/Context";
import {
  ExerciseElements as EE,
  ThermometerElementsProps,
} from "../../shared/Elements";

const Title = () => {
  return <EE.Title>Fear thermometer</EE.Title>;
};
const IntroductionText = () => {
  const { patientFirstName } = useThermometer();

  return (
    <ul>
      <EE.PaddedListItem>
        The Fear Thermometer helps us understand {patientFirstName}'s level of
        anxiety in connection with different situations or events.
      </EE.PaddedListItem>
      <EE.PaddedListItem>
        The thermometer helps us “take the temperature” on {patientFirstName}
        's feelings to understand what kinds of things lead to{" "}
        {patientFirstName}'s feeling anxious during the week, and how intense
        the feelings are.
      </EE.PaddedListItem>
      <EE.PaddedListItem>
        The thermometer can also help us measure whether certain practice
        exercises help lower {patientFirstName} anxiety.
      </EE.PaddedListItem>
    </ul>
  );
};

const InstructionsText = () => {
  const { patientFirstName } = useThermometer();

  return (
    <ul>
      <EE.PaddedListItem>
        The Fear Thermometer is a 0-10 scale that represents {patientFirstName}
        's level of fear or anxiety, with zero being no fear and ten being a
        very high level of anxiety or fearfulness. Keep in mind that the
        thermometer is used to capture a wide range of anxious feelings... not
        just the extremes!
      </EE.PaddedListItem>
      <EE.PaddedListItem>
        You can have {patientFirstName} enter a rating with you, or enter one on
        your own.
      </EE.PaddedListItem>
    </ul>
  );
};

const ImageMapping = [
  Images.Blob0,
  Images.Blob1,
  Images.Blob2,
  Images.Blob3,
  Images.Blob4,
  Images.Blob5,
  Images.Blob6,
  Images.Blob7,
  Images.Blob8,
  Images.Blob9,
  Images.Blob10,
];

const initialThermometerValue = 5;

const FearThermometerElements: ThermometerElementsProps = {
  InstructionsText,
  IntroductionText,
  ImageMapping,
  Title,
  initialThermometerValue,
  minValueLabel: "Not Scared",
  maxValueLabel: "Very Scared",
  exerciseQuestion: "mood on the thermometer",
};

export default FearThermometerElements;
