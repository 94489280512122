import React, { ReactElement } from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";

const GenericFiveStarRatingPage = (
  props: GenericMultiPageExerciseScreenProps & {
    prompt: ReactElement;
    value: number;
    setValue: Function;
  }
) => {
  const { numSteps, currentStep, nextPage, prompt, value, setValue } = props;

  const textValues = [
    "Click the stars",
    "Very dissatisfied",
    "Somewhat dissatisfied",
    "Neutral",
    "Somewhat satisfied",
    "Very satisfied",
  ];

  return (
    <>
      <EE.StepIndicator currentStep={currentStep} numSteps={numSteps} />

      {prompt}

      <EE.FiveStarRating
        value={value}
        setValue={setValue}
        textValues={textValues}
      />

      <EE.ButtonGroup>
        <EE.ExerciseCallToActionLink to={nextPage} disabled={value === null}>
          Next
        </EE.ExerciseCallToActionLink>
      </EE.ButtonGroup>
    </>
  );
};

export default GenericFiveStarRatingPage;
