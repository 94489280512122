import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const Section: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { children, className } = props;
  return (
    <>
      <div className={`flex mt-4 ${className}`}>{children}</div>
      <hr className="h-px my-4 bg-green-700 bg-opacity-25" />
    </>
  );
};

const Number: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { children, className } = props;
  return (
    <div className={`w-1/6 md:w-1/12 flex items-center ${className}`}>
      <EE.BigOldLetter className="font-sans text-3xl font-bold md:text-5xl text-orange-700">
        {children}
      </EE.BigOldLetter>
    </div>
  );
};

const Text: React.FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { children, className } = props;
  return (
    <div className={`w-5/6 md:w-11/12 flex items-center ${className}`}>
      <EE.Paragraph className="text-base md:text-lg">{children}</EE.Paragraph>
    </div>
  );
};

const LearningAboutAnxiety: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    behaviorsToGetRidOf: "",
    replacingBehaviors: "",
    timeOutPlace: "",
    whenIPlanToExplainTimeOut: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };

  const [behaviorsToGetRidOf, setBehaviorsToGetRidOf] = React.useState(
    initialState.behaviorsToGetRidOf
  );
  const [replacingBehaviors, setReplacingBehaviors] = React.useState(
    initialState.replacingBehaviors
  );
  const [timeOutPlace, setTimeOutPlace] = React.useState(
    initialState.timeOutPlace
  );
  const [whenIPlanToExplainTimeOut, setWhenIPlanToExplainTimeOut] =
    React.useState(initialState.whenIPlanToExplainTimeOut);

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      behaviorsToGetRidOf,
      replacingBehaviors,
      timeOutPlace,
      whenIPlanToExplainTimeOut,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Time Out</EE.Heading>
          <EE.HR />
          <EE.Paragraph>
            Help for parents to identify when to stop some of your child’s
            behavior
          </EE.Paragraph>
          <EE.Subheading>What Is Time Out?</EE.Subheading>
          <EE.Paragraph>
            “Time out” is the removal of your child from activities, rewards,
            and attention. Time out is meant to help stop some of your child’s
            behaviors that you find upsetting or harmful, and it can provide
            quick and lasting results if used properly. It does not cause any
            harm to your child, and it also can help you feel less angry and
            upset with your child. It is easy to learn, and with a little
            practice it becomes easy to use.
          </EE.Paragraph>
          <div className="block md:flex">
            <div className="md:flex-1">
              <EE.Subheading>Use Time Out for:</EE.Subheading>
              <ul>
                <li>Hitting, slapping, or pinching</li>
                <li>Throwing or breaking things</li>
                <li>Being mean to animals or people</li>
                <li>Disobeying an instruction</li>
                <li>Breaking a house rule</li>
                <li>Cursing or swearing</li>
                <li>Doing dangerous things</li>
                <li>Threatening others</li>
                <li>Hostile arguing</li>
                <li>Damaging property</li>
              </ul>
            </div>

            <div className="md:flex-1">
              <EE.Subheading>Don't Use Time Out for:</EE.Subheading>
              <ul>
                <li>Fussiness, complaining</li>
                <li>Talking back</li>
                <li>Mild arguing</li>
                <li>Legitimate accidents (e.g., spilling</li>
                <li>something, dropping something)</li>
                <li>Whining</li>
                <li>Asking same questions over and over</li>
                <li>Repeating things</li>
                <li>Doing things to get your attention</li>
                <li>Bad attitude</li>
              </ul>
            </div>
          </div>

          <EE.Subheading>Getting Ready</EE.Subheading>
          <EE.Paragraph>
            Pick two or three behaviors you’d like to get rid of. Check the
            lists above to make sure these are the right kinds of behavior for
            time out.
          </EE.Paragraph>
          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>
                From now on, I will give a time out to my child when:
              </EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setBehaviorsToGetRidOf(e.target.value)
              }
              value={behaviorsToGetRidOf}
            />
          </EE.QuestionLabel>
          <EE.Paragraph>
            Pick some behaviors you’d like to see instead of the problem
            behaviors. These could be things like playing safely and quietly,
            following through on a task, or being kind to a sibling. They should
            be the “opposite” of the behaviors chosen for time out.
          </EE.Paragraph>
          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>
                From now on, I will try to praise or reward my child for:
              </EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setReplacingBehaviors(e.target.value)
              }
              value={replacingBehaviors}
            />
          </EE.QuestionLabel>

          <EE.Paragraph>
            Pick a dull and boring place for the time out. It should be easy to
            get to quickly, and it should not have anything interesting nearby.
          </EE.Paragraph>
          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>Our time out place will be:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTimeOutPlace(e.target.value)
              }
              value={timeOutPlace}
            />
          </EE.QuestionLabel>

          <EE.Paragraph>
            If you have not done so already, pick a time to tell your child
            about time out. Choose a time when everything is going well and
            everybody is calm. Make sure your child understands that you still
            care about him or her, but that some behaviors will mean that your
            child must stop what he or she is doing and go to time out. Explain
            that this will be a new rule for your family. It is not something
            the child can argue about, and it is meant to help everyone feel
            better and have fun when you are together.
          </EE.Paragraph>
          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>
                Here is when I plan to explain time out to my family:
              </EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWhenIPlanToExplainTimeOut(e.target.value)
              }
              value={whenIPlanToExplainTimeOut}
            />
          </EE.QuestionLabel>

          <EE.Subheading>What to Do</EE.Subheading>

          <Section>
            <Number>1</Number>
            <Text>
              <span className="font-sans font-medium text-green-700">
                Give an instruction
              </span>{" "}
              (for example, “Please get out of your sister’s room,” or “Please
              do not tip back in your chair at the dinner table”).
            </Text>
          </Section>
          <Section>
            <Number>2</Number>
            <Text>
              <span className="font-sans font-medium text-green-700">
                Count to 5
              </span>{" "}
              in your head. If your child does not follow through, then...
            </Text>
          </Section>
          <Section>
            <Number>3</Number>
            <Text>
              <span className="font-sans font-medium text-green-700">
                Give your child a single warning{" "}
              </span>{" "}
              Be clear and brief (for example, “If you don’t get out of your
              sister’s room, you will have a time out,” or “If you don’t put
              your chair flat on the floor, you will have a time out”).
            </Text>
          </Section>
          <Section>
            <Number>4</Number>
            <Text>
              <span className="font-sans font-medium text-green-700">
                Count to 5
              </span>{" "}
              in your head again. If your child does not follow through, then...
            </Text>
          </Section>
          <Section>
            <Number>5</Number>
            <Text>
              <span className="font-sans font-medium text-green-700">
                Instruct your child to go to time out.
              </span>{" "}
              Do this quickly and calmly. State the specific reason for the time
              out, but try to use no more than 10 words in 10 seconds.
            </Text>
          </Section>
          <Section>
            <Number>6</Number>
            <Text>
              <span className="font-sans font-medium text-green-700">
                Don’t explain.
              </span>{" "}
              Don’t argue, scold, or even talk with your child once a time out
              has been issued. These rules have already been explained before.
              Now it’s time to put timeout into action.
            </Text>
          </Section>
          <Section>
            <Number>7</Number>
            <Text>
              <span className="font-sans font-medium text-green-700">
                Set a timer.
              </span>{" "}
              It can help to use a timer to keep track of how long the time out
              will be. For most children, 5 minutes is an appropriate length of
              time. It will help if your child can see the timer, but place it
              out of your child’s reach.
            </Text>
          </Section>
          <Section>
            <Number>8</Number>
            <Text>
              <span className="font-sans font-medium text-green-700">
                Reset the timer if needed.
              </span>{" "}
              If your child is screaming or yelling in time out, you may reset
              the timer. The time out begins when your child is settled.
            </Text>
          </Section>
          <Section>
            <Number>9</Number>
            <Text>
              <span className="font-sans font-medium text-green-700">
                Talk calmly afterwards.
              </span>{" "}
              After you let your child out of time out, check to see if he or
              she knows why you gave a time out. If your child says, “I don’t
              know” calmly explain the reason, but don’t lecture.
            </Text>
          </Section>
          <Section>
            <Number>10</Number>
            <Text>
              <span className="font-sans font-medium text-green-700">
                Repeat the instruction
              </span>{" "}
              calmly, and go back to step 4.
            </Text>
          </Section>

          <EE.Subheading>Help! Time Out Isn't Working!</EE.Subheading>
          <EE.Paragraph className="mt-4">
            <EE.Bold>Problem: My child argues and talks back.</EE.Bold>
          </EE.Paragraph>
          <EE.Paragraph>
            Ignore backtalk and arguing. Do not get into a discussion. If the
            arguing escalates into yelling, simply reset the timer when your
            child settles down.
          </EE.Paragraph>
          <EE.Paragraph className="mt-4">
            <EE.Bold>
              Problem: My child tantrums and makes a mess when I give a time
              out.
            </EE.Bold>
          </EE.Paragraph>
          <EE.Paragraph>
            Have your child do the time out anyway, and then when it is over,
            issue an instruction for your child to clean up his or her mess.
          </EE.Paragraph>
          <EE.Paragraph className="mt-4">
            <EE.Bold>
              Problem: My child does not believe me when I give a time out.
            </EE.Bold>
          </EE.Paragraph>
          <EE.Paragraph>
            Never give a time out unless you mean it, and never give more than
            one warning. Once a time out is given, it must be followed through.
            Over time, your child will learn that you mean it.
          </EE.Paragraph>
          <EE.Paragraph className="mt-4">
            <EE.Bold>
              Problem: My child will not go to time out or leaves time out
              before it is over.
            </EE.Bold>
          </EE.Paragraph>
          <EE.Paragraph>
            You can restate that your child needs to go to time out and gently
            guide your child back to the time out area. Another option is to
            take away privileges or toys for failure to follow through on a time
            out.
          </EE.Paragraph>
          <EE.Paragraph className="mt-4">
            <EE.Bold>
              Problem: We are out at a store or a friend’s house, so I can’t
              give a time out.
            </EE.Bold>
          </EE.Paragraph>
          <EE.Paragraph>
            When you go out shopping or visit neighbors, it can be helpful to
            pick a time out place as soon as you arrive and to let your child
            know about it. You can also bring the timer with you. If you can’t
            find a good time out place, you can have your child sit in a boring
            place right where you are. Just make sure you can always see your
            child when giving a time out away from home. If you need help
            thinking of more ideas, don’t be afraid to ask the therapist.
          </EE.Paragraph>
          <EE.Paragraph className="mt-4">
            <EE.Bold>
              Problem: Other people in my home aren’t helping me.
            </EE.Bold>
          </EE.Paragraph>
          <EE.Paragraph>
            Talk to your family about this at a time when things are calm.
            Explain that everyone has to work together and follow the same rules
            or the problem behaviors won’t improve. In fact, they might even get
            worse.
          </EE.Paragraph>
          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default LearningAboutAnxiety;
