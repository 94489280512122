import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  declare readonly requiredCheckboxesTarget: HTMLElement;
  declare readonly formTarget: HTMLFormElement;
  declare readonly errorTarget: HTMLElement;
  declare readonly pleaseExplainTarget: HTMLInputElement;
  declare readonly otherCheckboxTarget: HTMLInputElement;

  static targets = [
    "pleaseExplain",
    "otherCheckbox",
    "form",
    "error",
    "requiredCheckboxes",
  ];

  validate(event) {
    this.formTarget.checkValidity();
    this.formTarget.reportValidity();

    if (!this.requiredCheckboxGroup()) {
      event.preventDefault();
      this.requiredCheckboxesTarget.scrollIntoView();
      this.errorTarget.classList.remove("hidden");
    }

    if (this.otherCheckboxChecked() && this.pleaseExplainTarget.value == "") {
      event.preventDefault();
      this.pleaseExplainTarget.scrollIntoView({ block: "center" });
      this.pleaseExplainTarget.classList.add("border");
      this.pleaseExplainTarget.classList.add("border-danger");
    }
  }

  requiredCheckboxGroup() {
    const checkboxes = Array.prototype.slice.call(
      this.requiredCheckboxesTarget.querySelectorAll("input[type=checkbox]")
    );
    return checkboxes.some((checkbox: HTMLInputElement) => checkbox.checked);
  }

  otherCheckboxChecked() {
    return !!this.otherCheckboxTarget.checked;
  }
}
