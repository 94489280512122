import { datadogRum } from "@datadog/browser-rum";

let loaded = false;

const initialize = () => {
  if (loaded) {
    return;
  }
  const datadogEnvEl = document.getElementsByName("datadog-rum-env")[0];

  if (datadogEnvEl) {
    const environment = datadogEnvEl.dataset.environment;

    datadogRum.init({
      applicationId: "8efdb37e-60d7-4f8e-be3e-35519283d2f4",
      clientToken: "pub26f151ecf931632c161e864de7f37d1f",
      site: "datadoghq.com",
      service: "reef",
      env: environment,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 10, // premium samples are 4x the price.
      // Note: The trackInteractions initialization parameter enables the automatic collection of
      // potentially sensitive or private data within user clicks in your application.
      // Setting to false to be cautious.
      trackUserInteractions: false,
    });
  }
  loaded = true;
};

document.addEventListener("turbo:load", initialize);
// TurboToDo: Remove this + loaded check once turbolinks is gone.
document.addEventListener("turbolinks:load", initialize);
