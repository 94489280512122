import React, { useState } from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const planningQuestions = {
  transportation: [
    "Is a car available?",
    "A ride?",
    "Will there be parking?",
    "Do I need a map?",
    "What about weather?",
    "Have I left enough time?",
    "A bus schedule?",
    "Traffic?",
    "Do I have a backup plan?",
  ],
  schedule: [
    "Is this time too early or too late?",
    "Will I need child care?",
    "Is this too close to something else?",
    "Is this the best day?",
    "Will we be too tired/hungry?",
    "Do I have a backup plan?",
  ],
  support: [
    "Do I have a partner or other adult at home?",
    "Is that person 'on board'?",
    "Are there others? How do they feel?",
    "Will my family make time for me to practice?",
    "Are my other kids OK?",
    "Do I have a backup plan?",
  ],
  myRoutine: [
    "Does this work with my day to day life?",
    "What might get in the way at home?",
    "Is my family prepared?",
    "Who shares parenting?",
    "Is that person involved enough?",
    "A backup plan?",
  ],
  findingTime: [
    "What else needs to get done?",
    "What are the best and worst times for me to practice at home?",
    "What might have dropped for now?",
    "What might come up?",
    "Do I have a backup plan?",
  ],
  otherIssues: [],
};

const ThreeColumnList: React.FC = (props) => {
  return (
    <ul className="my-4 ml-4 list-disc md:three-column">{props.children}</ul>
  );
};

const ThinkingAheadScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    transportation: "",
    schedule: "",
    support: "",
    myRoutine: "",
    findingTime: "",
    otherIssues: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [transportation, setTransportation] = useState(
    initialState.transportation
  );
  const [schedule, setSchedule] = useState(initialState.schedule);
  const [support, setSupport] = useState(initialState.support);
  const [myRoutine, setMyRoutine] = useState(initialState.myRoutine);
  const [findingTime, setFindingTime] = useState(initialState.findingTime);
  const [otherIssues, setOtherIssues] = useState(initialState.otherIssues);

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      transportation,
      schedule,
      support,
      myRoutine,
      findingTime,
      otherIssues,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Thinking Ahead</EE.Heading>
          <EE.HR />

          <EE.Paragraph>
            What could get in the way of your goals? What could you do about it?
          </EE.Paragraph>
          <EE.Subheading>Transportation - How Do I Get There?</EE.Subheading>
          <ThreeColumnList>
            {planningQuestions.transportation.map((question) => (
              <li key={question} className="md:pr-4 md:pb-2">
                {question}
              </li>
            ))}
          </ThreeColumnList>
          <fieldset id="transportationInput">
            <EE.QuestionLabel id="transportation">
              <div className="text-xl text-marigold font-sans font-bold">
                Solutions
              </div>
              <EE.TextArea
                value={transportation}
                onChange={(e) => setTransportation(e.target.value)}
                aria-labelledby="transportation"
              />
            </EE.QuestionLabel>
          </fieldset>

          <fieldset id="scheduleInput">
            <EE.Subheading>Schedule - Is This a Good Time?</EE.Subheading>
            <ThreeColumnList>
              {planningQuestions.schedule.map((question) => (
                <li key={question} className="md:pr-4 md:pb-2">
                  {question}
                </li>
              ))}
            </ThreeColumnList>
            <EE.QuestionLabel id="schedule">
              <div className="text-xl text-marigold font-sans font-bold">
                Solutions
              </div>
              <EE.TextArea
                value={schedule}
                onChange={(e) => setSchedule(e.target.value)}
                aria-labelledby="schedule"
              />
            </EE.QuestionLabel>
          </fieldset>

          <fieldset id="supportInput">
            <EE.Subheading>Support - Who Is Helping Me?</EE.Subheading>
            <ThreeColumnList>
              {planningQuestions.support.map((question) => (
                <li key={question} className="align-middle md:pr-4 md:pb-2">
                  {question}
                </li>
              ))}
            </ThreeColumnList>
            <EE.QuestionLabel id="support">
              <div className="text-xl text-marigold font-sans font-bold">
                Solutions
              </div>
              <EE.TextArea
                value={support}
                onChange={(e) => setSupport(e.target.value)}
                aria-labelledby="support"
              />
            </EE.QuestionLabel>
          </fieldset>

          <fieldset id="routineInput">
            <EE.Subheading>My Routine - How Will This Fit in?</EE.Subheading>
            <ThreeColumnList>
              {planningQuestions.myRoutine.map((question) => (
                <li key={question} className="md:pr-4 md:pb-2">
                  {question}
                </li>
              ))}
            </ThreeColumnList>
            <EE.QuestionLabel id="my-routine">
              <div className="text-xl text-marigold font-sans font-bold">
                Solutions
              </div>
              <EE.TextArea
                value={myRoutine}
                onChange={(e) => setMyRoutine(e.target.value)}
                aria-labelledby="my-routine"
              />
            </EE.QuestionLabel>
          </fieldset>

          <fieldset id="findingTimeInput">
            <EE.Subheading>
              Finding Time - Can I Make Time for This?
            </EE.Subheading>
            <ThreeColumnList>
              {planningQuestions.findingTime.map((question) => (
                <li key={question} className="md:pr-4 md:pb-2">
                  {question}
                </li>
              ))}
            </ThreeColumnList>
            <EE.QuestionLabel id="finding-time">
              <div className="text-xl text-marigold font-sans font-bold">
                Solutions
              </div>
              <EE.TextArea
                value={findingTime}
                onChange={(e) => setFindingTime(e.target.value)}
                aria-labelledby="finding-time"
              />
            </EE.QuestionLabel>
          </fieldset>

          <fieldset id="otherIssuesInput">
            <EE.Subheading>Other Issues - Did I Miss Anything?</EE.Subheading>
            <EE.QuestionLabel id="other-issues">
              <div className="text-xl text-marigold font-sans font-bold">
                Solutions
              </div>
              <EE.TextArea
                value={otherIssues}
                onChange={(e) => setOtherIssues(e.target.value)}
                aria-labelledby="other-issues"
              />
            </EE.QuestionLabel>
          </fieldset>

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default ThinkingAheadScreens;
