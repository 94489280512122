import React from "react";

import {
  ExerciseElements as EE,
  ExerciseDoubleBubbleIcon,
} from "../shared/Elements";
import { useDoubleBubble } from "./Context";
import { Title } from "./Shared";

const Introduction = () => {
  const { patientFirstName, careLink } = useDoubleBubble();
  return (
    <div className="p-6">
      <div className="flex-grow-0">
        <EE.RoundedExerciseCard className="pt-8">
          <ExerciseDoubleBubbleIcon className="w-1/3" />
          <Title />
        </EE.RoundedExerciseCard>
        <div className="pt-8 text-mediumBrown">
          <ul>
            <EE.PaddedListItem>
              We all feel <EE.Bold>BLUE</EE.Bold> sometimes... luckily, there
              are strategies that can help like “
              <EE.Bold>Changing BLUE Thoughts</EE.Bold>
              ”. Changing BLUE Thoughts focuses on how our thoughts can directly
              affect how we feel.
            </EE.PaddedListItem>

            <EE.PaddedListItem>
              Support {patientFirstName} in changing the unrealistic{" "}
              <EE.Bold>BLUE thought</EE.Bold> into a{" "}
              <EE.Bold>TRUE thought</EE.Bold>, and record the more realistic
              true thought.{" "}
            </EE.PaddedListItem>
          </ul>
        </div>

        <div className="flex flex-col justify-center">
          <EE.ExerciseCallToActionLink to="/exercise/1">
            Start
          </EE.ExerciseCallToActionLink>
          <EE.SecondaryActionLink text="Cancel" href={careLink} />
          <hr className="mt-10 mb-6" />
        </div>
      </div>
    </div>
  );
};

export default Introduction;
