import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useAllAboutMe } from "./Context";

const DeepDescription = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const {
    description,
    setDescription,
    deepDescription,
    setDeepDescription,
    assignedExerciseId,
    completedExercise,
  } = useAllAboutMe();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      description,
      setDescription,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.GenericTextAreaPromptPage
      currentStep={currentStep}
      numSteps={numSteps}
      nextPage={nextPage}
      textPrompt="Let's take it a step deeper, how would you describe yourself if no one was reading it."
      showSaveForLater={false}
      textAreaValue={deepDescription}
      setTextAreaValue={setDeepDescription}
      submitExercise={submitExercise}
      setExerciseComplete={setExerciseComplete}
    />
  );
};

export default DeepDescription;
