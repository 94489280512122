import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import BodyMap from "./BodyMap";
import { useHowDoesConflictMakeYouFeel } from "./Context";

const Summary = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep } = props;

  const {
    feeling1,
    feeling2,
    feeling3,
    blobs,
    assignedExerciseId,
    completedExercise,
  } = useHowDoesConflictMakeYouFeel();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      feeling1,
      feeling2,
      feeling3,
      blobs,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <>
      <EE.SubmitableForm submitExercise={submitExercise}>
        <EE.StepIndicator numSteps={numSteps} currentStep={currentStep} />

        <EE.Subheading>When you feel conflict you think of:</EE.Subheading>
        <ol className="pl-10 my-4 list-decimal">
          <li>{feeling1}</li>
          <li>{feeling2}</li>
          <li>{feeling3}</li>
        </ol>
        <EE.Subheading>You feel conflict in your body here:</EE.Subheading>
        <BodyMap propsReadOnly={true} />
        <EE.ButtonGroup>
          <EE.ResumableExerciseActions
            submitButtonText="I'm done"
            setExerciseComplete={setExerciseComplete}
          />
        </EE.ButtonGroup>
      </EE.SubmitableForm>
    </>
  );
};

export default Summary;
