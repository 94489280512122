import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useSelfEsteemJournal } from "./Context";

const Summary = () => {
  const {
    goodEvent,
    positiveWords,
    selfCompliment,
    positiveFeeling,
    positiveAction,
    negativeThought,
    differentThought,
    makeTomorrowBetter,
    assignedExerciseId,
    completedExercise,
  } = useSelfEsteemJournal();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      goodEvent,
      positiveWords,
      selfCompliment,
      positiveFeeling,
      positiveAction,
      negativeThought,
      differentThought,
      makeTomorrowBetter,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.SubmitableForm submitExercise={submitExercise}>
      <div className="md:mt-12">
        Something good that happened to me today was... {goodEvent}
      </div>

      <div className="mt-6">
        Something positive someone said about me recently was... {positiveWords}
      </div>

      <div className="mt-6">
        A compliment that I would give myself today is... {selfCompliment}
      </div>

      <div className="mt-6">
        A positive feeling(s) I experienced today was... {positiveFeeling}
      </div>

      <div className="mt-6">
        I recently made someone feel good when... {positiveAction}
      </div>

      <div className="mt-6">
        I had a negative thought about myself recently when... {negativeThought}
      </div>

      <div className="mt-6">
        A different thought that I can have next time is... {differentThought}
      </div>

      <div className="mt-6">
        Something I can do to make tomorrow a better day is...{" "}
        {makeTomorrowBetter}
      </div>

      <EE.ButtonGroup>
        <EE.ResumableExerciseActions
          submitButtonText="I'm done"
          setExerciseComplete={setExerciseComplete}
        />
      </EE.ButtonGroup>
    </EE.SubmitableForm>
  );
};

export default Summary;
