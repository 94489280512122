import { Controller } from "@hotwired/stimulus";

/**
 * Show and hide elements with this controller. Highly generic.
 *
 * Use data-action="click->hideable#toggle" on any element which
 * should show/hide the hideable content. When that element is
 * clicked a `hideable--open` class will be toggled.
 *
 * Place data-hideable-target="hideable" on any element which should
 * be shown or hidden based on the toggle() method. This element
 * must be a descendant of the parent of the element that triggers
 * the toggle action.
 */
export default class extends Controller {
  static targets = ["hideable"];
  declare readonly hideableTargets: HTMLElement[];

  connect() {
    this.validateTargets();
  }

  toggle(event: Event) {
    event.preventDefault();
    if (!this.isElement(event.target)) return;

    const isTargetHidden = this.isNearestHideableTargetHidden(event.target);
    this.hideAll();
    if (isTargetHidden) {
      this.showNearestHideableTarget(event.target);
    }
  }

  toggleAll(event: any) {
    if (!event.key || ["Enter", "Escape", "Space"].includes(event.key)) {
      this.hideableTargets.forEach((target) => {
        target.classList.toggle("hidden");
      });
    }
  }

  hideAll(event?: any) {
    if (event?.key && event.key !== "Escape") return;
    this.element.querySelectorAll(".hideable--open").forEach((element) => {
      element.classList.remove("hideable--open");
      element.setAttribute("aria-expanded", "false");
    });
    this.hideAllTargets();
  }

  private hideAllTargets() {
    this.hideableTargets.forEach((target) => {
      target.classList.add("hidden");
    });
  }

  private isNearestHideableTargetHidden(element: Element) {
    return element.parentNode
      ?.querySelector('[data-hideable-target="hideable"]')
      ?.classList.contains("hidden");
  }

  private showNearestHideableTarget(element: Element) {
    element.classList.toggle("hideable--open");
    element.setAttribute("aria-expanded", "true");
    element.parentNode
      ?.querySelector('[data-hideable-target="hideable"]')
      ?.classList.remove("hidden");
  }

  private isElement(target: any): target is Element {
    return !!target.parentNode;
  }

  private validateTargets() {
    if (this.hideableTargets.length === 0) {
      throw new Error(
        `Hideable Controller requires at least one element with data-hideable-target="hideable"`
      );
    }
  }
}
