import Point from "./Point";

class Circle {
  constructor() {
    /** @type {Number} the number of points to build the perimeter of the circle */
    this.numberOfPoints = 6;
    /** @type {Number} the radius of the circle */
    this.radius = 75;
    this._points = [];
  }

  /**
   * An array of evenly-spaced points that form the circle.
   *
   * @returns {Point[]}
   */
  get points() {
    if (this._points.length > 0) return this._points;

    for (let step = 1; step <= this.numberOfPoints; step++) {
      const planeAngle = this.theta(step);
      const { x, y } = this.coordinates(planeAngle);

      this._points.push(new Point(x, y));
    }

    return this._points;
  }

  /**
   * Given a plane angle (theta), return the x and y coordinates of the point.
   * Derived from: https://en.wikipedia.org/wiki/Polar_coordinate_system
   *
   * @param {Number} planeAngle
   * @returns {{x: Number, y: Number}} object containing the x and y coordinates
   */
  coordinates(planeAngle) {
    const x = 100 + Math.cos(planeAngle) * this.radius;
    const y = 100 + Math.sin(planeAngle) * this.radius;

    return { x, y };
  }

  /**
   * Used to equally space points around a circle
   *
   * @params {number} numberOfPoints
   * @returns {number}
   */
  angleStep(numberOfPoints) {
    return (Math.PI * 2) / numberOfPoints;
  }

  /**
   * Theta is the angle of the arc formed by points along the perimeter of
   * a circle and it's center.
   *
   * @param {number} step
   * @returns {number} the plane angle
   */
  theta(step) {
    return step * this.angleStep(this.numberOfPoints);
  }
}

export default Circle;
