import HowConflictFeelsIcon from "@js/images/icons/HowConflictFeels.svg";
import React from "react";
import { Route } from "react-router-dom";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { HowDoesConflictMakeYouFeelProvider } from "./Context";
import AmazingAt from "./Draw";
import Draw from "./Draw";
import FindOnBody from "./FindOnBody";
import NameFeelings from "./NameFeelings";
import Summary from "./Summary";

const Screens: React.FC<ExerciseScreenProps> = (props) => {
  const title = "How Does Conflict Make You Feel?";
  const numSteps = 4;
  let currentStep = 1;

  const intro = (
    <EE.GenericExerciseIntroPage
      title={title}
      imageSrc={HowConflictFeelsIcon}
      imageAltText="A thought bubble with squiggly lines."
      introText="You can actually feel big emotions like anger or fear in your body. Figuring out where can help you get better at dealing with conflicts."
      nextPage="/draw"
    />
  );

  return (
    <HowDoesConflictMakeYouFeelProvider {...props}>
      <EE.WrapperWithIntro intro={intro}>
        <EE.ExerciseNavigation title={title} />

        <Route path="/draw">
          <Draw
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/name_feelings"
          />
        </Route>

        <Route path="/name_feelings">
          <NameFeelings
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/body_map"
          />
        </Route>

        <Route path="/body_map">
          <FindOnBody
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/summary"
          />
        </Route>

        <Route path="/summary">
          <Summary
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage=""
          />
        </Route>
      </EE.WrapperWithIntro>
    </HowDoesConflictMakeYouFeelProvider>
  );
};

export default Screens;
