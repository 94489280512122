import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useWhoIAm } from "./Context";

const Basics = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const {
    name,
    birthday,
    graduatingClass,
    ethnicity,
    setName,
    setBirthday,
    setGraduatingClass,
    setEthnicity,
    assignedExerciseId,
    completedExercise,
  } = useWhoIAm();
  const myName = name;
  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      name,
      birthday,
      graduatingClass,
      ethnicity,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <>
      <EE.StepIndicator numSteps={numSteps} currentStep={currentStep} />
      <EE.SubmitableForm submitExercise={submitExercise}>
        <EE.Subheading>The basics</EE.Subheading>
        <EE.QuestionLabel>
          Name
          <EE.TextInput
            value={name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
          />
        </EE.QuestionLabel>
        <EE.QuestionLabel>
          Birthday
          <EE.TextInput
            value={birthday}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setBirthday(e.target.value)
            }
          />
        </EE.QuestionLabel>
        <EE.QuestionLabel>
          Graduating class
          <EE.TextInput
            value={graduatingClass}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setGraduatingClass(e.target.value)
            }
          />
        </EE.QuestionLabel>
        <EE.QuestionLabel>
          Ethnicity/Race
          <EE.TextInput
            value={ethnicity}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEthnicity(e.target.value)
            }
          />
        </EE.QuestionLabel>

        <EE.ButtonGroup>
          <EE.ExerciseCallToActionLink to={nextPage}>
            Next
          </EE.ExerciseCallToActionLink>

          <EE.SaveForLaterButtonGreen
            text="Save for Later"
            setExerciseComplete={setExerciseComplete}
          />
        </EE.ButtonGroup>
      </EE.SubmitableForm>
    </>
  );
};

export default Basics;
