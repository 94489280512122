import React from "react";

import { ExerciseScreenProps } from "../shared/Elements";
import HaveNever from "./HaveNever";

const WhoIAm = React.createContext(undefined);

const WhoIAmProvider: React.FC<ExerciseScreenProps> = (props) => {
  const defaultState = {
    name: "",
    birthday: "",
    graduatingClass: "",
    ethnicity: "",
    placesIHaveLived1: "",
    placesIHaveLived2: "",
    placesIHaveLived3: "",
    placesIHaveLived4: "",
    placesIHaveLived5: "",
    placesIHaveLived6: "",
    supportSystem: "",
    hobbies1: "",
    hobbies2: "",
    hobbies3: "",
    hobbies4: "",
    hobbies5: "",
    hobbies6: "",
    favoriteMovieOrShow: "",
    favoriteColor: "",
    favoriteBookOrMagazine: "",
    favoriteFood: "",
    favoriteClassOrSubject: "",
    favoriteMusicGenre: "",
    pets: "",
    goodFriend: "",
    freeTime: "",
    haveNever: "",
  };

  const {
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  } = props;

  const initialState = { ...defaultState, ...completedExercise?.data };

  const [name, setName] = React.useState<string>(initialState.name);
  const [birthday, setBirthday] = React.useState<string>(initialState.birthday);
  const [graduatingClass, setGraduatingClass] = React.useState<string>(
    initialState.graduatingClass
  );
  const [ethnicity, setEthnicity] = React.useState<string>(
    initialState.ethnicity
  );

  const [placesIHaveLived1, setPlacesIHaveLived1] = React.useState<string>(
    initialState.placesIHaveLived1
  );
  const [placesIHaveLived2, setPlacesIHaveLived2] = React.useState<string>(
    initialState.placesIHaveLived2
  );
  const [placesIHaveLived3, setPlacesIHaveLived3] = React.useState<string>(
    initialState.placesIHaveLived3
  );
  const [placesIHaveLived4, setPlacesIHaveLived4] = React.useState<string>(
    initialState.placesIHaveLived4
  );
  const [placesIHaveLived5, setPlacesIHaveLived5] = React.useState<string>(
    initialState.placesIHaveLived5
  );
  const [placesIHaveLived6, setPlacesIHaveLived6] = React.useState<string>(
    initialState.placesIHaveLived6
  );
  const [supportSystem, setSupportSystem] = React.useState<string>(
    initialState.supportSystem
  );
  const [hobbies1, setHobbies1] = React.useState<string>(initialState.hobbies1);
  const [hobbies2, setHobbies2] = React.useState<string>(initialState.hobbies2);
  const [hobbies3, setHobbies3] = React.useState<string>(initialState.hobbies3);
  const [hobbies4, setHobbies4] = React.useState<string>(initialState.hobbies4);
  const [hobbies5, setHobbies5] = React.useState<string>(initialState.hobbies5);
  const [hobbies6, setHobbies6] = React.useState<string>(initialState.hobbies6);
  const [favoriteMovieOrShow, setFavoriteMovieOrShow] = React.useState<string>(
    initialState.favoriteMovieOrShow
  );
  const [favoriteColor, setFavoriteColor] = React.useState<string>(
    initialState.favoriteColor
  );
  const [favoriteBookOrMagazine, setFavoriteBookOrMagazine] =
    React.useState<string>(initialState.favoriteBookOrMagazine);
  const [favoriteFood, setFavoriteFood] = React.useState<string>(
    initialState.favoriteFood
  );
  const [favoriteClassOrSubject, setFavoriteClassOrSubject] =
    React.useState<string>(initialState.favoriteClassOrSubject);
  const [favoriteMusicGenre, setFavoriteMusicGenre] = React.useState<string>(
    initialState.favoriteMusicGenre
  );
  const [pets, setPets] = React.useState<string>(initialState.pets);
  const [goodFriend, setGoodFriend] = React.useState<string>(
    initialState.goodFriend
  );
  const [freeTime, setFreeTime] = React.useState<string>(initialState.freeTime);
  const [haveNever, setHaveNever] = React.useState<string>(
    initialState.haveNever
  );

  const value = {
    name,
    setName,
    birthday,
    setBirthday,
    graduatingClass,
    setGraduatingClass,
    ethnicity,
    setEthnicity,
    placesIHaveLived1,
    setPlacesIHaveLived1,
    placesIHaveLived2,
    setPlacesIHaveLived2,
    placesIHaveLived3,
    setPlacesIHaveLived3,
    placesIHaveLived4,
    setPlacesIHaveLived4,
    placesIHaveLived5,
    setPlacesIHaveLived5,
    placesIHaveLived6,
    setPlacesIHaveLived6,
    supportSystem,
    setSupportSystem,
    hobbies1,
    setHobbies1,
    hobbies2,
    setHobbies2,
    hobbies3,
    setHobbies3,
    hobbies4,
    setHobbies4,
    hobbies5,
    setHobbies5,
    hobbies6,
    setHobbies6,
    favoriteMovieOrShow,
    setFavoriteMovieOrShow,
    favoriteColor,
    setFavoriteColor,
    favoriteBookOrMagazine,
    setFavoriteBookOrMagazine,
    favoriteFood,
    setFavoriteFood,
    favoriteClassOrSubject,
    setFavoriteClassOrSubject,
    favoriteMusicGenre,
    setFavoriteMusicGenre,
    pets,
    setPets,
    goodFriend,
    setGoodFriend,
    freeTime,
    setFreeTime,
    haveNever,
    setHaveNever,
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  };

  return <WhoIAm.Provider value={value} {...props} />;
};

function useWhoIAm() {
  const context = React.useContext(WhoIAm);
  if (!context) {
    throw new Error("useWhoIAm must be used within a WhoIAmProvider");
  }
  return context;
}

export { WhoIAmProvider, WhoIAm, useWhoIAm };
