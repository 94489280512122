import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["navigation"];
  }
  static get values() {
    return {
      index: {
        type: Number,
        default: 0,
      },
    };
  }

  declare readonly navigationTargets: Array<HTMLElement>;
  declare indexValue: number;

  // allow dynamic control of which steps can be navigated through
  get visibleNavigationTargets(): Array<HTMLElement> {
    return this.navigationTargets.filter(
      (target) => !target.parentElement.classList.contains("hidden")
    );
  }

  initialize() {
    this.readIndexFromURL();
  }

  connect() {
    // Set initial visibility
    this.setVisibilityForTargets(this.navigationTargets);
    window.addEventListener("popstate", this.readIndexFromURL.bind(this));
  }

  disconnect() {
    window.removeEventListener("popstate", this.readIndexFromURL.bind(this));
  }

  // advance to the next step
  next() {
    this.indexValue++;
    this.pushStepToHistory();
  }

  // return to previous step
  prev() {
    this.indexValue--;
    this.pushStepToHistory();
  }

  indexValueChanged() {
    this.showCurrentStep();
  }

  // set inactive steps to hidden
  showCurrentStep() {
    this.setVisibilityForTargets(this.visibleNavigationTargets);
  }

  setVisibilityForTargets(targets: Array<HTMLElement>) {
    targets.forEach((element, index) => {
      element.hidden = index !== this.indexValue;
    });
  }

  readIndexFromURL() {
    const urlParams = new URL(document.location.toString()).searchParams;
    if (urlParams.has("page")) {
      this.indexValue = parseInt(urlParams.get("page").toString());
    }
  }

  pushStepToHistory() {
    const url = new URL(window.location.toString());
    url.searchParams.set("page", this.indexValue.toString());
    window.history.pushState({}, "", url.toString());
    window.scrollTo(0, 0);
  }
}
