import { Controller } from "@hotwired/stimulus";
import VimeoPlayer from "@vimeo/player";

export default class extends Controller {
  static targets = ["player", "duration"];

  connect() {
    this.player = new VimeoPlayer(this.playerTarget);

    Promise.all([
      this.player.getDuration(),
      this.player.getVideoWidth(),
      this.player.getVideoHeight(),
    ]).then(([duration, width, height]) => {
      this.setDuration(duration);
      this.setDimensions(width, height);
      this.setLoaded();
    });
  }

  setDuration(duration) {
    if (this.hasDurationTarget) {
      this.durationTarget.innerHTML = this.formatDuration(duration);
    }
  }

  setDimensions(width, height) {
    const existingWidth = this.playerTarget.width;
    const ratio = width / height;
    const newHeight = existingWidth / ratio;
    this.playerTarget.height = newHeight;
  }

  setLoaded() {
    this.element.classList.remove("opacity-0");
  }

  formatDuration(duration) {
    if (duration < 60) {
      return Math.round(duration);
    }

    return `${Math.floor(duration / 60)}:${String(
      Math.round(duration) % 60
    ).padStart(2, "0")}`;
  }
}
