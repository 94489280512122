import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const MyNegativeSelfMyPositiveSelf: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    negativeSelf: {
      look: "",
      act: "",
      say: "",
    },
    positiveSelf: {
      look: "",
      act: "",
      say: "",
    },
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };

  const [negativeSelfLook, setNegativeSelfLook] = React.useState(
    initialState.negativeSelf.look
  );
  const [negativeSelfAct, setNegativeSelfAct] = React.useState(
    initialState.negativeSelf.act
  );
  const [negativeSelfSay, setNegativeSelfSay] = React.useState(
    initialState.negativeSelf.say
  );
  const [positiveSelfLook, setPositiveSelfLook] = React.useState(
    initialState.positiveSelf.look
  );
  const [positiveSelfAct, setPositiveSelfAct] = React.useState(
    initialState.positiveSelf.act
  );
  const [positiveSelfSay, setPositiveSelfSay] = React.useState(
    initialState.positiveSelf.say
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      negativeSelf: {
        look: negativeSelfLook,
        act: negativeSelfAct,
        say: negativeSelfSay,
      },
      positiveSelf: {
        look: positiveSelfLook,
        act: positiveSelfAct,
        say: positiveSelfSay,
      },
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper className="max-w-6xl">
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>My Negative Self &amp; My Positive Self</EE.Heading>
          <EE.HR />
          <EE.Paragraph>
            What are the things you do to show the world that you are in a good
            or a bad mood? Make a list below of the ways you show your{" "}
            <EE.Bold>Negative Self</EE.Bold> and your{" "}
            <EE.Bold>Positive Self</EE.Bold>.
          </EE.Paragraph>
          <EE.Table>
            <EE.TableCell className="order-1 hidden md:flex font-sans font-bold"></EE.TableCell>
            <EE.TableCell className="order-2 hidden md:flex font-sans font-bold">
              How I Look (eye contact, posture, dress)
            </EE.TableCell>
            <EE.TableCell className="order-3 hidden md:flex font-sans font-bold">
              How I Act (how do I behave?):
            </EE.TableCell>
            <EE.TableCell className="order-4 hidden md:flex font-sans font-bold">
              What I Say (about myself, others, the world):
            </EE.TableCell>
            <EE.TableCell className="order-1 md:order-1">
              <EE.Subheading id="negativeSelf">Negative Self</EE.Subheading>
            </EE.TableCell>
            <EE.TableCell className="order-1 md:order-2">
              <EE.QuestionLabel id="negativeSelfLook">
                <EE.MobileOnlyText>
                  How I Look (eye contact, posture, dress):
                </EE.MobileOnlyText>
                <EE.TextArea
                  value={negativeSelfLook}
                  onChange={(e) => setNegativeSelfLook(e.target.value)}
                  aria-labelledby="negativeSelf negativeSelfLook"
                  placeholder={"1.\n2.\n3.\n"}
                />
              </EE.QuestionLabel>
            </EE.TableCell>
            <EE.TableCell className="order-1 md:order-3">
              <EE.QuestionLabel id="negativeSelfAct">
                <EE.MobileOnlyText>
                  How I Act (how do I behave?):
                </EE.MobileOnlyText>
                <EE.TextArea
                  value={negativeSelfAct}
                  onChange={(e) => setNegativeSelfAct(e.target.value)}
                  aria-labelledby="negativeSelf negativeSelfAct"
                  placeholder={"1.\n2.\n3.\n"}
                />
              </EE.QuestionLabel>
            </EE.TableCell>
            <EE.TableCell className="order-1 md:order-4">
              <EE.QuestionLabel id="negativeSelfSay">
                <EE.MobileOnlyText>
                  What I Say (about myself, others, the world):
                </EE.MobileOnlyText>
                <EE.TextArea
                  value={negativeSelfSay}
                  onChange={(e) => setNegativeSelfSay(e.target.value)}
                  aria-labelledby="negativeSelf negativeSelfSay"
                  placeholder={"1.\n2.\n3.\n"}
                />
              </EE.QuestionLabel>
            </EE.TableCell>

            <EE.TableCell className="order-2 md:order-1">
              <EE.Subheading id="positiveSelf">Positive Self</EE.Subheading>
            </EE.TableCell>
            <EE.TableCell className="order-2 md:order-2">
              <EE.QuestionLabel id="positiveSelfLook">
                <EE.MobileOnlyText>
                  How I Look (eye contact, posture, dress):
                </EE.MobileOnlyText>
                <EE.TextArea
                  value={positiveSelfLook}
                  onChange={(e) => setPositiveSelfLook(e.target.value)}
                  aria-labelledby="positiveSelf positiveSelfLook"
                  placeholder={"1.\n2.\n3.\n"}
                />
              </EE.QuestionLabel>
            </EE.TableCell>
            <EE.TableCell className="order-2 md:order-3">
              <EE.QuestionLabel id="positiveSelfAct">
                <EE.MobileOnlyText>
                  How I Act (how do I behave?):
                </EE.MobileOnlyText>
                <EE.TextArea
                  value={positiveSelfAct}
                  onChange={(e) => setPositiveSelfAct(e.target.value)}
                  aria-labelledby="positiveSelf positiveSelfAct"
                  placeholder={"1.\n2.\n3.\n"}
                />
              </EE.QuestionLabel>
            </EE.TableCell>
            <EE.TableCell className="order-2 md:order-4">
              <EE.QuestionLabel id="positiveSelfSay">
                <EE.MobileOnlyText>
                  What I Say (about myself, others, the world):
                </EE.MobileOnlyText>
                <EE.TextArea
                  value={positiveSelfSay}
                  onChange={(e) => setPositiveSelfSay(e.target.value)}
                  aria-labelledby="positiveSelf positiveSelfSay"
                  placeholder={"1.\n2.\n3.\n"}
                />
              </EE.QuestionLabel>
            </EE.TableCell>
          </EE.Table>
          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default MyNegativeSelfMyPositiveSelf;
