import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useStressManagementTools } from "./Context";

const Summary = (props) => {
  const {
    panicThoughts,
    panicPhysicalFeelings,
    panicBehaviors,
    worryThoughts,
    worryPhysicalFeelings,
    worryBehaviors,
    calmThoughts,
    calmPhysicalFeelings,
    calmBehaviors,
    assignedExerciseId,
    completedExercise,
  } = useStressManagementTools();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      panicThoughts,
      panicPhysicalFeelings,
      panicBehaviors,
      worryThoughts,
      worryPhysicalFeelings,
      worryBehaviors,
      calmThoughts,
      calmPhysicalFeelings,
      calmBehaviors,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  const tdClasses = "p-4 border-2 border-white";
  const trClasses = "h-12 bg-gray-100 border-2 border-white";

  return (
    <EE.SubmitableForm submitExercise={submitExercise}>
      <h2 className="mb-6 text-center">Your stress management grid</h2>

      <div className="block md:hidden">
        <div>
          <div className="flex items-center justify-center w-full h-12 mt-4 bg-red-500 rounded-t-lg">
            <p className="text-white">Panic</p>
          </div>

          <div className="p-4 bg-gray-100">
            <p className="font-bold">Thoughts</p>
            <p className="mt-4">{panicThoughts}</p>
          </div>

          <div className="p-4 my-0.5 bg-gray-100">
            <p className="font-bold">Physical feelings</p>
            <p className="mt-4">{panicPhysicalFeelings}</p>
          </div>

          <div className="p-4 bg-gray-100 rounded-b-lg">
            <p className="font-bold">Behaviors</p>
            <p className="mt-4">{panicBehaviors}</p>
          </div>
        </div>

        <div>
          <div className="flex items-center justify-center w-full h-12 mt-4 bg-yellow-300 rounded-t-lg">
            <p className="">Panic</p>
          </div>

          <div className="p-4 bg-gray-100">
            <p className="font-bold">Thoughts</p>
            <p className="mt-4">{worryThoughts}</p>
          </div>

          <div className="p-4 my-0.5 bg-gray-100">
            <p className="font-bold">Physical feelings</p>
            <p className="mt-4">{worryPhysicalFeelings}</p>
          </div>

          <div className="p-4 bg-gray-100 rounded-b-lg">
            <p className="font-bold">Behaviors</p>
            <p className="mt-4">{worryBehaviors}</p>
          </div>
        </div>

        <div>
          <div className="flex items-center justify-center w-full h-12 mt-4 bg-green-700 rounded-t-lg">
            <p className="text-white">Calm</p>
          </div>

          <div className="p-4 bg-gray-100">
            <p className="font-bold">Thoughts</p>
            <p className="mt-4">{calmThoughts}</p>
          </div>

          <div className="p-4 my-0.5 bg-gray-100">
            <p className="font-bold">Physical feelings</p>
            <p className="mt-4">{calmPhysicalFeelings}</p>
          </div>

          <div className="p-4 bg-gray-100 rounded-b-lg">
            <p className="font-bold">Behaviors</p>
            <p className="mt-4">{calmBehaviors}</p>
          </div>
        </div>
      </div>

      <table className="hidden md:block">
        <tr className="">
          <td className=""></td>
          <td className="w-1/3 px-2 pb-4 text-sm font-bold text-center">
            Thoughts
          </td>
          <td className="w-1/3 px-2 pb-4 text-sm font-bold text-center">
            Physical feelings
          </td>
          <td className="w-1/3 px-2 pb-4 text-sm font-bold text-center">
            Behaviors
          </td>
        </tr>
        <tr className={trClasses}>
          <td
            className={`${tdClasses} "text-center text-white bg-red-500 rounded-tl-lg`}
          >
            Panic
          </td>
          <td className={tdClasses}>{panicThoughts}</td>
          <td className={tdClasses}>{panicPhysicalFeelings}</td>
          <td className={`${tdClasses} border-2 border-white rounded-tr-lg`}>
            {panicBehaviors}
          </td>
        </tr>
        <tr className={trClasses}>
          <td className={`${tdClasses} text-center bg-yellow-300`}>Worry</td>
          <td className={tdClasses}>{worryThoughts}</td>
          <td className={tdClasses}>{worryPhysicalFeelings}</td>
          <td className={tdClasses}>{worryBehaviors}</td>
        </tr>
        <tr className={trClasses}>
          <td
            className={`${tdClasses} text-center text-white bg-green-700 rounded-bl-lg`}
          >
            Calm
          </td>
          <td className={tdClasses}>{calmThoughts}</td>
          <td className={tdClasses}>{calmPhysicalFeelings}</td>
          <td className={`${tdClasses} border-2 border-white rounded-br-lg`}>
            {calmBehaviors}
          </td>
        </tr>
      </table>

      <EE.ButtonGroup>
        <EE.ExerciseCallToActionLink to={props.nextPage}>
          Next
        </EE.ExerciseCallToActionLink>
      </EE.ButtonGroup>
    </EE.SubmitableForm>
  );
};

export default Summary;
