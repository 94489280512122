import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

/*
  button_to's and some forms are that are loaded via turbo broadcasts can sometimes have issues
  with authenticity tokens. This hooks into the submit event for those forms and refreshes the auth token
  to ensure that we don't receive errors in those instances. Noted here https://github.com/hotwired/turbo-rails/issues/243
*/

export default class extends Controller {
  refresh() {
    Rails.refreshCSRFTokens();
  }
}
