import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { hostname: String };
  declare readonly hostnameValue: string;

  connect() {
    this.element.querySelectorAll("a").forEach((a) => {
      if (a.href.indexOf(this.hostnameValue) < 0) {
        a.target = "_blank";
        a.rel = "noopener noreferrer";
      }
    });
  }
}
