import React, { useState } from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const MyFavoriteSkillsForFeelingGoodScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultFavoriteSkill = {
    skill: "",
    example: "",
    whenICanUse: "",
  };
  const defaultState = {
    favoriteSkills: [
      defaultFavoriteSkill,
      defaultFavoriteSkill,
      defaultFavoriteSkill,
    ],
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [skillOne, setSkillOne] = useState(
    initialState.favoriteSkills[0].skill
  );
  const [skillOneExample, setSkillOneExample] = useState(
    initialState.favoriteSkills[0].example
  );
  const [skillOneWhenICanUse, setSkillOneWhenICanUse] = useState(
    initialState.favoriteSkills[0].whenICanUse
  );

  const [skillTwo, setSkillTwo] = useState(
    initialState.favoriteSkills[1].skill
  );
  const [skillTwoExample, setSkillTwoExample] = useState(
    initialState.favoriteSkills[1].example
  );
  const [skillTwoWhenICanUse, setSkillTwoWhenICanUse] = useState(
    initialState.favoriteSkills[1].whenICanUse
  );

  const [skillThree, setSkillThree] = useState(
    initialState.favoriteSkills[2].skill
  );
  const [skillThreeExample, setSkillThreeExample] = useState(
    initialState.favoriteSkills[2].example
  );
  const [skillThreeWhenICanUse, setSkillThreeWhenICanUse] = useState(
    initialState.favoriteSkills[2].whenICanUse
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      favoriteSkills: [
        {
          skill: skillOne,
          example: skillOneExample,
          whenICanUse: skillOneWhenICanUse,
        },
        {
          skill: skillTwo,
          example: skillTwoExample,
          whenICanUse: skillTwoWhenICanUse,
        },
        {
          skill: skillThree,
          example: skillThreeExample,
          whenICanUse: skillThreeWhenICanUse,
        },
      ],
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>My Favorite Skills for Feeling Good</EE.Heading>
          <EE.HR />
          <EE.Paragraph>
            You have learned several new skills to help you feel better when you
            feel mad, sad, upset or down. Because everyone is different, some
            skills may help you more than others. Which skills are the most
            reliable tools in your "toolbox"? When would you use these tools?
          </EE.Paragraph>
          <EE.Subheading>My Favorite Skills Are:</EE.Subheading>
          <div className="flex-col">
            <div className="flex mt-4">
              <span className="w-1/12 text-2xl text-green-700 font-sans font-bold">
                1.{" "}
              </span>
              <div className="w-11/12">
                <EE.QuestionLabel>
                  Skill:
                  <EE.TextInput
                    value={skillOne}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSkillOne(e.target.value)
                    }
                  />
                </EE.QuestionLabel>
                <EE.QuestionLabel>
                  <span>Example: </span>
                  <EE.TextInput
                    value={skillOneExample}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSkillOneExample(e.target.value)
                    }
                  />
                </EE.QuestionLabel>
                <EE.QuestionLabel>
                  <span>When I can use this skill: </span>
                  <EE.TextInput
                    value={skillOneWhenICanUse}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSkillOneWhenICanUse(e.target.value)
                    }
                  />
                </EE.QuestionLabel>
              </div>
            </div>
            <div className="flex mt-4">
              <span className="w-1/12 text-2xl text-green-700 font-sans font-bold">
                2.{" "}
              </span>
              <div className="w-11/12">
                <EE.QuestionLabel>
                  Skill:
                  <EE.TextInput
                    value={skillTwo}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSkillTwo(e.target.value)
                    }
                  />
                </EE.QuestionLabel>
                <EE.QuestionLabel>
                  <span>Example: </span>
                  <EE.TextInput
                    value={skillTwoExample}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSkillTwoExample(e.target.value)
                    }
                  />
                </EE.QuestionLabel>
                <EE.QuestionLabel>
                  <span>When I can use this skill: </span>
                  <EE.TextInput
                    value={skillTwoWhenICanUse}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSkillTwoWhenICanUse(e.target.value)
                    }
                  />
                </EE.QuestionLabel>
              </div>
            </div>
            <div className="flex mt-4">
              <span className="w-1/12 text-2xl text-green-700 font-sans font-bold">
                3.{" "}
              </span>
              <div className="w-11/12">
                <EE.QuestionLabel>
                  Skill:
                  <EE.TextInput
                    value={skillThree}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSkillThree(e.target.value)
                    }
                  />
                </EE.QuestionLabel>
                <EE.QuestionLabel>
                  <span>Example: </span>
                  <EE.TextInput
                    value={skillThreeExample}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSkillThreeExample(e.target.value)
                    }
                  />
                </EE.QuestionLabel>
                <EE.QuestionLabel>
                  <span>When I can use this skill: </span>
                  <EE.TextInput
                    value={skillThreeWhenICanUse}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setSkillThreeWhenICanUse(e.target.value)
                    }
                  />
                </EE.QuestionLabel>
              </div>
            </div>
          </div>
          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default MyFavoriteSkillsForFeelingGoodScreens;
