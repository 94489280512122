import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const SlothSharkOwlScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    sharkLikeBehavior: "",
    slothLikeBehavior: "",
    owlLikeBehavior: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [sharkLikeBehavior, setSharkLikeBehavior] = React.useState(
    initialState.sharkLikeBehavior
  );
  const [slothLikeBehavior, setSlothLikeBehavior] = React.useState(
    initialState.slothLikeBehavior
  );
  const [owlLikeBehavior, setOwlLikeBehavior] = React.useState(
    initialState.owlLikeBehavior
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      sharkLikeBehavior,
      slothLikeBehavior,
      owlLikeBehavior,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Sloths, Sharks, and Owls</EE.Heading>
          <EE.HR />
          <EE.Paragraph className="my-4">
            There are three different ways we can communicate or talk to
            someone. Every time we talk to someone we can choose to be: passive
            (like a sloth), aggressive (like a shark), or assertive (like an
            owl). So what do these big words actually mean and look like when
            talking to someone?
          </EE.Paragraph>

          <h2 className="mt-4 text-base">
            When you are a passive communicator, like a sloth, you might...
          </h2>

          <EE.DiscList>
            <EE.PaddedListItem>
              Make little eye contact or look down often
            </EE.PaddedListItem>
            <EE.PaddedListItem>Avoid problems</EE.PaddedListItem>
            <EE.PaddedListItem>
              Not express your true feelings
            </EE.PaddedListItem>
            <EE.PaddedListItem>
              Say things like: “It’s fine. I’ll do whatever you want.”
            </EE.PaddedListItem>
          </EE.DiscList>

          <h2 className="mt-4 text-base">
            When you are an aggressive communicator, like a shark, you might...
          </h2>

          <EE.DiscList>
            <EE.PaddedListItem>Focus only on your needs</EE.PaddedListItem>
            <EE.PaddedListItem>Use angry words</EE.PaddedListItem>
            <EE.PaddedListItem>
              Act rude and not let others finish talking
            </EE.PaddedListItem>
            <EE.PaddedListItem>
              Roll your eyes or point fingers
            </EE.PaddedListItem>
            <EE.PaddedListItem>
              Say things like: “You can’t play my game unless you listen to my
              rules!”
            </EE.PaddedListItem>
          </EE.DiscList>

          <h2 className="mt-4 text-base">
            When you are an assertive communicator, like an owl, you might...
          </h2>

          <EE.DiscList>
            <EE.PaddedListItem>Make eye contact</EE.PaddedListItem>
            <EE.PaddedListItem>Have a calm, but firm voice</EE.PaddedListItem>
            <EE.PaddedListItem>
              Stand up for yourself when needed
            </EE.PaddedListItem>
            <EE.PaddedListItem>
              Say things like: “I don’t like basketball. Can we pick a different
              game together?”
            </EE.PaddedListItem>
          </EE.DiscList>

          <EE.Paragraph className="my-4">
            We all have moments where we may show different communication styles
            or we may act like a sloth, shark, or owl. Let’s talk about it!
          </EE.Paragraph>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>A time when I showed sloth-like behavior was...</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSlothLikeBehavior(e.target.value)
              }
              value={slothLikeBehavior}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>A time when I showed shark-like behavior was...</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSharkLikeBehavior(e.target.value)
              }
              value={sharkLikeBehavior}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>A time when I showed owl-like behavior was...</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setOwlLikeBehavior(e.target.value)
              }
              value={owlLikeBehavior}
            />
          </EE.QuestionLabel>

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default SlothSharkOwlScreens;
