import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["block"];

  updateBlockVisibility(event) {
    const checkedRadio = event.target;

    this.blockTargets.forEach((block) => this.hideBlock(block));
    const targetBlock = this.blockTargets.find(
      (block) => block.dataset.radioValue === checkedRadio.value
    );

    if (targetBlock) {
      this.showBlock(targetBlock);
    }
  }

  hideBlock(block) {
    block.classList.add("hidden");
  }

  showBlock(block) {
    block.classList.remove("hidden");
  }
}
