class StyleGuideClasses {
  static submit(additional_classes = "") {
    return `
      bg-green-700
      border
      border-transparent
      flex
      font-sans
      justify-center
      px-12
      py-4
      rounded-full
      text-base
      text-white
      hover:bg-opacity-95
      focus:outline-none
      focus:ring-2
      focus:ring-offset-2
      focus:ring-green-700
      disabled:opacity-50
      disabled:cursor-default
      cursor-pointer
      whitespace-nowrap
      ${additional_classes}
    `;
  }

  static save(additional_classes = "") {
    return `
      flex
      justify-center
      px-12
      py-4
      mt-2
      mb-2
      font-sans
      text-base
      border-2
      rounded-full
      cursor-pointer
      border-green-700
      text-green-700
      hover:bg-opacity-95
      focus:outline-none
      focus:ring-2
      focus:ring-offset-2
      focus:ring-green-700
      disabled:opacity-50
      disabled:cursor-default
      whitespace-nowrap
      ${additional_classes}
    `;
  }
}

export default StyleGuideClasses;
