import { Controller } from "@hotwired/stimulus";

const OPTION_NAME_SELECTOR = ".option-name";
const SELECTED_NAME_SELECTOR = ".selected-name";
const OPTION_ICON_SELECTOR = ".option-icon";
export default class extends Controller {
  static targets = ["selected", "list", "option", "realSelect"];

  toggleList() {
    this.listTarget.classList.toggle("hidden");
  }

  closeList({ target, key }) {
    if (key && key !== "Escape") return;

    const isTargetSelectedOption = this.selectedTarget.contains(target);
    if (!isTargetSelectedOption || key === "Escape") {
      this.hideList();
    }
  }

  selectOption({ target: option, key }) {
    if (key && key !== "Enter") return;

    this.hideAllIcons();
    this.showIconForOption(option);
    this.unboldAllOptions();
    this.emboldenOption(option);
    this.updateSelectedName(option);
    this.updateRealSelectValue(option);
    this.hideList();
  }

  focusOption({ target }) {
    target
      .querySelector(OPTION_ICON_SELECTOR)
      .classList.replace("text-gray-700", "text-white");
  }

  blurOption({ target }) {
    target
      .querySelector(OPTION_ICON_SELECTOR)
      .classList.replace("text-white", "text-gray-700");
  }

  hideAllIcons() {
    this.element.querySelectorAll(OPTION_ICON_SELECTOR).forEach((icon) => {
      icon.classList.add("hidden");
    });
  }

  showIconForOption(option) {
    this.getOptionIcon(option).classList.remove("hidden");
  }

  getOptionIcon(option) {
    return (
      option.querySelector(OPTION_ICON_SELECTOR) ||
      option.parentElement.querySelector(OPTION_ICON_SELECTOR)
    );
  }

  unboldAllOptions() {
    this.optionTargets.forEach((option) => {
      option
        .querySelector(OPTION_NAME_SELECTOR)
        .classList.remove("font-semibold");
    });
  }

  emboldenOption(option) {
    this.getOptionName(option).classList.add("font-semibold");
  }

  hideList() {
    this.listTarget.classList.add("hidden");
  }

  getOptionName(option) {
    return option.querySelector(OPTION_NAME_SELECTOR) || option;
  }

  updateSelectedName(option) {
    this.selectedTarget.querySelector(SELECTED_NAME_SELECTOR).innerHTML =
      this.getOptionName(option).innerHTML;
  }

  updateRealSelectValue(option) {
    this.realSelectTarget.value = this.getOptionName(option).innerHTML;
    // Changing the value doesn't trigger the change event
    const e = new Event("change");
    this.realSelectTarget.dispatchEvent(e);
  }
}
