import Blob0 from "@js/images/blobs/blob-0.png";
import Blob1 from "@js/images/blobs/blob-1.png";
import Blob10 from "@js/images/blobs/blob-10.png";
import Blob2 from "@js/images/blobs/blob-2.png";
import Blob3 from "@js/images/blobs/blob-3.png";
import Blob4 from "@js/images/blobs/blob-4.png";
import Blob5 from "@js/images/blobs/blob-5.png";
import Blob6 from "@js/images/blobs/blob-6.png";
import Blob7 from "@js/images/blobs/blob-7.png";
import Blob8 from "@js/images/blobs/blob-8.png";
import Blob9 from "@js/images/blobs/blob-9.png";

const Images = {
  Blob0,
  Blob1,
  Blob2,
  Blob3,
  Blob4,
  Blob5,
  Blob6,
  Blob7,
  Blob8,
  Blob9,
  Blob10,
};

export default Images;
