// import styles bundle
import "swiper/swiper-bundle.css";

import { Controller } from "@hotwired/stimulus";
import { VisualStory } from "@js/models/VisualStory";

export default class extends Controller {
  static targets = ["main", "slide", "vimeoIframe"];

  initialize() {
    this.visualStory = new VisualStory(this.mainTarget);
    this.visualStory.swiper.on("slideChangeTransitionEnd", () => {
      this.toggleVideos();
    });
  }

  startAtBeginning() {
    this.visualStory.startAtBeginning();
  }

  toggleVideos() {
    if (!this.vimeoPlayers) return;

    this.vimeoPlayers.forEach((vimeoPlayer) => {
      if (this.activeSlide.contains(vimeoPlayer.element)) {
        vimeoPlayer.play();
      } else {
        vimeoPlayer.pause();
      }
    });
  }

  pauseVideo() {
    if (!this.vimeoPlayers) return;

    this.vimeoPlayers[this.activeIndex]?.pause();
  }

  get activeIndex() {
    return this.visualStory.element.swiper.activeIndex;
  }

  get activeSlide() {
    return this.slideTargets[this.activeIndex];
  }

  get vimeoPlayers() {
    if (typeof Vimeo === typeof undefined) return;

    return this.vimeoIframeTargets.map((iframe) => new Vimeo.Player(iframe));
  }
}
