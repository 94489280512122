import React from "react";
import { Route, useHistory } from "react-router-dom";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { FearLadderProvider, useFearLadder } from "./Context";
import Exercise from "./Exercise";
import Introduction from "./Introduction";
import Thoughts from "./Thoughts";

const FearLadder: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  return (
    <FearLadderProvider {...props}>
      <FearLadderRoutes />
    </FearLadderProvider>
  );
};

const FearLadderRoutes = () => {
  const { completedBy } = useFearLadder();
  let history = useHistory();

  React.useEffect(() => {
    if (completedBy === "Caregiver" || completedBy === "Child") {
      history.push("/exercise");
    }
  }, [completedBy]);

  return (
    <>
      <EE.ScrollToTop />
      <Route exact path="/">
        <Introduction />
      </Route>
      <Route path="/exercise">
        <Exercise />
      </Route>
      <Route path="/thoughts">
        <Thoughts />
      </Route>
    </>
  );
};

export default FearLadder;
