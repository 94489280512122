import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import FeelingsThermometerElements from "../Thermometers/elements/FeelingsThermometer";

const TableCell = (props) => {
  return <td className="p-2">{props.children}</td>;
};

const MobileOnlyText = (props) => {
  return (
    <span className="inline md:hidden font-sans font-bold">
      {props.children}
    </span>
  );
};

const Table = (props) => {
  return <table className="w-full">{props.children}</table>;
};

const RatingSlider: React.FC<{
  thermometerValue: number;
  setThermometerValue: any;
  id: string;
  className?: string;
  showAnchors?: boolean;
}> = ({
  thermometerValue,
  setThermometerValue,
  children,
  id,
  showAnchors = false,
  ...rest
}) => {
  return (
    <EE.QuestionLabel id={id} {...rest}>
      {children}
      <EE.ThermometerNumber>{thermometerValue}</EE.ThermometerNumber>
      <div>
        <EE.ThermometerSlider
          thermometerValue={thermometerValue}
          onChange={setThermometerValue}
          ariaLabelledByForHandle={id}
        />
        {showAnchors && (
          <EE.ExerciseAnchors>
            <EE.ExerciseAnchor className="text-left">
              {FeelingsThermometerElements.minValueLabel}
            </EE.ExerciseAnchor>
            <EE.ExerciseAnchor className="text-right">
              {FeelingsThermometerElements.maxValueLabel}
            </EE.ExerciseAnchor>
          </EE.ExerciseAnchors>
        )}
      </div>
    </EE.QuestionLabel>
  );
};

const LearningYourAnxiousFeelings: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultAnxiety = { strong: 5, anxious: 5, similar: 5 };
  const defaultState = {
    headSideToSide: defaultAnxiety,
    holdHeadLow: defaultAnxiety,
    runInPlace: defaultAnxiety,
    holdYourBreath: defaultAnxiety,
    tenseMuscles: defaultAnxiety,
    spinInChair: defaultAnxiety,
    hyperventilate: defaultAnxiety,
    breatheThroughStraw: defaultAnxiety,
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };

  const [headSideToSideStrong, setHeadSideToSideStrong] = React.useState(
    initialState.headSideToSide.strong
  );
  const [headSideToSideAnxious, setHeadSideToSideAnxious] = React.useState(
    initialState.headSideToSide.anxious
  );
  const [headSideToSideSimilar, setHeadSideToSideSimilar] = React.useState(
    initialState.headSideToSide.similar
  );

  const [holdHeadLowStrong, setHoldHeadLowStrong] = React.useState(
    initialState.holdHeadLow.strong
  );
  const [holdHeadLowAnxious, setHoldHeadLowAnxious] = React.useState(
    initialState.holdHeadLow.anxious
  );
  const [holdHeadLowSimilar, setHoldHeadLowSimilar] = React.useState(
    initialState.holdHeadLow.similar
  );

  const [runInPlaceStrong, setRunInPlaceStrong] = React.useState(
    initialState.runInPlace.strong
  );
  const [runInPlaceAnxious, setRunInPlaceAnxious] = React.useState(
    initialState.runInPlace.anxious
  );
  const [runInPlaceSimilar, setRunInPlaceSimilar] = React.useState(
    initialState.runInPlace.similar
  );

  const [holdYourBreathStrong, setHoldYourBreathStrong] = React.useState(
    initialState.holdYourBreath.strong
  );
  const [holdYourBreathAnxious, setHoldYourBreathAnxious] = React.useState(
    initialState.holdYourBreath.anxious
  );
  const [holdYourBreathSimilar, setHoldYourBreathSimilar] = React.useState(
    initialState.holdYourBreath.similar
  );

  const [tenseMusclesStrong, setTenseMusclesStrong] = React.useState(
    initialState.tenseMuscles.strong
  );
  const [tenseMusclesAnxious, setTenseMusclesAnxious] = React.useState(
    initialState.tenseMuscles.anxious
  );
  const [tenseMusclesSimilar, setTenseMusclesSimilar] = React.useState(
    initialState.tenseMuscles.similar
  );

  const [spinInChairStrong, setSpinInChairStrong] = React.useState(
    initialState.spinInChair.strong
  );
  const [spinInChairAnxious, setSpinInChairAnxious] = React.useState(
    initialState.spinInChair.anxious
  );
  const [spinInChairSimilar, setSpinInChairSimilar] = React.useState(
    initialState.spinInChair.similar
  );

  const [hyperventilateStrong, setHyperventilateStrong] = React.useState(
    initialState.hyperventilate.strong
  );
  const [hyperventilateAnxious, setHyperventilateAnxious] = React.useState(
    initialState.hyperventilate.anxious
  );
  const [hyperventilateSimilar, setHyperventilateSimilar] = React.useState(
    initialState.hyperventilate.similar
  );

  const [breatheThroughStrawStrong, setBreatheThroughStrawStrong] =
    React.useState(initialState.breatheThroughStraw.strong);
  const [breatheThroughStrawAnxious, setBreatheThroughStrawAnxious] =
    React.useState(initialState.breatheThroughStraw.anxious);
  const [breatheThroughStrawSimilar, setBreatheThroughStrawSimilar] =
    React.useState(initialState.breatheThroughStraw.similar);

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      headSideToSide: {
        strong: headSideToSideStrong,
        anxious: headSideToSideAnxious,
        similar: headSideToSideSimilar,
      },
      holdHeadLow: {
        strong: holdHeadLowStrong,
        anxious: holdHeadLowAnxious,
        similar: holdHeadLowSimilar,
      },
      runInPlace: {
        strong: runInPlaceStrong,
        anxious: runInPlaceAnxious,
        similar: runInPlaceSimilar,
      },
      holdYourBreath: {
        strong: holdYourBreathStrong,
        anxious: holdYourBreathAnxious,
        similar: holdYourBreathSimilar,
      },
      tenseMuscles: {
        strong: tenseMusclesStrong,
        anxious: tenseMusclesAnxious,
        similar: tenseMusclesSimilar,
      },
      spinInChair: {
        strong: spinInChairStrong,
        anxious: spinInChairAnxious,
        similar: spinInChairSimilar,
      },
      hyperventilate: {
        strong: hyperventilateStrong,
        anxious: hyperventilateAnxious,
        similar: hyperventilateSimilar,
      },
      breatheThroughStraw: {
        strong: breatheThroughStrawStrong,
        anxious: breatheThroughStrawAnxious,
        similar: breatheThroughStrawSimilar,
      },
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper className="max-w-6xl">
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Learning Your Anxious Feelings</EE.Heading>
          <EE.HR />
          <EE.Subheading>What does your anxiety feel like?</EE.Subheading>
          <EE.Paragraph>
            We want you to practice some different exercises to create different
            feelings in your body. The exercises will help us learn what your
            anxiety feels like for you. Later, they can help us know how to
            practice together to make these feelings less scary or
            uncomfortable. I will show you some of these exercises first, and
            then you can take a turn. After each exercise, I will ask you to
            give 3 different ratings with the Feelings Thermometer.
          </EE.Paragraph>
          <ul>
            <li className="list-decimal list-inside">
              how <EE.Bold>strong</EE.Bold> the feelings are
            </li>
            <li className="list-decimal list-inside">
              how <EE.Bold>anxious</EE.Bold> the feelings make you feel
            </li>
            <li className="list-decimal list-inside">
              how <EE.Bold>similar</EE.Bold> the feelings are to what you
              usually feel when panicked or anxious
            </li>
          </ul>
          <EE.Paragraph>Go ahead and give it a try!</EE.Paragraph>
          <Table className="w-full">
            <thead>
              <tr>
                <TableCell></TableCell>
                <TableCell>
                  <EE.Subheading id="howStrong-strong">
                    How Strong?
                  </EE.Subheading>
                </TableCell>
                <TableCell>
                  <EE.Subheading id="howStrong-anxious">
                    How Anxious?
                  </EE.Subheading>
                </TableCell>
                <TableCell>
                  <EE.Subheading id="howStrong-similar">
                    How Similar?
                  </EE.Subheading>
                </TableCell>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TableCell className="order-2 hidden md:flex font-sans font-bold">
                  Move your head side to side (30 sec)
                </TableCell>

                <TableCell>
                  <EE.QuestionLabel id="headSideToSide-strong">
                    <MobileOnlyText>How Strong?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={headSideToSideStrong}
                      setThermometerValue={setHeadSideToSideStrong}
                      id="headSideToSide-strong-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <EE.QuestionLabel id="headSideToSide-anxious">
                    <MobileOnlyText>How Anxious?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={headSideToSideAnxious}
                      setThermometerValue={setHeadSideToSideAnxious}
                      id="headSideToSide-anxious-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <EE.QuestionLabel id="headSideToSide-similar">
                    <MobileOnlyText>How Similar?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={headSideToSideSimilar}
                      setThermometerValue={setHeadSideToSideSimilar}
                      id="headSideToSide-similar-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
              </tr>
              <tr>
                <TableCell className="order-2 hidden md:flex font-sans font-bold">
                  Hold your head low between your legs (30 sec), then lift
                  quickly
                </TableCell>

                <TableCell>
                  <EE.QuestionLabel id="holdHeadLowStrong">
                    <MobileOnlyText>How Strong?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={holdHeadLowStrong}
                      setThermometerValue={setHoldHeadLowStrong}
                      id="holdHeadLowStrong-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <EE.QuestionLabel id="holdHeadLowAnxious">
                    <MobileOnlyText>How Anxious?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={holdHeadLowAnxious}
                      setThermometerValue={setHoldHeadLowAnxious}
                      id="holdHeadLowAnxious-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <EE.QuestionLabel id="holdHeadLowSimilar">
                    <MobileOnlyText>How Similar?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={holdHeadLowSimilar}
                      setThermometerValue={setHoldHeadLowSimilar}
                      id="holdHeadLowSimilar-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
              </tr>
              <tr>
                <TableCell className="order-2 hidden md:flex font-sans font-bold">
                  Run in place or do jumping jacks (1 min)
                </TableCell>

                <TableCell>
                  <EE.QuestionLabel id="runInPlaceStrong">
                    <MobileOnlyText>How Strong?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={runInPlaceStrong}
                      setThermometerValue={setRunInPlaceStrong}
                      id="runInPlaceStrong-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <EE.QuestionLabel id="runInPlaceAnxious">
                    <MobileOnlyText>How Anxious?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={runInPlaceAnxious}
                      setThermometerValue={setRunInPlaceAnxious}
                      id="runInPlaceAnxious-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <EE.QuestionLabel id="runInPlaceSimilar">
                    <MobileOnlyText>How Similar?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={runInPlaceSimilar}
                      setThermometerValue={setRunInPlaceSimilar}
                      id="runInPlaceSimilar-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
              </tr>
              <tr>
                <TableCell className="order-2 hidden md:flex font-sans font-bold">
                  Hold your breath (45 sec)
                </TableCell>

                <TableCell>
                  <EE.QuestionLabel id="holdYourBreathStrong">
                    <MobileOnlyText>How Strong?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={holdYourBreathStrong}
                      setThermometerValue={setHoldYourBreathStrong}
                      id="holdYourBreathStrong-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <EE.QuestionLabel id="holdYourBreathAnxious">
                    <MobileOnlyText>How Anxious?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={holdYourBreathAnxious}
                      setThermometerValue={setHoldYourBreathAnxious}
                      id="holdYourBreathAnxious-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <EE.QuestionLabel id="holdYourBreathSimilar">
                    <MobileOnlyText>How Similar?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={holdYourBreathSimilar}
                      setThermometerValue={setHoldYourBreathSimilar}
                      id="holdYourBreathSimilar-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
              </tr>
              <tr>
                <TableCell className="order-2 hidden md:flex font-sans font-bold">
                  Tense all your muscles or hold a push-up position (1 min)
                </TableCell>

                <TableCell>
                  <EE.QuestionLabel id="tenseMusclesStrong">
                    <MobileOnlyText>How Strong?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={tenseMusclesStrong}
                      setThermometerValue={setTenseMusclesStrong}
                      id="tenseMusclesStrong-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <EE.QuestionLabel id="tenseMusclesAnxious">
                    <MobileOnlyText>How Anxious?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={tenseMusclesAnxious}
                      setThermometerValue={setTenseMusclesAnxious}
                      id="tenseMusclesAnxious-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <EE.QuestionLabel id="tenseMusclesSimilar">
                    <MobileOnlyText>How Similar?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={tenseMusclesSimilar}
                      setThermometerValue={setTenseMusclesSimilar}
                      id="tenseMusclesSimilar-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
              </tr>
              <tr>
                <TableCell className="order-2 hidden md:flex font-sans font-bold">
                  Spin in a swivel chair (1 min)
                </TableCell>

                <TableCell>
                  <EE.QuestionLabel id="spinInChairStrong">
                    <MobileOnlyText>How Strong?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={spinInChairStrong}
                      setThermometerValue={setSpinInChairStrong}
                      id="spinInChairStrong-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <EE.QuestionLabel id="spinInChairAnxious">
                    <MobileOnlyText>How Anxious?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={spinInChairAnxious}
                      setThermometerValue={setSpinInChairAnxious}
                      id="spinInChairAnxious-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <EE.QuestionLabel id="spinInChairSimilar">
                    <MobileOnlyText>How Similar?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={spinInChairSimilar}
                      setThermometerValue={setSpinInChairSimilar}
                      id="spinInChairSimilar-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
              </tr>
              <tr>
                <TableCell className="order-2 hidden md:flex font-sans font-bold">
                  Hyperventilate (1 min)
                </TableCell>

                <TableCell>
                  <EE.QuestionLabel id="hyperventilateStrong">
                    <MobileOnlyText>How Strong?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={hyperventilateStrong}
                      setThermometerValue={setHyperventilateStrong}
                      id="hyperventilateStrong-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <EE.QuestionLabel id="hyperventilateAnxious">
                    <MobileOnlyText>How Anxious?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={hyperventilateAnxious}
                      setThermometerValue={setHyperventilateAnxious}
                      id="hyperventilateAnxious-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <EE.QuestionLabel id="hyperventilateSimilar">
                    <MobileOnlyText>How Similar?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={hyperventilateSimilar}
                      setThermometerValue={setHyperventilateSimilar}
                      id="hyperventilateSimilar-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
              </tr>
              <tr>
                <TableCell className="order-2 hidden md:flex font-sans font-bold">
                  Breathe through a small straw or as slowly as possible (2 min)
                </TableCell>

                <TableCell>
                  <EE.QuestionLabel id="breatheThroughStrawStrong">
                    <MobileOnlyText>How Strong?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={breatheThroughStrawStrong}
                      setThermometerValue={setBreatheThroughStrawStrong}
                      id="breatheThroughStrawStrong-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <EE.QuestionLabel id="breatheThroughStrawAnxious">
                    <MobileOnlyText>How Anxious?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={breatheThroughStrawAnxious}
                      setThermometerValue={setBreatheThroughStrawAnxious}
                      id="breatheThroughStrawAnxious-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
                <TableCell>
                  <EE.QuestionLabel id="breatheThroughStrawSimilar">
                    <MobileOnlyText>How Similar?</MobileOnlyText>
                    <RatingSlider
                      thermometerValue={breatheThroughStrawSimilar}
                      setThermometerValue={setBreatheThroughStrawSimilar}
                      id="breatheThroughStrawSimilar-slider"
                    />
                  </EE.QuestionLabel>
                </TableCell>
              </tr>
            </tbody>
          </Table>
          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default LearningYourAnxiousFeelings;
