import Triangle from "@js/images/exercises/exercise-triangle-nolabel.svg";
import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useMappingYourNeeds } from "./Context";

const row = (value, last = false) => {
  const border = last ? null : (
    <span className="w-full h-0 border-b border-white"></span>
  );
  return (
    <>
      <div className="w-full h-10 pt-2 text-center bg-transparent focus:outline-none">
        {value}
      </div>
      {border}
    </>
  );
};

const Map = () => {
  const {
    need1,
    need2,
    need3,
    need4,
    need5,
    assignedExerciseId,
    completedExercise,
  } = useMappingYourNeeds();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      need1,
      need2,
      need3,
      need4,
      need5,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.SubmitableForm submitExercise={submitExercise}>
      <p className="mb-10 text-xl md:mt-12">Your needs</p>

      <p className="table mx-auto text-sm text-gray-600">Fulfilment needs</p>
      <div
        className="flex flex-col items-center justify-end bg-center bg-no-repeat bg-auto bg-cover h-60"
        style={{
          backgroundImage: `url(${Triangle})`,
          backgroundSize: "100% 100%",
        }}
      >
        {row(need1)}
        {row(need2)}
        {row(need3)}
        {row(need4)}
        {row(need5, true)}
      </div>
      <p className="table mx-auto mt-2 text-sm text-gray-600">Basic needs</p>

      <EE.ButtonGroup>
        <EE.ResumableExerciseActions
          submitButtonText="I'm done"
          setExerciseComplete={setExerciseComplete}
        />
      </EE.ButtonGroup>
    </EE.SubmitableForm>
  );
};

export default Map;
