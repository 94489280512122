import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["swapStafferName", "selectedStafferName"];
  static values = { assignedCareGuide: String };

  selectValue() {
    const select = this.selectedStafferNameTarget;
    const selectedStafferName = select.options[select.selectedIndex].text;
    const swapButton = document.getElementById("swap-button");

    if (this.assignedCareGuideValue) {
      if (selectedStafferName === this.assignedCareGuideValue) {
        swapButton.disabled = true;
      } else {
        swapButton.disabled = false;
        this.swapStafferNameTarget.innerText = selectedStafferName;
      }
    }
  }
}
