import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";

export default class CheckInsController extends Controller {
  declare readonly circleTarget: HTMLElement;
  declare readonly textTarget: HTMLElement;
  declare readonly valueTarget: HTMLElement;
  declare readonly stepValue: number;
  declare readonly memberValue: string;
  static values = {
    step: Number,
    member: String,
  };

  static targets = ["circle", "text", "value", "step"];

  onChange(event: any) {
    const options = this.textTarget.dataset.checkInsOptions || "{}";
    const value = event.target.value;

    this.textTarget.innerHTML = JSON.parse(options)[value];
    this.valueTarget.innerHTML = value;

    this.circleTarget.classList.remove("bg-gray-100");
    this.textTarget.classList.remove("mt-7");
    this.valueTarget.classList.remove("hidden");
  }

  trackClosed() {
    ahoy.track("CheckIns:Exited", this.getEventProperties());
  }

  getEventProperties() {
    return {
      page: window.location.pathname,
      member_id: this.memberValue,
      modal_step: this.stepValue,
    };
  }
}
