import { Controller } from "@hotwired/stimulus";
import { useDebounce } from "stimulus-use";

export default class extends Controller {
  static debounces = ["requestSubmit"];

  static values = {
    delay: Number,
  };

  static targets = [
    "clearable",
    "resettable",
    "submittable",
    "clickable",
    "checkbox",
  ];

  connect() {
    if (this.hasDelayValue) {
      useDebounce(this, { wait: this.delayValue });
    }
  }

  clear() {
    this.clearableTargets.forEach((form) => {
      form.querySelectorAll("input").forEach((input) => {
        if (input.type === "hidden") return;

        if (input.type == "checkbox") {
          input.checked = false;
        } else {
          input.value = "";
        }
      });
    });
  }

  reset() {
    this.resettableTargets.forEach((form) => {
      if (form.reset) {
        form.reset();
      }
    });
  }

  submit(event) {
    if (event.key && event.key !== "Enter") return;
    this.submittableTargets.forEach((form) => form.submit());
  }

  requestSubmit(event) {
    if (event.key && event.key !== "Enter") return;

    this.submittableTargets.forEach((form) => {
      let submitButton = event.target;

      if (submitButton.dataset.disableWith) {
        submitButton.disabled = true;
        submitButton.innerText = submitButton.dataset.disableWith;
      }

      form.requestSubmit();
    });
  }

  clickLink(event) {
    this.clickableTargets.forEach((link) => link.click());
  }

  toggleCheckbox(event) {
    const clickedElement = event.currentTarget;

    const checkbox = clickedElement.querySelector(
      '[data-forms-target="checkbox"]'
    );

    if (checkbox) {
      checkbox.checked = !checkbox.checked;
    }
  }
}
