import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { autoRequest: { type: Boolean, default: false } };

  connect() {
    if (this.autoRequestValue) {
      this.request();
    }
  }

  request() {
    if (Notification.permission == "granted") {
      return;
    }

    Notification.requestPermission().then((permission) => {
      if (
        Notification.permission === "denied" ||
        Notification.permission === "default"
      ) {
        console.log("Could not enable notifications.");
      }
    });
  }

  test(event) {
    event.preventDefault();
    new Notification("Test notification", {
      body: "If you see this, notifications work as expected",
    });
  }
}
