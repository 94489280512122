import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useWhoIAm } from "./Context";

const Favorites = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const {
    name,
    birthday,
    graduatingClass,
    ethnicity,
    placesIHaveLived1,
    placesIHaveLived2,
    placesIHaveLived3,
    placesIHaveLived4,
    placesIHaveLived5,
    placesIHaveLived6,
    supportSystem,
    hobbies1,
    hobbies2,
    hobbies3,
    hobbies4,
    hobbies5,
    hobbies6,
    favoriteMovieOrShow,
    favoriteColor,
    favoriteBookOrMagazine,
    favoriteFood,
    favoriteClassOrSubject,
    favoriteMusicGenre,
    setFavoriteMovieOrShow,
    setFavoriteColor,
    setFavoriteBookOrMagazine,
    setFavoriteFood,
    setFavoriteClassOrSubject,
    setFavoriteMusicGenre,
    assignedExerciseId,
    completedExercise,
  } = useWhoIAm();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      name,
      birthday,
      graduatingClass,
      ethnicity,
      placesIHaveLived1,
      placesIHaveLived2,
      placesIHaveLived3,
      placesIHaveLived4,
      placesIHaveLived5,
      placesIHaveLived6,
      supportSystem,
      hobbies1,
      hobbies2,
      hobbies3,
      hobbies4,
      hobbies5,
      hobbies6,
      favoriteMovieOrShow,
      favoriteColor,
      favoriteBookOrMagazine,
      favoriteFood,
      favoriteClassOrSubject,
      favoriteMusicGenre,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <>
      <EE.StepIndicator numSteps={numSteps} currentStep={currentStep} />
      <EE.SubmitableForm submitExercise={submitExercise}>
        <EE.Subheading>Favorites</EE.Subheading>
        <EE.QuestionLabel>
          Movie or TV Show
          <EE.TextInput
            value={favoriteMovieOrShow}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFavoriteMovieOrShow(e.target.value)
            }
          />
        </EE.QuestionLabel>
        <EE.QuestionLabel>
          Color
          <EE.TextInput
            value={favoriteColor}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFavoriteColor(e.target.value)
            }
          />
        </EE.QuestionLabel>
        <EE.QuestionLabel>
          Book or magazine
          <EE.TextInput
            value={favoriteBookOrMagazine}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFavoriteBookOrMagazine(e.target.value)
            }
          />
        </EE.QuestionLabel>
        <EE.QuestionLabel>
          Food
          <EE.TextInput
            value={favoriteFood}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFavoriteFood(e.target.value)
            }
          />
        </EE.QuestionLabel>
        <EE.QuestionLabel>
          Class or subject
          <EE.TextInput
            value={favoriteClassOrSubject}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFavoriteClassOrSubject(e.target.value)
            }
          />
        </EE.QuestionLabel>
        <EE.QuestionLabel>
          Music genre
          <EE.TextInput
            value={favoriteMusicGenre}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFavoriteMusicGenre(e.target.value)
            }
          />
        </EE.QuestionLabel>
        <EE.ButtonGroup>
          <EE.ExerciseCallToActionLink to={nextPage}>
            Next
          </EE.ExerciseCallToActionLink>

          <EE.SaveForLaterButtonGreen
            text="Save for Later"
            setExerciseComplete={setExerciseComplete}
          />
        </EE.ButtonGroup>
      </EE.SubmitableForm>
    </>
  );
};

export default Favorites;
