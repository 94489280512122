import React from "react";

import { ExerciseScreenProps } from "../shared/Elements";

const AllAboutMeContext = React.createContext(undefined);

const AllAboutMeProvider: React.FC<ExerciseScreenProps> = (props) => {
  const defaultState = {
    description: "",
    deepDescription: "",
    amazingAt: "",
    surprising: "",
    hereBecause: "",
    sumItUp: "",
  };

  const {
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  } = props;

  const initialState = { ...defaultState, ...completedExercise?.data };

  const [description, setDescription] = React.useState<string>(
    initialState.description
  );
  const [deepDescription, setDeepDescription] = React.useState<string>(
    initialState.deepDescription
  );
  const [amazingAt, setAmazingAt] = React.useState<string>(
    initialState.amazingAt
  );
  const [surprising, setSurprising] = React.useState<string>(
    initialState.surprising
  );
  const [hereBecause, setHereBecause] = React.useState<string>(
    initialState.hereBecause
  );
  const [sumItUp, setSumItUp] = React.useState<string>(initialState.sumItUp);

  const value = {
    description,
    setDescription,
    deepDescription,
    setDeepDescription,
    amazingAt,
    setAmazingAt,
    surprising,
    setSurprising,
    hereBecause,
    setHereBecause,
    sumItUp,
    setSumItUp,
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  };

  return <AllAboutMeContext.Provider value={value} {...props} />;
};

function useAllAboutMe() {
  const context = React.useContext(AllAboutMeContext);
  if (!context) {
    throw new Error("useAllAboutMe must be used within a AllAboutMeProvider");
  }
  return context;
}

export { AllAboutMeProvider, AllAboutMeContext, useAllAboutMe };
