import GrowthMindsetIcon from "@js/images/icons/GrowthMindset.svg";
import React from "react";
import { Route } from "react-router-dom";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { FixedVsGrowthMindsetsProvider } from "./Context";
import Example from "./Example";
import Practice from "./Practice";

const Screens: React.FC<ExerciseScreenProps> = (props) => {
  const title = "Fixed vs Growth Mindsets";
  const totalPages = 6;
  let pageNumber = 1;

  const intro = (
    <EE.GenericExerciseIntroPage
      imageSrc={GrowthMindsetIcon}
      imageAltText="A fruit tree."
      title={title}
      introText="We all have moments where we think, &quot;I can't do it!&quot; Here's how you can reframe that negative self talk."
      nextPage="/example_1"
    />
  );

  return (
    <FixedVsGrowthMindsetsProvider {...props}>
      <EE.WrapperWithIntro intro={intro}>
        <EE.ExerciseNavigation title={title} />

        <Route path="/example_1">
          <Example
            dontThink="I can't do it."
            doThink="I'm still learning. I'll keep trying."
            nextPage="/example_2"
            currentStep={pageNumber++}
            numSteps={totalPages}
          />
        </Route>

        <Route path="/example_2">
          <Example
            dontThink="It's too hard."
            doThink="With more practice it will get easier!"
            nextPage="/example_3"
            currentStep={pageNumber++}
            numSteps={totalPages}
          />
        </Route>

        <Route path="/example_3">
          <Example
            dontThink="I'm afraid of making a mistake."
            doThink="Mistakes are how I learn &amp; get better!"
            nextPage="/example_4"
            currentStep={pageNumber++}
            numSteps={totalPages}
          />
        </Route>

        <Route path="/example_4">
          <Example
            dontThink="They are better than I am."
            doThink="What can I learn from them?"
            nextPage="/example_5"
            currentStep={pageNumber++}
            numSteps={totalPages}
          />
        </Route>

        <Route path="/example_5">
          <Example
            dontThink="I give up."
            doThink="I'll try a different way!"
            nextPage="/practice"
            currentStep={pageNumber++}
            numSteps={totalPages}
          />
        </Route>

        <Route path="/practice">
          <Practice currentStep={pageNumber++} numSteps={totalPages} />
        </Route>
      </EE.WrapperWithIntro>
    </FixedVsGrowthMindsetsProvider>
  );
};

export default Screens;
