/*
Purpose: This controller is intended to be applied to <details> elements that enclose a form with HTML5 validations. If there is a failing HTML5 validation in a closed details tag, it'll force it open.

Example: 
<div data-controller="details-form">
 <details>
    <%= form_with(model: @member, data: {details_form_target: "form"}}) do |form| %>
      <% form.text_field :first_name, required: true %>
    <% end %>
  </details>

  <%= submit_tag "Submit", data: {action: "click->details-form#validate"} %>
</div>
*/

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  validate(event) {
    let inputs = this.formTarget.querySelectorAll("input:invalid");
    inputs.forEach((input) => {
      let details = input.closest("details");
      if (details) details.setAttribute("open", true);
    });
  }
}
