import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useWhoIAm } from "./Context";

const Lived = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const {
    name,
    birthday,
    graduatingClass,
    ethnicity,
    placesIHaveLived1,
    placesIHaveLived2,
    placesIHaveLived3,
    placesIHaveLived4,
    placesIHaveLived5,
    placesIHaveLived6,
    setPlacesIHaveLived1,
    setPlacesIHaveLived2,
    setPlacesIHaveLived3,
    setPlacesIHaveLived4,
    setPlacesIHaveLived5,
    setPlacesIHaveLived6,
    assignedExerciseId,
    completedExercise,
  } = useWhoIAm();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      name,
      birthday,
      graduatingClass,
      ethnicity,
      placesIHaveLived1,
      placesIHaveLived2,
      placesIHaveLived3,
      placesIHaveLived4,
      placesIHaveLived5,
      placesIHaveLived6,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <>
      <EE.StepIndicator numSteps={numSteps} currentStep={currentStep} />
      <EE.SubmitableForm submitExercise={submitExercise}>
        <EE.Subheading id="places-label">Places I've lived:</EE.Subheading>
        <EE.TextInput
          aria-labelledby="places-label"
          className="mb-4"
          value={placesIHaveLived1}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlacesIHaveLived1(e.target.value)
          }
        />
        <EE.TextInput
          aria-labelledby="places-label"
          className="mb-4"
          value={placesIHaveLived2}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlacesIHaveLived2(e.target.value)
          }
        />
        <EE.TextInput
          aria-labelledby="places-label"
          className="mb-4"
          value={placesIHaveLived3}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlacesIHaveLived3(e.target.value)
          }
        />
        <EE.TextInput
          aria-labelledby="places-label"
          className="mb-4"
          value={placesIHaveLived4}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlacesIHaveLived4(e.target.value)
          }
        />
        <EE.TextInput
          aria-labelledby="places-label"
          className="mb-4"
          value={placesIHaveLived5}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlacesIHaveLived5(e.target.value)
          }
        />
        <EE.TextInput
          aria-labelledby="places-label"
          className="mb-4"
          value={placesIHaveLived6}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPlacesIHaveLived6(e.target.value)
          }
        />
        <EE.ButtonGroup>
          <EE.ExerciseCallToActionLink to={nextPage}>
            Next
          </EE.ExerciseCallToActionLink>

          <EE.SaveForLaterButtonGreen
            text="Save for Later"
            setExerciseComplete={setExerciseComplete}
          />
        </EE.ButtonGroup>
      </EE.SubmitableForm>
    </>
  );
};

export default Lived;
