import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";
import debounce from "lodash/debounce";

export default class extends Controller {
  scrollTimeout = 250;

  connect() {
    document.addEventListener("scroll", this.trackOnScrollStop);
  }

  disconnect() {
    document.removeEventListener("scroll", this.trackOnScrollStop);
  }

  trackOnScrollStop = debounce(this.track, this.scrollTimeout);

  track() {
    const documentHeight = document.documentElement.scrollHeight;
    const viewportHeight = document.documentElement.clientHeight;

    if (viewportHeight === documentHeight) return; // Would cause division by zero

    const viewportTopPosition = window.scrollY;
    const percentScrolled = Math.round(
      (viewportTopPosition / (documentHeight - viewportHeight)) * 100
    );

    const properties = {
      documentHeight: documentHeight,
      viewportHeight: viewportHeight,
      viewportTopPosition: viewportTopPosition,
      percentScrolled: Math.min(percentScrolled, 100),
      page: window.location.pathname,
    };
    ahoy.track("Member:Scrolled", properties);
  }
}
