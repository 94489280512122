import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["card", "background"];
  static values = {
    shouldPersist: Boolean,
    delayHide: { type: Number, default: 0 },
  };

  connect() {
    if (!this.shouldPersistValue) {
      this.hide();
    }
  }

  show() {
    this.cardTarget.classList.remove("d-none");
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
      this.hideTimeout = null;
    }
  }

  hide() {
    this.hideTimeout = setTimeout(() => {
      this.cardTarget.classList.add("d-none");
      if (this.hasBackgroundTarget) {
        this.backgroundTarget.classList.add("d-none");
      }
      this.hideTimeout = null;
    }, this.delayHideValue);
  }
}
