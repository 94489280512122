import { Controller } from "@hotwired/stimulus";

/*
  Adds the contents of a template specified with an value
  data attribute to a specified `container` target. Before
  the template contents are added a provided replacement
  token is replaced with the current timestamp in any `for`,
  `name` or `id` attributes within the template.

  Example html:

    <div data-controller="add-template" data-add-template-template-name-value="theTemplateName" data-add-template-replacement-token-value="replaceMe">
      <div data-add-template-target="container"></div>
      <a href="#" data-action="add-template#add">Add</a>
      <template data-template-name="theTemplateName">
        <label for="prefix_replaceMe_foo_attribute">Attribute</label>
        <select name="prefix[replaceMe][foo][attribute]" id="prefix_replaceMe_foo_attribute">
          <option value="option1">Option 1</option>
        </select>
      </template>
    </div>
*/

export default class extends Controller {
  declare readonly containerTarget: HTMLElement;
  declare readonly replacementTokenValue: string;
  declare readonly templateNameValue: string;

  static targets = ["container"];
  static values = { replacementToken: String, templateName: String };

  add(event: Event) {
    event.preventDefault();

    const selectedTemplate = this.templateNameValue;
    if (selectedTemplate) {
      let uniqueValue = Date.now();

      const templateContent = this.cloneTemplate(
        selectedTemplate,
        this.element as HTMLElement
      );

      this.replaceToken(
        this.replacementTokenValue,
        uniqueValue.toString(),
        templateContent as HTMLElement
      );

      this.containerTarget.append(templateContent);
    }
  }

  cloneTemplate(name: string, templateRoot: HTMLElement): Node {
    const selector = "template[data-template-name='" + name + "']";
    const template = templateRoot.querySelector(
      selector
    ) as HTMLTemplateElement | null;

    if (template) {
      return template.content.cloneNode(true);
    } else {
      throw "Template not found: " + name;
    }
  }

  replaceToken(
    toReplace: string,
    replacement: string,
    templateContent: HTMLElement
  ) {
    ["for", "name", "id"].forEach((attributeName) => {
      templateContent
        .querySelectorAll(`[${attributeName}*='${toReplace}']`)
        .forEach(function (node) {
          let oldValue = node.getAttribute(attributeName) || "";
          let newValue = oldValue.replace(toReplace, replacement);

          node.setAttribute(attributeName, newValue);
        });
    });
  }
}
