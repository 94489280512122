import React from "react";

export default function InputField(props: InsuranceFormInputFields) {
  const {
    onChange,
    value,
    labelTitle,
    labelName,
    error,
    bonusProps,
    fieldType,
  } = props;
  return (
    <div className="pt-4">
      <label htmlFor={labelName}>{labelTitle}</label>
      <input
        type={fieldType || "text"}
        value={value}
        className="input"
        name={labelName}
        onChange={(e) => onChange(e.target.value)}
        {...bonusProps}
      />
      {error && <div className="mt-2 error">{error}</div>}
    </div>
  );
}

interface InsuranceFormInputFields {
  onChange: (value: string) => void;
  value: string;
  labelTitle: string;
  labelName: string;
  error: string;
  fieldType?: string;
  bonusProps?: any;
}
