import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    this.requiredInputs = this.getRequiredInputs();
    this.handleInvalid = this.onInvalid.bind(this);

    this.requiredInputs.forEach((input) =>
      input.addEventListener("invalid", this.handleInvalid)
    );
  }

  disconnect() {
    this.requiredInputs.forEach((input) =>
      input.removeEventListener("invalid", this.handleInvalid)
    );
  }

  getRequiredInputs() {
    return this.formTarget.querySelectorAll(
      "input[required],select[required],textarea[required]"
    );
  }

  onInvalid(event) {
    event.preventDefault();
    this.formTarget.querySelectorAll("input:invalid")[0]?.focus();

    const target = event.target;
    const errorEl = document.getElementById(`${target.name}-error`);

    target.setAttribute("aria-describedby", errorEl.id);
    errorEl?.classList.remove("hidden");
    errorEl?.setAttribute("aria-hidden", "false");
  }
}
