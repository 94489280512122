import React from "react";

import { ExerciseScreenProps } from "../shared/Elements";

const StressManagementToolsContext = React.createContext(undefined);

const StressManagementToolsProvider: React.FC<ExerciseScreenProps> = (
  props
) => {
  const defaultState = {
    panicThoughts: "",
    panicPhysicalFeelings: "",
    panicBehaviors: "",
    worryThoughts: "",
    worryPhysicalFeelings: "",
    worryBehaviors: "",
    calmThoughts: "",
    calmPhysicalFeelings: "",
    calmBehaviors: "",
    tryItOutWho: "",
    tryItOutTeach: "",
    tryItOutResponse: "",
  };

  const {
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  } = props;

  const initialState = { ...defaultState, ...completedExercise?.data };

  const [panicThoughts, setPanicThoughts] = React.useState<string>(
    initialState.panicThoughts
  );
  const [worryThoughts, setWorryThoughts] = React.useState<string>(
    initialState.worryThoughts
  );
  const [calmThoughts, setCalmThoughts] = React.useState<string>(
    initialState.calmThoughts
  );
  const [panicPhysicalFeelings, setPanicPhysicalFeelings] =
    React.useState<string>(initialState.panicPhysicalFeelings);
  const [worryPhysicalFeelings, setWorryPhysicalFeelings] =
    React.useState<string>(initialState.worryPhysicalFeelings);
  const [calmPhysicalFeelings, setCalmPhysicalFeelings] =
    React.useState<string>(initialState.calmPhysicalFeelings);
  const [panicBehaviors, setPanicBehaviors] = React.useState<string>(
    initialState.panicBehaviors
  );
  const [worryBehaviors, setWorryBehaviors] = React.useState<string>(
    initialState.worryBehaviors
  );
  const [calmBehaviors, setCalmBehaviors] = React.useState<string>(
    initialState.calmBehaviors
  );
  const [tryItOutWho, setTryItOutWho] = React.useState<string>(
    initialState.tryItOutWho
  );
  const [tryItOutTeach, setTryItOutTeach] = React.useState<string>(
    initialState.tryItOutTeach
  );
  const [tryItOutResponse, setTryItOutResponse] = React.useState<string>(
    initialState.tryItOutResponse
  );

  const value = {
    panicThoughts,
    setPanicThoughts,
    worryThoughts,
    setWorryThoughts,
    calmThoughts,
    setCalmThoughts,
    panicPhysicalFeelings,
    setPanicPhysicalFeelings,
    worryPhysicalFeelings,
    setWorryPhysicalFeelings,
    calmPhysicalFeelings,
    setCalmPhysicalFeelings,
    panicBehaviors,
    setPanicBehaviors,
    worryBehaviors,
    setWorryBehaviors,
    calmBehaviors,
    setCalmBehaviors,
    tryItOutWho,
    setTryItOutWho,
    tryItOutTeach,
    setTryItOutTeach,
    tryItOutResponse,
    setTryItOutResponse,
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  };

  return <StressManagementToolsContext.Provider value={value} {...props} />;
};

function useStressManagementTools() {
  const context = React.useContext(StressManagementToolsContext);
  if (!context) {
    throw new Error(
      "useStressManagementTools must be used within a StressManagementToolsProvider"
    );
  }
  return context;
}

export {
  StressManagementToolsProvider,
  StressManagementToolsContext,
  useStressManagementTools,
};
