import { gql } from "@apollo/client";

export const SAVE_EXERCISE = gql`
  mutation saveExercise($input: SaveExerciseInput!) {
    saveExercise(input: $input) {
      id
      errors {
        path
        message
      }
    }
  }
`;

export const COMPLETE_ASSESSMENT_ASSIGNMENT = gql`
  mutation completeAssessmentAssignment(
    $input: CompleteAssessmentAssignmentInput!
  ) {
    completeAssessmentAssignment(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;
