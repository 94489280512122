import angry from "@js/images/feelings/emoji-angry.svg";
import annoyed from "@js/images/feelings/emoji-annoyed.svg";
import confused from "@js/images/feelings/emoji-confused.svg";
import disappointed from "@js/images/feelings/emoji-disappointed.svg";
import embarrassed from "@js/images/feelings/emoji-embarrassed.svg";
import excited from "@js/images/feelings/emoji-excited.svg";
import frustrated from "@js/images/feelings/emoji-frustrated.svg";
import happy from "@js/images/feelings/emoji-happy.svg";
import loved from "@js/images/feelings/emoji-loved.svg";
import proud from "@js/images/feelings/emoji-proud.svg";
import sad from "@js/images/feelings/emoji-sad.svg";
import scared from "@js/images/feelings/emoji-scared.svg";
import shy from "@js/images/feelings/emoji-shy.svg";
import silly from "@js/images/feelings/emoji-silly.svg";
import tired from "@js/images/feelings/emoji-tired.svg";
import worried from "@js/images/feelings/emoji-worried.svg";

const FeelingImages = {
  happy,
  frustrated,
  excited,
  angry,
  silly,
  tired,
  sad,
  scared,
  proud,
  embarrassed,
  confused,
  worried,
  annoyed,
  shy,
  disappointed,
  loved,
};

export default FeelingImages;
