import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String, interval: { type: Number, default: 5000 } };

  initialize() {
    this.notifications = {};
  }

  connect() {
    this.interval = setInterval(
      () => this.pullNotifications(),
      this.intervalValue
    );
    this.pullNotifications();
  }

  disconnect() {
    clearInterval(this.interval);
  }

  pullNotifications() {
    fetch(this.urlValue)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.handleNotifications(data["notifications"]);
      })
      .catch((error) => {
        console.log("Something went wrong", error);
      });
  }

  handleNotifications(notifications) {
    const ids = notifications.map((notification) => notification.id);
    Object.keys(this.notifications)
      .filter((id) => !ids.includes(id))
      .forEach((id) => {
        this.closeNotification(id);
      });

    notifications.forEach((notification) =>
      this.triggerNotification(notification)
    );
    console.log(
      "Tracked notifications: ",
      Object.keys(this.notifications).join(", ")
    );
  }

  triggerNotification({ id, title, body }) {
    if (!this.notifications[id]) {
      console.log("Trigger notification for ", id, " with ", title);
      const notification = new Notification(title, {
        body,
        requireInteraction: true,
      });
      this.notifications[id] = notification;
    }
  }

  closeNotification(id) {
    if (this.notifications.hasOwnProperty(id)) {
      console.log("Closing notification for ", id);
      this.notifications[id].close();
      delete this.notifications[id];
    }
  }
}
