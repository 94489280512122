import { Controller } from "@hotwired/stimulus";
import { readableBusinessTimeSince } from "@js/helpers/businesstime";
import { ONE_SECOND } from "@js/helpers/constants";
import { readableTimeSince } from "@js/helpers/time";

export default class extends Controller {
  static values = {
    conversationStart: Number,
    useBusinessTime: Boolean,
  };

  static targets = ["time"];
  declare readonly timeTarget: Element;
  declare readonly useBusinessTimeValue: boolean;
  declare readonly conversationStartValue: number;
  declare readonly hasConversationStartValue: boolean;

  connect() {
    this.setTime(this.timeTarget);

    setInterval(() => {
      this.setTime(this.timeTarget);
    }, ONE_SECOND);
  }

  setTime(element: Element) {
    if (this.hasConversationStartValue) {
      element.innerHTML = this.useBusinessTimeValue
        ? readableBusinessTimeSince(this.conversationStartValue)
        : readableTimeSince(this.conversationStartValue);
    }
  }
}
