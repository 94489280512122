import { Controller } from "@hotwired/stimulus";

/**
 * Replace the contents of an element with the specified contents
 *
 * Use data-action="click->replaceable#replace" on any element which
 * should trigger the replacement of the content. When that element is
 * clicked the `data-replaceable-content` attribute will be used to replace the
 * content of the replaceable target.
 *
 */
export default class extends Controller {
  static targets = ["replace"];

  declare readonly replaceTarget: HTMLElement;

  replace(event: Event) {
    this.replaceTarget.innerHTML = this.replaceableContent(event);
  }

  replaceableContent(event: Event) {
    const target = event.target as HTMLElement;

    return target.dataset["replaceableContent"];
  }
}
