import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    console.log("Emitting all turbo events to the console");

    // document

    document.addEventListener("turbo:click", () => {
      console.log("turbo:click");
    });

    document.addEventListener("turbo:before-visit", (event) => {
      console.log("turbo:before-visit", "url: ", event.detail.url);
    });

    document.addEventListener("turbo:visit", () => {
      console.log("turbo:visit");
    });

    document.addEventListener("turbo:before-cache", () => {
      console.log("turbo:before-cache");
    });

    document.addEventListener("turbo:before-render", (event) => {
      console.log(
        "turbo:before-render",
        "renderMethod: ",
        event.detail.renderMethod,
        "newBody: ",
        event.detail.newBody
      );
    });

    document.addEventListener("turbo:render", (event) => {
      console.log("turbo:render", "renderMethod: ", event.detail.renderMethod);
    });

    document.addEventListener("turbo:load", (event) => {
      console.log("turbo:load", "url: ", event.detail.url);
    });

    // refreshes

    document.addEventListener("turbo:morph", (event) => {
      console.log(
        "turbo:morph",
        "currentElement: ",
        event.detail.currentElement,
        "newElement: ",
        event.detail.newElement
      );
    });

    // forms

    document.addEventListener("turbo:submit-start", () => {
      console.log("turbo:submit-start");
    });

    document.addEventListener("turbo:submit-end", (event) => {
      console.log(
        "turbo:submit-end",
        "success:",
        event.detail.success,
        "fetchResponse:",
        event.detail.fetchResponse,
        "error:",
        event.detail.error
      );
    });
    // frames
    document.addEventListener("turbo:before-frame-render", (event) => {
      console.log(
        "turbo:before-frame-render",
        "newFrame: ",
        event.detail.newFrame
      );
    });
    document.addEventListener("turbo:frame-render", (event) => {
      console.log(
        "turbo:frame-render",
        "fetchResponse: ",
        event.detail.fetchResponse
      );
    });

    document.addEventListener("turbo:frame-load", () => {
      console.log("turbo:frame-load");
    });

    document.addEventListener("turbo:frame-missing", (event) => {
      console.log(
        "turbo:frame-missing",
        "response: ",
        event.detail.response,
        "visit: ",
        event.detail.visit
      );
    });

    // streams

    document.addEventListener("turbo:before-stream-render", (event) => {
      console.log(
        "turbo:before-stream-render",
        "newStream: ",
        event.detail.newStream
      );
    });

    // requests
    document.addEventListener("turbo:before-fetch-request", (event) => {
      console.log(
        "turbo:before-fetch-request",
        "fetchOptions: ",
        event.detail.fetchOptions,
        "url: ",
        event.detail.url
      );
    });

    document.addEventListener("turbo:before-fetch-response", (event) => {
      console.log(
        "turbo:before-fetch-response",
        "fetchResponse: ",
        event.detail.fetchResponse
      );
    });

    document.addEventListener("turbo:before-prefetch", () => {
      console.log("turbo:before-prefetch");
    });

    document.addEventListener("turbo:fetch-request-error", (event) => {
      console.log(
        "turbo:fetch-request-error",
        "request: ",
        event.detail.request,
        "error: ",
        event.detail.error
      );
    });
  }
}
