import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const MakingAPlan: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    childHasExtraTroubleWhen: "",
    beforeWeTryAgain: "",
    expectMyChildTo: "",
    rewards: "",
    consequences: "",
    planToPractice: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [childHasExtraTroubleWhen, setChildHasExtraTroubleWhen] =
    React.useState(initialState.childHasExtraTroubleWhen);
  const [beforeWeTryAgain, setBeforeWeTryAgain] = React.useState(
    initialState.beforeWeTryAgain
  );
  const [expectMyChildTo, setExpectMyChildTo] = React.useState(
    initialState.expectMyChildTo
  );
  const [rewards, setRewards] = React.useState(initialState.rewards);
  const [consequences, setConsequences] = React.useState(
    initialState.consequences
  );
  const [planToPractice, setPlanToPractice] = React.useState(
    initialState.planToPractice
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      childHasExtraTroubleWhen,
      beforeWeTryAgain,
      expectMyChildTo,
      rewards,
      consequences,
      planToPractice,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Making a Plan</EE.Heading>
          <EE.HR />
          <EE.Caption>
            Help for parents to feel prepared ahead of time for difficult
            situations
          </EE.Caption>
          <EE.Paragraph className="my-4">
            One of the best ways to deal with behavior problems is to prevent
            them before they even happen. If you already know what places or
            times might be especially challenging, it’s a good idea to make a
            plan beforehand so that your child has a chance to do his or her
            best.
          </EE.Paragraph>
          <EE.Paragraph className="my-4">
            Try to plan for various scenarios such as your child feeling bored,
            tired, hungry or restless. We recommend creating guidelines or rules
            for your child to follow, along with planned rewards (ex. bringing
            stickers when your child accompanies you on errands), and
            consequences (such as loss of privileges for minor violations and a
            removal from the situation for more severe misbehavior). If
            possible, practice these situations with your child ahead of time,
            like going to the dentist or doctor, and coming home and putting
            away a coat and backpack. As you walk through some scenarios
            together, be sure to provide plenty of praise for good behavior.
          </EE.Paragraph>

          <EE.Subheading>Getting Started</EE.Subheading>

          <EE.QuestionLabel>
            <EE.Blockquote quotes={false}>
              Pick a situation that is challenging for your child that occurs
              regularly (like shopping trips, visits to neighbors, school
              drop-off, or long drives).
            </EE.Blockquote>
            <EE.Paragraph>
              <EE.Bold>My child has extra trouble when:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setChildHasExtraTroubleWhen(e.target.value)
              }
              value={childHasExtraTroubleWhen}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Blockquote quotes={false}>
              What are some things you could do to be ready for that situation
              next time?
            </EE.Blockquote>
            <EE.Paragraph>
              <EE.Bold>
                Before we try that again, I will make sure that:
              </EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setBeforeWeTryAgain(e.target.value)
              }
              value={beforeWeTryAgain}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Blockquote quotes={false}>
              What rules does your child need to follow for that situation?
            </EE.Blockquote>
            <EE.Paragraph>
              <EE.Bold>I expect my child to:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setExpectMyChildTo(e.target.value)
              }
              value={expectMyChildTo}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Blockquote quotes={false}>
              What are the rewards and consequences connected to those rules?
            </EE.Blockquote>
            <EE.Paragraph>
              <EE.Bold>If my child obeys the rules:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRewards(e.target.value)
              }
              value={rewards}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>If my child does not follow the rules:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setConsequences(e.target.value)
              }
              value={consequences}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Blockquote quotes={false}>
              Will you be able to practice this situation? If so, pick a time
              when you can practice it together.
            </EE.Blockquote>
            <EE.Paragraph>
              <EE.Bold>Here is when I plan to practice this situation:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPlanToPractice(e.target.value)
              }
              value={planToPractice}
            />
          </EE.QuestionLabel>

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default MakingAPlan;
