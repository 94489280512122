import React from "react";

import { useThermometerImage } from "../shared";
import {
  ExerciseElements as EE,
  ExerciseDoubleBubbleIcon,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useDoubleBubble } from "./Context";
import { ImageMapping, Title } from "./Shared";

const Reflection = () => {
  const {
    patientFirstName,
    initialThermometerValue,
    initialThoughts,
    finalThermometerValue,
    finalThoughts,
    differenceNoticed,
    reflectionThermometerValue,
    setReflectionThermometerValue,
    reflectionThoughts,
    setReflectionThoughts,
    assignedExerciseId,
    completedExercise,
  } = useDoubleBubble();
  const { ThermometerImage } = useThermometerImage(
    ImageMapping,
    reflectionThermometerValue
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      initialThermometerValue,
      finalThermometerValue,
      reflectionThermometerValue,
      initialThoughts,
      finalThoughts,
      differenceNoticed,
      reflectionThoughts,
    },
    assignedExerciseId,
    completedExercise?.id
  );
  return (
    <div className="p-6">
      <div className="flex-grow-0">
        <EE.Form
          onSubmit={(e: React.ChangeEvent<HTMLFormElement>) => {
            submitExercise(e);
          }}
        >
          <EE.RoundedExerciseCard className="pt-8">
            <ExerciseDoubleBubbleIcon className="w-1/3" />
            <Title />
          </EE.RoundedExerciseCard>
          <EE.CenteredExerciseText className="py-8" id="slider-label">
            Nice work! How is {patientFirstName} feeling now?
          </EE.CenteredExerciseText>

          <ThermometerImage className="h-16 pb-4 mx-auto" />
          <EE.ThermometerNumber>
            {reflectionThermometerValue}
          </EE.ThermometerNumber>
          <EE.ThermometerSlider
            thermometerValue={reflectionThermometerValue}
            onChange={setReflectionThermometerValue}
            ariaLabelledByForHandle="slider-label"
          />
          <EE.ExerciseAnchors>
            <EE.ExerciseAnchor className="text-left">
              Very Sad
            </EE.ExerciseAnchor>
            <EE.ExerciseAnchor className="text-right">
              Very Happy
            </EE.ExerciseAnchor>
          </EE.ExerciseAnchors>
          <EE.CenteredExerciseText
            className="py-8"
            htmlFor="reflection-thoughts-input"
          >
            Is there anything else we should know?
          </EE.CenteredExerciseText>

          <EE.TextArea
            id="reflection-thoughts-input"
            value={reflectionThoughts}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setReflectionThoughts(e.target.value)
            }
          />

          <div className="flex flex-col justify-center">
            <EE.ResumableExerciseActions
              cancelPath="/exercise/5"
              setExerciseComplete={setExerciseComplete}
            />

            <hr className="mt-10 mb-6" />
          </div>
        </EE.Form>
      </div>
    </div>
  );
};

export default Reflection;
