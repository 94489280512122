import { Controller } from "@hotwired/stimulus";
import { useMutation } from "stimulus-use";

export default class extends Controller {
  static values = {
    classes: Array,
    hideInput: Boolean,
    alwaysShow: Boolean,
    placeholder: String,
    showWeekends: Boolean,
    availableDates: Array,
  };

  connect() {
    useMutation(this, { attributes: true, childList: true, subtree: true });

    this.setPlaceholder();
    this.addDatePickerAdapterToDuetDatePickerInput();
    this.bindDuetDatePickerEvents();

    if (!this.showWeekendsValue) {
      this.element.isDateDisabled = this.shouldDisableDay.bind(this);
    }

    if (this.alwaysShowValue) {
      // this is needed to force the always shown date picker to refresh on duetChange (when rerendering with a new value), but it focuses the input (causing page to jump) https://github.com/duetds/date-picker/blob/a89499198d6e5555073bb0dec3a3dab9a5b3648b/src/components/duet-date-picker/duet-date-picker.tsx#L324

      this.element.show();

      // hide is needed to prevent the focus that is set in show(), because it resets the focus to the (hidden) button
      // don't hate the player. Hate javascript.

      this.element.hide();
    }
  }

  disconnect() {
    this.unbindDuetDatePickerEvents();
  }

  bindDuetDatePickerEvents() {
    this.boundChangeEvent = this.emitChangeEvent.bind(this);
    this.element.addEventListener("duetChange", this.boundChangeEvent);

    this.boundOpenEvent = this.emitOpenEvent.bind(this);
    this.element.addEventListener("duetOpen", this.boundOpenEvent);

    this.boundCloseEvent = this.emitCloseEvent.bind(this);
    this.element.addEventListener("duetClose", this.boundCloseEvent);
  }

  unbindDuetDatePickerEvents() {
    if (this.boundChangeEvent) {
      this.element.removeEventListener("duetChange", this.boundChangeEvent);
    }

    if (this.boundOpenEvent) {
      this.element.removeEventListener("duetOpen", this.boundOpenEvent);
    }

    if (this.boundCloseEvent) {
      this.element.removeEventListener("duetClose", this.boundCloseEvent);
    }
  }

  emitChangeEvent(event) {
    this.dispatch("change", { detail: event.detail });
  }

  emitOpenEvent(event) {
    this.dispatch("open", { detail: event.detail });
  }

  emitCloseEvent(event) {
    this.dispatch("close", { detail: event.detail });
  }

  setPlaceholder() {
    let defaultLocalization = {
      buttonLabel: "Choose date",
      selectedDateMessage: "Selected date is",
      prevMonthLabel: "Previous month",
      nextMonthLabel: "Next month",
      monthSelectLabel: "Month",
      yearSelectLabel: "Year",
      closeLabel: "Close window",
      calendarHeading: "Choose a date",
      dayNames: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      monthNamesShort: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    };

    this.element.localization = {
      ...defaultLocalization,
      placeholder: this.placeholderValue,
    };
  }

  addDatePickerAdapterToDuetDatePickerInput() {
    const DATE_FORMAT_US = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

    this.element.dateAdapter = {
      parse(value = "", createDate) {
        const matches = value.match(DATE_FORMAT_US);

        if (matches) {
          return createDate(matches[3], matches[1], matches[2]);
        } else {
          return null;
        }
      },
      format(date) {
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      },
    };
  }

  mutate(entries) {
    for (const mutation of entries) {
      if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
        this.hideDuetDatePickerInput(mutation.target);
        this.addClassesToDuetDatePickerInput(mutation.target);
      }
    }
  }

  // Wait for the Duet Date Picker input to be added to the page and add classes it.
  // It's a little hacky, but this makes the date input styling consistent with other inputs.
  addClassesToDuetDatePickerInput(mutationTarget) {
    if (this.hideInputValue) return;

    const input = mutationTarget.getElementsByClassName("duet-date__input")[0];
    if (!input) return;

    input.classList.add(...this.classesValue);
  }

  // Wait for the Duet Date Picker input wrapper to be added to the page and hide it.
  hideDuetDatePickerInput(mutationTarget) {
    if (!this.hideInputValue) return;

    const inputWrapper = mutationTarget.getElementsByClassName(
      "duet-date__input-wrapper"
    )[0];
    if (!inputWrapper) return;

    inputWrapper.classList.add("hidden");
  }

  shouldDisableDay(date) {
    const dateString = date.toISOString().split("T")[0];

    if (date.getDay() === 0 || date.getDay() === 6) return true;
    if (this.availableDatesValue.length == 0) return false;
    if (this.availableDatesValue.includes(dateString)) {
      return false;
    } else {
      return true;
    }
  }
}
