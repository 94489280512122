import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    elementIdentifier: String,
  };

  declare readonly elementIdentifierValue: string;
  declare readonly hasElementIdentifierValue: boolean;

  remove(event: Event) {
    event.preventDefault();

    if (this.confirmed(event) === true) {
      this.removeElement();
    }
  }

  confirmed(event: Event) {
    let element = event.target as HTMLElement;
    let confirmString: string = element.dataset.removableConfirmValue;

    if (!confirmString) return true;

    let confirmed = confirm(confirmString);
    if (confirmed) return true;

    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  removeElement() {
    if (this.hasElementIdentifierValue) {
      document
        .querySelectorAll(this.elementIdentifierValue)
        .forEach((target) => {
          target.remove();
        });
    } else {
      this.element.remove();
    }
  }
}
