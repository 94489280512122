import KnowYourselfIcon from "@js/images/icons/GetToKnowYourself.svg";
import React from "react";
import { Route } from "react-router-dom";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { GetToKnowYourselfProvider, useGetToKnowYourself } from "./Context";
import GenericFiveStarRatingPage from "./GenericFiveStarRatingPage";
import Summary from "./Summary";

const Screens: React.FC<ExerciseScreenProps> = (props) => {
  return (
    <GetToKnowYourselfProvider {...props}>
      <ExerciseContent />
    </GetToKnowYourselfProvider>
  );
};

// Separate component b/c we need access to the setters and variables from the Context
const ExerciseContent = () => {
  const title = "Get to Know Yourself";
  const numSteps = 7;
  let currentStep = 1;

  const {
    familyRelationships,
    setFamilyRelationships,
    friendsAndRelationships,
    setFriendsAndRelationships,
    fitness,
    setFitness,
    diet,
    setDiet,
    socialLife,
    setSocialLife,
    senseOfBelonging,
    setSenseOfBelonging,
    hobbiesSports,
    setHobbiesSports,
  } = useGetToKnowYourself();

  const questionPrefix = "How do you feel about your ";

  const intro = (
    <EE.GenericExerciseIntroPage
      imageSrc={KnowYourselfIcon}
      imageAltText="A red and yellow star."
      title={title}
      introText="We all have unique strengths — and challenges. Here's how to (honestly) recognize your own."
      nextPage="/familiy_relationships"
    />
  );

  return (
    <EE.WrapperWithIntro intro={intro}>
      <EE.ExerciseNavigation title={title} />

      <Route path="/familiy_relationships">
        <GenericFiveStarRatingPage
          numSteps={numSteps}
          currentStep={currentStep++}
          prompt={
            <p>
              {questionPrefix}
              <EE.Bold>family relationships</EE.Bold>?
            </p>
          }
          value={familyRelationships}
          setValue={setFamilyRelationships}
          nextPage="/friends_and_relationships"
        />
      </Route>

      <Route path="/friends_and_relationships">
        <GenericFiveStarRatingPage
          numSteps={numSteps}
          currentStep={currentStep++}
          prompt={
            <p>
              {questionPrefix}
              <EE.Bold>friends and relationships</EE.Bold>?
            </p>
          }
          value={friendsAndRelationships}
          setValue={setFriendsAndRelationships}
          nextPage="/fitness"
        />
      </Route>

      <Route path="/fitness">
        <GenericFiveStarRatingPage
          numSteps={numSteps}
          currentStep={currentStep++}
          prompt={
            <p>
              {questionPrefix}
              <EE.Bold>fitness</EE.Bold>?
            </p>
          }
          value={fitness}
          setValue={setFitness}
          nextPage="/diet"
        />
      </Route>

      <Route path="/diet">
        <GenericFiveStarRatingPage
          numSteps={numSteps}
          currentStep={currentStep++}
          prompt={
            <p>
              {questionPrefix}
              <EE.Bold>diet</EE.Bold>?
            </p>
          }
          value={diet}
          setValue={setDiet}
          nextPage="/social_life"
        />
      </Route>

      <Route path="/social_life">
        <GenericFiveStarRatingPage
          numSteps={numSteps}
          currentStep={currentStep++}
          prompt={
            <p>
              {questionPrefix}
              <EE.Bold>social life</EE.Bold>?
            </p>
          }
          value={socialLife}
          setValue={setSocialLife}
          nextPage="/sense_of_belonging"
        />
      </Route>

      <Route path="/sense_of_belonging">
        <GenericFiveStarRatingPage
          numSteps={numSteps}
          currentStep={currentStep++}
          prompt={
            <p>
              {questionPrefix}
              <EE.Bold>sense of belonging</EE.Bold>?
            </p>
          }
          value={senseOfBelonging}
          setValue={setSenseOfBelonging}
          nextPage="/hobbies_and_sports"
        />
      </Route>

      <Route path="/hobbies_and_sports">
        <GenericFiveStarRatingPage
          numSteps={numSteps}
          currentStep={currentStep++}
          prompt={
            <p>
              {questionPrefix}
              <EE.Bold>hobbies and/or sports</EE.Bold>?
            </p>
          }
          value={hobbiesSports}
          setValue={setHobbiesSports}
          nextPage="/summary"
        />
      </Route>

      <Route path="/summary">
        <Summary />
      </Route>
    </EE.WrapperWithIntro>
  );
};

export default Screens;
