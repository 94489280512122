import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["expand"];
  declare readonly expandTargets: HTMLElement[];

  expand(event: Event) {
    this.hideAllExpandTargets();

    const target = event.target as HTMLElement;
    let sectionId = target.dataset["expandOneSectionId"];

    document.getElementById(sectionId).classList.remove("hidden");
  }

  hideAllExpandTargets() {
    this.expandTargets.forEach((e) => {
      e.classList.add("hidden");
    });
  }
}
