import { Controller } from "@hotwired/stimulus";

/*
  Adds the contents of a template selected with an id from
  a select field specified with a `select` target to a
  specified `container` target. Before the template contents
  are added a provided replacement token is replaced with
  the current timestamp in any `for`, `name` or `id`
  attributes within the template.

  Example html:

    <div data-controller="add-selected-template" data-add-selected-template-replacement-token-value="replaceMe">
      <div data-add-selected-template-target="container"></div>
      <select id="add_selected_template" data-add-selected-template-target="select">
        <option value="template1">Template 1</option>
      </select>
      <a href="#" data-action="add-selected-template#add">Add</a>
      <template data-template-name="template1">
        <label for="prefix_replaceMe_template1_attribute">Attribute</label>
        <select name="prefix[replaceMe][template1][attribute]" id="prefix_replaceMe_template1_attribute">
          <option value="option1">Option 1</option>
        </select>
      </template>
    </div>
 */

export default class extends Controller {
  declare readonly selectTarget: HTMLSelectElement;
  declare readonly containerTarget: HTMLElement;
  declare readonly replacementTokenValue: string;

  static targets = ["select", "container"];
  static values = { replacementToken: String };

  add(event: Event) {
    event.preventDefault();

    const selectedTemplate = this.selectTarget.selectedOptions[0].value;
    if (selectedTemplate) {
      let uniqueValue = Date.now();

      const templateContent = this.cloneTemplate(
        selectedTemplate,
        this.element as HTMLElement
      );

      this.replaceToken(
        this.replacementTokenValue,
        uniqueValue.toString(),
        templateContent as HTMLElement
      );

      this.containerTarget.append(templateContent);
    }
  }

  cloneTemplate(name: string, templateRoot: HTMLElement): Node {
    const selector = "template[data-template-name='" + name + "']";
    const template = templateRoot.querySelector(
      selector
    ) as HTMLTemplateElement | null;

    if (template) {
      return template.content.cloneNode(true);
    } else {
      throw "Template not found: " + name;
    }
  }

  replaceToken(
    toReplace: string,
    replacement: string,
    templateContent: HTMLElement
  ) {
    ["for", "name", "id"].forEach((attributeName) => {
      templateContent
        .querySelectorAll(`[${attributeName}*='${toReplace}']`)
        .forEach(function (node) {
          let oldValue = node.getAttribute(attributeName) || "";
          let newValue = oldValue.replace(toReplace, replacement);

          node.setAttribute(attributeName, newValue);
        });
    });
  }
}
