import React from "react";

import { useThermometerImage } from "../shared";
import { useThermometer } from "../shared/Context";
import {
  ExerciseElements as EE,
  ThermometerElementsProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const Thoughts = (thermometerElements: ThermometerElementsProps) => {
  const {
    thoughts,
    setThoughts,
    thermometerValue,
    assignedExerciseId,
    completedExercise,
    readOnly,
  } = useThermometer();
  const { Title, imageIsFace, ImageMapping, minValue } = thermometerElements;
  const { ThermometerImage } = useThermometerImage(
    ImageMapping,
    thermometerValue,
    minValue
  );

  const { submitExercise } = useSubmitExercise(
    {
      value: thermometerValue,
      thoughts,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  const imageStyles = (imageIsFace = false) => {
    if (imageIsFace) {
      return "w-1/4 pt-8 mx-auto";
    } else {
      return "w-1/2 mx-auto";
    }
  };

  return (
    <EE.Form
      onSubmit={(e: React.ChangeEvent<HTMLFormElement>) => {
        submitExercise(e);
      }}
    >
      <div className="p-2">
        <div className="flex-grow-0">
          <EE.RoundedExerciseCard>
            <ThermometerImage className={imageStyles(imageIsFace)} />
            <Title />
          </EE.RoundedExerciseCard>
          <EE.CenteredExerciseText className="py-4" id="thoughts-label">
            Is there anything else we should know?
          </EE.CenteredExerciseText>
          <EE.TextArea
            id="thoughts-input"
            value={thoughts}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setThoughts(e.target.value)
            }
            aria-labelledby="thoughts-label"
          />

          <EE.ActionButtons>
            <EE.FormSubmitButton text="Log Mood" />

            <EE.SecondaryActionLink text="Cancel" to="/exercise" />
          </EE.ActionButtons>
        </div>
      </div>
    </EE.Form>
  );
};

export default Thoughts;
