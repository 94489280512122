import { Controller } from "@hotwired/stimulus";

/**
 * This controller scrolls to the element it's attached to.
 */
export default class extends Controller {
  connect() {
    setTimeout(() => {
      const navbarHeight = 88;
      const yMargin = 16;
      const yOffset = -(navbarHeight + yMargin);
      const elementRect = this.element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.scrollY;
      const adjustedTop = absoluteElementTop + yOffset;
      window.scrollTo({ top: adjustedTop, behavior: "smooth" });
    }, 0);
  }
}
