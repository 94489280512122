import Image from "@js/components/shared/Image";
import FeelingImages from "@js/images/feelings/";
import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useMyFeelingsScale } from "./Context";

const FeelingStrength = () => {
  const {
    feeling,
    feelingStrength,
    setFeelingStrength,
    assignedExerciseId,
    completedExercise,
  } = useMyFeelingsScale();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      feeling,
      feelingStrength,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.Form
      onSubmit={(e: React.ChangeEvent<HTMLFormElement>) => {
        submitExercise(e);
      }}
    >
      <div className="text-center">
        <Image src={FeelingImages[feeling]} className="w-1/6 pt-8 mx-auto" />
        <div className="pt-6 pb-8 font-sans font-bold capitalize">
          {feeling}
        </div>
      </div>

      <EE.CenteredExerciseText className="pt-12 pb-6" id="slider-label">
        How strong is this feeling?
      </EE.CenteredExerciseText>

      <EE.ThermometerNumber>{feelingStrength}</EE.ThermometerNumber>
      <div className="py-4">
        <EE.ThermometerSlider
          minValue={1}
          maxValue={5}
          thermometerValue={feelingStrength}
          onChange={setFeelingStrength}
          ariaLabelledByForHandle="slider-label"
        />
        <EE.ExerciseAnchors>
          <EE.ExerciseAnchor className="text-left">A little</EE.ExerciseAnchor>
          <EE.ExerciseAnchor className="text-right">
            Very strong
          </EE.ExerciseAnchor>
        </EE.ExerciseAnchors>
      </div>

      <EE.ActionButtons>
        <EE.FormSubmitButton
          text="Log Feeling"
          setExerciseComplete={setExerciseComplete}
        />
        <EE.SaveForLaterButton
          text="Save for Later"
          setExerciseComplete={setExerciseComplete}
        />
        <EE.SecondaryActionLink text="Back" to="/" />
      </EE.ActionButtons>
    </EE.Form>
  );
};

export default FeelingStrength;
