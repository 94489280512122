import ahoy from "ahoy.js";
import Swiper from "swiper";
import { A11y, Keyboard, Navigation, Pagination } from "swiper/modules";

export class VisualStory {
  constructor(element) {
    this.element = element;
    this.swiper = new Swiper(this.element, {
      modules: [Keyboard, A11y, Navigation, Pagination],
      // If we need pagination
      pagination: {
        el: ".swiper-pagination-visual-story",
        clickable: true,
      },
      a11y: {
        nextSlideMessage: "Next visual story slide",
        prevSlideMessage: "Previous visual story slide",
        lastSlideMessage: "This is the last visual story slide",
        firstSlideMessage: "This is the first visual story slide",
        containerMessage: `Here is content for this visual story`,
        slideLabelMessage: "Slide {{index}} out of {{slidesLength}}",
      },
      keyboard: true,
      // Navigation arrows
      navigation: {
        nextEl: ".visual-story-button-next",
        prevEl: ".visual-story-button-prev",
      },
      scrollbar: false,
    });

    this.swiper.on("reachBeginning", () => {
      this.setActiveSlideAttributes();
      ahoy.track(
        "MemberVisualStorySlide:Viewed",
        this.getAhoyEventProperties.bind(this)
      );
    });

    this.swiper.on("slideChange", () => {
      this.setActiveSlideAttributes();
      ahoy.track(
        "MemberVisualStorySlide:Viewed",
        this.getAhoyEventProperties.bind(this)
      );
    });
  }

  get swiper() {
    return this.element.swiper;
  }

  set swiper(swiper) {
    this._swiper = swiper;
  }

  get slides() {
    return this.swiper.slides;
  }

  get activeSlide() {
    return this.slides[this.swiper.activeIndex];
  }

  getAhoyEventProperties() {
    return {
      currentSlide: this.swiper.activeIndex + 1,
      totalSlides: this.slides.length,
      page: window.location.pathname,
    };
  }

  setActiveSlideAttributes() {
    for (let slide of this.slides) {
      slide.setAttribute("aria-hidden", "true");
    }
    this.activeSlide.setAttribute("aria-hidden", "false");
  }

  startAtBeginning() {
    this.swiper.setProgress(0);
  }
}
