import { AnalyticsBrowser } from "@segment/analytics-next";
import * as Sentry from "@sentry/react";

import { getCookies, setCookie } from "./cookies";

const setAnonymousId = async (segment, existingAnonymousId) => {
  return new Promise((resolve) => {
    if (existingAnonymousId) {
      setCookie("anonymous_id", existingAnonymousId, 30);
      segment.setAnonymousId(existingAnonymousId);
    }

    const anonymousId = getCookies()["anonymous_id"] || existingAnonymousId;

    if (anonymousId) {
      resolve(anonymousId);
    } else {
      segment.user().then((user) => {
        const anonymousId = user.anonymousId();
        if (anonymousId === undefined) {
          Sentry.captureMessage("Anonymous ID is undefined.");
        }
        setCookie("anonymous_id", anonymousId, 30);
        resolve(anonymousId);
      });
    }
  });
};

const initialize = () => {
  const segmentEnvEl = document.getElementsByName("segment-env")[0];
  if (segmentEnvEl) {
    const segment = AnalyticsBrowser.load({
      writeKey: segmentEnvEl.dataset.key,
    });
    return segment;
  }
};

const client = initialize();

export { client as SegmentClient, setAnonymousId };
