import React from "react";

import { ExerciseElements as EE, Icons } from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useFearLadder } from "./Context";
import { FearLadderHeader } from "./Shared";

const Thoughts: React.FC = (props) => {
  const {
    fears,
    completedBy,
    assignedExerciseId,
    completedExercise,
    thoughts,
    setThoughts,
    patientFirstName,
  } = useFearLadder();
  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      fears,
      completedBy,
      thoughts,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <FearLadderHeader />

          <EE.CenteredExerciseText className="py-4" htmlFor="thoughts-input">
            Is there anything else we should know?
          </EE.CenteredExerciseText>
          <EE.TextArea
            id="thoughts-input"
            value={thoughts}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setThoughts(e.target.value)
            }
          />

          <EE.LinkGroup>
            <EE.ResumableExerciseActions
              submitButtonText="Log Fears"
              cancelPath="/exercise"
              setExerciseComplete={setExerciseComplete}
            />
          </EE.LinkGroup>
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default Thoughts;
