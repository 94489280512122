import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const ParentObservationRecord: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    day: "",
    did: "",
    didNotDo: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [day, setDay] = React.useState(initialState.day);
  const [did, setDid] = React.useState(initialState.did);
  const [didNotDo, setDidNotDo] = React.useState(initialState.didNotDo);

  const { submitExercise } = useSubmitExercise(
    {
      day,
      did,
      didNotDo,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Behavior Observation Chart</EE.Heading>
          <EE.HR />
          <EE.Caption>
            As you go through your week, keep a tally of the number of times
            your child does what you ask and the number of times they do not do
            what you ask—choose up to an hour each day to monitor this. This
            will help both of us troubleshoot their behavior!
          </EE.Caption>

          <EE.QuestionLabel>
            <EE.Bold>Day</EE.Bold>
            <EE.TextInput
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDay(e.target.value)
              }
              value={day}
            />
          </EE.QuestionLabel>

          <div className="flex">
            <EE.QuestionLabel className="flex-grow pr-2 my-2">
              <EE.Bold>My child did what I asked</EE.Bold>
              <EE.TextInput
                type="number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setDid(e.target.value)
                }
                value={did}
              />
            </EE.QuestionLabel>

            <EE.QuestionLabel className="flex-grow pl-2 my-2">
              <EE.Bold>
                My child did <u>not</u> do what I asked
              </EE.Bold>
              <EE.TextInput
                type="number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setDidNotDo(e.target.value)
                }
                value={didNotDo}
              />
            </EE.QuestionLabel>
          </div>
          <EE.ActionButtons>
            <EE.FormSubmitButton text="Log" />
          </EE.ActionButtons>
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default ParentObservationRecord;
