import React from "react";
import { Route } from "react-router-dom";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { MyFeelingsScaleProvider } from "./Context";
import Exercise from "./Exercise";
import FeelingStrength from "./FeelingStrength";

const Screens = (props: ExerciseScreenProps) => {
  return (
    <MyFeelingsScaleProvider {...props}>
      <EE.Wrapper>
        <Route exact path="/">
          <Exercise />
        </Route>
        <Route exact path="/feeling_strength">
          <FeelingStrength />
        </Route>
      </EE.Wrapper>
    </MyFeelingsScaleProvider>
  );
};

export default Screens;
