import Image from "@js/components/shared/Image";
import SocialConnectionsIcon from "@js/images/exercises/SocialConnections.png";
import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const MySocialMapKidsScreen: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    myConnections1: "",
    myConnections2: "",
    myConnections3: "",
    myConnections4: "",
    myConnections5: "",
    myConnections6: "",
    myConnections7: "",
    myConnections8: "",
    whoTalkToAboutFeelings: "",
    whoHelpWithChores: "",
    whoLikeToPlayWith: "",
    whoSpendMoreTimeWith: "",
    mySocialSupportPlan: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [myConnections1, setMyConnections1] = React.useState(
    initialState.myConnections1
  );
  const [myConnections2, setMyConnections2] = React.useState(
    initialState.myConnections2
  );
  const [myConnections3, setMyConnections3] = React.useState(
    initialState.myConnections3
  );
  const [myConnections4, setMyConnections4] = React.useState(
    initialState.myConnections4
  );
  const [myConnections5, setMyConnections5] = React.useState(
    initialState.myConnections5
  );
  const [myConnections6, setMyConnections6] = React.useState(
    initialState.myConnections6
  );
  const [myConnections7, setMyConnections7] = React.useState(
    initialState.myConnections7
  );
  const [myConnections8, setMyConnections8] = React.useState(
    initialState.myConnections8
  );
  const [whoTalkToAboutFeelings, setWhoTalkToAboutFeelings] = React.useState(
    initialState.whoTalkToAboutFeelings
  );
  const [whoHelpWithChores, setWhoHelpWithChores] = React.useState(
    initialState.whoHelpWithChores
  );
  const [whoLikeToPlayWith, setWhoLikeToPlayWith] = React.useState(
    initialState.whoLikeToPlayWith
  );
  const [whoSpendMoreTimeWith, setWhoSpendMoreTimeWith] = React.useState(
    initialState.whoSpendMoreTimeWith
  );
  const [mySocialSupportPlan, setMySocialSupportPlan] = React.useState(
    initialState.mySocialSupportPlan
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      myConnections1,
      myConnections2,
      myConnections3,
      myConnections4,
      myConnections5,
      myConnections6,
      myConnections7,
      myConnections8,
      whoTalkToAboutFeelings,
      whoHelpWithChores,
      whoLikeToPlayWith,
      whoSpendMoreTimeWith,
      mySocialSupportPlan,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  const connectionsRow = (
    val1: string,
    callback1: Function,
    val2: string,
    callback2: Function
  ) => {
    return (
      <>
        <div className="fieldrows flex flex-row">
          {connection(val1, callback1)}
          &nbsp;&nbsp;
          {connection(val2, callback2)}
        </div>
      </>
    );
  };

  const connection = (val: string, callback: Function) => {
    return (
      <EE.QuestionLabel>
        <EE.TextInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            callback(e.target.value)
          }
          value={val}
        ></EE.TextInput>
      </EE.QuestionLabel>
    );
  };

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.HeadingCenterBlack>My Social Map</EE.HeadingCenterBlack>

          <br />
          <br />

          <div className="flex justify-evenly">
            <div className="social-map-graphic hidden md:block">
              <Image src={SocialConnectionsIcon} />
            </div>

            <div className="">
              <div className="">
                <EE.Bold>My Connections:</EE.Bold>
              </div>

              {connectionsRow(
                myConnections1,
                setMyConnections1,
                myConnections2,
                setMyConnections2
              )}

              {connectionsRow(
                myConnections3,
                setMyConnections3,
                myConnections4,
                setMyConnections4
              )}

              {connectionsRow(
                myConnections5,
                setMyConnections5,
                myConnections6,
                setMyConnections6
              )}

              {connectionsRow(
                myConnections7,
                setMyConnections7,
                myConnections8,
                setMyConnections8
              )}
            </div>
          </div>

          {EE.TextInputPromptSingle(
            "Who I can talk to about my feelings:",
            whoTalkToAboutFeelings,
            setWhoTalkToAboutFeelings
          )}

          {EE.TextInputPromptSingle(
            "Who I go to when I need help with chores:",
            whoHelpWithChores,
            setWhoHelpWithChores
          )}

          {EE.TextInputPromptSingle(
            "Who I like playing with:",
            whoLikeToPlayWith,
            setWhoLikeToPlayWith
          )}

          {EE.TextInputPromptSingle(
            "Who I want to spend more time with:",
            whoSpendMoreTimeWith,
            setWhoSpendMoreTimeWith
          )}

          {EE.TextInputPromptSingle(
            "My social support plan:",
            mySocialSupportPlan,
            setMySocialSupportPlan
          )}

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default MySocialMapKidsScreen;
