import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";

const InputWithText = (props) => {
  const { value, setValue, label, ...rest } = props;

  return (
    <div className="mt-6">
      <label className="mb-2" htmlFor={rest.id}>
        {label}
      </label>
      <p>
        <EE.TextInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValue(e.target.value)
          }
          autoComplete="off"
          value={value}
          {...rest}
        ></EE.TextInput>
      </p>
    </div>
  );
};

export default InputWithText;
