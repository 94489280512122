import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.initialTitle = document.title;
    this.observer = new MutationObserver((mutations) => {
      if (this.initialTitle !== mutations[0].target.textContent) {
        this.element.hidden = false;
      }
    });

    this.observer.observe(document.querySelector("title"), {
      subtree: true,
      characterData: true,
      childList: true,
    });
  }

  disconnect() {
    this.observer.disconnect();
  }
}
