import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const ShowingYouCareScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    showFamilyICare: "",
    showFriendICare: "",
    showTeacherICare: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [showFamilyICare, setShowFamilyICare] = React.useState(
    initialState.showFamilyICare
  );
  const [showFriendICare, setShowFriendICare] = React.useState(
    initialState.showFriendICare
  );
  const [showTeacherICare, setShowTeacherICare] = React.useState(
    initialState.showTeacherICare
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      showFamilyICare,
      showFriendICare,
      showTeacherICare,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Showing You Care</EE.Heading>
          <EE.HR />
          <EE.Paragraph className="my-4">
            There are so many different ways we can show a friend, family
            member, or teacher, that we care about them.
          </EE.Paragraph>
          <EE.Paragraph className="my-4">
            For example, we can say kind words like: “Thanks for sharing your
            crayons when I forgot mine at home.” We can also give gifts, like
            drawings or homemade cookies, to those we care about. We can spend
            special time with them and do an activity together, like putting a
            puzzle together or riding bikes. Or you may choose to give a hug to
            a close friend or family member, to show them you are grateful, or
            care about them.
          </EE.Paragraph>
          <EE.Paragraph className="my-4">
            We all show we care in different ways, so what are the ways in which
            you show you care? Let’s talk about it!
          </EE.Paragraph>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>I show my teacher I care by:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setShowTeacherICare(e.target.value)
              }
              value={showTeacherICare}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>I show someone in my family I care by:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setShowFamilyICare(e.target.value)
              }
              value={showFamilyICare}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>I show my friend I care by:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setShowFriendICare(e.target.value)
              }
              value={showFriendICare}
            />
          </EE.QuestionLabel>

          <EE.Subheading>Caregivers, how do you show you care?</EE.Subheading>

          <ul>
            <EE.PaddedListItem>
              Reflect on how you show you care to other family members or those
              in your community. How are you modeling to your child that you
              care about those individuals?
            </EE.PaddedListItem>
            <EE.PaddedListItem>
              Children often get told to say “thank you,” or give a hug after
              spending time with someone they love. Are the caregivers in your
              child’s life modeling the same behaviors towards the child (i.e.
              saying “thanks for helping your brother find his soccer shoes!”)
              or is it more of a command (i.e. “help your brother find his
              soccer shoes!”).
            </EE.PaddedListItem>
            <EE.PaddedListItem>
              Every child shows affection differently and it may change over
              time. Reflect on the completed exercise to see what your child
              wrote. When discussing how your child would like to show they care
              about someone, encourage choices as their preferences for
              expressing gratitude may change as they grow, “should we make
              cookies for grandma’s birthday or do you want to draw a picture
              for her?”
            </EE.PaddedListItem>
          </ul>

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default ShowingYouCareScreens;
