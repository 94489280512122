import ChildAndCaregiverPainting from "@js/images/exercises/ChildAndCaregiverPainting.jpg";
import DadAndChildScooting from "@js/images/exercises/DadAndChildScooting.jpg";
import GirlJumpRoping from "@js/images/exercises/GirlJumpRoping.jpg";
import React, { useState } from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const TenThingsICanDoToFeelGood: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = { things: Array(10).fill("") };
  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [things, setThings] = useState(initialState.things);

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      things,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  const updateItem = (array, setterFn, indexToUpdate, updatedItem) => {
    const updatedArray = array.map((item, index) =>
      index === indexToUpdate ? updatedItem : item
    );

    setterFn(updatedArray);
  };

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Ten Things I Can Do To Feel Good</EE.Heading>
          <EE.HR />

          <div className="w-full">
            {things.map((val, i) => {
              return (
                <EE.LabeledInput
                  key={i}
                  data-testid={`things-${i}`}
                  label={`${i + 1}`}
                  value={val}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    updateItem(things, setThings, i, e.target.value)
                  }
                />
              );
            })}
          </div>

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default TenThingsICanDoToFeelGood;
