import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import BodyMapExercise from "./BodyMapExercise";
import { useBodyMap } from "./Context";

const Exercise = () => {
  const { patientFirstName } = useBodyMap();

  return (
    <>
      <EE.RoundedExerciseCard>
        <BodyMapExercise />
      </EE.RoundedExerciseCard>
      <div className="p-2">
        <span className="block w-full mt-4 text-center">
          Click on the body parts where {patientFirstName} experiences anxiety.
        </span>

        <EE.LinkGroup>
          <EE.ExerciseCallToActionLink to="/post-exercise">
            Next
          </EE.ExerciseCallToActionLink>
          <EE.SecondaryActionLink text="Cancel" to="/pre-exercise" />
        </EE.LinkGroup>
      </div>
    </>
  );
};

export default Exercise;
