import tailwindConfig from "@js/../../tailwind.config";
import ChildIcon from "@js/images/icons/Child";
import { motion, useMotionValue, useSpring, useTransform } from "framer-motion";
import range from "lodash/range";
import React, { useEffect, useRef } from "react";

import { useExerciseForm } from "../shared/Context";
import { useBodyMap } from "./Context";

const BodyMapExercise = (props) => {
  const { blobs, setBlobs } = useBodyMap();

  const TOTAL_DURATION = 2000;
  const MAXIMUM_RADIUS = 60;
  const INITIAL_RADIUS = 20;
  const { propsReadOnly } = props;

  const { readOnly } = useExerciseForm();

  const bodyMapRef = useRef<SVGSVGElement>(null);
  const iconRef = useRef<SVGPathElement>(null);

  const onTap = (event, tapInfo) => {
    if (navigator.vibrate) {
      navigator.vibrate(1000);
    }
    if (readOnly || propsReadOnly) {
      return;
    }
    if (event.target == iconRef.current || event.target.nodeName == "circle") {
      const domRectangle = bodyMapRef.current.getBoundingClientRect();
      const newBlob = {
        x: Math.floor(event.offsetX),
        y: Math.floor(event.offsetY),
        r: INITIAL_RADIUS,
        color: "#9EDCD0",
      };
      setBlobs(blobs.concat(newBlob));
    }
  };

  return (
    <motion.div className="flex flex-col select-none" onTap={onTap}>
      <motion.svg
        id="bodyMap"
        ref={bodyMapRef}
        className="ml-auto mr-auto text-center"
        height={320}
        width={320}
      >
        <ChildIcon
          childRef={iconRef}
          x={32}
          y={52}
          width={256}
          height={256}
          className={"cursor-pointer bg-lightBrown fill-current"}
        />
        {blobs.map((blob) => {
          return (
            <motion.circle
              key={`${blob.x}-${blob.y}-${blob.r}}`}
              cx={blob.x}
              cy={blob.y}
              r={blob.r}
              fill={blob.color}
            ></motion.circle>
          );
        })}
      </motion.svg>
      {!readOnly && !propsReadOnly && (
        <button
          className=""
          onClick={(e) => {
            e.preventDefault();
            setBlobs([]);
          }}
        >
          Clear
        </button>
      )}
    </motion.div>
  );
};

export default BodyMapExercise;
