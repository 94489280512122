import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "selected"];

  connect() {
    document.addEventListener(
      "turbo:load",
      this.scrollSelectedIntoView.bind(this)
    );
  }

  scrollSelectedIntoView() {
    if (!this.selectedTarget) return;

    this.containerTarget.scrollLeft =
      this.selectedTarget.offsetLeft -
      this.containerTarget.offsetLeft -
      this.containerTarget.offsetWidth / 2 +
      this.selectedTarget.offsetWidth / 2;
  }
}
