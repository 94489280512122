import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const BigFeelingsScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    whenImAngry: "",
    whenImSad: "",
    whenImScared: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [whenImAngry, setWhenImAngry] = React.useState(
    initialState.whenImAngry
  );
  const [whenImSad, setWhenImSad] = React.useState(initialState.whenImSad);
  const [whenImScared, setWhenImScared] = React.useState(
    initialState.whenImScared
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      whenImAngry,
      whenImSad,
      whenImScared,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>What helps ME when I feel big feelings?</EE.Heading>
          <EE.HR />
          <EE.Paragraph className="my-4">
            We all get angry, scared, and sad sometimes. No matter our age. No
            matter where we live. We all experience these three feelings.
          </EE.Paragraph>
          <EE.Paragraph className="my-4">
            “Big feelings” like angry, scared, and sad often come on strong and
            suddenly. We may want the feelings to go away as quickly as they
            started. You may also find that you have typical reactions, or
            things that you do, when you are feeling a big feeling.
          </EE.Paragraph>
          <EE.Paragraph className="my-4">
            Let’s talk about this and learn what the other person does when they
            feel: angry, scared, and sad.
          </EE.Paragraph>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>When I’m angry I...</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWhenImAngry(e.target.value)
              }
              value={whenImAngry}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>When I’m scared I...</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWhenImScared(e.target.value)
              }
              value={whenImScared}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>When I’m sad I...</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWhenImSad(e.target.value)
              }
              value={whenImSad}
            />
          </EE.QuestionLabel>

          <EE.Subheading>Caregivers...</EE.Subheading>

          <ul>
            <EE.PaddedListItem>
              As we are starting to discuss emotions and how we manage our big
              feelings, it is important to model how you feel and self-regulate
              at home to create an open, honest, and supportive environment.
            </EE.PaddedListItem>
            <EE.PaddedListItem>
              Examples include: “I’m feeling really upset because I couldn’t
              find my car keys, I’m going to take a few deep breaths before I
              pick up your brother from soccer practice. You can take some with
              me (count aloud 1...2...3...).”
            </EE.PaddedListItem>
            <EE.PaddedListItem>
              Try this out at home and encourage your family members to do the
              same! It can take some time and practice to make it become a
              habit, so be patient with yourself.
            </EE.PaddedListItem>
          </ul>

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default BigFeelingsScreens;
