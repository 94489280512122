import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useStressManagementTools } from "./Context";
import InputWithText from "./InputWithText";

const Panic = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const {
    panicThoughts,
    panicPhysicalFeelings,
    panicBehaviors,
    setPanicThoughts,
    setPanicPhysicalFeelings,
    setPanicBehaviors,
    assignedExerciseId,
    completedExercise,
  } = useStressManagementTools();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      panicThoughts,
      panicPhysicalFeelings,
      panicBehaviors,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.SubmitableForm submitExercise={submitExercise}>
      <EE.StepIndicator numSteps={numSteps} currentStep={currentStep} />

      <p className="mt-6 mb-8">
        When you feel <EE.Bold>panic...</EE.Bold>
      </p>

      <InputWithText
        label={
          <>
            ...what <EE.Bold>thoughts</EE.Bold> do you have?
          </>
        }
        id="thoughts"
        value={panicThoughts}
        setValue={setPanicThoughts}
      />

      <InputWithText
        label={
          <>
            ...what <EE.Bold>physical feelings</EE.Bold> do you have?
          </>
        }
        id="physical_feelings"
        value={panicPhysicalFeelings}
        setValue={setPanicPhysicalFeelings}
      />

      <InputWithText
        label={
          <>
            ...what <EE.Bold>behaviors</EE.Bold> do you have?
          </>
        }
        id="behaviors"
        value={panicBehaviors}
        setValue={setPanicBehaviors}
      />

      <EE.ButtonGroup>
        <EE.ExerciseCallToActionLink to={nextPage}>
          Next
        </EE.ExerciseCallToActionLink>
      </EE.ButtonGroup>
    </EE.SubmitableForm>
  );
};

export default Panic;
