import { captureMessage } from "@sentry/react";

const setHTMLForId = (id: string, html: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.innerHTML = html;
  } else {
    const message = `Replacing HTML on ${pageContext()} for element with id '#${id}' failed`;
    captureMessage(message);
  }
};

const setValueForId = (id: string, value: string) => {
  const element = document.getElementById(id) as HTMLInputElement;
  if (element) {
    element.value = value;
    // trigger any input-related effects, e.g. Stimulus handlers
    element.dispatchEvent(new Event("input"));
  } else {
    const message = `Setting value on ${pageContext()} for element with id '#${id}' failed`;
    captureMessage(message);
  }
};

const updatePagyNav = (html: string) => {
  document
    .querySelectorAll(".pagy-bootstrap-nav-wrapper")
    .forEach(function (elem) {
      elem.innerHTML = html;
    });
};

const pageContext = () => {
  const controller =
    document.getElementsByTagName("body")[0].dataset["page-context-controller"];
  const action =
    document.getElementsByTagName("body")[0].dataset["page-context-action"];
  if (controller && action) {
    return `${controller}#${action}`;
  }
};

/**
 * Replaces the contents of the element with the given id with the given html,
 * including the element itself.
 *
 * To replace the contents without replacing the element itself, use setHTMLForId.
 */
const replaceHTMLById = (id: string, html: string) => {
  const element = document.getElementById(id);
  const newElement = convertStringToHtml(html);
  if (element && newElement) {
    element.replaceWith(newElement);
  } else {
    const message = `Replacing HTML on ${pageContext()} for element with id '#${id}' failed`;
    captureMessage(message);
  }
};

const convertStringToHtml = (str: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, "text/html");
  return doc.body.firstChild;
};

const Brightline = {
  pageContext,
  updatePagyNav,
  setHTMLForId,
  setValueForId,
  replaceHTMLById,
};
export default Brightline;
