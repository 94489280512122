import React from "react";

import { ThermometerProvider } from "../shared/Context";
import ThermometerScreens from "./Screens";

const Screens = (props) => {
  return (
    <ThermometerProvider {...props}>
      <ThermometerScreens componentName={props.componentName} />
    </ThermometerProvider>
  );
};

export default Screens;
