import { Controller } from "@hotwired/stimulus";
import { currentCanonicalTimeZoneName } from "@js/helpers/datetime";

export default class extends Controller {
  static targets = ["input"];

  declare readonly inputTarget: HTMLInputElement;

  connect() {
    this.inputTarget.value = currentCanonicalTimeZoneName();
  }
}
