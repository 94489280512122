/*
Purpose: This is a fairly specialized controller that oscillates the size of a blob to mimic a breathing pattern.

Example:

<div data-controller="blob-pulser" class="relative flex justify-center items-center">
  <div class="absolute top-1/2 left-1/2" data-action="click->blob-pulser#pulse">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" fill="none" style="width: 50%" data-blob-pulser-target="blob">
      <path d="" fill="#E6F1FF"></path>
    </svg>
  </div>
</div>

*/

import { Controller } from "@hotwired/stimulus";
import Animation from "@js/helpers/animation";
import Blob from "@js/helpers/Blob/Blob";
import { ONE_SECOND } from "@js/helpers/constants";

export default class extends Controller {
  static targets = ["blob"];

  initialize() {
    this.running = false;
  }

  connect() {
    this.blobs = Blob.from(this.blobTargets);
  }

  pulse() {
    if (this.running) {
      return;
    }
    const animation = new Animation();
    this.blobTargets.forEach((blob) => {
      animation.oscillator(25, 100, 0.01, (value) => {
        const half = value / 2;
        blob.setAttribute(
          "style",
          `width:${value}%; margin-left: -${half}%; margin-top: -${half}%`
        );
      });
    });
    this.blobs.forEach((blob) => {
      blob.animate({ continuous: true, move: false, noiseStep: 0.001 });
    });
    this.running = true;
  }
}
