import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useAllAboutMe } from "./Context";

const AmazingAt = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const {
    description,
    deepDescription,
    amazingAt,
    setAmazingAt,
    assignedExerciseId,
    completedExercise,
  } = useAllAboutMe();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      description,
      deepDescription,
      amazingAt,
      setAmazingAt,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.GenericTextAreaPromptPage
      currentStep={currentStep}
      numSteps={numSteps}
      nextPage={nextPage}
      textPrompt="I am amazing at..."
      textAreaValue={amazingAt}
      showSaveForLater={false}
      setTextAreaValue={setAmazingAt}
      submitExercise={submitExercise}
      setExerciseComplete={setExerciseComplete}
    />
  );
};

export default AmazingAt;
