import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useStressManagementTools } from "./Context";

const TryItOut = (props) => {
  const {
    panicThoughts,
    panicPhysicalFeelings,
    panicBehaviors,
    worryThoughts,
    worryPhysicalFeelings,
    worryBehaviors,
    calmThoughts,
    calmPhysicalFeelings,
    calmBehaviors,
    tryItOutWho,
    tryItOutTeach,
    tryItOutResponse,
    setTryItOutWho,
    setTryItOutTeach,
    setTryItOutResponse,
    assignedExerciseId,
    completedExercise,
  } = useStressManagementTools();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      panicThoughts,
      panicPhysicalFeelings,
      panicBehaviors,
      worryThoughts,
      worryPhysicalFeelings,
      worryBehaviors,
      calmThoughts,
      calmPhysicalFeelings,
      calmBehaviors,
      tryItOutWho,
      tryItOutTeach,
      tryItOutResponse,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.SubmitableForm submitExercise={submitExercise}>
      <h2 className="mb-6 text-center">Try it out</h2>

      <p className="text-lg">
        Reach out to three people and ask what they do to de-stress. Then, ask
        if you can teach them some of the new stress management tools and
        strategies you've learned!
      </p>

      <label className="mt-8 mb-4 text-lg block" htmlFor="who_did_you_talk_to">
        Who did you talk to?
      </label>

      <EE.TextArea
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setTryItOutWho(e.target.value)
        }
        id="who_did_you_talk_to"
        autoComplete="off"
        value={tryItOutWho}
      ></EE.TextArea>

      <label
        className="mt-8 mb-4 text-lg block"
        htmlFor="what_strategy_did_you_teach_them"
      >
        What strategy did you teach them?
      </label>

      <EE.TextArea
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setTryItOutTeach(e.target.value)
        }
        id="what_strategy_did_you_teach_them"
        autoComplete="off"
        value={tryItOutTeach}
      ></EE.TextArea>

      <label
        className="mt-8 mb-4 text-lg block"
        htmlFor="what_was_their_response"
      >
        What was their response?
      </label>

      <EE.TextArea
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setTryItOutResponse(e.target.value)
        }
        id="what_was_their_response"
        autoComplete="off"
        value={tryItOutResponse}
      ></EE.TextArea>

      <EE.ButtonGroup>
        <EE.ResumableExerciseActions
          submitButtonText="I'm done"
          setExerciseComplete={setExerciseComplete}
        />
      </EE.ButtonGroup>
    </EE.SubmitableForm>
  );
};

export default TryItOut;
