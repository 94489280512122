const readableTimeSince = (initialTime: number) => {
  let currentTimeInSeconds = Date.now() / 1000;
  let timeDifferenceInSeconds = currentTimeInSeconds - initialTime;

  let seconds = timeDifferenceInSeconds;
  let minutes = seconds / 60;
  let hours = minutes / 60;
  let days = hours / 24;
  let months = days / 30;
  let years = days / 365;

  if (seconds === 0) {
    return "Just now";
  } else if (seconds < 60) {
    return timeString("second", seconds);
  } else if (minutes < 60) {
    return timeString("minute", minutes);
  } else if (hours < 24) {
    return timeString("hour", hours);
  } else if (days < 30) {
    return timeString("day", days);
  } else if (months < 12) {
    return timeString("month", months);
  } else {
    return timeString("year", years);
  }
};

const timeString = (timeType: string, timeInt: number) => {
  let pluralize = Math.floor(timeInt) === 1 ? "" : "s";

  return `${Math.floor(timeInt)} ${timeType}${pluralize} ago`;
};

export { readableTimeSince };
