import React from "react";

import { ExerciseElements as EE, ExerciseChildIcon } from "../shared/Elements";
import { useBodyMap } from "./Context";
import { Title } from "./Shared";

const Introduction = () => {
  const { patientFirstName, careLink } = useBodyMap();
  return (
    <div className="p-2">
      <div className="flex-grow-0">
        <EE.RoundedExerciseCard>
          <ExerciseChildIcon />
          <Title />
        </EE.RoundedExerciseCard>
        <div className="pt-8 text-mediumBrown">
          <ul>
            <EE.PaddedListItem>
              It’s common for children to experience physical sensations when
              they feel anxious, like feeling out of breath, having a racing
              heart, tense muscles, sweating, or feeling shaky.
            </EE.PaddedListItem>

            <EE.PaddedListItem>
              The Body Map helps children recognize these bodily sensations are
              connected with anxiety, and understand it’s both okay and common
              to have these feelings.
            </EE.PaddedListItem>

            <EE.PaddedListItem>
              The Body Map is a tool that can help {patientFirstName} to label
              each area that feels different when anxious or scared.
            </EE.PaddedListItem>
          </ul>
        </div>

        <EE.LinkGroup>
          <EE.ExerciseCallToActionLink to="/pre-exercise">
            Start
          </EE.ExerciseCallToActionLink>
          <EE.SecondaryActionLink text="Cancel" href={careLink} />
          <hr className="mt-10 mb-6" />
        </EE.LinkGroup>
      </div>
    </div>
  );
};

export default Introduction;
