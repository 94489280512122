import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["removeTopicButton", "skeleton", "hidden"];

  connect() {
    document.addEventListener("ajax:send", () => {
      this.setButtonsDisabled(true);
    });
    document.addEventListener("ajax:complete", () => {
      this.setButtonsDisabled(false);
    });
  }

  skeletize() {
    this.skeletonTargets.forEach((skeleton) => {
      this.traverseNodes(skeleton);
    });
    this.hiddenTargets.forEach((hidden) => {
      hidden.style.display = "none";
    });
  }

  setButtonsDisabled(disabled) {
    this.removeTopicButtonTargets.forEach(
      (button) => (button.disabled = disabled)
    );
  }

  /**
   * Recursively traverse all child nodes, adding skeleton UI
   * classes where appropriate (images and text only nodes).
   */
  traverseNodes(node) {
    if (node.childNodes.length === 0) return;

    node.childNodes.forEach((child) => {
      if (this.isTextNode(child) && this.hasTextContent(child)) {
        this.replaceTextWithPlaceholder(child);
      }

      if (child.nodeType === Node.ELEMENT_NODE) {
        if (child.nodeName === "IMG") {
          this.replaceImageWithPlaceholder(child);
        } else {
          this.traverseNodes(child);
        }
      }
    });
  }

  replaceTextWithPlaceholder(node) {
    this.addSkeleton(node.parentElement);
    node.parentElement.style.width = this.getNodeWidth(node);
  }

  replaceImageWithPlaceholder(node) {
    const { height, width } = node;
    node.alt = "loading";
    node.src = "";
    node.style.height = `${Math.ceil(height)}px`;
    node.style.width = `${Math.ceil(width)}px`;
    this.addSkeleton(node);
  }

  getNodeWidth(node) {
    const { width } = this.getNodeRects(node);
    return `${Math.ceil(width)}px`;
  }

  getNodeRects(node) {
    const range = document.createRange();
    range.selectNodeContents(node);
    const rects = range.getClientRects();
    if (rects.length === 0) return {};
    return rects[0];
  }

  addSkeleton(node) {
    node.classList.add("skeleton--active");
    node.setAttribute("role", "presentation");
  }

  isTextNode(node) {
    return node.nodeType === Node.TEXT_NODE;
  }

  hasTextContent(node) {
    return node.nodeValue.trim().length > 0;
  }
}
