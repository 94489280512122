import React from "react";

import { ExerciseScreenProps } from "../shared/Elements";

const MyFeelingsScaleContext = React.createContext(undefined);

const MyFeelingsScaleProvider: React.FC<ExerciseScreenProps> = (props) => {
  const defaultState = {
    feeling: "",
    feelingStrength: 3,
  };

  const { assignedExerciseId, completedExercise, careLink } = props;
  const initialState = { ...defaultState, ...completedExercise?.data };

  const [feeling, setFeeling] = React.useState(initialState.feeling);
  const [feelingStrength, setFeelingStrength] = React.useState(
    initialState.feelingStrength
  );

  const value = {
    feeling,
    feelingStrength,
    setFeeling,
    setFeelingStrength,
    assignedExerciseId,
    completedExercise,
    careLink,
  };

  return <MyFeelingsScaleContext.Provider value={value} {...props} />;
};

function useMyFeelingsScale() {
  const context = React.useContext(MyFeelingsScaleContext);
  if (!context) {
    throw new Error(
      "useMyFeelingsScale must be used within a MyFeelingsScaleProvider"
    );
  }
  return context;
}

export { MyFeelingsScaleProvider, MyFeelingsScaleContext, useMyFeelingsScale };
