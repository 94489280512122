import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["more", "expandLink", "expandable"];
  contentTypeValue?: string | null;
  isExpanded?: boolean;

  static values = {
    collapsedLabel: String,
    expandedLabel: String,
  };

  declare readonly expandableTargets: Element[];
  declare readonly expandLinkTarget: Element;
  declare readonly hasExpandLinkTarget: boolean;
  declare readonly collapsedLabelValue: string;
  declare readonly expandedLabelValue: string;

  initialize() {
    this.isExpanded = false;
    this.contentTypeValue = this.element.getAttribute(
      "data-toggle-content-type-value"
    );
    this.refreshLinkLabel();
  }

  toggle(event: MouseEvent) {
    event.preventDefault();

    this.isExpanded = !this.isExpanded;
    this.refreshLinkLabel();
    this.expandableTargets.forEach((target) =>
      target.classList.toggle("hidden")
    );
  }

  private refreshLinkLabel() {
    this.setExpandLinkLabel(this.labelText());
  }

  private labelText() {
    // Use the explicitly set labels first
    if (this.collapsedLabelValue && !this.isExpanded) {
      return this.collapsedLabelValue;
    } else if (this.expandedLabelValue && this.isExpanded) {
      return this.expandedLabelValue;
    }

    return `${this.arrowIcon()} ${this.isExpanded ? "Fewer" : "More"} ${
      this.contentTypeValue || ""
    }`;
  }

  private arrowIcon() {
    return `<span class="material-icons self-center mr-2">${
      this.isExpanded ? "arrow_back" : "arrow_forward"
    }</span>`;
  }

  private setExpandLinkLabel(label: string) {
    if (this.hasExpandLinkTarget) {
      this.expandLinkTarget.innerHTML = label;
    }
  }
}
