import Image from "@js/components/shared/Image";
import PersonA from "@js/images/exercises/PersonA.png";
import PersonB from "@js/images/exercises/PersonB.png";
import ThinkingFeelingDoingImage from "@js/images/exercises/ThinkingFeelingDoing.png";
import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const ThinkingFeelingDoing: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    whichPersonFeelsBetter: "",
    whatWasHappening: "",
    whatCouldYouHaveThoughtInstead: "",
    howMightYouHaveFeltInstead: "",
    whatCouldYouHaveDoneInstead: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };

  const [whichPersonFeelsBetter, setWhichPersonFeelsBetter] = React.useState(
    initialState.whichPersonFeelsBetter
  );
  const [whatWasHappening, setWhatWasHappening] = React.useState(
    initialState.whatWasHappening
  );
  const [whatCouldYouHaveThoughtInstead, setWhatCouldYouHaveThoughtInstead] =
    React.useState(initialState.whatCouldYouHaveThoughtInstead);
  const [howMightYouHaveFeltInstead, setHowMightYouHaveFeltInstead] =
    React.useState(initialState.howMightYouHaveFeltInstead);
  const [whatCouldYouHaveDoneInstead, setWhatCouldYouHaveDoneInstead] =
    React.useState(initialState.whatCouldYouHaveDoneInstead);

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      whichPersonFeelsBetter,
      whatWasHappening,
      whatCouldYouHaveThoughtInstead,
      howMightYouHaveFeltInstead,
      whatCouldYouHaveDoneInstead,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper className="max-w-3xl">
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Thinking-Feeling-Doing</EE.Heading>
          <EE.HR />
          <EE.Paragraph>
            Everybody feels sad, down, grumpy, or upset sometimes, but getting
            stuck in these feelings can be a problem. To get unstuck, we can
            change how we <EE.Bold>think</EE.Bold> and what we{" "}
            <EE.Bold>do</EE.Bold>, because thinking and doing have a big impact
            on how we <EE.Bold>feel</EE.Bold>.
          </EE.Paragraph>
          <EE.Subheading>Imagine a Rainy Day</EE.Subheading>
          <EE.QuestionLabel>
            Explain which person below feels better. What makes that person feel
            better?
            <EE.TextArea
              value={whichPersonFeelsBetter}
              onChange={(e) => {
                setWhichPersonFeelsBetter(e.target.value);
              }}
            />
          </EE.QuestionLabel>
          <div className="flex flex-col">
            <div className="w-full">
              <EE.Subheading>Person A</EE.Subheading>
              <Image src={PersonA} className="my-8" />
            </div>
            <div className="w-full">
              <EE.Subheading>Person B</EE.Subheading>
              <Image src={PersonB} className="my-8" />
            </div>
          </div>
          <EE.Subheading>What do you think, feel, and do?</EE.Subheading>
          <EE.Paragraph>
            What about YOU? Think of a time recently when you got stuck in a bad
            mood. What did you <EE.Bold>think</EE.Bold>, <EE.Bold>feel</EE.Bold>
            , and <EE.Bold>do</EE.Bold>? If you had different thoughts or took
            different actions, would you have felt differently?
          </EE.Paragraph>
          <Image src={ThinkingFeelingDoingImage} className="my-8" />
          <EE.QuestionLabel>
            What was happening?
            <EE.TextArea
              value={whatWasHappening}
              onChange={(e) => {
                setWhatWasHappening(e.target.value);
              }}
            />
          </EE.QuestionLabel>
          <EE.QuestionLabel>
            What could you have <EE.Bold>thought</EE.Bold> instead?
            <EE.TextArea
              value={whatCouldYouHaveThoughtInstead}
              onChange={(e) => {
                setWhatCouldYouHaveThoughtInstead(e.target.value);
              }}
            />
          </EE.QuestionLabel>
          <EE.QuestionLabel>
            How might you have <EE.Bold>felt</EE.Bold> instead?
            <EE.TextArea
              value={howMightYouHaveFeltInstead}
              onChange={(e) => {
                setHowMightYouHaveFeltInstead(e.target.value);
              }}
            />
          </EE.QuestionLabel>
          <EE.QuestionLabel>
            What could you have <EE.Bold>done</EE.Bold> instead?
            <EE.TextArea
              value={whatCouldYouHaveDoneInstead}
              onChange={(e) => {
                setWhatCouldYouHaveDoneInstead(e.target.value);
              }}
            />
          </EE.QuestionLabel>
          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default ThinkingFeelingDoing;
