import dayjs from "dayjs";
import dayjsBusinessTime from "dayjs-business-time";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
import utc from "dayjs/plugin/utc";

// Attach plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(dayjsBusinessTime);

const memberSupportHours = [{ start: "08:00:00", end: "24:00:00" }];
const businessTimes: dayjs.BusinessHoursMap = {
  sunday: null,
  monday: memberSupportHours,
  tuesday: memberSupportHours,
  wednesday: memberSupportHours,
  thursday: memberSupportHours,
  friday: memberSupportHours,
  saturday: null,
};

// Set Business Times in dayjs
dayjs.setBusinessTime(businessTimes);

const memberSupportBusinessTimeDifferenceMinutes = (initialTime: number) => {
  // Member Support times are in Eastern Time
  const now = dayjs().tz("America/New_York");
  const start = dayjs.unix(initialTime).tz("America/New_York");

  // There seems to be a bug that leads to an error if there is no elapsed business time, so short-circuiting
  if (start.nextBusinessTime().isSame(now.nextBusinessTime())) {
    return 0;
  }

  return start.businessTimeDiff(now, "minutes");
};

export const readableBusinessTimeSince = (initialTime: number) => {
  const minutes = memberSupportBusinessTimeDifferenceMinutes(initialTime);

  const hours = minutes / 60;

  if (minutes === 0) {
    return "Less than 1 minute old";
  } else if (minutes < 60) {
    return timeOldString("minute", minutes);
  } else {
    return timeOldString("hour", hours);
  }
};

const timeOldString = (timeType: string, time: number) => {
  let pluralize = Math.floor(time) === 1 ? "" : "s";

  return `${Math.floor(time)} ${timeType}${pluralize} old`;
};
