import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "canvas",
    "currentPage",
    "pageCount",
    "previousPage",
    "nextPage",
    "zoomIn",
    "zoomOut",
  ];

  static values = {
    src: String,
    currentPage: Number,
    pageCount: Number,
    zoom: Number,
  };

  pdfDocData = null;

  connect() {
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.6.82/pdf.worker.min.mjs";

    pdfjsLib
      .getDocument(this.srcValue)
      .promise.then((data) => {
        this.pdfDocData = data;

        this.renderPage();
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  showPreviousPage = () => {
    if (this.pdfDocData === null || this.currentPageValue <= 1) return;
    this.currentPageValue--;
    this.renderPage();
  };

  showNextPage = () => {
    if (
      this.pdfDocData === null ||
      this.currentPageValue >= this.pdfDocData._pdfInfo.numPages
    ) {
      return;
    }

    this.currentPageValue++;
    this.renderPage();
  };

  download() {
    window.open(this.srcValue);
  }

  updateButtonStates() {
    this.currentPageTarget.innerText = this.currentPageValue;
    this.pageCountTarget.innerText = this.pageCountValue;
    if (this.currentPageValue === this.pageCountValue) {
      this.nextPageTarget.disabled = true;
    } else {
      this.nextPageTarget.disabled = false;
    }

    if (this.currentPageValue === 1) {
      this.previousPageTarget.disabled = true;
    } else {
      this.previousPageTarget.disabled = false;
    }
  }

  renderPage = () => {
    this.pdfDocData.getPage(this.currentPageValue).then((page) => {
      const canvas = this.canvasTarget;
      const ctx = canvas.getContext("2d");
      const unscaledViewport = page.getViewport({ scale: 1 });

      const windowViewportWidth = window.innerWidth;
      const windowViewportHeight = window.innerHeight;

      this.zoomValue = Math.min(
        windowViewportWidth / unscaledViewport.width,
        windowViewportHeight / unscaledViewport.height
      );

      const scaledViewport = page.getViewport({ scale: this.zoomValue });

      canvas.height = scaledViewport.height;
      canvas.width = scaledViewport.width;

      const renderCtx = {
        canvasContext: ctx,
        viewport: scaledViewport,
      };

      this.pageCountValue = this.pdfDocData._pdfInfo.numPages;
      this.updateButtonStates();

      page.render(renderCtx);
    });
  };
}
