import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";

export default class extends Controller {
  static targets = ["expander", "expandable", "indicator"];
  static values = {
    transitionDuration: { type: Number, default: 200 },
    eventPrefix: String,
    eventData: { type: Object, default: {} },
    isExpanded: Boolean,
  };
  isExpanded?: boolean;

  declare readonly expandableTarget: HTMLElement;
  declare readonly expanderTarget: HTMLElement;
  declare readonly indicatorTarget: HTMLElement;
  declare readonly hasExpanderTarget: boolean;
  declare readonly hasExpandableTarget: boolean;
  declare readonly transitionDurationValue: number;
  declare readonly eventPrefixValue: string;
  declare readonly eventDataValue: object;
  declare readonly isExpandedValue: boolean;

  initialize() {
    this.isExpanded = this.isExpandedValue ?? false;

    if (this.hasExpanderTarget && this.hasExpandableTarget) {
      // Bind the click event on the expander element
      this.expanderTarget.addEventListener("click", this.toggle.bind(this));

      // Add Tailwind transition animation classes to the animating elements
      this.indicatorTarget.classList.add(
        "transform",
        "transition-transform",
        `duration-${this.transitionDurationValue}`
      );
      this.expandableTarget.classList.add(
        "hidden",
        "overflow-hidden",
        "max-h-0",
        "transition-height",
        `duration-${this.transitionDurationValue}`
      );
    }
  }

  connect() {
    if (this.isExpanded) {
      this.autoExpand();
    }
  }

  private toggle(event: MouseEvent) {
    event.preventDefault();

    this.isExpanded ? this.collapse() : this.expand();
  }

  // NOTE: This function is used when the expandable section is expanded
  // by the system and not by the user so we can skip firing an ahoy event.
  private autoExpand() {
    this.expand({ logEvent: false });
  }

  private expand({ logEvent = true } = {}) {
    this.expandableTarget.classList.remove("hidden");
    this.indicatorTarget.classList.add("rotate-180");
    this.expandableTarget.style.maxHeight =
      this.expandableTarget.scrollHeight + "px";
    setTimeout(() => {
      this.isExpanded = true;
    }, this.transitionDurationValue);
    if (this.eventPrefixValue && logEvent) {
      ahoy.track(this.eventPrefixValue + ":Expanded", this.getEventDetails());
    }
  }

  private collapse() {
    this.indicatorTarget.classList.remove("rotate-180");
    this.expandableTarget.style.maxHeight = "";
    setTimeout(() => {
      this.expandableTarget.classList.add("hidden");
      this.isExpanded = false;
    }, this.transitionDurationValue);
  }

  private getEventDetails() {
    return {
      page: window.location.pathname,
      ...this.eventDataValue,
    };
  }
}
