import { Controller } from "@hotwired/stimulus";
import VimeoPlayer from "@vimeo/player";
import ahoy from "ahoy.js";

export default class extends Controller {
  static targets = ["player"];
  static values = { id: { type: String, default: "unknown" } };

  initialize() {
    this.progressTracked = 0;
  }

  connect() {
    this.player = new VimeoPlayer(this.playerTarget);
    this.player.on("play", (event) =>
      this.trackEvent("VimeoVideo:Played", event)
    );
    this.player.on("pause", (event) =>
      this.trackEvent("VimeoVideo:Paused", event)
    );
    this.player.on("ended", (event) =>
      this.trackEvent("VimeoVideo:Ended", event)
    );
    this.player.on("seeked", (event) =>
      this.trackEvent("VimeoVideo:Seeked", event)
    );

    this.player.on("progress", (event) => this.trackProgress(event));
  }

  trackProgress(event) {
    const nearestQuarter = Math.floor(event.percent * 4) / 4;

    if (nearestQuarter > this.progressTracked) {
      this.progressTracked = nearestQuarter;

      this.trackEvent("VimeoVideo:Progress", {
        ...event,
        percent: nearestQuarter,
      });
    }
  }

  trackEvent(eventName, customMetadata = {}) {
    const metadata = {
      page: window.location.pathname,
      id: this.idValue,
      ...customMetadata,
    };

    return ahoy.track(eventName, metadata);
  }
}
