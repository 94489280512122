import { Controller } from "@hotwired/stimulus";

// Manually trigger HTML form validations when clicking on a link, and prevent
// that link's default action if the form is invalid.
export default class extends Controller {
  static targets = ["link"];

  connect() {
    this.linkTarget.addEventListener("click", (ev) => {
      if (!this.element.reportValidity()) {
        ev.preventDefault();
        ev.stopPropagation();
      }
    });
  }
}
