import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle"];
  static values = { language: String };

  updateRequestWithFormData(event) {
    const form = document.querySelector("#guest_registration");

    if (form) {
      event.preventDefault();
      const formData = new FormData(form);
      const url = new URL(this.toggleTarget.formAction);
      url.searchParams.set("locale", this.languageValue);
      url.searchParams.set(
        "children_count",
        formData.get("guest_access[children_count]")
      );
      url.searchParams.set("zip_code", formData.get("guest_access[zip_code]"));
      url.searchParams.set("email", formData.get("guest_access[email]"));
      url.searchParams.delete("children_age[]");
      formData.getAll("guest_access[children_age][]").forEach((age) => {
        url.searchParams.append("children_age[]", age);
      });

      window.location.href = url.href;
    }
  }
}
