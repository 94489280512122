import React from "react";

import { ExerciseElements as EE, Icons } from "../shared/Elements";
import { useFearLadder } from "./Context";
import { FearLadderHeader } from "./Shared";

const ChooseParticipantButton: React.FC<{ option: string }> = (props) => {
  const { setCompletedBy } = useFearLadder();
  const { option, children } = props;

  const onClick = (completedBy) => {
    setCompletedBy(completedBy);
  };

  return (
    <button
      className="w-full mt-2 mb-2 btn btn-secondary"
      onClick={() => {
        onClick(option);
      }}
    >
      {children || option}
    </button>
  );
};

const Introduction: React.FC = (props) => {
  const { patientFirstName, careLink } = useFearLadder();

  return (
    <>
      <EE.Wrapper>
        <FearLadderHeader />

        <EE.Paragraph className="mt-6 text-sm text-mediumBrown">
          The Fear Ladder exercise helps us find out more about{" "}
          {patientFirstName}’s specific fears or worries by constructing a
          “ladder” of anxiety-provoking situations. It also lets us know what
          types of situations might trigger anxiety, how {patientFirstName}{" "}
          reacts to anxiety, and the intensity level of these worries or fears.{" "}
        </EE.Paragraph>

        <EE.Title className="text-center">Completed by</EE.Title>
        <ChooseParticipantButton option="Caregiver" />
        <ChooseParticipantButton option="Child">
          {patientFirstName}
        </ChooseParticipantButton>
        <EE.SecondaryActionLink text="Cancel" href={careLink} />
      </EE.Wrapper>
    </>
  );
};

export default Introduction;
