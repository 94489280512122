import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useWheelOfLife } from "./Context";
import SelfWorthGraph from "./SelfWorthGraph";

const DoubleGraph = (props: any) => {
  const {
    currentSources,
    idealSources,
    assignedExerciseId,
    completedExercise,
  } = useWheelOfLife();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      currentSources,
      idealSources,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.SubmitableForm submitExercise={submitExercise}>
      <SelfWorthGraph
        headerText="You currently get your self-worth from:"
        sources={currentSources}
      />

      <SelfWorthGraph
        headerText="You ideally want to get your self-worth from:"
        sources={idealSources}
      />

      <EE.ButtonGroup>
        <EE.ResumableExerciseActions
          submitButtonText="I'm done"
          setExerciseComplete={setExerciseComplete}
        />
      </EE.ButtonGroup>
    </EE.SubmitableForm>
  );
};

export default DoubleGraph;
