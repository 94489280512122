import { splitParagraphIntoLines } from "@js/helpers/text";
import { motion } from "framer-motion";
import React from "react";

import { useThermometerColor } from "../../shared";

const ThoughtBubble: React.FC<{
  thermometerValue: number;
  text: string;
  direction: "left" | "right";
}> = ({ thermometerValue, text, direction }): JSX.Element => {
  const { thermometerColor, thermometerOpacity } =
    useThermometerColor(thermometerValue);
  let bubble1XTransform, bubble2XTransform, textXTransform;

  if (direction === "right") {
    bubble1XTransform = -18;
    bubble2XTransform = -28;
    textXTransform = -15;
  } else {
    bubble1XTransform = 30;
    bubble2XTransform = 40;
    textXTransform = 15;
  }

  const splitString = splitParagraphIntoLines(text, 25);

  return (
    <>
      <motion.ellipse
        cx="0"
        cy="0"
        rx="120"
        ry="93"
        fill={thermometerColor}
        fillOpacity={thermometerOpacity}
      />
      <motion.circle
        cx={bubble1XTransform}
        cy="110"
        r="7"
        fill={thermometerColor}
        fillOpacity={thermometerOpacity}
      />
      <motion.circle
        cx={bubble2XTransform}
        cy="127"
        r="4"
        fill={thermometerColor}
        fillOpacity={thermometerOpacity}
      />
      <g transform={`translate(${textXTransform} -10)`}>
        <text textAnchor="middle" x="0" y="0" className="text-sm">
          <tspan x="0" dy="0em">
            {splitString[0]}
          </tspan>
          <tspan x="0" dy="1.4em">
            {splitString[1]}
          </tspan>
          <tspan x="0" dy="1.4em">
            {splitString[2]}
          </tspan>
          <tspan x="0" dy="1.4em">
            {splitString[3]}
          </tspan>
          <tspan x="0" dy="1.4em">
            {splitString[4]}
          </tspan>
        </text>
      </g>
    </>
  );
};

export default ThoughtBubble;
