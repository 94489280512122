import ahoy from "ahoy.js";

import Brightline from "../helpers/dom";

window.Brightline ||= {};
window.Brightline = { ...window.Brightline, ...Brightline };

var memberComponentRequireContext = require.context("components/member", true);

var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(memberComponentRequireContext);
ReactRailsUJS.handleEvent("turbo:load", ReactRailsUJS.handleMount);
ReactRailsUJS.handleEvent("turbo:before-render", ReactRailsUJS.handleUnmount);

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:

// Fire view tracking on turbo:load so we don't miss out on $view events
// When navigation happens through turbo
document.addEventListener("turbo:load", function (event) {
  ahoy.trackView();
});
ahoy.trackClicks("a:not([data-no-track]), button, input[type=submit]");
