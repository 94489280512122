import { Controller } from "@hotwired/stimulus";

/*
  TurboToDo: Remove this controller. Once we've converted all member
  to using turbo, we can remove this controller.

  Turbolinks caches pages before navigating away. If you return
  to a cached page later, it will load its version from the cache,
  then fetch a fresh version of the page.

  There are certain elements, like flash messages and modals, that
  we want to remove before caching so they don't show up unexpectedly
  on a reload. By applying this controller, we ensure those elements
  are removed before Turbolinks caches the page.

  Turbo provides a data-turbo-cache="false" attribute you can set
  directly, but Turbolinks does not seem to support this.
*/

export default class extends Controller {
  connect() {
    this.beforeCache = this.beforeTurbolinksCache.bind(this);

    document.addEventListener("turbolinks:before-cache", this.beforeCache);
  }

  disconnect() {
    document.removeEventListener("turbolinks:before-cache", this.beforeCache);
  }

  beforeTurbolinksCache() {
    this.element.remove();
  }
}
