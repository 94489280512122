import AllAboutMeIcon from "@js/images/icons/AllAboutMe.svg";
import React from "react";
import { Route } from "react-router-dom";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import AmazingAt from "./AmazingAt";
import { AllAboutMeProvider } from "./Context";
import DeepDescription from "./DeepDescription";
import Description from "./Description";
import HereBecause from "./HereBecause";
import SumItUp from "./SumItUp";
import Summary from "./Summary";
import Surprising from "./Surprising";

const Screens: React.FC<ExerciseScreenProps> = (props) => {
  const title = "All About Me";
  const numSteps = 6;
  let currentStep = 1;

  const intro = (
    <EE.GenericExerciseIntroPage
      title={title}
      imageSrc={AllAboutMeIcon}
      imageAltText="A handheld mirror."
      introText="Everyone's got a purpose. Here's how to look for meaning in your own life."
      nextPage="/description"
    />
  );

  return (
    <AllAboutMeProvider {...props}>
      <EE.WrapperWithIntro intro={intro}>
        <EE.ExerciseNavigation title={title} />

        <Route path="/description">
          <Description
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/deep_description"
          />
        </Route>

        <Route path="/deep_description">
          <DeepDescription
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/amazing_at"
          />
        </Route>

        <Route path="/amazing_at">
          <AmazingAt
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/surprising"
          />
        </Route>

        <Route path="/surprising">
          <Surprising
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/here_because"
          />
        </Route>

        <Route path="/here_because">
          <HereBecause
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/sum_it_up"
          />
        </Route>

        <Route path="/sum_it_up">
          <SumItUp
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/summary"
          />
        </Route>

        <Route path="/summary">
          <Summary />
        </Route>
      </EE.WrapperWithIntro>
    </AllAboutMeProvider>
  );
};

export default Screens;
