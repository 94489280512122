import { Controller } from "@hotwired/stimulus";
import * as Sentry from "@sentry/react";
import ahoy from "ahoy.js";
import * as focusTrap from "focus-trap";

export default class extends Controller {
  static targets = ["modal", "focus"];
  static values = {
    name: String,
    source: String,
    displayLanguageId: String,
    disableBackgroundClick: { type: String, default: "false" },
  };

  modalTargetDisconnected(target) {
    document.body.classList.remove("overflow-y-hidden");
    if (this.trap) {
      this.trap.deactivate();
    }
  }

  modalTargetConnected(target) {
    if (target.dataset.forceOpen === "true") {
      this.show();
    }
    if (!this.isClosed) {
      this.trackEvent("MemberModal:Opened");
      document.body.classList.add("overflow-y-hidden");
      this.sendBreadcrumbToSentry("Activating focus trap");
      this.trap = focusTrap.createFocusTrap(this.modalTarget, {
        fallbackFocus: this.modalTarget,
      });
      this.trap.activate();
    }
  }

  show() {
    this.sendBreadcrumbToSentry("Opening");

    this.modalTarget.showModal();

    this.boundBackgroundClick = this.backgroundClick.bind(this);
    this.modalTarget.addEventListener("click", this.boundBackgroundClick);
    document.body.classList.add("overflow-y-hidden");
    this.focusFirstElement();
    this.dispatch("show");
    this.trackEvent("MemberModal:Opened");
  }

  hide(event) {
    if (this.isClosed) {
      return;
    }

    this.close();
    this.trackEvent("MemberModalCloseButton:Clicked");
  }

  // This should probably live in the Turbo layer, but Turbo only has a submit-end event
  // and not a submit-success event to mirror ajax:success. We'll hack it for now.
  // See https://github.com/hotwired/turbo/issues/96 for discussion.
  hideIfSuccessful(event) {
    if (event.detail.success) {
      this.hide(event);
    }
  }

  close() {
    this.sendBreadcrumbToSentry("Closing");

    this.modalTarget.close();
    this.modalTarget.removeEventListener("click", this.boundBackgroundClick);
    document.body.classList.remove("overflow-y-hidden");
    if (this.trap) {
      this.trap.deactivate();
    }
    this.dispatch("closed");
  }

  closeOtherModals() {
    // this is only being used specifically for the exit intent modal
    // when any modal opens, it closes all modals except the exit intent modal
    this.close();
  }

  backgroundClick(event) {
    const disableBackgroundClick = this.disableBackgroundClickValue === "true";

    if (
      disableBackgroundClick ||
      this.isClosed ||
      event.target !== this.modalTarget
    ) {
      return;
    } else {
      this.close();
      this.trackEvent("MemberModalBackground:Clicked");
    }
  }

  toggle() {
    this.isClosed ? this.show() : this.hide();
  }

  trackEvent(eventName) {
    // Don't track event unless name specified
    if (this.nameValue) {
      ahoy.track(eventName, this.getModalDetails());
    }
  }

  getModalDetails() {
    return {
      modalName: this.nameValue,
      source: this.sourceValue,
      page: window.location.pathname,
      displayLanguageId: this.displayLanguageIdValue,
    };
  }

  // Maybe put this somewhere else someday
  focusFirstElement() {
    const implicitFocusTarget = this.modalTarget.querySelector(
      "img, a, button, href, input, select, textarea"
    );

    const focusTarget = this.explicitFocusTarget() || implicitFocusTarget;

    if (focusTarget) {
      focusTarget.focus();
    }
  }

  explicitFocusTarget() {
    const autoFocusTarget = this.modalTarget.querySelector("[autofocus]");
    if (autoFocusTarget) {
      return autoFocusTarget;
    }
    if (this.hasFocusTarget) {
      return this.focusTarget;
    }
  }

  get isClosed() {
    return !this.modalTarget.open;
  }

  sendBreadcrumbToSentry(message) {
    const payload = {
      category: "modals",
      message: message,
      level: "info",
      data: {
        ...this.getModalDetails(),
        element: JSON.stringify(this.modalTarget.outerHTML),
      },
    };
    Sentry.addBreadcrumb(payload);
  }
}
