import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element", "count"];

  elementTargetConnected() {
    this.updateCount();
  }

  elementTargetDisconnected() {
    this.updateCount();
  }

  updateCount() {
    if (this.hasCountTarget) {
      this.countTarget.innerHTML = this.elementTargets.length;
    }
  }
}
