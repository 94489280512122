import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useAllAboutMe } from "./Context";

const Surprising = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const {
    description,
    deepDescription,
    amazingAt,
    surprising,
    setSurprising,
    assignedExerciseId,
    completedExercise,
  } = useAllAboutMe();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      description,
      deepDescription,
      amazingAt,
      surprising,
      setSurprising,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.GenericTextAreaPromptPage
      currentStep={currentStep}
      numSteps={numSteps}
      nextPage={nextPage}
      textPrompt="You'll probably be surprised and delighted by..."
      textAreaValue={surprising}
      showSaveForLater={false}
      setTextAreaValue={setSurprising}
      submitExercise={submitExercise}
      setExerciseComplete={setExerciseComplete}
    />
  );
};

export default Surprising;
