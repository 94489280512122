// The purpose of this controller is to add a class to the label of a radio button when it is checked.
// Example:
// <div data-controller="custom-radio-label">
//   <label data-custom-radio-label-target="label">
//     <input type="radio" name="radio" value="1" data-action="change->custom-radio-label#toggle">
//     Option 1
//   </label>
//   <label data-custom-radio-label-target="label">
//     <input type="radio" name="radio" value="2" data-action="change->custom-radio-label#toggle">
//     Option 2
//   </label>
// </div>
// The controller will add the classes bg-blue-100 and border-blue-700 to the label when the radio button is checked, and enable the submit button if any radio button is checked.

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["label", "submit"];

  connect() {
    this.updateCheckedClass();
    this.updateSubmitButton();
  }

  updateCheckedClass() {
    this.labelTargets.forEach((label) => {
      const radio = label.querySelector('input[type="radio"]');
      if (radio.checked) {
        label.classList.add("bg-blue-100", "border-blue-700");
      } else {
        label.classList.remove("bg-blue-100", "border-blue-700");
      }
    });
  }

  updateSubmitButton() {
    const isAnyRadioChecked = this.labelTargets.some(
      (label) => label.querySelector('input[type="radio"]').checked
    );

    if (isAnyRadioChecked && this.submitTarget.disabled) {
      this.submitTarget.disabled = false;
    }
  }

  toggle(event) {
    this.updateCheckedClass();
    this.updateSubmitButton();
  }
}
