import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  preventDefault(event) {
    event.preventDefault();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  stopKeyboardClick(event) {
    if (this.#isKeyboardClick(event)) {
      event.stopImmediatePropagation();
    }
  }

  #isKeyboardClick(event) {
    return event.type === "click" && event.clientX === 0 && event.clientY === 0;
  }
}
