import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["name", "age", "form", "fieldset"];
  declare readonly nameTargets: HTMLInputElement[];
  declare readonly ageTargets: HTMLInputElement[];
  declare readonly memberidTargets: HTMLInputElement[];
  declare readonly fieldsetTargets: HTMLElement[];
  declare readonly formTarget: HTMLInputElement;

  connect() {
    this.toggleFieldsetVisibility();
    this.toggleFieldsetAttributes();
  }

  incrementChild(e: Event) {
    e.preventDefault();

    if (this.numChildren() < this.maxChildren()) {
      this.incrementNumChildren();

      this.toggleFieldsetVisibility();
      this.toggleFieldsetAttributes();
    } else {
      alert(
        `We only support up to ${this.maxChildren()} children right now. Please contact support to add more.`
      );
    }
  }

  decrementChild(e: Event) {
    e.preventDefault();
    this.decrementNumChildren();
    this.toggleFieldsetVisibility();
    this.toggleFieldsetAttributes();
  }

  maxChildren() {
    return parseInt(this.formTarget.dataset["maxChildren"] || "3");
  }

  numChildren() {
    return parseInt(this.formTarget.dataset["numChildren"] || "1");
  }

  incrementNumChildren() {
    this.formTarget.dataset["numChildren"] = `${this.numChildren() + 1}`;
  }

  decrementNumChildren() {
    this.formTarget.dataset["numChildren"] = `${this.numChildren() - 1}`;
  }

  toggleFieldsetVisibility() {
    this.fieldsetTargets.forEach((element, index) => {
      if (index >= this.numChildren()) {
        element.classList.add("hidden");
      } else {
        element.classList.remove("hidden");
      }
    });
  }

  toggleFieldsetAttributes() {
    this.fieldsetTargets.forEach((fieldset, index) => {
      fieldset
        .querySelectorAll("input, select, duet-date-picker")
        .forEach((formElement) => {
          const element = FormElement.from(formElement);
          if (!element) return;

          const isHidden = index >= this.numChildren();
          if (isHidden) {
            element.setHiddenAttributes();
          } else {
            element.setVisibleAttributes();
          }
        });
    });
  }
}

class FormElement {
  static from(element: Element) {
    if (
      element instanceof HTMLInputElement ||
      element instanceof HTMLSelectElement
    ) {
      return new InputOrSelectElement(element);
    } else if (FormElement.isDuetDatePicker(element)) {
      return new DuetElement(element);
    }
  }

  static isDuetDatePicker(element: any): element is DuetDatePicker {
    return element.nodeName === "DUET-DATE-PICKER";
  }
}

class InputOrSelectElement {
  constructor(public element: HTMLInputElement | HTMLSelectElement) {}

  setHiddenAttributes() {
    if (this.element.required) {
      this.element.dataset["required"] = "true";
      this.element.setCustomValidity(""); // remove custom validation when element is hidden
    }
    if (this.element.name) {
      this.element.dataset["name"] = this.element.name;
      this.element.removeAttribute("name");
      this.element.removeAttribute("required");
    }
  }

  setVisibleAttributes() {
    if (this.element.dataset["name"]) {
      this.element.name = this.element.dataset["name"];
    }
    if (this.element.dataset["required"]) {
      this.element.required = true;
    }
  }
}

class DuetElement {
  constructor(public element: DuetDatePicker) {}

  setHiddenAttributes() {
    if (this.element.required) {
      this.element.dataset["required"] = "true";
    }

    if (this.element.name) {
      this.element.dataset["name"] = this.element.name;
      this.element.removeAttribute("name");
      this.element.removeAttribute("required");
    }
  }

  setVisibleAttributes() {
    if (this.element.dataset["name"]) {
      this.element.name = this.element.dataset["name"];
    }
    if (this.element.dataset["required"]) {
      this.element.required = true;
    }
  }
}

interface DuetDatePicker extends Element {
  required: boolean;
  name: string;
  dataset: Record<any, any>;
}
