/*
Purpose: This controller is intended for cases where we want to show a page
with a button to transition to another page, e.g. a "Get Started" screen
before the triage assessment.

Example:
<div data-controller="intro">
  <div data-intro-target="intro">
    <button data-action="click->intro#start">
      Get Started
    </button>
  </div>

  <div data-intro-target="content">
    Some content to show when Get Started is clicked
  </div>
</div>
*/

import { Controller } from "@hotwired/stimulus";

// TODO: We are using invisible to fix a bug around blob layouts,
// but we should figure out how to do it with hidden instead.
export default class extends Controller {
  static targets = ["intro", "content"];

  connect() {
    this.introTarget.classList.remove("hidden");
    this.contentTarget.classList.add("invisible");
  }

  start() {
    this.introTarget.classList.add("hidden");
    this.contentTarget.classList.remove("invisible");
    this.contentTarget.classList.remove("hidden");
    window.scrollTo(0, 0);
    this.dispatch("start");
  }
}
