import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "commentFormUrl",
    "commentInput",
    "commentSection",
    "feedbackForm",
    "helpfulInput",
    "helpfulSection",
    "submittedSection",
  ];
  declare readonly commentFormUrlTarget: HTMLInputElement;
  declare readonly commentInputTarget: HTMLInputElement;
  declare readonly commentSectionTarget: HTMLElement;
  declare readonly feedbackFormTarget: HTMLElement;
  declare readonly helpfulInputTarget: HTMLInputElement;
  declare readonly helpfulSectionTarget: HTMLElement;
  declare readonly submittedSectionTarget: HTMLElement;

  setUpdateUrl(event: any) {
    const [data, status, xhr] = event.detail;
    const contentFeedbackId = JSON.parse(xhr.responseText).id;

    this.commentFormUrlTarget.value = `${this.commentFormUrlTarget.value}/${contentFeedbackId}`;
  }

  submitComment() {
    Rails.ajax({
      url: this.commentFormUrlTarget.value,
      type: "patch",
      dataType: "script",
      data: this.commentData(),
    });

    this.commentSectionTarget.classList.add("hidden");
    this.submittedSectionTarget.classList.remove("hidden");
  }

  helpful() {
    this.submitForm("true");
    this.submittedSectionTarget.classList.remove("hidden");
  }

  notHelpful() {
    this.submitForm("false");
    this.commentSectionTarget.classList.remove("hidden");
  }

  private commentData() {
    return Rails.serializeElement(this.commentInputTarget, []);
  }

  private submitForm(helpful: string) {
    this.helpfulSectionTarget.classList.add("hidden");
    this.helpfulInputTarget.value = helpful;
    Rails.fire(this.feedbackFormTarget, "submit");
  }
}
