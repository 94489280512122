import isEmpty from "lodash/isEmpty";
import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useFearLadder } from "./Context";
import { FearSection, defaultFear } from "./Shared";

const Exercise: React.FC = (props) => {
  const {
    patientFirstName,
    fears,
    setFears,
    clinicianFirstName,
    careLink,
    chatLink,
    readOnly,
    graduatedFears,
    completedBy,
  } = useFearLadder();

  const addNewFear = (event) => {
    event.preventDefault();
    const tmpFears = [...fears, defaultFear];
    setFears(tmpFears);
  };

  return (
    <>
      <EE.Wrapper>
        <EE.Header>Completed by {completedBy}</EE.Header>
        {fears.map((fear, index) => (
          <FearSection
            fear={fear}
            index={index}
            key={`fear-section-${fear.name}`}
          />
        ))}
        {!readOnly && (
          <button
            className="block mt-6 mb-10 text-lg text-center text-green-700 w-full"
            onClick={(e) => {
              addNewFear(e);
            }}
          >
            + Add new fear
          </button>
        )}

        {!isEmpty(graduatedFears) && (
          <>
            <EE.Title>Graduated Fears</EE.Title>
            <ul className="list-disc">
              {graduatedFears.map((fear) => {
                return <li>{fear.name}</li>;
              })}
            </ul>
          </>
        )}
        <EE.Title className="text-center">Introduction</EE.Title>
        <EE.Paragraph>
          The Fear Ladder exercise helps us find out more about{" "}
          {patientFirstName}’s specific fears or worries by constructing a
          “ladder” of anxiety-provoking situations. It also lets us know what
          types of situations might trigger anxiety, how {patientFirstName}{" "}
          reacts to anxiety, and the intensity level of these worries or fears.{" "}
        </EE.Paragraph>

        <EE.Title className="text-center">Instructions</EE.Title>
        <EE.Paragraph>
          Each situation that {patientFirstName} identified as causing anxiety
          is a rung of the ladder, representing something that triggers worry or
          fear. Choose one day this week to fill in ratings for each of the
          items on the ladder. If possible, have {patientFirstName} complete the
          Fear Ladder on their own on the same day as you complete it, without
          sharing with each other. This will help {clinicianFirstName} to
          compare your ratings at the next session to see if there are any
          differences. If it’s too difficult to complete separately, don’t
          worry—it’s fine to do it together too!
        </EE.Paragraph>

        <EE.LinkGroup>
          <EE.ExerciseCallToActionLink to="/thoughts">
            Next
          </EE.ExerciseCallToActionLink>
          <EE.SecondaryActionLink text="Cancel" href={careLink} />
        </EE.LinkGroup>

        <EE.HaveQuestions chatLink={chatLink} />
      </EE.Wrapper>
    </>
  );
};

export default Exercise;
