import React from "react";

import { ExerciseScreenProps } from "../shared/Elements";

interface ChecklistBuilderData {
  checklistTitle: string;
  setChecklistTitle: Function;
  checklistItems: ChecklistItem[];
  setChecklistItems: Function;
  exerciseContext: ExerciseContext;
}

interface ChecklistItem {
  text: string;
  checked: boolean;
}

interface CompletedExercise {
  data: object;
  id: number;
}
interface ExerciseContext {
  assignedExerciseId: string;
  completedExercise?: CompletedExercise;
  chatLink: string;
  patientFirstName: string;
  readOnly?: boolean;
}

const ChecklistBuilderContext = React.createContext<ChecklistBuilderData>(
  {} as ChecklistBuilderData
);

const ChecklistBuilderProvider: React.FC<ExerciseScreenProps> = (props) => {
  const defaultState = {
    checklistTitle: "",
    checklistItems: [
      { text: "", checked: false },
      { text: "", checked: false },
      { text: "", checked: false },
    ],
  };

  const {
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  } = props;

  const initialState = { ...defaultState, ...completedExercise?.data };

  const [checklistTitle, setChecklistTitle] = React.useState<string>(
    initialState.checklistTitle
  );
  const [checklistItems, setChecklistItems] = React.useState<ChecklistItem[]>(
    initialState.checklistItems
  );

  const value = {
    checklistTitle,
    setChecklistTitle,
    checklistItems,
    setChecklistItems,
    exerciseContext: {
      assignedExerciseId,
      chatLink,
      completedExercise,
      patientFirstName,
      readOnly,
    },
  };

  return <ChecklistBuilderContext.Provider value={value} {...props} />;
};

function useChecklistBuilder() {
  const context = React.useContext(ChecklistBuilderContext);
  if (!context) {
    throw new Error(
      "useChecklistBuilder must be used within a ChecklistBuilderProvider"
    );
  }
  return context;
}

export {
  ChecklistBuilderProvider,
  ChecklistBuilderContext,
  useChecklistBuilder,
};
