import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const MyProgramScreen: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    myProgram: "",
    myMainGoal: "",
    skill1: "",
    skill1Time: "",
    skill2: "",
    skill2Time: "",
    skill3: "",
    skill3Time: "",
    skill4: "",
    skill4Time: "",
    programLengthEstimate: "",
    parentInvolvement: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [myProgram, setMyProgram] = React.useState(initialState.myProgram);
  const [myMainGoal, setMyMainGoal] = React.useState(initialState.myMainGoal);
  const [skill1, setSkill1] = React.useState(initialState.skill1);
  const [skill1Time, setSkill1Time] = React.useState(initialState.skill1Time);
  const [skill2, setSkill2] = React.useState(initialState.skill2);
  const [skill2Time, setSkill2Time] = React.useState(initialState.skill2Time);
  const [skill3, setSkill3] = React.useState(initialState.skill3);
  const [skill3Time, setSkill3Time] = React.useState(initialState.skill3Time);
  const [skill4, setSkill4] = React.useState(initialState.skill4);
  const [skill4Time, setSkill4Time] = React.useState(initialState.skill4Time);
  const [programLengthEstimate, setProgramLengthEstimate] = React.useState(
    initialState.programLengthEstimate
  );
  const [parentInvolvement, setParentInvolvement] = React.useState(
    initialState.parentInvolvement
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      myProgram,
      myMainGoal,
      skill1,
      skill1Time,
      skill2,
      skill2Time,
      skill3,
      skill3Time,
      skill4,
      skill4Time,
      programLengthEstimate,
      parentInvolvement,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  const fifthsRow = (
    label1: string,
    placeholder1: string,
    val1: string,
    callback1: Function,
    label2: string,
    placeholder2: string,
    val2: string,
    callback2: Function
  ) => {
    return (
      <EE.QuestionLabel>
        <EE.Paragraph className="flex justify-between">
          <div className="w-3/5">
            <EE.Bold>{label1}</EE.Bold>
            <EE.TextInput
              id={`fifths-row-${label1}`}
              data-testid={`fifths-row-${label1}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                callback1(e.target.value)
              }
              value={val1}
              placeholder={placeholder1}
            />
          </div>
          &nbsp; &nbsp;
          <div className="w-2/5">
            <EE.Bold>{label2}</EE.Bold>
            <EE.TextInput
              id={`fifths-row-${label1}-${label2}`}
              data-testid={`fifths-row-${label1}-${label2}`}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                callback2(e.target.value)
              }
              value={val2}
              placeholder={placeholder2}
            />
          </div>
        </EE.Paragraph>
      </EE.QuestionLabel>
    );
  };

  const rightFifthsRow = (
    label: string,
    placeholder: string,
    val: string,
    callback: Function
  ) => {
    return (
      <EE.QuestionLabel>
        <EE.Paragraph className="flex justify-between">
          <div className="w-3/5">&nbsp;</div>
          &nbsp; &nbsp;
          <div className="w-2/5">
            <EE.Bold>{label}</EE.Bold>
            <EE.TextInput
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                callback(e.target.value)
              }
              value={val}
              placeholder={placeholder}
            ></EE.TextInput>
          </div>
        </EE.Paragraph>
      </EE.QuestionLabel>
    );
  };

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.HeadingCenterBlack>My Program</EE.HeadingCenterBlack>

          {EE.TextInputPromptSingle("My Program", myProgram, setMyProgram)}

          {EE.TextInputPromptSingle("My Main Goal", myMainGoal, setMyMainGoal)}

          {fifthsRow(
            "Skill 1",
            "Something",
            skill1,
            setSkill1,
            "Time",
            "1 week",
            skill1Time,
            setSkill1Time
          )}

          {fifthsRow(
            "Skill 2",
            "Something",
            skill2,
            setSkill2,
            "Time",
            "1 week",
            skill2Time,
            setSkill2Time
          )}

          {fifthsRow(
            "Skill 3",
            "Something",
            skill3,
            setSkill3,
            "Time",
            "1 week",
            skill3Time,
            setSkill3Time
          )}

          {fifthsRow(
            "Skill 4",
            "Something",
            skill4,
            setSkill4,
            "Time",
            "1 week",
            skill4Time,
            setSkill4Time
          )}

          {rightFifthsRow(
            "Program Length (Estimate)",
            "4 weeks",
            programLengthEstimate,
            setProgramLengthEstimate
          )}

          {rightFifthsRow(
            "Parent Involvement",
            "30 min/week",
            parentInvolvement,
            setParentInvolvement
          )}

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default MyProgramScreen;
