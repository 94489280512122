import { Controller } from "@hotwired/stimulus";
import Brightline from "@js/helpers/dom";

const wakeUpMap = {
  "-10": "2:00 pm",
  "-9.5": "2:30 pm",
  "-9": "3:00 pm",
  "-8.5": "3:30 pm",
  "-8": "4:00 pm",
  "-7.5": "4:30 pm",
  "-7": "5:00 pm",
  "-6.5": "5:30 pm",
  "-6": "6:00 pm",
  "-5.5": "6:30 pm",
  "-5": "7:00 pm",
  "-4.5": "7:30 pm",
  "-4": "8:00 pm",
  "-3.5": "8:30 pm",
  "-3": "9:00 pm",
  "-2.5": "9:30 pm",
  "-2": "10:00 pm",
  "-1.5": "10:30 pm",
  "-1": "11:00 pm",
  "-0.5": "11:30 pm",
  "0": "12:00 am",
  "0.5": "12:30 am",
  "1": "1:00 am",
  "1.5": "1:30 am",
  "2": "2:00 am",
  "2.5": "2:30 am",
  "3": "3:00 am",
  "3.5": "3:30 am",
  "4": "4:00 am",
  "4.5": "4:30 am",
  "5": "5:00 am",
  "5.5": "5:30 am",
  "6": "6:00 am",
  "6.5": "6:30 am",
  "7": "7:00 am",
  "7.5": "7:30 am",
  "8": "8:00 am",
  "8.5": "8:30 am",
  "9": "9:00 am",
  "9.5": "9:30 am",
  "10": "10:00 am",
  "10.5": "10:30 am",
  "11": "11:00 am",
};

export default class extends Controller {
  static get targets() {
    return ["ageSliderValue", "wakeUpSliderValue"];
  }

  static get values() {
    return {
      age: {
        type: Number,
        default: 0,
      },
      wakeUp: {
        type: Number,
        default: 0,
      },
    };
  }

  declare readonly ageSliderValueTarget: { value: string };
  declare readonly wakeUpSliderValueTarget: { value: string };
  declare ageValue: number;
  declare wakeUpValue: number;

  initialize() {
    Brightline.setHTMLForId("wake-up", wakeUpMap[this.wakeUpValue]);
    this.updateReflection();
  }

  exploreRecommendation(event: any) {
    // gets target name, then updates value on reflection page with input value
    document.getElementById("placeholder").style.display = "none";
    for (let i = 1; i < 6; i++) {
      const identifier = `sleep-${i}`;
      if (i == event.target.value) {
        document.getElementById(identifier).style.display = "flex";
      } else document.getElementById(identifier).style.display = "none";
    }
  }

  updateAge(event: any) {
    Brightline.setHTMLForId("age", event.target.value.toString());
    this.ageSliderValueTarget.value = event.target.value;
    this.ageValue = event.target.value;
    this.updateReflection();
  }

  updateWakeUp(event: any) {
    const formattedWakeUpTime = wakeUpMap[event.target.value];
    Brightline.setHTMLForId("wake-up", formattedWakeUpTime);
    this.wakeUpSliderValueTarget.value = event.target.value;
    this.wakeUpValue = event.target.value;
    this.updateReflection();
  }

  private updateReflection() {
    const bedtimeArray = this.calculateBedtime(this.ageValue, this.wakeUpValue);
    // update all reflection page values with initial values if they exist
    Brightline.setHTMLForId("age-reflection", this.ageValue.toString());
    Brightline.setHTMLForId("wake-up-reflection", wakeUpMap[this.wakeUpValue]);
    Brightline.setHTMLForId(
      "bedtime-reflection",
      `${wakeUpMap[bedtimeArray.max.toString()]}-${
        wakeUpMap[bedtimeArray.min.toString()]
      }`
    );
  }

  private calculateBedtime(age, wakeUp) {
    // TODO: No question about "athletes" so at the moment nobody is in the 11-14 hrs category
    switch (true) {
      case age >= 14:
        return { min: wakeUp - 8, max: wakeUp - 10 };
      case age >= 6:
        return { min: wakeUp - 9, max: wakeUp - 11 };
      default:
        return { min: wakeUp - 10, max: wakeUp - 13 };
    }
  }
}
