import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["region", "language", "languageField", "zip", "zipLabel"];

  connect() {
    if (this.hasZipTarget) {
      this.updateFormFields();
    }

    if (this.hasLanguageTarget) {
      this.updateFormFields();
    }
  }

  updateFormFields() {
    this.updateZipLabel();
    this.displayLanguage();
  }

  isCalifornia() {
    return this.regionTarget.value.toLowerCase() === "california";
  }

  updateZipLabel() {
    if (this.isCalifornia() && !this.hasAsterisk()) {
      this.zipLabelTarget.innerText = `${this.zipLabelText()}*`;
    } else if (!this.isCalifornia() && this.hasAsterisk()) {
      this.zipLabelTarget.innerText = this.zipLabelText().slice(0, -1);
    }
  }

  displayLanguage() {
    // this check can be removed when display_language flag is removed
    if (this.hasLanguageTarget) {
      if (this.isCalifornia()) {
        this.languageTarget.classList.remove("hidden");
        this.languageFieldTarget.disabled = false;
      } else {
        this.languageTarget.classList.add("hidden");
        this.languageFieldTarget.disabled = true;
      }
    }
  }

  zipLabelText() {
    return this.zipLabelTarget.innerText.trim();
  }

  hasAsterisk() {
    return this.zipLabelText().endsWith("*");
  }
}
