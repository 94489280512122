import WeatherImages from "@js/images/weather";
import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";

const Title = () => {
  return <EE.Title>Double Bubble</EE.Title>;
};

const ImageMapping = WeatherImages.BadToGood;

export { ImageMapping, Title };
