import SelfEsteemJournalIcon from "@js/images/icons/Self-esteemJournal.svg";
import React from "react";
import { Route } from "react-router-dom";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { SelfEsteemJournalProvider } from "./Context";
import DifferentThought from "./DifferentThought";
import GoodEvent from "./GoodEvent";
import MakeTomorrowBetter from "./MakeTomorrowBetter";
import NegativeThought from "./NegativeThought";
import PositiveAction from "./PositiveAction";
import PositiveFeeling from "./PositiveFeeling";
import PositiveWords from "./PositiveWords";
import SelfCompliment from "./SelfCompliment";
import Summary from "./Summary";

const Screens: React.FC<ExerciseScreenProps> = (props) => {
  const title = "Self Esteem Journal";
  const numSteps = 8;
  let currentStep = 1;

  const intro = (
    <EE.GenericExerciseIntroPage
      imageSrc={SelfEsteemJournalIcon}
      imageAltText="The sun."
      title={title}
      introText="What makes you feel good or bad about yourself? Make a plan to keep your self-esteem steady."
      nextPage="/good_event"
    />
  );

  return (
    <SelfEsteemJournalProvider {...props}>
      <EE.WrapperWithIntro intro={intro}>
        <EE.ExerciseNavigation title={title} />

        <Route path="/good_event">
          <GoodEvent
            nextPage="/positive_words"
            currentStep={currentStep++}
            numSteps={numSteps}
          />
        </Route>

        <Route path="/positive_words">
          <PositiveWords
            nextPage="/self_compliment"
            currentStep={currentStep++}
            numSteps={numSteps}
          />
        </Route>

        <Route path="/self_compliment">
          <SelfCompliment
            nextPage="/positive_feeling"
            currentStep={currentStep++}
            numSteps={numSteps}
          />
        </Route>

        <Route path="/positive_feeling">
          <PositiveFeeling
            nextPage="/positive_action"
            currentStep={currentStep++}
            numSteps={numSteps}
          />
        </Route>

        <Route path="/positive_action">
          <PositiveAction
            nextPage="/negative_thought"
            currentStep={currentStep++}
            numSteps={numSteps}
          />
        </Route>

        <Route path="/negative_thought">
          <NegativeThought
            nextPage="/different_thought"
            currentStep={currentStep++}
            numSteps={numSteps}
          />
        </Route>

        <Route path="/different_thought">
          <DifferentThought
            nextPage="/make_tomorrow_better"
            currentStep={currentStep++}
            numSteps={numSteps}
          />
        </Route>

        <Route path="/make_tomorrow_better">
          <MakeTomorrowBetter
            nextPage="/summary"
            currentStep={currentStep++}
            numSteps={numSteps}
          />
        </Route>

        <Route path="/summary">
          <Summary />
        </Route>
      </EE.WrapperWithIntro>
    </SelfEsteemJournalProvider>
  );
};

export default Screens;
