// This controller contains JavaScript we only want to run
// in the Android app. Anything logged to the console will
// show up in the Android app's logs via Logcat.

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.logCookies();
  }

  logCookies() {
    const cookies = document.cookie.split("; ");
    cookies.forEach((cookie) => {
      console.log("[cookie]", cookie);
    });
  }
}
