import Image from "@js/components/shared/Image";
import Triangle from "@js/images/exercises/exercise-triangle-labeled.svg";
import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";

const Instructions = (props: { nextPage: string }) => {
  return (
    <>
      <p className="text-xl md:mt-12">The needs triangle</p>
      <Image className="mx-auto mt-10" src={Triangle} />

      <p className="mt-8">
        This triangle represents your needs by categorizing them from basic
        (important) needs up to fulfillment (nice to have) needs. In the next
        screen, let's think of what matters most in your life and where they fit
        in the triangle.
      </p>

      <EE.ButtonGroup>
        <EE.ExerciseCallToActionLink to={props.nextPage}>
          Next
        </EE.ExerciseCallToActionLink>
      </EE.ButtonGroup>
    </>
  );
};

export default Instructions;
