import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const SLPEvaluationSummaryScreen: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    sessionDate: "",
    therapistName: "",
    weEvaluated: "",
    takeaways: "",
    nextSteps: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [sessionDate, setSessionDate] = React.useState(
    initialState.sessionDate
  );
  const [therapistName, setTherapistName] = React.useState(
    initialState.therapistName
  );
  const [weEvaluated, setWeEvaluated] = React.useState(
    initialState.weEvaluated
  );
  const [takeaways, setTakeaways] = React.useState(initialState.takeaways);
  const [nextSteps, setNextSteps] = React.useState(initialState.nextSteps);

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      sessionDate,
      therapistName,
      weEvaluated,
      takeaways,
      nextSteps,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.HeadingCenterBlack>SLP Evaluation Summary</EE.HeadingCenterBlack>

          {EE.TextInputPromptSingle(
            "Date of Session:",
            sessionDate,
            setSessionDate
          )}

          {EE.TextInputPromptSingle(
            "Therapist Name:",
            therapistName,
            setTherapistName
          )}

          <br />
          <EE.HR />

          {EE.TextAreaPromptSingle(
            "Today, we evaluated...",
            weEvaluated,
            setWeEvaluated
          )}

          {EE.TextAreaPromptSingle(
            "Here are the most important takeaways...",
            takeaways,
            setTakeaways
          )}

          {EE.TextAreaPromptSingle(
            "Next steps are...",
            nextSteps,
            setNextSteps
          )}

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default SLPEvaluationSummaryScreen;
