import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { showUrl: String, authOptIn: Boolean };
  static targets = ["editButtonContainer", "saveButtonContainer"];

  makeEditable(event) {
    event.preventDefault();

    this.element
      .querySelectorAll("input, select, duet-date-picker")
      .forEach((el) => {
        if (this.isEditable(el)) {
          el.disabled = false;
        } else {
          el.disabled = true;
        }
      });

    this.editButtonContainerTarget.classList.add("hidden");
    this.saveButtonContainerTarget.classList.remove("hidden");
  }

  resetForm(event) {
    event.preventDefault();

    Rails.ajax({
      url: this.showUrlValue,
      type: "get",
      dataType: "script",
    });
  }

  isEditable(el) {
    switch (el.id) {
      case "member_email":
        return false;
      // case "member_auth0_multi_factor_authentication_opt_in":
      //   return this.shouldEnableAuthField();
      default:
        return true;
    }
  }

  shouldEnableAuthField() {
    let authValue = document.getElementById(
      "member_auth0_multi_factor_authentication_opt_in"
    ).value;
    let phoneValue = document.getElementById("member_phone").value;

    if (authValue === "true") return true;
    if (phoneValue.length !== 0) return true;
    if (phoneValue.length === 0 && this.authOptInValue === false) return false;

    return true;
  }
}
