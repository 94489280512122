import { Controller } from "@hotwired/stimulus";

/*
  Navigate to a path based on the currently selected option of a select field.

  Constructs the path by replacing a token in a path template string with the
  current value of the select field. The token and path template are expected to
  be defined as data attributes on the target of the Event triggering navigation.
 */
export default class extends Controller {
  static targets = ["select"];
  declare readonly selectTarget: HTMLSelectElement;

  navigate(event: Event) {
    event.preventDefault();

    const target = event.target as Element;
    const pathTemplate = target.getAttribute("data-path-template");
    const pathToken = target.getAttribute("data-path-token");
    const selectedValue = this.selectTarget.value;

    if (pathTemplate && pathToken && selectedValue) {
      location.pathname = pathTemplate.replace(pathToken, selectedValue);
    }
  }
}
