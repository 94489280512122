import React from "react";
import { Route } from "react-router-dom";

import { useThermometer } from "../shared";
import { ExerciseElements as EE } from "../shared/Elements";
import FearThermometerElements from "./elements/FearThermometer";
import FeelingsThermometerElements from "./elements/FeelingsThermometer";
import Exercise from "./Exercise";
import Introduction from "./Introduction";
import Thoughts from "./Thoughts";

const ThermometerScreens: React.FC<{
  componentName: string;
}> = (props) => {
  const { componentName } = props;
  const elementMapping = {
    FeelingsThermometer: FeelingsThermometerElements,
    FearThermometer: FearThermometerElements,
  };
  const { careLink } = useThermometer();

  const thermometerElements = elementMapping[componentName];

  return (
    <EE.Wrapper>
      <Route exact path="/">
        <Introduction {...thermometerElements} careLink={careLink} />
      </Route>
      <Route path="/exercise">
        <Exercise {...thermometerElements} />
      </Route>
      <Route path="/comments">
        <Thoughts {...thermometerElements} />
      </Route>
    </EE.Wrapper>
  );
};

export default ThermometerScreens;
