import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const DoingSomethingFunToFeelBetter: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    badDayThermometerValue: 5,
    afterFunThermometerValue: 5,
    didForFun: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [badDayThermometerValue, setBadDayThermometerValue] = React.useState(
    initialState.badDayThermometerValue
  );
  const [afterFunThermometerValue, setAfterFunThermometerValue] =
    React.useState(initialState.afterFunThermometerValue);
  const [didForFun, setDidForFun] = React.useState(initialState.didForFun);

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      badDayThermometerValue,
      afterFunThermometerValue,
      didForFun,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Doing Something Fun to Feel Better</EE.Heading>
          <EE.HR />
          <EE.Paragraph>
            Imagine you are having a very bad day. What does it look like? How
            do you feel? Log into your app and complete the{" "}
            <EE.Bold>feelings thermometer</EE.Bold> digital activity. Then
            record your answer below.
          </EE.Paragraph>
          <EE.Subheading id="after-imagine-bad-day">
            Thermometer rating after I imagine a bad day:
          </EE.Subheading>
          <EE.BasicThermometer
            value={badDayThermometerValue}
            setValue={setBadDayThermometerValue}
            ariaLabelledByForHandle="after-imagine-bad-day"
          />
          <EE.QuestionLabel>
            <EE.Subheading>What we did for fun:</EE.Subheading>
            <EE.TextInput
              value={didForFun}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDidForFun(e.target.value);
              }}
            />
          </EE.QuestionLabel>
          <EE.Paragraph className="mt-4">
            That looked like a good time! How do you feel now? Log into your app
            and complete the <EE.Bold>feelings thermometer</EE.Bold> digital
            activity. Then record your answer below.{" "}
          </EE.Paragraph>

          <EE.Subheading id="after-fun-activity">
            Thermometer rating after the fun activity:
          </EE.Subheading>
          <EE.BasicThermometer
            value={afterFunThermometerValue}
            setValue={setAfterFunThermometerValue}
            ariaLabelledByForHandle="after-fun-activity"
          />
          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default DoingSomethingFunToFeelBetter;
