import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  declare readonly blockTargets: HTMLElement[];
  declare readonly selectTarget: HTMLSelectElement;
  static targets = ["block", "select"];

  connect() {
    this.updateBlockVisibility(this.selectTarget.value);

    this.selectTarget.addEventListener(
      "change",
      this.selectTargetEventListener.bind(this)
    );
  }

  disconnect() {
    this.selectTarget.removeEventListener(
      "change",
      this.selectTargetEventListener.bind(this)
    );
  }

  updateBlockVisibility(value: string) {
    this.blockTargets.forEach((blockTarget: HTMLElement) => {
      if (this.blockTargetShouldToggleFor(blockTarget, value)) {
        blockTarget.hidden = false;
        (blockTarget as HTMLFieldSetElement).disabled = false;
      } else if (
        blockTarget.dataset.selectBlockToggleFor == "default" &&
        value === ""
      ) {
        blockTarget.hidden = false;
        (blockTarget as HTMLFieldSetElement).disabled = false;
      } else {
        blockTarget.hidden = true;
        (blockTarget as HTMLFieldSetElement).disabled = true;
      }
    });
  }

  blockTargetShouldToggleFor(blockTarget: HTMLElement, value: string) {
    if (!!blockTarget.dataset.selectBlockToggleForAny) {
      const toggleFor = JSON.parse(
        blockTarget.dataset.selectBlockToggleForAny
      ) as any[];
      return toggleFor.some((t) => t == value);
    }

    return blockTarget.dataset.selectBlockToggleFor == value;
  }

  selectTargetEventListener(event: Event) {
    this.updateBlockVisibility((event.target as HTMLSelectElement).value);
  }
}
