import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";

export default class extends Controller {
  static targets = ["datePicker", "toggleElement"];
  static values = { ahoyMetadata: Object, path: String };

  initialize() {
    this.calendarVisible = false;
  }

  toggleElementTargetConnected(toggleElement) {
    this.boundShow = this.show.bind(this);
    this.boundKeydown = this.handleKeydown.bind(this);
    this.boundKeyup = this.handleKeyup.bind(this);

    toggleElement.addEventListener("click", this.boundShow);
    toggleElement.addEventListener("keydown", this.boundKeydown);
    toggleElement.addEventListener("keyup", this.boundKeyup);
  }

  toggleElementTargetDisconnected(toggleElement) {
    if (this.boundShow) {
      toggleElement.removeEventListener("click", this.boundShow);
    }

    if (this.boundKeydown) {
      toggleElement.removeEventListener("keydown", this.boundKeydown);
    }

    if (this.boundKeyup) {
      toggleElement.removeEventListener("keyup", this.boundKeyup);
    }
  }

  // We only need a show action (currently) as we can rely on the duet-date-picker's global document:click event to
  // handle closing the date-picker calendar for us.
  show(event) {
    if (event && event.preventDefault) event.preventDefault();

    this.datePickerTarget.show();
  }

  calendarShown() {
    this.calendarVisible = true;
    this.blockPointerEvents();
  }

  calendarHidden() {
    this.calendarVisible = false;
    this.permitPointerEvents();
  }

  updateDate(event) {
    const date = event.detail.value;
    const params = Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    );
    params.date = date;
    const paramString = new URLSearchParams(params).toString();

    ahoy.track("AvailabilityDatePickerDate:Selected", {
      selected_date: date,
      ...this.ahoyMetadataValue,
    });

    if (window.Turbo === undefined) {
      Turbolinks.visit(`${window.location.pathname}?${paramString}`);
    } else {
      Turbo.visit(`${window.location.pathname}?${paramString}`);
    }
  }

  updateDateAjax(event) {
    const date = event.detail.value;
    const params = Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    );
    params.date = date;
    const paramString = new URLSearchParams(params).toString();

    Rails.ajax({
      type: "GET",
      url: `${this.pathValue || window.location.pathname}?${paramString}`,
    });
  }

  handleKeydown(event) {
    if (event.code === "Space" || event.code === "Enter") {
      event.preventDefault();
    }
  }

  handleKeyup(event) {
    if (event.code === "Space" || event.code === "Enter") {
      this.show();
    }
  }

  // We prevent our toggleElement from receiving any pointer (click) events once the calendar is open. This allows the
  // document:click event in the duet-date-picker to close the calendar without us immediately reopening it in the case
  // that the user clicked on the toggleElement to close the calendar.
  blockPointerEvents() {
    this.toggleElementTarget.classList.add("pointer-events-none");
  }

  // Once the calendar is closed again, we re-enable pointer (click) events on our toggleElement.
  permitPointerEvents() {
    this.toggleElementTarget.classList.remove("pointer-events-none");
  }
}
