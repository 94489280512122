import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["types", "forms", "members"];
  declare readonly typesTarget: HTMLSelectElement;
  declare readonly formsTarget: HTMLSelectElement;
  declare readonly membersTarget: HTMLSelectElement;
  declare readonly hasTypesTarget: boolean;

  connect() {
    if (!this.hasTypesTarget) {
      this.documentTypeSelected();
    }
  }

  documentTypeSelected() {
    const type = this.hasTypesTarget
      ? this.typesTarget.value
      : this.formsTarget.options[1].dataset.type;

    for (let i = 0; i < this.formsTarget.options.length; i++) {
      const option = this.formsTarget.options[i];

      if (option.dataset.type === type || option.dataset.type == null) {
        option.hidden = false;
      } else {
        option.hidden = true;
      }
    }

    this.formsTarget.selectedIndex = 0;
    this.formsTarget.disabled = false;
  }

  formSelected() {
    this.membersTarget.disabled = false;
  }
}
