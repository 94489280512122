import React, { useState } from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const WhatsNewScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    couldntDoBefore: Array(9).fill(""),
    canDoNow: Array(9).fill(""),
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };

  const [couldntDoBefore, setCouldntDoBefore] = useState(
    initialState.couldntDoBefore
  );
  const [canDoNow, setCanDoNow] = useState(initialState.canDoNow);

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      couldntDoBefore,
      canDoNow,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  const updateItem = (array, setterFn, indexToUpdate, updatedItem) => {
    const updatedArray = array.map((item, index) =>
      index === indexToUpdate ? updatedItem : item
    );

    setterFn(updatedArray);
  };

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <h1 className="mt-8 text-green-700">What's New</h1>
          <hr className="h-px mb-4 bg-marigold" />

          <div className="flex">
            <EE.InputGroup className="w-1/2">
              <EE.QuestionLabel className="h-12">
                What I couldn't do because of anxiety:
              </EE.QuestionLabel>
              {couldntDoBefore.map((val, i) => {
                return (
                  <EE.LabeledInput
                    key={i}
                    data-testid={`couldnt-do-before-${i}`}
                    label={`${i + 1}`}
                    value={val}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      updateItem(
                        couldntDoBefore,
                        setCouldntDoBefore,
                        i,
                        e.target.value
                      )
                    }
                  />
                );
              })}
            </EE.InputGroup>
            <EE.InputGroup className="w-1/2">
              <EE.QuestionLabel className="h-12">
                What I can do now:
              </EE.QuestionLabel>
              {canDoNow.map((val, i) => {
                return (
                  <EE.LabeledInput
                    key={i}
                    data-testid={`can-do-now-${i}`}
                    label={`${i + 1}`}
                    value={val}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      updateItem(canDoNow, setCanDoNow, i, e.target.value)
                    }
                  />
                );
              })}
            </EE.InputGroup>
          </div>

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default WhatsNewScreens;
