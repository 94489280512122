import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];
  declare readonly modalTarget: Element;

  show() {
    $(this.modalTarget).modal("show");
  }

  close() {
    $(this.modalTarget).modal("hide");
  }
}
