// import styles bundle
import "swiper/swiper-bundle.css";

import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";
import Swiper from "swiper";
import { A11y, FreeMode, Keyboard, Navigation } from "swiper/modules";

export default class extends Controller {
  static targets = ["paginator"];
  static values = {
    id: String,
    cards: Number,
    space: Number,
  };
  declare readonly paginatorTarget: HTMLElement;
  declare readonly idValue: String;
  declare readonly cardsValue: number;
  declare readonly spaceValue: number;

  connect() {
    let spaceBetween = this.spaceValue;
    let slidesPerView = this.cardsValue;
    let identifier = this.idValue;
    let breakpoints = {};

    if (this.cardsValue == 5) {
      breakpoints = {
        // when window width is >= 768px (Tailwind medium breakpoint)
        768: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
        // when window width is >= 1024px (Tailwind large breakpoint)
        1024: {
          slidesPerView: 5,
          slidesPerGroup: 5,
        },
      };
      slidesPerView = 2;
    } else if (this.cardsValue == 4) {
      breakpoints = {
        // when window width is >= 768px (Tailwind medium breakpoint)
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        // when window width is >= 1024px (Tailwind large breakpoint)
        1024: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
      };
      slidesPerView = 2;
    } else if (this.cardsValue == 3) {
      breakpoints = {
        // when window width is >= 1024px (Tailwind large breakpoint)
        1024: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
      };
      slidesPerView = 2;
    }

    // https://swiperjs.com/swiper-api
    const paginator = new Swiper(this.paginatorTarget, {
      modules: [Keyboard, A11y, FreeMode, Navigation],
      slidesPerView: slidesPerView,
      slidesPerGroup: slidesPerView,
      spaceBetween: spaceBetween,
      allowTouchMove: false,
      a11y: {
        nextSlideMessage: `Scroll to next content slide for ${identifier}`,
        prevSlideMessage: `Scroll to previous content card for ${identifier}`,
        lastSlideMessage: `This is the last content card for ${identifier}`,
        firstSlideMessage: `This is the first content card for ${identifier}`,
        containerMessage: `Here is content for ${identifier}`,
        slideLabelMessage: `Card {{index}} out of {{slidesLength}} for ${identifier}`,
      },
      // Turned this off A. it was changing multiple rows at a time and B. Screen readers can still tab through elements
      keyboard: false,
      // Navigation arrows
      navigation: {
        nextEl: `.card-pagination-button-next-${identifier}`,
        prevEl: `.card-pagination-button-prev-${identifier}`,
      },
      breakpoints: breakpoints,
      preventClicks: false,
      preventClicksPropagation: false,
    });
    paginator.on("slideChange", function () {
      const properties = {
        cardCarouselIdValue: identifier,
        currentSlide: paginator.activeIndex + 1,
        slidesPerView: paginator.params.slidesPerGroup,
        totalSlides: paginator.slides.length,
        page: window.location.pathname,
      };
      ahoy.track("MemberCardCarouselArrowButton:Clicked", properties);
    });
  }
}
