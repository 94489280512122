import ChecklistBuilderIcon from "@js/images/icons/ChecklistBuilder.svg";
import React from "react";
import { Route } from "react-router-dom";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { ChecklistBuilderProvider, useChecklistBuilder } from "./Context";

const ScreensWrapper = (props: ExerciseScreenProps) => {
  return (
    <ChecklistBuilderProvider {...props}>
      <Screens {...props} />
    </ChecklistBuilderProvider>
  );
};

const Screens: React.FC<ExerciseScreenProps> = (props) => {
  const exerciseTitle = "Checklist builder";
  const { preview } = props;

  const {
    checklistTitle,
    setChecklistTitle,
    checklistItems,
    setChecklistItems,
    exerciseContext,
  } = useChecklistBuilder();

  const { assignedExerciseId, completedExercise, readOnly } = exerciseContext;

  const { submitExercise, setExerciseComplete, isSaving } = useSubmitExercise(
    {
      checklistTitle,
      setChecklistTitle,
      checklistItems,
      setChecklistItems,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  const intro = (
    <EE.GenericExerciseIntroPage
      title={exerciseTitle}
      imageSrc={ChecklistBuilderIcon}
      imageAltText="A clipboard with a checklist and some items crossed completed."
      introText="Make small changes to support your child at home."
      nextPage="/description"
    />
  );

  const setTextFor = (index: number, text: string) => {
    const newChecklistItems = [...checklistItems];
    newChecklistItems[index].text = text;
    setChecklistItems(newChecklistItems);
  };

  const addAnotherItem = () => {
    setChecklistItems(checklistItems.concat({ text: "", checked: false }));
  };

  const handleCheck = (index: number) => {
    const newChecklistItems = [...checklistItems];
    newChecklistItems[index].checked = !newChecklistItems[index].checked;
    setChecklistItems(newChecklistItems);
  };

  // TODO: There is definitely a bunch of cleanup to do in the components
  // below, especially around the action buttons on each page.
  return (
    <EE.WrapperWithIntro intro={intro}>
      <EE.ExerciseNavigation title={exerciseTitle} />

      <Route path="/description">
        <EE.StepIndicator currentStep={1} numSteps={3} />
        <p>
          Some routines, habits, and simple tweaks to your living space can help
          you create more learning and communication opportunities with your
          child. Create a personalized checklist of them here.
        </p>

        <EE.ButtonGroup>
          <EE.ExerciseCallToActionLink to={"/build_checklist"}>
            Next
          </EE.ExerciseCallToActionLink>
        </EE.ButtonGroup>
      </Route>

      <Route path="/build_checklist">
        <EE.StepIndicator currentStep={2} numSteps={3} />
        <h3>Create your own checklist:</h3>

        <div className="mt-4">
          <label htmlFor="checklist-title">Title</label>

          <input
            type="text"
            id="checklist-title"
            className="w-full p-2 rounded-md disabled:opacity-50"
            disabled={readOnly}
            value={checklistTitle}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setChecklistTitle(e.target.value);
            }}
          />
        </div>

        {checklistItems.map(({ text }, index) => {
          return (
            <div key={index} className="mt-4">
              <label htmlFor={`checklist-item-${index}`}>Checklist item</label>

              <input
                type="text"
                id={`checklist-item-${index}`}
                className="w-full p-2 rounded-md disabled:opacity-50"
                disabled={readOnly}
                value={text}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setTextFor(index, e.target.value)
                }
              />
            </div>
          );
        })}

        <button
          className="mt-4 disabled:opacity-50"
          onClick={addAnotherItem}
          disabled={readOnly}
        >
          + Add another item
        </button>

        <EE.SubmitableForm submitExercise={submitExercise}>
          <EE.ButtonGroup>
            <EE.ExerciseCallToActionLink to={"/check_items"}>
              Create my list
            </EE.ExerciseCallToActionLink>
          </EE.ButtonGroup>
        </EE.SubmitableForm>
      </Route>

      <Route path="/check_items">
        <EE.StepIndicator currentStep={3} numSteps={3} />
        <div>{checklistTitle}</div>

        {checklistItems.map(({ text, checked }, index) => {
          return (
            <label
              key={index}
              className="block mt-4"
              htmlFor={`checkbox-${index}`}
            >
              <input
                type="checkbox"
                id={`checkbox-${index}`}
                className="disabled:opacity-50"
                checked={checked}
                disabled={readOnly}
                onChange={() => handleCheck(index)}
              />
              <span className="ml-2">{text}</span>
            </label>
          );
        })}

        <EE.ActionButtons>
          <EE.SubmitableForm submitExercise={submitExercise}>
            <EE.FormSubmitButton
              text="I'm done"
              setExerciseComplete={setExerciseComplete}
              disabled={isSaving}
            />
          </EE.SubmitableForm>
        </EE.ActionButtons>
      </Route>
    </EE.WrapperWithIntro>
  );
};

export default ScreensWrapper;
