import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const FourFactors: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultSets = { positives: "", challenges: "" };

  const defaultState = {
    child: defaultSets,
    parent: defaultSets,
    lifeEvents: defaultSets,
    consequences: defaultSets,
  };
  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [childPositives, setChildPositives] = React.useState(
    initialState.child.positives
  );
  const [childChallenges, setChildChallenges] = React.useState(
    initialState.child.challenges
  );

  const [parentPositives, setParentPositives] = React.useState(
    initialState.parent.positives
  );
  const [parentChallenges, setParentChallenges] = React.useState(
    initialState.parent.challenges
  );

  const [lifeEventsPositives, setLifeEventsPositives] = React.useState(
    initialState.lifeEvents.positives
  );
  const [lifeEventsChallenges, setLifeEventsChallenges] = React.useState(
    initialState.lifeEvents.challenges
  );

  const [consequencesPositives, setConsequencesPositives] = React.useState(
    initialState.consequences.positives
  );
  const [consequencesChallenges, setConsequencesChallenges] = React.useState(
    initialState.consequences.challenges
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      child: {
        positives: childPositives,
        challenges: childChallenges,
      },
      parent: {
        positives: parentPositives,
        challenges: parentChallenges,
      },
      lifeEvents: {
        positives: lifeEventsPositives,
        challenges: lifeEventsChallenges,
      },
      consequences: {
        positives: consequencesPositives,
        challenges: consequencesChallenges,
      },
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Four Factors</EE.Heading>
          <EE.HR />

          <fieldset id="childQuestions">
            <EE.Subheading id="child">Child</EE.Subheading>
            <EE.QuestionLabel id="childPositives">
              <EE.Paragraph>Positives:</EE.Paragraph>
              <EE.TextArea
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setChildPositives(e.target.value)
                }
                value={childPositives}
                aria-labelledby="child childPositives"
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel id="childChallenges">
              <EE.Paragraph>Challenges:</EE.Paragraph>
              <EE.TextArea
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setChildChallenges(e.target.value)
                }
                value={childChallenges}
                aria-labelledby="child childChallenges"
              />
            </EE.QuestionLabel>
          </fieldset>
          <fieldset id="parentQuestions">
            <EE.Subheading id="parent">Parent</EE.Subheading>
            <EE.QuestionLabel id="parentPositives">
              <EE.Paragraph>Positives:</EE.Paragraph>
              <EE.TextArea
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setParentPositives(e.target.value)
                }
                value={parentPositives}
                aria-labelledby="parent parentPositives"
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel id="parentChallenges">
              <EE.Paragraph>Challenges:</EE.Paragraph>
              <EE.TextArea
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setParentChallenges(e.target.value)
                }
                value={parentChallenges}
                aria-labelledby="parent parentChallenges"
              />
            </EE.QuestionLabel>
          </fieldset>

          <fieldset id="consequenceQuestions">
            <EE.Subheading id="consequences">Consequences</EE.Subheading>
            <EE.QuestionLabel id="consequencesPositives">
              <EE.Paragraph>Positive Behaviors:</EE.Paragraph>
              <EE.TextArea
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setConsequencesPositives(e.target.value)
                }
                value={consequencesPositives}
                aria-labelledby="consequences consequencesPositives"
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel id="consequencesChallenges">
              <EE.Paragraph>Challenging Behaviors:</EE.Paragraph>
              <EE.TextArea
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setConsequencesChallenges(e.target.value)
                }
                value={consequencesChallenges}
                aria-labelledby="consequences consequencesChallenges"
              />
            </EE.QuestionLabel>
          </fieldset>

          <fieldset id="lifeEventQuestions">
            <EE.Subheading id="lifeEvents">Life Events</EE.Subheading>
            <EE.QuestionLabel id="lifeEventsPositives">
              <EE.Paragraph>Positive:</EE.Paragraph>
              <EE.TextArea
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLifeEventsPositives(e.target.value)
                }
                value={lifeEventsPositives}
                aria-labelledby="lifeEvents lifeEventsPositives"
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel id="lifeEventsChallenges">
              <EE.Paragraph>Challenging:</EE.Paragraph>
              <EE.TextArea
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setLifeEventsChallenges(e.target.value)
                }
                value={lifeEventsChallenges}
                aria-labelledby="lifeEvents lifeEventsChallenges"
              />
            </EE.QuestionLabel>
          </fieldset>
          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default FourFactors;
