interface CookieMap {
  [key: string]: string;
}

/**
 * Returns a hash representation of document.cookies.
 *
 * @example
 * const cookies = getCookies();
 * // returns { is_visitor_testing: "true", ... }
 *
 * @returns {CookieMap} A hash of cookie name keys and cookie values.
 */
export const getCookies = (): CookieMap => {
  const cookies: CookieMap = {};
  document.cookie
    .split(";")
    .map((cookie) => cookie.trim())
    .forEach((cookie) => {
      const [key, value] = cookie.split("=");
      if (key.length === 0) return;

      cookies[key] = value;
    });
  return cookies;
};

/**
 * Sets a cookie with given expiration date, SameSite value, and path
 *
 * @example
 * setCookie(my_cookie, "my_new_cookie_value", 1, "Strict", "/");
 * // creates a cookie called "my_cookie" with provided value, an expiration date one day in the future, SameSite set to "Strict", and path set to "/"
 *
 * @returns void
 */
export const setCookie = (
  name,
  value,
  days,
  sameSite = "Lax",
  path = "/"
): void => {
  let expiration = new Date();
  expiration.setDate(expiration.getDate() + days);
  document.cookie =
    encodeURIComponent(name) +
    "=" +
    encodeURIComponent(value) +
    (expiration ? "; expires=" + expiration.toUTCString() : "") +
    "; SameSite=" +
    sameSite +
    "; path=" +
    path;
};
