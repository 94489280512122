class Point {
  constructor(x, y) {
    this.x = x;
    this.y = y;
    this.originalX = x;
    this.originalY = y;
    this.noiseOffsetX = Math.random() * 1000;
    this.noiseOffsetY = Math.random() * 1000;
  }

  setCoordinates(x, y) {
    this.x = x;
    this.y = y;
  }
}

export default Point;
