import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";
import { useIntersection } from "stimulus-use";
import { IntersectionComposableController } from "stimulus-use/dist/types/use-intersection/intersection-controller";

/*
This controller handles some analytics, but it's a shaky implementation.
Ideally we would figure out how to get this to work with Turbo or turbolinks.
It will likely break when we switch it on, so if this stops working perhaps
we've enabled Turbo(links).

  This controller handles client-side Ahoy::Event creation.

  Attributes:

  - data-ahoy-event [string]:
      The name of the Ahoy event to be created.
  - data-ahoy-metadata [object]:
      An object of additional data to be added to the Ahoy event properties.

  Actions:

  Use the following actions to trigger the `ahoy.track(...)` call.

  - data-action="click->ahoy#track"
*/
export default class extends Controller {
  // For more information on the available options, see:
  // https://github.com/stimulus-use/stimulus-use/blob/main/docs/use-intersection.md#reference
  options = {
    threshold: 0.75,
    eventPrefix: false,
  };

  connect() {
    if (this.isIntersectionComposableController(this)) {
      useIntersection(this, this.options);
    }

    // prevent ahoy from using the default tracking method (ahoy.trackClicks())
    if (this.element instanceof HTMLElement) {
      this.element.dataset["noTrack"] = "true";
    }
  }

  // Overwrites default $click tracking and adds a few more properties
  // data-ahoy-event will add an `event` property for a descriptive name
  // data-ahoy-metadata will add arbitrary properties
  track(event: any) {
    if (event.key && event.key !== "Enter") return;

    const eventName = event.currentTarget.dataset["ahoyEvent"] || event.type;
    const customMetadata = JSON.parse(
      event.currentTarget.dataset["ahoyMetadata"] || "{}"
    );

    const metadata = {
      ...this.eventProperties(event.target),
      ...customMetadata,
      event: event.type,
    };

    ahoy.track(eventName, metadata);
  }

  private eventProperties(target: any) {
    if (!target) {
      return {};
    }
    return {
      tag: target.tagName.toLowerCase(),
      id: target.id,
      class: target.className,
      text: (target.textContent || target.innerText || target.innerHTML)
        .replace(/[\s\r\n]+/g, " ")
        .trim(),
      href: target.href,
      page: window.location.pathname,
      section: this.getClosestSection(target),
    };
  }

  // Taken from ahoy.js
  private getClosestSection(target: any) {
    for (; target && target !== document; target = target.parentNode) {
      if (target.hasAttribute("data-section")) {
        return target.getAttribute("data-section");
      }
    }
    return null;
  }

  private isIntersectionComposableController(
    variable: any
  ): variable is IntersectionComposableController {
    return variable instanceof Controller;
  }
}
