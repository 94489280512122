import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const LearningAboutAnxiety: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    redLightExample: "",
    yellowLightExample: "",
    bestWayToFixFalseAlarms: "",
    practiceIdeaOne: "",
    practiceIdeaTwo: "",
    practiceIdeaThree: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [redLightExample, setRedLightExample] = React.useState(
    initialState.redLightExample
  );
  const [yellowLightExample, setYellowLightExample] = React.useState(
    initialState.yellowLightExample
  );
  const [bestWayToFixFalseAlarms, setBestWayToFixFalseAlarms] = React.useState(
    initialState.bestWayToFixFalseAlarms
  );
  const [practiceIdeaOne, setPracticeIdeaOne] = React.useState(
    initialState.practiceIdeaOne
  );
  const [practiceIdeaTwo, setPracticeIdeaTwo] = React.useState(
    initialState.practiceIdeaTwo
  );
  const [practiceIdeaThree, setPracticeIdeaThree] = React.useState(
    initialState.practiceIdeaThree
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      redLightExample,
      yellowLightExample,
      bestWayToFixFalseAlarms,
      practiceIdeaOne,
      practiceIdeaTwo,
      practiceIdeaThree,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Learning About Anxiety (True/False Alarms)</EE.Heading>
          <EE.HR />
          <EE.Paragraph>
            You’ve learned a lot about anxiety this week. The games and
            activities on this worksheet will help you remember some of the most
            important things you’ve learned.
          </EE.Paragraph>
          <EE.Subheading>Stoplights</EE.Subheading>
          <EE.QuestionLabel>
            <EE.Paragraph>Give an example of a "red light":</EE.Paragraph>
            <EE.TextInput
              id="red-light-example"
              className="border-red-500 rounded-lg"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRedLightExample(e.target.value)
              }
              value={redLightExample}
            />
          </EE.QuestionLabel>
          <EE.QuestionLabel>
            <EE.Paragraph>Give an example of a "yellow light":</EE.Paragraph>
            <EE.TextInput
              id="yellow-light-example"
              className="border-yellow-300 rounded-lg"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setYellowLightExample(e.target.value)
              }
              value={yellowLightExample}
            />
          </EE.QuestionLabel>
          <EE.Paragraph>
            Anxiety can cause problems when we feel nervous or afraid even when
            there is no real danger around us. Some examples are below:
          </EE.Paragraph>
          <EE.Subheading>False Alarms and True Alarms</EE.Subheading>
          <div className="flex mt-8">
            <div className="items-center justify-center flex-1 text-center">
              <EE.Paragraph>
                <EE.Bold>False alarm:</EE.Bold> a dark house
              </EE.Paragraph>
              <EE.Paragraph>
                <EE.Bold>False alarm:</EE.Bold> a dog nearby
              </EE.Paragraph>
            </div>
            <div className="items-center justify-center flex-1 text-center">
              <EE.Paragraph>
                <EE.Bold>True alarm:</EE.Bold> a house on fire
              </EE.Paragraph>
              <EE.Paragraph>
                <EE.Bold>True alarm:</EE.Bold> a barking dog
              </EE.Paragraph>
            </div>
          </div>
          <EE.Subheading>Fixing False Alarms</EE.Subheading>
          <EE.Paragraph>
            What’s the best way to fix false alarms? Fill in the letters below
            to find out! (Hint: This is the best way to get over feeling nervous
            or scared, and it’s also the best way to get better at other things,
            like playing an instrument or playing a sport.)
          </EE.Paragraph>
          <div className="flex items-center p-8 text-xl">
            <EE.QuestionLabel htmlFor="best-way-to-fix-false-alarms">
              <EE.PineLetter>P</EE.PineLetter>
            </EE.QuestionLabel>
            <EE.TextInput
              placeholder="_ _ _ _ _ _ _"
              id="best-way-to-fix-false-alarms"
              className="ml-4 font-sans text-xl font-bold tracking-widest uppercase rounded-lg border-green-700 text-green-700"
              value={bestWayToFixFalseAlarms}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setBestWayToFixFalseAlarms(e.target.value)
              }
            />
          </div>
          <EE.Paragraph>
            By practicing things that make you nervous or scared, you can get
            rid of your false alarms and become braver. When you practice, you
            should start with smaller, less scary things and work up to bigger
            things.
          </EE.Paragraph>
          <EE.Paragraph className="mt-8">
            <EE.Bold>Example</EE.Bold>: Pretend you have a friend who is afraid
            to talk to their peers. Here are some things your friend could do to
            practice. If your friend keeps practicing, eventually they will be
            able to talk to others.
          </EE.Paragraph>
          <div className="p-8">
            <EE.Paragraph className="mb-4 text-xl">
              <EE.PineLetter>#1</EE.PineLetter> Smile at the other person
            </EE.Paragraph>
            <EE.Paragraph className="mb-4 text-2xl">
              <EE.PineLetter>#2</EE.PineLetter> Say hi to the other person
            </EE.Paragraph>
            <EE.Paragraph className="mb-4 text-3xl">
              <EE.PineLetter>#3</EE.PineLetter> Ask the other person's name
            </EE.Paragraph>
          </div>
          <EE.Paragraph className="mt-8">
            <EE.Bold>Now you try!​ </EE.Bold>:​ Imagine you have a friend who is
            afraid of going to the doctor's office. Make up some things your
            friend could practice to help them become brave enough to go to the
            doctor. <EE.Bold>Remember</EE.Bold>: start with smaller things and
            work up to bigger things.
          </EE.Paragraph>
          <div className="p-8">
            <div className="flex items-center text-xl">
              <EE.QuestionLabel htmlFor="practice-idea-one">
                <EE.PineLetter>#1</EE.PineLetter>
              </EE.QuestionLabel>
              <EE.TextInput
                id="practice-idea-one"
                className="ml-4 rounded-lg border-green-700"
                value={practiceIdeaOne}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPracticeIdeaOne(e.target.value)
                }
              />
            </div>
            <div className="flex items-center mt-4 text-2xl">
              <EE.QuestionLabel htmlFor="practice-idea-two">
                <EE.PineLetter>#2</EE.PineLetter>
              </EE.QuestionLabel>
              <EE.TextInput
                id="practice-idea-two"
                className="ml-4 rounded-lg border-green-700"
                value={practiceIdeaTwo}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPracticeIdeaTwo(e.target.value)
                }
              />
            </div>
            <div className="flex items-center mt-4 text-3xl">
              <EE.QuestionLabel htmlFor="practice-idea-three">
                <EE.PineLetter>#3</EE.PineLetter>
              </EE.QuestionLabel>
              <EE.TextInput
                id="practice-idea-three"
                className="ml-4 rounded-lg border-green-700"
                value={practiceIdeaThree}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPracticeIdeaThree(e.target.value)
                }
              />
            </div>
          </div>
          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default LearningAboutAnxiety;
