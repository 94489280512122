import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["image", "placeholder"];

  showImage() {
    this.placeholderTarget.classList.add("hidden");
    this.imageTarget.classList.remove("hidden");
  }
}
