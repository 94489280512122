import { Controller } from "@hotwired/stimulus";

/**
 * Select and unselect elements with this controller.
 *
 * Use data-action="click->selectable#toggleSelected" on any element which
 * should become active. When that element is clicked, values specified in
 * data-selected-classes will be toggled on, and all other elements that are
 * selectable options will have inactiveClasses toggled on.
 *
 */
export default class extends Controller {
  declare readonly optionTargets: HTMLButtonElement[];

  static targets = ["option"];

  retrieveClasses(target: HTMLElement, dataKey: string): string[] {
    let dataValue = target.dataset[dataKey];
    if (typeof dataValue === "undefined") {
      return [];
    }

    let classes = dataValue.split(" ");

    // The filtering done here protects in the case double spaces or an empty
    // data-selected-classes/data-inactive-classes (i.e, ="" / = "foo   bar")
    //
    return classes.filter((className) => {
      return className !== "";
    });
  }

  selectedClasses(target) {
    return this.retrieveClasses(target, "selectedClasses");
  }

  inactiveClasses(target) {
    return this.retrieveClasses(target, "inactiveClasses");
  }

  toggleSelected(event) {
    this.optionTargets.forEach((optionTarget) => {
      if (event.currentTarget.contains(optionTarget)) {
        this.inactiveClasses(optionTarget).forEach((c) =>
          optionTarget.classList.remove(c)
        );
        this.selectedClasses(optionTarget).forEach((c) =>
          optionTarget.classList.add(c)
        );
      } else {
        this.inactiveClasses(optionTarget).forEach((c) =>
          optionTarget.classList.add(c)
        );
        this.selectedClasses(optionTarget).forEach((c) =>
          optionTarget.classList.remove(c)
        );
      }
    });
  }
}
