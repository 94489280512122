/*
Purpose: Allows triggering of other controller actions based on broadcasting a
custom event after receiving a remote form `ajax:success` response.

Targets:
- form: This is optional if for when need to declare the controller outside the
  form.
- listener: Any dom element where you want to listen to the "saved" event that
  is broadcasted

<form data-controller="form-listener" data-remote="true">
  <div data-form-listener-target="listener" data-action="saved->other-controller#action">
  </div>
</form>

 */
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  declare readonly formTarget: HTMLElement;
  declare readonly hasFormTarget: boolean;
  declare readonly listenerTarget: HTMLElement;
  declare readonly listenerTargets: Array<HTMLElement>;

  static targets = ["form", "listener"];

  connect() {
    this.eventSource().addEventListener("ajax:success", (e) => {
      this.disptachSaved(e);
    });

    this.eventSource().addEventListener("ajax:error", (e) => {});
  }

  eventSource(): HTMLElement {
    if (this.hasFormTarget) {
      return this.formTarget;
    } else {
      return this.element as HTMLElement;
    }
  }

  disptachSaved(event) {
    this.listenerTargets.forEach((listener) => {
      let newEvent: CustomEvent = new CustomEvent("saved", {
        bubbles: true,
        detail: {
          form: this.eventSource(),
          sourceEvent: event,
        },
      });
      listener.dispatchEvent(newEvent);
    });
  }
}
