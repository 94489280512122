import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useMySelfInventory } from "./Context";

const Values = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const {
    strengths,
    values,
    setValues,
    assignedExerciseId,
    completedExercise,
  } = useMySelfInventory();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      strengths,
      values,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.GenericTextAreaPromptPage
      currentStep={currentStep}
      numSteps={numSteps}
      nextPage={nextPage}
      textPrompt="My values:"
      textAreaValue={values}
      showSaveForLater={false}
      setTextAreaValue={setValues}
      submitExercise={submitExercise}
      setExerciseComplete={setExerciseComplete}
    />
  );
};

export default Values;
