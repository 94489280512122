import { Controller } from "@hotwired/stimulus";
import { useMutation } from "stimulus-use";

export default class extends Controller {
  static targets = ["sponsor"];

  disable(event) {
    this.sponsorTarget.disabled = true;
  }
}
