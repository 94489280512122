import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["form"];
  declare readonly formTarget: HTMLFormElement;

  submit(event: Event) {
    event.preventDefault();
    if (this.formTarget.dataset["remote"] == "true") {
      Rails.fire(this.formTarget, "submit");
    } else {
      this.formTarget.submit();
    }
  }
}
