/*
Purpose: This controller is intended for cases where we want to show a temporary
"interstitial" animation / image before transitioning to the main content.

Example:
<div data-controller="interstitial" data-interstitial-delay-value="1000">
  <div data-interstitial-target="interstitial">
    Content that will show initially, then disappear after delay value
  </div>
  <div data-interstitial-target="content">
    Content that will appear after delay value
  </div>
</div>
*/

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["interstitial", "content"];
  static values = {
    delay: { type: Number, default: 3000 },
    shouldDisplay: { type: Boolean, default: true },
    historyReplaceState: String,
  };

  connect() {
    if (this.shouldDisplayValue) {
      this.interstitialTarget.classList.remove("hidden");
      this.contentTarget.classList.add("hidden");

      setTimeout(() => {
        this.interstitialTarget.classList.add("hidden");
        this.contentTarget.classList.remove("hidden");

        if (this.hasHistoryReplaceStateValue) {
          window.history.replaceState(null, "", this.historyReplaceStateValue);
        }
      }, this.delayValue);
    }
  }
}
