// No exports, just side effects to configure Sentry
import * as Sentry from "@sentry/react";
import flatMap from "lodash/flatMap";

let loaded = false;

const IGNORED_ERRORS = {
  sendbird: [
    "User update is not allowed from clients",
    "Command received no ack",
    "Connection is closed",
    "Connection is canceled",
    "Connection is required",
  ],
};

const initialize = () => {
  const sentryEnvEl = document.getElementsByName("sentry-env")[0];

  if (sentryEnvEl) {
    const ignoreErrors = flatMap(
      IGNORED_ERRORS,
      (errorMessages, _from) => errorMessages
    );

    // Sentry.init seems to modify the arguments, so be sure to clone first
    const sentryConfig = {
      ...sentryEnvEl.dataset,
      beforeSend(event, hint) {
        // removing errors tied to https://hellobrightline.atlassian.net/browse/ENT-1813
        // following sentry's recommendation here https://github.com/getsentry/sentry-javascript/issues/2514
        if (hint.originalException === "Timeout") return null;
        return event;
      },
      ignoreErrors,
    };
    Sentry.init(sentryConfig);
  } else {
    console.warn(
      "Couldn't configure Sentry - no application errors will be logged"
    );
  }
};

const setUserContext = () => {
  const sentryUserContextEl = document.getElementsByName(
    "sentry-user-context"
  )[0];

  if (sentryUserContextEl) {
    const sentryConfig = { ...sentryUserContextEl.dataset };
    Sentry.setUser(sentryConfig);
  } else {
    Sentry.setUser({});
  }
};

const setTagContext = () => {
  const sentryTagContextEl =
    document.getElementsByName("sentry-tag-context")[0];

  if (sentryTagContextEl) {
    const sentryConfig = { ...sentryTagContextEl.dataset };
    Sentry.setTags(sentryConfig);
  }
};

const setup = () => {
  if (loaded) {
    return;
  }
  initialize();
  setUserContext();
  setTagContext();
  loaded = true;
};

document.addEventListener("turbo:load", setup);
// TurboToDo: Remove this + loaded check once turbolinks is gone.
document.addEventListener("turbolinks:load", setup);
