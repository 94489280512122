import React from "react";

import { AccountProps, CoachProps } from "./DeprecatedMemberChatApp";

const OutOfOfficeBanner = (props: OutOfOfficeBannerProps) => {
  const { account, assignedCoach, backupCoach, channelId, chatType } = props;

  // should only be visible:
  // - if a coach chat
  // - if assigned coach is out of office
  // - if there is no assigned backup coach
  const visible =
    chatType === "coach" && !!assignedCoach.outOfOfficeBlock && !backupCoach;

  if (!visible) return null;

  const startDate = new Date(assignedCoach.outOfOfficeBlock.start);
  const startDateDisplay = startDate.toLocaleString("default", {
    month: "long",
    day: "numeric",
  });

  const endDate = new Date(assignedCoach.outOfOfficeBlock.end);
  const endDateDisplay =
    startDate.getMonth() === endDate.getMonth()
      ? endDate.toLocaleString("default", { day: "numeric" })
      : endDate.toLocaleString("default", { month: "long", day: "numeric" });

  const bannerText = `Your regular coach ${assignedCoach.firstName} is unavailable ${startDateDisplay} - ${endDateDisplay}. Feel free to chat with a substitute coach until ${assignedCoach.firstName} returns.`;

  const requestCoachLink = `/accounts/${account.id}/backup_coach_proposals/ask_for?sendbird_chat_channel_id=${channelId}`;

  return (
    <div className="justify-center bg-gray-100 border-b border-gray-300 grid grid-cols-12">
      <div className="col-span-12 lg:col-span-7 pl-7 lg:pl-24 pr-12 pt-7 pb-2 lg:pb-7 text-sm m-auto">
        {bannerText}
      </div>

      <div className="col-span-12 lg:col-span-5 pl-7 lg:pl-0 lg:pr-24 pb-7 pt-2 lg:pt-7 flex text-sm font-sans-medium">
        <div className="border-2 border-green-800 font-bold rounded-full bg-transparent hover:bg-green-800 text-green-800 hover:text-white text-center cursor-pointer lg:m-auto py-2 px-6">
          <a href={requestCoachLink}>Chat with a substitute coach</a>
        </div>
      </div>
    </div>
  );
};

interface OutOfOfficeBannerProps {
  account: AccountProps;
  assignedCoach: CoachProps;
  backupCoach: CoachProps;
  channelId: string;
  chatType: string;
}

export default OutOfOfficeBanner;
