import { Controller } from "@hotwired/stimulus";

/**
 * Used to indicate to screen readers which option in the select list is selected.
 */
export default class extends Controller {
  static targets = ["ariaOption"];

  connect() {
    if (!this.element.options) return;

    Array.from(this.element.options).forEach((option) =>
      option.setAttribute("aria-selected", option.selected)
    );
  }

  toggleSelected(event) {
    this.ariaOptionTargets.forEach((element) => {
      element.setAttribute("aria-selected", event.target == element);
    });
  }
}
