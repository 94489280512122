import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const IMessagesAndYouMessagesScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    scenarioOne: "",
    scenarioOneResponse: "",
    scenarioTwo: "",
    scenarioTwoResponse: "",
    scenarioThree: "",
    scenarioThreeResponse: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [scenarioOne, setScenarioOne] = React.useState(
    initialState.scenarioOne
  );
  const [scenarioOneResponse, setScenarioOneResponse] = React.useState(
    initialState.scenarioOneResponse
  );
  const [scenarioTwo, setScenarioTwo] = React.useState(
    initialState.scenarioTwo
  );
  const [scenarioTwoResponse, setScenarioTwoResponse] = React.useState(
    initialState.scenarioTwoResponse
  );
  const [scenarioThree, setScenarioThree] = React.useState(
    initialState.scenarioThree
  );
  const [scenarioThreeResponse, setScenarioThreeResponse] = React.useState(
    initialState.scenarioThreeResponse
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      scenarioOne,
      scenarioOneResponse,
      scenarioTwo,
      scenarioTwoResponse,
      scenarioThree,
      scenarioThreeResponse,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>I-Messages and You-Messages</EE.Heading>
          <EE.HR />
          <EE.Paragraph className="my-4">
            When we feel big feelings, our first reaction might be to say "
            <i>you</i> made me feel this way," or it’s "<i>your</i> fault!"
            These are examples of what we like to call You-Messages.
            You-Messages point the blame at others, rather than telling the
            person how you actually feel and what they could do differently. In
            order to do this, we need to use I-Messages to tell someone how we
            feel. I-Messages follow this structure: I felt [emotion] when
            [action/event that took place].
          </EE.Paragraph>
          <EE.Paragraph className="my-4">
            You may recall the 3 different communication styles we learned
            about: passive (like a sloth), aggressive (like a shark), and
            assertive (like an owl). As we discussed before, we should aim to
            use an owl-like communication style. An owl-like communication style
            also includes using "I-Messages" when expressing big feelings.
          </EE.Paragraph>
          <EE.Paragraph className="my-4">
            Let’s practice a few scenarios: on the playground, at home, and in
            the classroom. Think about a time when you had a disagreement with a
            peer or family member. What could you say to your communication
            partner, using an "I" message rather than a "You" message, to get
            your feelings across?
          </EE.Paragraph>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>Scenario #1:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setScenarioOne(e.target.value)
              }
              value={scenarioOne}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>You could say...</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setScenarioOneResponse(e.target.value)
              }
              value={scenarioOneResponse}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>Scenario #2:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setScenarioTwo(e.target.value)
              }
              value={scenarioTwo}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>You could say...</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setScenarioTwoResponse(e.target.value)
              }
              value={scenarioTwoResponse}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>Scenario #3:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setScenarioThree(e.target.value)
              }
              value={scenarioThree}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>You could say...</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setScenarioThreeResponse(e.target.value)
              }
              value={scenarioThreeResponse}
            />
          </EE.QuestionLabel>

          <EE.Subheading>Caregivers...</EE.Subheading>

          <EE.Paragraph className="my-4">
            While it is important to remind your child to use more "I" messages,
            rather than accusatory statements or "You" messages, throughout
            challenging situations, reflect on how you are communicating with
            your family members.
          </EE.Paragraph>
          <EE.Paragraph className="my-4">
            How frequently are you using "I" messages versus "You" messages? Are
            there times when you could also focus on increasing the number of
            "I" messages you use in a day?
          </EE.Paragraph>
          <EE.Paragraph className="my-4">
            Educate other family members on I versus You messages to help keep
            everyone accountable.
          </EE.Paragraph>

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default IMessagesAndYouMessagesScreens;
