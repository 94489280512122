import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useDoubleBubble, useExerciseParams } from "./Context";
import {
  DoubleBubbleExerciseGraphic,
  DoubleBubbleExerciseSteps,
} from "./Elements";

// TODO: Add an error boundary to top level exercise component

const Exercise = () => {
  const {
    careLink,
    initialThoughts,
    finalThoughts,
    initialThermometerValue,
    finalThermometerValue,
    patientFirstName,
  } = useDoubleBubble();

  const { nextPath, step } = useExerciseParams();

  return (
    <div className="p-4">
      <div className="flex-grow-0">
        <DoubleBubbleExerciseGraphic
          initialThought={initialThoughts}
          initialThermometerValue={initialThermometerValue}
          finalThought={finalThoughts}
          finalThermometerValue={finalThermometerValue}
        />

        <DoubleBubbleExerciseSteps step={step} />

        <div className="flex flex-col justify-center">
          <EE.ExerciseCallToActionLink to={nextPath}>
            Next
          </EE.ExerciseCallToActionLink>
          <EE.SecondaryActionLink text="Cancel" href={careLink} />
          <hr className="mt-10 mb-6" />
        </div>
        <EE.Header>Introduction</EE.Header>
        <ul>
          <EE.PaddedListItem>
            We all feel <EE.Bold>BLUE</EE.Bold> sometimes... luckily, there are
            strategies that can help like “
            <EE.Bold>Changing BLUE Thoughts</EE.Bold>”. Changing BLUE Thoughts
            focuses on how our thoughts can directly affect how we feel.
          </EE.PaddedListItem>
          <EE.PaddedListItem>
            When children experience thoughts that are overly negative or
            unrealistic, it can lead to feeling sad or down.{" "}
          </EE.PaddedListItem>
          <EE.PaddedListItem>
            By learning how to recognize these thoughts, {patientFirstName} can
            begin to understand the connection between thoughts and feelings,
            and how to change these thoughts in order to feel better.{" "}
          </EE.PaddedListItem>
        </ul>
        <EE.Header>Instructions</EE.Header>
        <ul>
          <EE.PaddedListItem>
            {patientFirstName} learned about four types of negative thoughts
            that spell the word B-L-U-E.
          </EE.PaddedListItem>
          <EE.PaddedListItem>
            <EE.DiscList>
              <EE.PaddedListItem>
                Blaming myself - assuming things are your fault
              </EE.PaddedListItem>
              <EE.PaddedListItem>
                Looking for the bad news - ignoring the good things about a
                situation and focusing only on the negative aspects
              </EE.PaddedListItem>
              <EE.PaddedListItem>
                Unhappy guessing - expecting bad things to happen{" "}
              </EE.PaddedListItem>
              <EE.PaddedListItem>
                Exaggerating - believing things are worse than they really are
              </EE.PaddedListItem>
            </EE.DiscList>
          </EE.PaddedListItem>
          <EE.PaddedListItem>
            In order to practice changing BLUE thoughts into more realistic TRUE
            thoughts, encourage {patientFirstName} to think of a situation from
            their own life when they had a BLUE thought. Start with the BLUE
            Thought (“I’ll never be good at math” or “It’s all my fault the team
            lost”, for example).
          </EE.PaddedListItem>
          <EE.PaddedListItem>
            Help {patientFirstName} be like a detective and answer questions
            like:
          </EE.PaddedListItem>
          <EE.PaddedListItem>
            <EE.DiscList>
              <EE.PaddedListItem>
                What’s the evidence that this thought is true?
              </EE.PaddedListItem>
              <EE.PaddedListItem>
                Is there another way to look at the situation?
              </EE.PaddedListItem>
              <EE.PaddedListItem>
                What would you tell a friend who had this thought?
              </EE.PaddedListItem>
              <EE.PaddedListItem>
                What if it is true - would that really be so bad?
              </EE.PaddedListItem>
            </EE.DiscList>
          </EE.PaddedListItem>
          <EE.PaddedListItem>
            Support {patientFirstName} in changing the unrealistic BLUE thought
            into a TRUE thought, and record the more realistic true thought. For
            example:
          </EE.PaddedListItem>
          <EE.PaddedListItem>
            BLUE Thought: I’ll never be good at math.
          </EE.PaddedListItem>
          <li className="text-mediumBrown">
            TRUE Thought: Math may be harder for me than other subjects, but if
            I study I will get better.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Exercise;
