import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "form"];

  get currentModal() {
    return this.modalTargets[this.modalIndex];
  }

  connect() {
    this.formTarget.addEventListener(
      "modal-step-form-valid",
      this.handleFormValid
    );
    this.formTarget.addEventListener(
      "modal-step-form-invalid",
      this.handleFormInvalid
    );
  }

  disconnect() {
    this.formTarget.removeEventListener(
      "modal-step-form-valid",
      this.handleFormValid
    );
    this.formTarget.removeEventListener(
      "modal-step-form-invalid",
      this.handleFormInvalid
    );
  }

  handleFormValid = (event) => {
    const action = event.target.getAttribute("data-next-action-function");

    if (action === "next") {
      this.next();
    } else if (action === "finish") {
      this.finish();
    }
  };

  handleFormInvalid = () => {
    return;
  };

  start() {
    this.modalIndex = 0;

    this.showDialog(this.currentModal);
  }

  validate() {
    if (this.formTarget.checkValidity()) {
      this.formTarget.dispatchEvent(new CustomEvent("modal-step-form-valid"));
    } else {
      this.formTarget.dispatchEvent(new CustomEvent("modal-step-form-invalid"));
    }
  }

  finish() {
    this.hideDialog(this.currentModal);
    this.dispatch("finished", { modalIndex: this.modalIndex });
    this.modalIndex = 0;
  }

  next() {
    if (this.modalIndex + 1 >= this.modalTargets.length) {
      return this.finish();
    }

    this.hideDialog(this.currentModal);

    this.modalIndex++;
    this.showDialog(this.currentModal);
  }

  showDialog(target) {
    this.modalsControllerFor(target).show();
  }

  hideDialog(target) {
    this.modalsControllerFor(target).hide();
  }

  modalsControllerFor(target) {
    return this.application.getControllerForElementAndIdentifier(
      target,
      "modals"
    );
  }
}
