import React, { MutableRefObject } from "react";

const ChildIcon: React.FC<{
  childRef: MutableRefObject<SVGPathElement>;
  x: number;
  y: number;
  width: number;
  height: number;
  className?: string;
}> = ({ childRef, x, y, width, height, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 1792 1792"
      x={x}
      y={y}
      className={className}
    >
      <title>A figure of a child.</title>
      <path
        ref={childRef}
        d="M1444 548l-292 292v824q0 46-33 79t-79 33-79-33-33-79v-384h-64v384q0 46-33 79t-79 33-79-33-33-79V840L348 548q-28-28-28-68t28-68q29-28 68.5-28t67.5 28l228 228h368l228-228q28-28 68-28t68 28q28 29 28 68.5t-28 67.5zm-324-164q0 93-65.5 158.5T896 608t-158.5-65.5T672 384t65.5-158.5T896 160t158.5 65.5T1120 384z"
      ></path>
    </svg>
  );
};

export default ChildIcon;
