// Can be used to prevent navigation with a modal to leave anyway
//
import { Controller } from "@hotwired/stimulus";

export default class NavigationAlertController extends Controller {
  declare readonly formTarget: HTMLElement;
  declare readonly modalTarget: Element;

  declare targetUrl: string;
  declare isNavigationAllowed: Boolean;

  static targets = ["form", "modal"];
  static values = {
    changed: { type: Boolean, default: false },
    leaveAnyway: { type: Boolean, default: false },
  };

  initialize() {
    this.isNavigationAllowed = false;
  }

  alertLeavingPage(event) {
    // If the user is navigating to a new page, the url will be in event.data.url
    this.targetUrl = event.data != null ? event.data.url : window.location.href;
    if (!this.isNavigationAllowed) {
      event.preventDefault();
      this.modalTarget.classList.remove("hidden");
      document.body.classList.add("overflow-y-hidden");
    } else {
      return event.returnValue;
    }
  }

  allowNavigation() {
    this.isNavigationAllowed = true;
  }

  hideModal() {
    this.modalTarget.classList.add("hidden");
    document.body.classList.remove("overflow-y-hidden");
  }

  leaveAnyway() {
    this.isNavigationAllowed = true;

    if (this.targetUrl) {
      window.location.href = this.targetUrl;
    }
  }
}
