import { Controller } from "@hotwired/stimulus";

// This stimulus controller uses 1 allTarget and many optionTargets.
// All targets should be HTML input elements that can be "checked".
// If the allTarget is checked, any checked optionTargets are unchecked.
// If ALL of the optionTargets are checked, they are immediately unchecked and
//   the allTarget is checked
// If ANY (but not ALL) of the optionTargets is checked, the allTarget is unchecked.
// if uncheck is set to FALSE, then the optionTargets will NOT be unchecked when all are selected.

export default class extends Controller {
  static targets = ["all", "option"];
  static values = {
    uncheck: { type: Boolean, default: true },
  };

  declare allTarget: HTMLInputElement;
  declare optionTargets: HTMLInputElement[];
  declare uncheckValue: boolean;

  connect() {
    this.allTarget.addEventListener(
      "input",
      this.allTargetEventListener.bind(this)
    );

    this.optionTargets.forEach((optionTarget) => {
      optionTarget.addEventListener(
        "input",
        this.optionTargetsEventListener.bind(this)
      );
    });
  }

  allTargetEventListener(event: Event) {
    if ((event.target as HTMLInputElement).checked) {
      this.optionTargets.forEach((optionTarget) => {
        optionTarget.checked = false;
      });
    }
  }

  optionTargetsEventListener(event: Event) {
    if (
      this.uncheckValue &&
      this.optionTargets.every((optionTarget) => optionTarget.checked)
    ) {
      this.allTarget.checked = true;
      this.optionTargets.forEach(
        (optionTarget) => (optionTarget.checked = false)
      );
    } else if ((event.target as HTMLInputElement).checked) {
      this.allTarget.checked = false;
    }
  }

  disconnect() {
    this.allTarget.removeEventListener(
      "input",
      this.allTargetEventListener.bind(this)
    );
    this.optionTargets.forEach((optionTarget) => {
      optionTarget.removeEventListener(
        "input",
        this.optionTargetsEventListener.bind(this)
      );
    });
  }
}
