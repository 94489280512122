import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String };
  static targets = ["form"];

  update() {
    Rails.ajax({
      url: this.url,
      type: "get",
      dataType: "script",
    });

    this.replaceHistory();
  }

  replaceHistory() {
    history.replaceState(null, null, this.url);
  }

  get url() {
    const baseURL = this.urlValue || window.location.pathname;
    return `${baseURL}?${this.params}`;
  }

  get params() {
    const data = new FormData(this.form);
    return new URLSearchParams(data).toString();
  }

  get form() {
    return this.element;
  }
}
