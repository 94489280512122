import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  toggleMatches({ target }) {
    console.log(target);
    const componentGroups = document.querySelectorAll(".component-group");
    const filteredGroups = Array.from(componentGroups).filter(
      (group) => !group.querySelector(".match")
    );

    if (target.checked) {
      filteredGroups.forEach((group) => (group.style.display = "none"));
    } else {
      filteredGroups.forEach((group) => (group.style.display = "block"));
    }
  }

  clear({ target }) {
    target.form.reset();
    target.form.querySelector("textarea").value = "";
    target.form.submit();
  }
}
