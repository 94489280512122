import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "container"];

  connect() {
    if (this.inputTarget.dataset.directUploadUrl) {
      addEventListener(
        "direct-upload:initialize",
        this.initializeProgressBar.bind(this)
      );
      addEventListener(
        "direct-upload:progress",
        this.updateProgress.bind(this)
      );
      addEventListener("direct-upload:error", this.logProgressError.bind(this));
      addEventListener("direct-upload:end", this.logProgressSuccess.bind(this));
    }
  }

  display(event) {
    if (event && event.target && event.target.files) {
      let file = event.target.files[0];
      this.label.innerHTML = file.name;
    } else {
      this.label.innerHTML = "Choose a validated file";
    }
  }

  get label() {
    return this.containerTarget.querySelector(
      ".form-group .custom-file .custom-file-label"
    );
  }

  initializeProgressBar(event) {
    const { target, detail } = event;
    const { id } = detail;

    // sometimes we append a ton of these progress bars, checking if it already exists ensures we don't have duplicates.
    if (this.elementForId(id)) return false;

    target.insertAdjacentHTML(
      "afterend",
      `
      <div class="progress mt-1">
        <div class="progress-bar progress-bar-striped progress-bar-animated" id="direct-upload-progress-${id}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <small>Uploading...</small>
    `
    );
  }

  updateProgress(event) {
    const { id, progress } = event.detail;
    const progressElement = this.elementForId(id);
    progressElement.style.width = `${progress}%`;
  }

  logProgressError(event) {
    const { id } = event.detail;
    this.setElementClassById(id, "bg-danger");
  }

  logProgressSuccess(event) {
    const { id } = event.detail;
    this.setElementClassById(id, "bg-success");
  }

  setElementClassById(id, klass) {
    const element = this.elementForId(id);
    element.classList.add(klass);
  }

  elementForId(id) {
    return document.getElementById(`direct-upload-progress-${id}`);
  }
}
