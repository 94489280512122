export class LiveRegionAnnouncer {
  ANNOUNCEMENT_TIMEOUT = 10000;
  observers = [];
  timer = null;

  announceChangesTo(elements) {
    elements.forEach((element) => {
      const callback = (mutationList) => {
        const message = this.mutationMessages(mutationList).join(", ");
        if (message.length > 0) {
          this.announce(message);
        }
      };
      const observer = new MutationObserver(callback);
      observer.observe(element, { attributes: true, attributeOldValue: true });
      this.observers.push(observer);
    });
  }

  mutationMessages(mutationList) {
    return [
      ...new Set(
        mutationList
          .map((mutation) => {
            return MutationMessage.from(mutation).toString();
          })
          .filter((message) => message)
      ),
    ];
  }

  announce(eventOrMessage) {
    if (typeof eventOrMessage === "string") {
      this.setLiveRegionText(eventOrMessage);
    } else {
      this.setLiveRegionText(eventOrMessage.target.validationMessage);
    }

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (this.liveRegion) this.setLiveRegionText("");
    }, this.ANNOUNCEMENT_TIMEOUT);
  }

  setLiveRegionText(text) {
    this.liveRegion.textContent = text;
  }

  get liveRegion() {
    return document.querySelector("#live-region");
  }

  ensureLiveRegionInDocument() {
    if (this.liveRegion) return;

    const liveRegion = document.createElement("div");
    liveRegion.id = "live-region";
    liveRegion.classList.add("sr-only");
    liveRegion.setAttribute("aria-live", "assertive");
    document.body.appendChild(liveRegion);
  }

  disconnect() {
    this.observers.forEach((observer) => observer.disconnect());
  }
}

class MutationMessage {
  static from(mutation) {
    return new MutationMessage(mutation);
  }

  constructor(mutation) {
    this.mutation = mutation;
    this.newValue =
      this.mutation.target.attributes[this.mutation.attributeName]?.value;
  }

  isValueChanged() {
    return this.mutation.oldValue !== this.newValue;
  }

  toString() {
    if (this.isValueChanged()) {
      return `${this.newValue}: ${this.mutation.target.innerText}`;
    }
  }
}
