import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useMySelfInventory } from "./Context";

const SelfEsteem = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const {
    strengths,
    values,
    selfEsteem,
    setSelfEsteem,
    assignedExerciseId,
    completedExercise,
  } = useMySelfInventory();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      strengths,
      values,
      selfEsteem,
      setSelfEsteem,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.GenericTextAreaPromptPage
      currentStep={currentStep}
      numSteps={numSteps}
      nextPage={nextPage}
      textPrompt="My definition of self-esteem:"
      textAreaValue={selfEsteem}
      showSaveForLater={false}
      setTextAreaValue={setSelfEsteem}
      submitExercise={submitExercise}
      setExerciseComplete={setExerciseComplete}
    />
  );
};

export default SelfEsteem;
