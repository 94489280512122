import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useWheelOfLife } from "./Context";
import { Source, SourceSection, defaultSource } from "./Shared";

const CurrentSelfWorth = (props: { nextPage: string }) => {
  const { nextPage } = props;

  const {
    currentSources,
    setCurrentSources,
    assignedExerciseId,
    completedExercise,
  } = useWheelOfLife();

  const addNewSource = (event) => {
    event.preventDefault();

    if (maxSources()) return false;

    const tmpSources = [...currentSources, defaultSource];
    setCurrentSources(tmpSources);
  };

  const maxSources = () => {
    return currentSources.length == 10;
  };

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      currentSources,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  const totalPercent = () => {
    let total = 0;
    currentSources.forEach((source) => {
      if (parseInt(source.percent)) {
        total += parseInt(source.percent);
      }
    });
    return total;
  };

  return (
    <EE.SubmitableForm submitExercise={submitExercise}>
      <h2 className="text-xl md:mt-12">
        Where do you currently get your self-worth?
      </h2>

      <EE.Paragraph className="mt-6">
        List your sources of self-worth and how much importance you give each.
        Make sure your percentages add up to 100%
      </EE.Paragraph>

      {currentSources.map((_source: Source, index: number) =>
        SourceSection(index, currentSources, setCurrentSources)
      )}

      <button
        className="mt-8 text-green-700"
        onClick={addNewSource}
        hidden={maxSources()}
      >
        + Add another source
      </button>

      <p className="mt-8 font-bold">
        Your percentages add up to: {totalPercent()}%
      </p>

      <EE.ButtonGroup>
        <EE.ExerciseCallToActionLink
          to={nextPage}
          disabled={totalPercent() != 100}
        >
          Next
        </EE.ExerciseCallToActionLink>
      </EE.ButtonGroup>
    </EE.SubmitableForm>
  );
};

export default CurrentSelfWorth;
