import { clone } from "lodash";
import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";

interface Source {
  name: string;
  percent: string | number;
}

const defaultSource: Source = { name: "", percent: "" };

const SourceSection = (
  index: number,
  sources: Array<Source>,
  setSources: Function
) => {
  const source = sources[index];

  const setSourceName = (value: string) => {
    const newSource: Source = { ...source, name: value };
    const tmpSources = clone(sources);
    tmpSources[index] = newSource;
    setSources(tmpSources);
  };

  const setSourcePercent = (value: string) => {
    const val = parseInt(value) || "";
    const newSource: Source = { ...source, percent: val };
    const tmpSources = clone(sources);
    tmpSources[index] = newSource;
    setSources(tmpSources);
  };

  return (
    <div className="flex w-full mt-6" key={index}>
      <label className="w-full">
        <p className="pb-2">Source {index + 1}:</p>
        <EE.TextInput
          value={source.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSourceName(e.target.value)
          }
          maxLength={35}
          id={`source_${index + 1}`}
          autoComplete="off"
          placeholder="e.g. friends"
        />
      </label>

      <label className="w-20 ml-4" htmlFor={`percent_${index + 1}`}>
        <p className="pb-2">%</p>
        <EE.TextInput
          value={source.percent}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSourcePercent(e.target.value)
          }
          id={`percent_${index + 1}`}
          maxLength={3}
          autoComplete="off"
        />
      </label>
    </div>
  );
};

export { Source, defaultSource, SourceSection };
