import { Controller } from "@hotwired/stimulus";
import Blob from "@js/helpers/Blob/Blob";
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = ["blob"];

  initialize() {
    // Debounce at 300 milliseconds to prevent dancing blobs when rapidly clicking
    this.animate = debounce(this.animate, 300).bind(this);
  }

  connect() {
    this.blobs = Blob.from(this.blobTargets);
    this.renderWithoutAnimation();
  }

  renderWithoutAnimation() {
    this.blobs.forEach((blob) => blob.animate({ duration: 0, move: false }));
  }

  animate(event) {
    if (event.key && event.key !== "Enter") return;

    this.blobs.forEach((blob) => blob.animate());
  }
}
