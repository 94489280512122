// Adapted from https://tailwindui.com/components/application-ui/elements/button-groups#component-bfc7e9cc9d7b5762cb139096ac3266c1
import { Controller } from "@hotwired/stimulus";

const showClasses = ["ease-out", "opacity-100", "scale-100", "duration-100"];
const hideClasses = [
  "hidden",
  "ease-in",
  "opacity-0",
  "scale-95",
  "duration-75",
];

/* TODO: This should be a sidecar asset in Wave, but it's not getting loaded correctly. */
export default class extends Controller {
  static targets = ["button", "dropdown"];

  connect() {
    this.buttonTarget.addEventListener("click", this.toggleDropdown.bind(this));
  }

  toggleDropdown() {
    if (this.dropdownTarget.classList.contains("hidden")) {
      this.open();
    } else {
      this.close();
    }
  }

  open() {
    this.dropdownTarget.classList.remove(...hideClasses);
    this.dropdownTarget.classList.add(...showClasses);
    document.addEventListener("click", this.handleExternalClick.bind(this));
  }

  close() {
    this.dropdownTarget.classList.remove(...showClasses);
    this.dropdownTarget.classList.add(...hideClasses);
    document.removeEventListener("click", this.handleExternalClick.bind(this));
  }

  handleExternalClick(event) {
    const withinBoundaries =
      event.composedPath().includes(this.buttonTarget) ||
      event.composedPath().includes(this.dropdownTarget);

    if (!withinBoundaries) {
      this.close();
    }
  }
}
