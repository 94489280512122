import Images from "@js/images/blobs";
import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";

const Title = () => {
  return <EE.Title>Body map</EE.Title>;
};

const ImageMapping = [
  Images.Blob0,
  Images.Blob1,
  Images.Blob2,
  Images.Blob3,
  Images.Blob4,
  Images.Blob5,
  Images.Blob6,
  Images.Blob7,
  Images.Blob8,
  Images.Blob9,
  Images.Blob10,
];

export { ImageMapping, Title };
