import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import debounce from "lodash/debounce";
import resolveConfig from "tailwindcss/resolveConfig";

import tailwindConfig from "../../../tailwind.config.js";

export default class extends Controller {
  static targets = ["form", "field", "clearButton"];
  static values = { autocomplete: String };

  declare readonly formTarget: HTMLFormElement;
  declare readonly fieldTarget: HTMLTextAreaElement;
  declare readonly clearButtonTarget: HTMLButtonElement;
  declare readonly autocompleteValue: string;
  declare fullConfig;

  initialize() {
    this.autocomplete = debounce(this.autocomplete, 300).bind(this);
    this.fullConfig = resolveConfig(tailwindConfig);

    this.setPlaceholder();
    window.addEventListener("resize", () => this.setPlaceholder(this));
  }

  input(event) {
    const shouldAutocomplete = this.autocompleteValue == "true";

    this.clearButtonTarget.style.display = this.queryPresent()
      ? "flex"
      : "none";

    if (event.key && event.key == "Enter" && this.queryPresent()) {
      this.submit();
    } else if (shouldAutocomplete) {
      this.autocomplete();
    }
  }

  submit() {
    this.formTarget.submit();
  }

  autocomplete() {
    Rails.fire(this.formTarget, "submit");
    const resultsElement = document.getElementById(
      "desktop-nav-search-results"
    );
    if (this.onDesktop() && this.queryPresent()) {
      resultsElement?.classList.remove("hidden");
      this.fieldTarget.classList.add("rounded-b-none");
      this.fieldTarget.classList.add("border-b-0");
    } else {
      resultsElement?.classList.add("hidden");
      this.fieldTarget.classList.remove("rounded-b-none");
      this.fieldTarget.classList.remove("border-b-0");
    }
  }

  queryPresent() {
    return this.fieldTarget.value && this.fieldTarget.value.trim() !== "";
  }

  onDesktop() {
    return this.fieldTarget.getAttribute("data-device") === "desktop";
  }

  clear(event) {
    this.fieldTarget.value = "";
    this.input(event);
  }

  setPlaceholder(controller = this) {
    const screens = controller.fullConfig.theme.screens;
    const windowSize = window.innerWidth;
    if (
      !controller.onDesktop() ||
      windowSize >= parseInt(screens.lg) ||
      windowSize < parseInt(screens.sm)
    ) {
      controller.fieldTarget.placeholder = "Search the library...";
    } else {
      controller.fieldTarget.placeholder = "Search...";
    }
  }
}
