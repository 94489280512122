import React from "react";

import { ExerciseScreenProps } from "../shared/Elements";
import { Source, defaultSource } from "./Shared";

const WheelOfLifeContext = React.createContext(undefined);

const WheelOfLifeProvider: React.FC<ExerciseScreenProps> = (props) => {
  const {
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  } = props;

  const defaultState = {
    currentSources: [
      defaultSource,
      defaultSource,
      defaultSource,
      defaultSource,
    ],
    idealSources: [defaultSource, defaultSource, defaultSource, defaultSource],
  };

  const initialState = { ...defaultState, ...completedExercise?.data };

  const [currentSources, setCurrentSources] = React.useState<Source[]>(
    initialState.currentSources
  );
  const [idealSources, setIdealSources] = React.useState<Source[]>(
    initialState.idealSources
  );

  const value = {
    currentSources,
    setCurrentSources,
    idealSources,
    setIdealSources,
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  };

  return <WheelOfLifeContext.Provider value={value} {...props} />;
};

function useWheelOfLife() {
  const context = React.useContext(WheelOfLifeContext);
  if (!context) {
    throw new Error("useWheelOfLife must be used within a WheelOfLifeProvider");
  }
  return context;
}

export { WheelOfLifeProvider, WheelOfLifeContext, useWheelOfLife };
