import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useMySelfInventory } from "./Context";

const StillGrowing = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const {
    strengths,
    values,
    selfEsteem,
    stillGrowing,
    setStillGrowing,
    assignedExerciseId,
    completedExercise,
  } = useMySelfInventory();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      strengths,
      values,
      stillGrowing,
      setStillGrowing,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.GenericTextAreaPromptPage
      currentStep={currentStep}
      numSteps={numSteps}
      nextPage={nextPage}
      textPrompt="Where I'm still growing:"
      textAreaValue={stillGrowing}
      showSaveForLater={false}
      setTextAreaValue={setStillGrowing}
      submitExercise={submitExercise}
      setExerciseComplete={setExerciseComplete}
    />
  );
};

export default StillGrowing;
