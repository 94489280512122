import React from "react";

const Login = () => {
  const button: HTMLButtonElement = document.querySelector("#js-login");
  button.form.submit();
  return (
    <div className="w-full mt-16 text-center">
      <span className="animate-spin material-icons">loop</span>
    </div>
  );
};

export default Login;
