// Note: When we finally upgrade from turbolinks to turbo, let's put base_members.js back into this file and delete turbolinks_members.js

// NOTE: This file will soon be deprecated in favor of members.js.

require("./members_1");
require("./members_2");

Turbo.session.drive = true;
Turbo.setProgressBarDelay(20);

require("./members_3");
