import React from "react";

import { useThermometerImage } from "../shared";
import { ExerciseElements as EE, ExerciseChildIcon } from "../shared/Elements";
import { useBodyMap } from "./Context";
import { ImageMapping, Title } from "./Shared";

// TODO: Make the slider bigger everywhere

const PreExercise = () => {
  const {
    patientFirstName,
    careLink,
    initialThermometerValue,
    setInitialThermometerValue,
  } = useBodyMap();

  const { ThermometerImage } = useThermometerImage(
    ImageMapping,
    initialThermometerValue
  );

  return (
    <div className="p-2">
      <div className="flex-grow-0 text-center ">
        <EE.RoundedExerciseCard>
          <ExerciseChildIcon />
          <Title />
        </EE.RoundedExerciseCard>
        <div className="py-4">
          <span className="mt-4" id="slider-label">
            Before we begin, how is {patientFirstName} feeling now?
          </span>
        </div>
        <ThermometerImage className="h-32 mx-auto" />
        <EE.ThermometerNumber>{initialThermometerValue}</EE.ThermometerNumber>
        <div className="py-4">
          <EE.ThermometerSlider
            thermometerValue={initialThermometerValue}
            onChange={setInitialThermometerValue}
            ariaLabelledByForHandle="slider-label"
          />
          <EE.ExerciseAnchors>
            <EE.ExerciseAnchor className="text-left">
              Not Scared
            </EE.ExerciseAnchor>
            <EE.ExerciseAnchor className="text-right">
              Very Scared
            </EE.ExerciseAnchor>
          </EE.ExerciseAnchors>
        </div>

        <EE.LinkGroup>
          <EE.ExerciseCallToActionLink to="/exercise">
            Next
          </EE.ExerciseCallToActionLink>
          <EE.SecondaryActionLink text="Cancel" to={careLink} />
        </EE.LinkGroup>
      </div>
    </div>
  );
};

export default PreExercise;
