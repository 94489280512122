import React from "react";

/**
 * A component that can be used to display an image. It adds alt
 * text based on convention: alt text must exist in the Ruby locale
 * (e.g., en.rb) matching the file path of the image.
 *
 * @param props The props to pass to the img tag.
 *
 * @example
 * <Image src="/images/exercise_images/self_esteem_journal/positive_words.png" />
 * // Would look for alt text in en.rb at images.exercise_images.self_esteem_journal.positive_words.
 */
const Image = ({
  src,
  ...rest
}: React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>) => {
  const cleanedPath = cleanDoubleSlashedPrefixedUrl(src);
  const altText = accessLocaleByFilePath(cleanedPath);
  return <img src={cleanedPath} alt={altText} {...rest} />;
};

function accessLocaleByFilePath(filepath?: string): string | undefined {
  if (!filepath) {
    return undefined;
  }
  if (isExternalUrl(filepath)) return;

  const pathWithoutExtension = removeExtensionFromPath(filepath);
  const pathWithoutCacheBust = pathWithoutCacheHash(pathWithoutExtension);
  const pathParts = cleanPathParts(pathWithoutCacheBust);
  let locale = window.Brightline.I18n;
  for (let i = 0; i < pathParts.length; i++) {
    if (locale === undefined) {
      break;
    }
    locale = locale[pathParts[i]];
  }

  if (isString(locale)) {
    return locale;
  }

  console.log("No alt text found for image:", filepath);
  return undefined;
}

function cleanDoubleSlashedPrefixedUrl(path?: string): string | undefined {
  // This is intended to handle contentful URLs that are prefixed with //
  if (!path) {
    return undefined;
  }

  if (path.startsWith("//")) {
    return window.location.protocol + path;
  } else {
    return path;
  }
}

function isExternalUrl(path: string): boolean {
  try {
    const url = new URL(path);
    return url.origin !== window.location.origin;
  } catch {
    return false;
  }
}

function removeExtensionFromPath(filepath: string): string {
  const parts = filepath.split(".");
  parts.pop();
  return parts.join(".");
}

/**
 * Given a path with a packs or packs-test directory, returns an array
 * of path parts without the packs or packs-test directory.
 *
 * @example
 * pathWithoutPacks("/anything/packs-test/media/images/icons/AllAboutMe")
 * // => ["media", "images", "icons", "AllAboutMe"]
 */
function cleanPathParts(path: string) {
  return path.split(/\/packs.*?\//)[1].split("/");
}

function pathWithoutCacheHash(path: string) {
  const parts = path.split("-");
  parts.pop();
  return parts.join("-");
}

function isString(value: any): value is string {
  return typeof value === "string" || value instanceof String;
}

export default Image;
