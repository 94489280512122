import React from "react";
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Text,
  Tooltip,
} from "recharts";

const PieChartWithLabels = (props) => {
  const {
    data,
    colors,
    labelFontSize,
    labelColor,
    outerRadius,
    height,
    textSpace,
  } = props;

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 1;
    const buffer = 5;
    const distanceFromCenter = radius + buffer;
    const x = cx + distanceFromCenter * Math.cos(-midAngle * RADIAN);
    const y = cy + distanceFromCenter * Math.sin(-midAngle * RADIAN);

    // Regular label
    return (
      <Text
        x={x}
        y={y}
        fill={labelColor}
        textAnchor={x > cx ? "start" : "end"}
        verticalAnchor={y > cy ? "start" : "end"}
        dominantBaseline="central"
        fontSize={labelFontSize}
        width={textSpace}
      >
        {data[index].name}
      </Text>
    );
  };

  return (
    <ResponsiveContainer width="99%" height={height}>
      <PieChart>
        <Pie
          dataKey="value"
          isAnimationActive={false}
          data={data}
          outerRadius={outerRadius}
          fill="#8884d8"
          label={renderCustomizedLabel}
          labelLine={false}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartWithLabels;
