import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String, delay: { type: Number, default: 2000 } };

  connect() {
    this.timer = setInterval(() => {
      Rails.ajax({
        url: this.urlValue,
        type: "get",
        dataType: "script",
      });
    }, this.delayValue);
  }

  disconnect() {
    clearInterval(this.timer);
  }
}
