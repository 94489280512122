import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useAllAboutMe } from "./Context";

const Description = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const { description, setDescription, assignedExerciseId, completedExercise } =
    useAllAboutMe();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      description,
      setDescription,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.GenericTextAreaPromptPage
      currentStep={currentStep}
      numSteps={numSteps}
      nextPage={nextPage}
      textPrompt="Let's describe you! What's the first thing that comes to mind when you think about who you are?"
      showSaveForLater={false}
      textAreaValue={description}
      setTextAreaValue={setDescription}
      submitExercise={submitExercise}
      setExerciseComplete={setExerciseComplete}
    />
  );
};

export default Description;
