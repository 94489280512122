import React, { useState } from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const MyRelaxingPlaceScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    relaxingPlace: "",
    see: "",
    hear: "",
    taste: "",
    smell: "",
    feel: "",
  };
  const initialState = { ...defaultState, ...props.completedExercise?.data };

  const [relaxingPlace, setRelaxingPlace] = useState(
    initialState.relaxingPlace
  );
  const [see, setSee] = useState(initialState.see);
  const [hear, setHear] = useState(initialState.hear);
  const [taste, setTaste] = useState(initialState.taste);
  const [smell, setSmell] = useState(initialState.smell);
  const [feel, setFeel] = useState(initialState.feel);

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      relaxingPlace,
      see,
      hear,
      taste,
      smell,
      feel,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>My Relaxing Place</EE.Heading>
          <EE.HR />
          <EE.QuestionLabel>
            <EE.Subheading>My Relaxing Place is:</EE.Subheading>
            <EE.TextInput
              value={relaxingPlace}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRelaxingPlace(e.target.value)
              }
            />
          </EE.QuestionLabel>
          <EE.QuestionLabel>
            What I see:
            <EE.TextArea
              value={see}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSee(e.target.value)
              }
            />
          </EE.QuestionLabel>
          <EE.QuestionLabel>
            What I smell:
            <EE.TextArea
              value={smell}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSmell(e.target.value)
              }
            />
          </EE.QuestionLabel>
          <EE.QuestionLabel>
            What I feel/touch:
            <EE.TextArea
              value={feel}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFeel(e.target.value)
              }
            />
          </EE.QuestionLabel>
          <EE.QuestionLabel>
            What I hear:
            <EE.TextArea
              value={hear}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setHear(e.target.value)
              }
            />
          </EE.QuestionLabel>
          <EE.QuestionLabel>
            What I taste:
            <EE.TextArea
              value={taste}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTaste(e.target.value)
              }
            />
          </EE.QuestionLabel>
          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default MyRelaxingPlaceScreens;
