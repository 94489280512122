import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "disable"];

  declare disableTargets: HTMLInputElement[];
  declare inputTargets: HTMLInputElement[];

  disable() {
    this.disableTargets.forEach((element) => {
      element.disabled = true;
    });
  }

  enable() {
    const shouldDisable = !this.inputTargets.some((element) => element.checked);

    this.disableTargets.forEach((element) => {
      element.disabled = shouldDisable;
    });
  }
}
