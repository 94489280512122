import React from "react";

import { useThermometerImage } from "../shared";
import { ExerciseFormContext, useExerciseForm } from "../shared/Context";
import { ExerciseElements as EE } from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import BodyMapExercise from "./BodyMapExercise";
import { useBodyMap } from "./Context";
import { ImageMapping, Title } from "./Shared";

const PostExercise = () => {
  const {
    patientFirstName,
    initialThermometerValue,
    finalThermometerValue,
    setFinalThermometerValue,
    thoughts,
    blobs,
    setThoughts,
    assignedExerciseId,
    completedExercise,
  } = useBodyMap();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      initialThermometerValue,
      blobs,
      finalThermometerValue,
      thoughts,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  const { ThermometerImage } = useThermometerImage(
    ImageMapping,
    finalThermometerValue
  );

  return (
    <div className="p-2 text-center ">
      <EE.RoundedExerciseCard>
        <ExerciseFormContext.Provider value={{ readOnly: true }}>
          <BodyMapExercise />
        </ExerciseFormContext.Provider>
        <Title />
      </EE.RoundedExerciseCard>
      <p className="block pt-8 pb-6 font-sans" id="slider-label">
        Nice work! How is {patientFirstName} feeling now?
      </p>
      <ThermometerImage className="h-32 mx-auto" />
      <EE.ThermometerNumber>{finalThermometerValue}</EE.ThermometerNumber>
      <div className="p-4">
        <EE.ThermometerSlider
          thermometerValue={finalThermometerValue}
          onChange={setFinalThermometerValue}
          ariaLabelledByForHandle="slider-label"
        />
        <EE.ExerciseAnchors>
          <EE.ExerciseAnchor className="text-left">
            Not Scared
          </EE.ExerciseAnchor>
          <EE.ExerciseAnchor className="text-right">
            Very Scared
          </EE.ExerciseAnchor>
        </EE.ExerciseAnchors>
      </div>
      <form
        onSubmit={(e: React.ChangeEvent<HTMLFormElement>) => submitExercise(e)}
      >
        <EE.QuestionLabel>
          <EE.CenteredExerciseText>
            Is there anything else we should know?
          </EE.CenteredExerciseText>
          <EE.TextArea
            id="thoughts-input"
            value={thoughts}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              setThoughts(e.target.value)
            }
          />
        </EE.QuestionLabel>
        <EE.LinkGroup>
          <EE.ResumableExerciseActions
            submitButtonText="Log Mood"
            cancelPath="/exercise"
            setExerciseComplete={setExerciseComplete}
          />
        </EE.LinkGroup>
      </form>
    </div>
  );
};

export default PostExercise;
