import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const IStatementsScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    iFeel: "",
    because: "",
    iNeed: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [iFeel, setIFeel] = React.useState(initialState.iFeel);
  const [because, setBecause] = React.useState(initialState.because);
  const [iNeed, setINeed] = React.useState(initialState.iNeed);

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      iFeel,
      because,
      iNeed,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>I-Statements</EE.Heading>
          <EE.HR />
          <EE.Subheading className="my-4">Let’s give it a try!</EE.Subheading>
          <EE.Blockquote>
            I feel <u>sad</u> because <u>my brother said something mean</u>. I
            need <u>a hug</u>!
          </EE.Blockquote>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>I feel...</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setIFeel(e.target.value)
              }
              value={iFeel}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>Because...</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setBecause(e.target.value)
              }
              value={because}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>I need...</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setINeed(e.target.value)
              }
              value={iNeed}
            />
          </EE.QuestionLabel>

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default IStatementsScreens;
