import Image from "@js/components/shared/Image";
import HappyChild from "@js/images/exercises/HappyChild.png";
import RollerCoaster from "@js/images/exercises/RollerCoaster.png";
import UnhappyChild from "@js/images/exercises/UnhappyChild.png";
import FeelingImages from "@js/images/feelings";
import { clone, includes, without } from "lodash";
import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const StopWorksheet: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    scaredFeelings: [],
    whenDoYouFeelScared: "",
    goodThought: "",
    badThought: "",
    happyBoyThoughts: "",
    sadGirlThoughts: "",
    otherThoughtsOne: "",
    otherThoughtTwo: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };

  const [scaredFeelings, setScaredFeelings] = React.useState(
    initialState.scaredFeelings
  );
  const [whenDoYouFeelScared, setWhenDoYouFeelScared] = React.useState(
    initialState.whenDoYouFeelScared
  );
  const [goodThought, setGoodThought] = React.useState(
    initialState.goodThought
  );
  const [badThought, setBadThought] = React.useState(initialState.badThought);
  const [happyBoyThoughts, setHappyBoyThoughts] = React.useState(
    initialState.happyBoyThoughts
  );
  const [sadGirlThoughts, setSadGirlThoughts] = React.useState(
    initialState.sadGirlThoughts
  );
  const [otherThoughtsOne, setOtherThoughtsOne] = React.useState(
    initialState.otherThoughtsOne
  );
  const [otherThoughtTwo, setOtherThoughtTwo] = React.useState(
    initialState.otherThoughtTwo
  );

  const toggleChecked = (string) => {
    var modifiedScaredFeelings = clone(scaredFeelings);
    if (includes(scaredFeelings, string)) {
      const removedFeelings = without(modifiedScaredFeelings, string);
      setScaredFeelings(removedFeelings);
    } else {
      modifiedScaredFeelings.push(string);
      setScaredFeelings(modifiedScaredFeelings);
    }
  };

  const scaredOptions = [
    "Dizzy",
    "Sweaty",
    "Shortness of breath",
    "Shaky",
    "Blushing",
    "Butterflies",
    "Fast heartbeat",
  ];

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      scaredFeelings,
      whenDoYouFeelScared,
      goodThought,
      badThought,
      happyBoyThoughts,
      sadGirlThoughts,
      otherThoughtsOne,
      otherThoughtTwo,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>STOP Worksheet</EE.Heading>
          <EE.Paragraph>
            STOP will be an important part of getting braver. When we talk about{" "}
            <EE.Bold>STOP</EE.Bold>, <EE.Bold>S</EE.Bold> stands for scared,{" "}
            <EE.Bold>T</EE.Bold> stands for thoughts, <EE.Bold>O</EE.Bold>{" "}
            stands for other thoughts, and <EE.Bold>P</EE.Bold> stands for
            praise. Let’s learn more about <EE.Bold>STOP</EE.Bold>.
          </EE.Paragraph>

          <h2 className="my-8 text-green-700">
            S<span className="text-green-700 text-opacity-50">TOP</span> -
            Scared
          </h2>

          <EE.Subheading style={{ marginTop: 0 }}>Feelings Match</EE.Subheading>
          <EE.Paragraph>
            Here are some of the feelings you might have when you are scared.
            Can you check up to three feelings you remember having the last time
            you were scared?
          </EE.Paragraph>
          <ul>
            {scaredOptions.map((option) => {
              return (
                <li key={`scaredOption${option}`}>
                  <EE.QuestionLabel className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      onChange={() => toggleChecked(option)}
                      checked={includes(scaredFeelings, option)}
                    />
                    <span className="ml-2">{option}</span>
                  </EE.QuestionLabel>
                </li>
              );
            })}
          </ul>
          <EE.Subheading id="whenDoYouFeelScared">
            When do you feel scared?
          </EE.Subheading>
          <EE.Paragraph>
            It is important to know when you feel scared, nervous, or worried,
            so that you can practice feeling better. What are some times when
            you feel like this?
          </EE.Paragraph>
          <EE.QuestionLabel aria-labelledby="whenDoYouFeelScared">
            <EE.Paragraph>
              <EE.Bold>Example:</EE.Bold> When the teacher asks me a question
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWhenDoYouFeelScared(e.target.value)
              }
              value={whenDoYouFeelScared}
            />
          </EE.QuestionLabel>

          <h2 className="my-8 text-green-700">
            <span className="text-green-700 text-opacity-50">S</span>T
            <span className="text-green-700 text-opacity-50">OP</span> -
            Thoughts
          </h2>

          <EE.Subheading>Good Thoughts / Bad Thoughts</EE.Subheading>
          <EE.Paragraph>
            Let's learn more about the way thoughts work. Complete the following
            activity.
          </EE.Paragraph>

          <EE.Blockquote>I get to see my friends this afternoon!</EE.Blockquote>
          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>Write something good that will happen:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setGoodThought(e.target.value)
              }
              value={goodThought}
            />
          </EE.QuestionLabel>

          <EE.Blockquote>Everyone will laugh at me</EE.Blockquote>
          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>Write something bad that will happen:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setBadThought(e.target.value)
              }
              value={badThought}
            />
          </EE.QuestionLabel>

          <EE.Subheading>Thoughts and Feelings</EE.Subheading>
          <EE.Paragraph>
            The thoughts you have can change how you feel. What thoughts do
            these people have about the <EE.Bold>rollercoaster</EE.Bold>? Who
            feels better? Write in the thoughts they are having.
          </EE.Paragraph>
          <Image src={RollerCoaster} className="w-full mt-4" />
          <div className="md:flex">
            <EE.QuestionLabel className="flex flex-col mt-4 md:p-4 md:flex-grow">
              <Image src={HappyChild} className="self-center h-64 py-4" />
              <EE.TextArea
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setHappyBoyThoughts(e.target.value)
                }
                value={happyBoyThoughts}
                className="flex-grow"
                aria-label="A happy child"
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel className="flex flex-col mt-4 md:p-4 md:flex-grow">
              <Image src={UnhappyChild} className="self-center h-64 py-4" />
              <EE.TextArea
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSadGirlThoughts(e.target.value)
                }
                value={sadGirlThoughts}
                className="w-4/5"
                aria-label="A sad child"
              />
            </EE.QuestionLabel>
          </div>

          <h2 className="my-8 text-green-700">
            <span className="text-green-700 text-opacity-50">ST</span>O
            <span className="text-green-700 text-opacity-50">P</span> - Other
            Thoughts
          </h2>

          <EE.Subheading>Practicing Other Thoughts</EE.Subheading>
          <EE.Paragraph>
            We can learn to have other thoughts about things so that we don’t
            feel scared or nervous. Try writing some other thoughts below.
          </EE.Paragraph>

          <EE.Blockquote>
            No one at my new school will want to be my friend.
          </EE.Blockquote>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>Other Thought:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setOtherThoughtsOne(e.target.value)
              }
              value={otherThoughtsOne}
            />
          </EE.QuestionLabel>

          <EE.Blockquote>
            If I can’t answer the teacher’s question, everyone will laugh.
          </EE.Blockquote>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>Other Thought:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setOtherThoughtTwo(e.target.value)
              }
              value={otherThoughtTwo}
            />
          </EE.QuestionLabel>

          <h2 className="my-8 text-green-700">
            <span className="text-green-700 text-opacity-50">STO</span>P -
            Praise
          </h2>

          <div className="block md:flex">
            <EE.Paragraph className="flex-grow">
              P stands for praise. Don’t forget to tell yourself that you are
              doing a terrific job when you use S-T-O-P to make yourself feel
              less nervous or scared!
            </EE.Paragraph>
            <Image src={FeelingImages.happy} className="w-24 pl-8 mx-auto" />
          </div>

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default StopWorksheet;
