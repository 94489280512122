import Image from "@js/components/shared/Image";
import FeelingsIcons from "@js/images/feelings";
import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const HowDoIFeel: React.FC<{ setterFn: any; selected: boolean }> = (props) => {
  return (
    <>
      <EE.CenteredExerciseText>
        How do I feel now?
        <br />
        <SadButton {...props} />
        <HappyButton {...props} />
      </EE.CenteredExerciseText>
    </>
  );
};
const SadButton: React.FC<{ setterFn: any; selected: boolean }> = (props) => {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        props.setterFn(false);
      }}
      className="p-2"
      id="sad-button"
    >
      <Image
        src={FeelingsIcons.sad}
        alt="sad"
        className={`inline-block w-32 ${
          props.selected === false ? "opacity-100" : "opacity-20"
        }`}
      />
    </button>
  );
};

const HappyButton: React.FC<{ setterFn: any; selected: boolean }> = (props) => {
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        props.setterFn(true);
      }}
      className="p-2"
    >
      <Image
        src={FeelingsIcons.happy}
        alt="happy"
        className={`inline-block w-32 ${
          props.selected === true ? "opacity-100" : "opacity-20"
        }`}
      />
    </button>
  );
};

const FollowingMyPlans: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    skillOne: "",
    skillOneWorked: null,
    skillTwo: "",
    skillTwoWorked: null,
    skillThree: "",
    skillThreeWorked: null,
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [skillOne, setSkillOne] = React.useState(initialState.skillOne);
  const [skillOneWorked, setSkillOneWorked] = React.useState<boolean>(
    initialState.skillOneWorked
  );
  const [skillTwo, setSkillTwo] = React.useState(initialState.skillTwo);
  const [skillTwoWorked, setSkillTwoWorked] = React.useState<boolean>(
    initialState.skillTwoWorked
  );
  const [skillThree, setSkillThree] = React.useState(initialState.skillThree);
  const [skillThreeWorked, setSkillThreeWorked] = React.useState<boolean>(
    initialState.skillThreeWorked
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      skillOne,
      skillOneWorked,
      skillTwo,
      skillTwoWorked,
      skillThree,
      skillThreeWorked,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Following My Plans</EE.Heading>
          <div className="flex mt-4">
            <div className="w-1/5 md:p-2">
              <Image
                src={FeelingsIcons.happy}
                alt="happy"
                className="inline-block w-32 opacity-100"
              />
            </div>
            <div className="w-4/5 pl-4">
              <EE.Paragraph>
                <EE.Bold>
                  "I am feeling bad, sad, mad, or stressed out!"
                </EE.Bold>
              </EE.Paragraph>
              <EE.Paragraph>
                So I use my chosen skills. I have three plans for feeling
                better. Plans A, B, and C.
              </EE.Paragraph>
            </div>
          </div>

          <EE.QuestionLabel>
            <EE.Subheading>Try Plan A</EE.Subheading>
            <EE.TextInput
              id="skillOne"
              value={skillOne}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSkillOne(e.target.value)
              }
              autoComplete="off"
            />
          </EE.QuestionLabel>

          <HowDoIFeel setterFn={setSkillOneWorked} selected={skillOneWorked} />
          {skillOneWorked === false && (
            <>
              <EE.QuestionLabel>
                <EE.Subheading>Try Plan B</EE.Subheading>
                <EE.TextInput
                  id="skillTwo"
                  value={skillTwo}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSkillTwo(e.target.value)
                  }
                  autoComplete="off"
                />
              </EE.QuestionLabel>
              <HowDoIFeel
                setterFn={setSkillTwoWorked}
                selected={skillTwoWorked}
              />
            </>
          )}

          {skillTwoWorked === false && (
            <>
              <EE.QuestionLabel>
                <EE.Subheading>Try Plan C</EE.Subheading>
                <EE.TextInput
                  id="skillThree"
                  value={skillThree}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSkillThree(e.target.value)
                  }
                  autoComplete="off"
                />
              </EE.QuestionLabel>
              <HowDoIFeel
                setterFn={setSkillThreeWorked}
                selected={skillThreeWorked}
              />
            </>
          )}
          {(skillOneWorked || skillTwoWorked || skillThreeWorked) && (
            <>
              <EE.Subheading>Wonderful!</EE.Subheading>
              <EE.Paragraph>
                Since you're feeling better, great! You've finished the
                exercise.
              </EE.Paragraph>
            </>
          )}
          {skillThreeWorked === false && (
            <>
              <EE.Subheading>Keep Trying! Don't Give Up!</EE.Subheading>
              <EE.Paragraph>
                I will keep trying new skills until I solve my problem and I
                feel better!
              </EE.Paragraph>
            </>
          )}
          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default FollowingMyPlans;
