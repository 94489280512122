import { Controller } from "@hotwired/stimulus";

let paymentMethod;
export default class extends Controller {
  static targets = ["submit", "error", "nonce", "form"];

  connect() {
    this.submitTarget.disabled = true;
    this.loadSquareScript();
  }

  loadSquareScript() {
    const head = document.getElementsByTagName("head").item(0);
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", this.scriptSrc);
    script.onload = () => {
      this.initSquare();
    };
    head.appendChild(script);
  }

  async initSquare() {
    if (!window.Square) {
      this.handleException("Square.js failed to load properly");
    }

    try {
      const payments = window.Square.payments(this.appId, this.locationId);
      paymentMethod = await payments.card();
      document.querySelector("#card-container").innerHTML = "";
      await paymentMethod.attach("#card-container");
      this.addAriaLabelToIFrame();
      this.submitTarget.disabled = false;
    } catch (e) {
      this.handleException(
        `Initializing payment method form failed: ${e.message}`
      );
      return;
    }
  }

  addAriaLabelToIFrame() {
    const iframe = document.querySelector("#card-container iframe");
    if (iframe) {
      iframe.setAttribute("aria-label", "New payment method form");
    } else {
      console.warn("Square payment form not found");
    }
  }

  get locationId() {
    return this.data.get("location-id");
  }

  get appId() {
    return this.data.get("app-id");
  }

  get scriptSrc() {
    return this.data.get("script-src");
  }

  enableSubmit() {
    Rails.enableElement(this.submitTarget);
  }

  handleException(message) {
    this.errorTarget.innerHTML = message;
    throw new Error(message);
  }

  submitPayment(event) {
    event.preventDefault();
    // explicitly disable since we have to wait on a nonce to be generated
    Rails.disableElement(this.submitTarget);
    this.handlePaymentMethodSubmission();
  }

  async handlePaymentMethodSubmission() {
    try {
      const token = await this.tokenize();
      if (token) {
        this.nonceTarget.value = token;
        this.formTarget.requestSubmit();
      } else {
        Rails.enableElement(this.submitTarget);
      }
    } catch (e) {
      this.handleException(e.message);
      Rails.enableElement(this.submitTarget);
    }
  }

  async tokenize() {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    }
  }
}
