import React from "react";

const Banner = (props: ChatAppDisclaimerBannerProps) => {
  const { fixed, fullWidth, margin, padding, bannerText } = props;

  const fixedClasses = fixed ? "fixed z-10" : "";
  const fullWidthClasses = fullWidth ? "w-full" : "";

  return (
    <div
      className={`${fixedClasses} flex justify-center ${fullWidthClasses} ${padding} ${margin} "bg-blue-300" 
      }`}
    >
      <div className="grid grid-cols-10">
        <div className="col-span-9 pt-2 text-sm">{bannerText}</div>
      </div>
    </div>
  );
};

interface ChatAppDisclaimerBannerProps {
  fixed?: boolean;
  fullWidth?: boolean;
  margin?: string;
  padding?: string;
  bannerText: string;
}

export default Banner;
