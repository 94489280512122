import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return [
      "sliderValue",
      "face0",
      "face1",
      "face2",
      "face3",
      "face4",
      "face5",
      "face6",
      "face7",
      "face8",
      "face9",
      "face10",
    ];
  }

  declare readonly sliderValueTarget: { value: string };
  declare activeFaceValue: number;

  static get values() {
    return {
      activeFace: {
        type: Number,
        default: 5,
      },
    };
  }

  initialize() {
    // show both face targets based on initial value
    this[
      "face" + this.activeFaceValue.toString() + "Targets"
    ][0].style.display = "block";
    this[
      "face" + this.activeFaceValue.toString() + "Targets"
    ][1].style.display = "block";
    this.sliderValueTarget.value = this.activeFaceValue.toString();
  }

  updateValue(event) {
    // show the new face targets and hide the old ones
    this["face" + event.target.value.toString() + "Targets"][0].style.display =
      "block";
    this["face" + event.target.value.toString() + "Targets"][1].style.display =
      "block";
    this[
      "face" + this.activeFaceValue.toString() + "Targets"
    ][0].style.display = "none";
    this[
      "face" + this.activeFaceValue.toString() + "Targets"
    ][1].style.display = "none";
    this.activeFaceValue = event.target.value;
    this.sliderValueTarget.value = event.target.value;
  }
}
