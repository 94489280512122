import React from "react";

import {
  ExerciseElements as EE,
  GenericMultiPageExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useSelfEsteemJournal } from "./Context";

const PositiveWords = (props: GenericMultiPageExerciseScreenProps) => {
  const { numSteps, currentStep, nextPage } = props;

  const {
    goodEvent,
    positiveWords,
    setPositiveWords,
    assignedExerciseId,
    completedExercise,
  } = useSelfEsteemJournal();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      goodEvent,
      positiveWords,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.GenericTextAreaPromptPage
      currentStep={currentStep}
      numSteps={numSteps}
      nextPage={nextPage}
      textPrompt="Something positive someone said about me recently was..."
      showSaveForLater={false}
      textAreaValue={positiveWords}
      setTextAreaValue={setPositiveWords}
      submitExercise={submitExercise}
      setExerciseComplete={setExerciseComplete}
    />
  );
};

export default PositiveWords;
