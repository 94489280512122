import "@sendbird/uikit-react/dist/index.css";
import "@js/css/ChatApp.scss";

import ChatAppDisclaimerBanner from "@js/components/member/ChatAppDisclaimerBanner";
import OutOfOfficeBanner from "@js/components/member/OutOfOfficeBanner";
import React from "react";

import Image from "../shared/Image";
import SendbirdChatApp, {
  ChatEnv,
  ChatParticipant,
} from "../shared/SendbirdChatApp";
import ChatSidebarElement from "./ChatSidebarElement";

const DeprecatedMemberChatApp = (props: MemberChatProps) => {
  const { bannerText, currentChannelId, memberChannels } = props;

  if (!memberChannels) {
    return <div>Loading...</div>;
  }

  const currentChannelInfo = memberChannels.find(
    (channelInfo) => channelInfo.channel.id === currentChannelId
  );

  const mobileClasses = (col: String) => {
    const shouldDisplay = currentChannelInfo ? "chat" : "channels";
    return col === shouldDisplay ? "w-full" : "hidden";
  };

  const Channels: React.FC = (props) => {
    return (
      <div
        className={`border-r h-screen border-gray-300 ${mobileClasses(
          "channels"
        )} sm:block sm:w-1/3`}
      >
        {props.children}
      </div>
    );
  };

  const Chat: React.FC = (props) => {
    return (
      <div className={`${mobileClasses("chat")} sm:block sm:w-2/3`}>
        {props.children}
      </div>
    );
  };

  const MobileHiddenHeader: React.FC = (props) => {
    return (
      <div className="items-center hidden h-20 font-sans text-center bg-yellow-300 border-t border-b border-gray-300 sm:flex place-content-center">
        {props.children}
      </div>
    );
  };

  const chatContents = () => {
    if (currentChannelInfo) {
      return (
        <div style={{ height: "70vh" }} className="w-full">
          <SendbirdChatApp
            sendbirdChannelUrl={currentChannelInfo.channel.url}
            {...props}
          />
        </div>
      );
    } else {
      return (
        <div className="flex items-center h-screen pb-40 text-xl text-center text-gray-600 place-content-center">
          <div className="inline-block align-middle">
            No messages selected.
            <br />
            Please select someone to chat with on the left.
          </div>
        </div>
      );
    }
  };

  return (
    <div className="flex pb-16 sm:pb-0" data-section="chat-sidebar">
      <Channels>
        <MobileHiddenHeader>Messages</MobileHiddenHeader>
        <div className="flex flex-col">
          <div>
            <ChatAppDisclaimerBanner
              padding="pl-8 pt-4 pb-6 pr-3"
              bannerText={bannerText}
            />
            {memberChannels.map((channelInfo) => {
              return (
                <ChatSidebarElement
                  key={channelInfo.channel.id}
                  channelInfo={channelInfo}
                  currentChannelId={currentChannelId}
                />
              );
            })}
          </div>
        </div>
      </Channels>

      <Chat>
        <MobileHiddenHeader>
          {currentChannelInfo && (
            <CurrentChannelHeader channelInfo={currentChannelInfo} />
          )}
        </MobileHiddenHeader>
        {currentChannelInfo && (
          <OutOfOfficeBanner
            account={currentChannelInfo.account}
            assignedCoach={currentChannelInfo.account.assignedCoach}
            backupCoach={currentChannelInfo.account.backupCoach}
            channelId={currentChannelId}
            chatType={currentChannelInfo.channel.chatType}
          />
        )}
        {chatContents()}
      </Chat>
    </div>
  );
};

export default DeprecatedMemberChatApp;

const CurrentChannelHeader = ({
  channelInfo,
}: {
  channelInfo: ChannelInfoProps;
}) => {
  return (
    <>
      <div className="flex justify-center w-2/3 space-x-0">
        <UserProfiles channelInfo={channelInfo} />
        <UserNames channelInfo={channelInfo} />
      </div>
    </>
  );
};

export const StafferImage = ({
  brightlineParticipant,
}: {
  brightlineParticipant: ChatUserProfile;
}) => {
  const photoUrl = brightlineParticipant.photoUrl;
  const firstInitial = brightlineParticipant.nickname.charAt(0);

  if (photoUrl) {
    return <Image src={photoUrl} className="w-12 h-12 rounded-full" />;
  } else {
    return (
      <div className="relative items-center w-12 h-12 text-center text-black align-middle bg-orange-700 rounded-full place-content-center">
        <div className="absolute w-12 top-3 ">{firstInitial}</div>
      </div>
    );
  }
};

const UserProfiles = ({ channelInfo }: { channelInfo: ChannelInfoProps }) => {
  return (
    <>
      {channelInfo.brightlineParticipants.map((brightlineParticipant) => {
        return (
          <div key={brightlineParticipant.id}>
            <div className="flex flex-col items-center m-auto">
              <div className="w-12 h-12">
                <StafferImage brightlineParticipant={brightlineParticipant} />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

const UserNames = ({ channelInfo }: { channelInfo: ChannelInfoProps }) => {
  return (
    <div className="pl-3 m-auto">
      {channelInfo.brightlineParticipants.map((bp) => bp.nickname).join(" & ")}
    </div>
  );
};
export interface MemberChatProps {
  currentChannelId: string;
  currentChatParticipant: ChatParticipant;
  bannerText: string;
  env: ChatEnv;
  memberChannels: ChannelInfoProps[];
}

export interface ChannelInfoProps {
  lastMessage?: {
    createdAt: number;
    message: string;
  };
  channel: ChannelProps;
  unread: boolean;
  brightlineParticipants: ChatUserProfile[];
  account: AccountProps;
}

interface ChatUserProfile {
  id: string;
  nickname: string;
  photoUrl: string;
}

export interface ChannelProps {
  accountId: string;
  chatType: string;
  id: string;
  url: string;
}

export interface AccountProps {
  id: string;
  assignedCoach: CoachProps;
  backupCoach: CoachProps;
}

export interface CoachProps {
  id: string;
  firstName: string;
  lastName: string;
  outOfOfficeBlock: OutOfOfficeProps;
}

interface OutOfOfficeProps {
  start: string;
  end: string;
}
