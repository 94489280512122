import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const StrategiesToRelieveStressScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    strategyOne: "",
    strategyOneUse: "",
    strategyTwo: "",
    strategyTwoUse: "",
    strategyThree: "",
    strategyThreeUse: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [strategyOne, setStrategyOne] = React.useState(
    initialState.strategyOne
  );
  const [strategyOneUse, setStrategyOneUse] = React.useState(
    initialState.strategyOneUse
  );
  const [strategyTwo, setStrategyTwo] = React.useState(
    initialState.strategyTwo
  );
  const [strategyTwoUse, setStrategyTwoUse] = React.useState(
    initialState.strategyTwoUse
  );
  const [strategyThree, setStrategyThree] = React.useState(
    initialState.strategyThree
  );
  const [strategyThreeUse, setStrategyThreeUse] = React.useState(
    initialState.strategyThreeUse
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      strategyOne,
      strategyOneUse,
      strategyTwo,
      strategyTwoUse,
      strategyThree,
      strategyThreeUse,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Strategies to Relieve Stress</EE.Heading>
          <EE.HR />
          <EE.Paragraph className="my-4">
            You and your coach have talked about different strategies to use
            when you are stressed. It’s helpful to think ahead about the
            strategies that work for you so that you have them ready to use when
            stress hits.
          </EE.Paragraph>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>Strategy #1:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setStrategyOne(e.target.value)
              }
              value={strategyOne}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>When I can use this strategy:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setStrategyOneUse(e.target.value)
              }
              value={strategyOneUse}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>Strategy #2:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setStrategyTwo(e.target.value)
              }
              value={strategyTwo}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>When I can use this strategy:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setStrategyTwoUse(e.target.value)
              }
              value={strategyTwoUse}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>Strategy #3:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setStrategyThree(e.target.value)
              }
              value={strategyThree}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>When I can use this strategy:</EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setStrategyThreeUse(e.target.value)
              }
              value={strategyThreeUse}
            />
          </EE.QuestionLabel>

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default StrategiesToRelieveStressScreens;
