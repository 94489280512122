import "@sendbird/uikit-react/dist/index.css";
import "@js/css/ChatApp.scss";

import {
  addLiveRegionToAnnouncements,
  linkifySendbirdMessages,
  makeFileHeaderLinksVisibleToScreenReaders,
  makeMessageButtonsVisibleToScreenReaders,
  removeChatHeader,
  rewriteSendbirdAttachmentLinks,
  tabifyFilePreviewControls,
} from "@js/helpers/sendbirdLinks";
import BrightlineIcon from "@js/images/branding/icons/Marigold.png";
import Channel from "@sendbird/uikit-react/Channel";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import React, { useEffect } from "react";

const ChatApp = (props: ChatProps) => {
  const { env, currentChatParticipant, sendbirdChannelUrl } = props;
  if (!currentChatParticipant || !sendbirdChannelUrl) {
    return <div>Loading...</div>;
  }
  const { appID, logLevel } = env.sendbird;
  const { id: userId, name, sessionToken, role } = currentChatParticipant;

  useEffect(() => {
    const monitorLinks = setInterval(linkifySendbirdMessages, 500);

    return () => {
      clearInterval(monitorLinks);
    };
  });

  useEffect(() => {
    const monitorAttachments = setInterval(rewriteSendbirdAttachmentLinks, 500);
    return () => {
      clearInterval(monitorAttachments);
    };
  });

  useEffect(() => {
    const monitorFilePreviewControls = setInterval(
      tabifyFilePreviewControls,
      500
    );

    return () => {
      clearInterval(monitorFilePreviewControls);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(makeFileHeaderLinksVisibleToScreenReaders, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(removeChatHeader, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(makeMessageButtonsVisibleToScreenReaders, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const timer = setInterval(addLiveRegionToAnnouncements, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <SendbirdProvider
      appId={appID}
      userId={userId}
      nickname={name}
      accessToken={sessionToken}
      profileUrl={`${BrightlineIcon}`}
      config={{ logLevel }}
    >
      <Channel
        isReactionEnabled={false}
        channelUrl={sendbirdChannelUrl}
        replyType="NONE"
        disableUserProfile={true}
      />
    </SendbirdProvider>
  );
};

export default ChatApp;

export interface ChatProps {
  sendbirdChannelUrl: string;
  currentChatParticipant: ChatParticipant;
  env: ChatEnv;
}

export interface ChatParticipant {
  id: string;
  name: string;
  sessionToken: string;
  role: string;
}

export interface ChatEnv {
  sendbird: {
    appID: string;
    logLevel: string;
  };
}
