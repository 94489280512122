import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";

export default class extends Controller {
  static targets = ["expand", "collapse"];
  declare readonly expandTargets: HTMLSelectElement[];
  declare readonly collapseTargets: HTMLSelectElement[];

  // name and eventMetadata can be used to send event data to Ahoy
  static values = {
    name: String,
    eventMetadata: Object,
  };

  declare readonly nameValue: String;
  declare readonly eventMetadataValue: Object;

  connect() {
    this.collapseTargets.forEach((collapseTarget) =>
      collapseTarget.classList.add("hidden")
    );
  }

  toggle(event: any) {
    if (event.key && event.key !== "Enter") return;

    if (
      this.collapseTargets.some((collapseTarget) =>
        collapseTarget.classList.contains("hidden")
      )
    ) {
      this.expand();
    } else {
      this.collapse();
    }
  }

  expand() {
    this.collapseTargets.forEach((collapseTarget) =>
      collapseTarget.classList.remove("hidden")
    );
    this.expandTargets.forEach((expandTarget) =>
      expandTarget.classList.add("hidden")
    );
    if (this.nameValue) {
      ahoy.track("Accordion:Expanded", this.getEventProperties());
    }
  }

  collapse() {
    this.collapseTargets.forEach((collapseTarget) =>
      collapseTarget.classList.add("hidden")
    );
    this.expandTargets.forEach((expandTarget) =>
      expandTarget.classList.remove("hidden")
    );
    if (this.nameValue) {
      ahoy.track("Accordion:Collapsed", this.getEventProperties());
    }
  }

  getEventProperties() {
    return {
      accordionName: this.nameValue,
      page: window.location.pathname,
      ...this.eventMetadataValue,
    };
  }
}
