/*
This controller can be used to fire events to Segment. You can fire events on connect or via a stimulus action.

 Setup:
   <div data-controller="landing-page-track"/>

   Fire event on connect:
     <div data-controller="landing-page-track" data-landing-page-track-event-value="SomeEvent:Viewed" data-landing-page-track-properties-value='{"member_id":123}' />

 Actions:

  Use the following actions to trigger the `segment.track(...)` call on click.

  Example button click:
  <div data-controller="landing-page-track">
    <button data-action="click->landing-page-track#track" data-event="SomeEvent:Clicked" data-properties='{"member_id":123}' />
  </div>


*/

import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";

import { SegmentClient, setAnonymousId } from "../helpers/segment-client";

export default class extends Controller {
  declare readonly hasEventValue: boolean;
  declare eventValue: string;
  declare propertiesValue: object;
  declare identifierValue: string;

  static get values() {
    return {
      event: {
        type: String,
        default: "",
      },
      properties: {
        type: Object,
        default: {},
      },
      identifier: {
        type: String,
        default: "",
      },
    };
  }

  connect() {
    // I shouldn't have to check for an empty string but, if I remove the default of "" I get a type error.
    if (this.hasEventValue && this.eventValue !== "") {
      // Wait till we have set the anonymous id cookie before sending the event.
      setAnonymousId(SegmentClient, this.identifierValue).then(
        (anonymousId) => {
          this.sendTrack(this.eventValue, this.properties());
        }
      );
    }
  }

  // Copied logic from our analytics_controller.js maybe need to be changed once used more frequently.
  track(event: any) {
    if (event.key && event.key !== "Enter") return;

    const target = event.target;
    const eventName = target.data.get("event");
    const properties = target.data.get("properties") || "{}";
    if (!eventName) return;

    this.sendTrack(eventName, properties);
  }

  sendTrack(eventName: string, properties: object) {
    // Dual sending for now until we can make sure marketing and DS can pick up the events proxied through ahoy.
    SegmentClient?.track(eventName, properties);
    ahoy.track(eventName, properties);
  }

  properties() {
    return {
      ...this.propertiesValue,
      ...this.parseReferrer(),
    };
  }

  parseReferrer() {
    let result: { [key: string]: string } = {};
    if (document.referrer && document.referrer !== "") {
      const referrer = new URL(document.referrer);

      let fbclid = referrer.searchParams.get("fbclid");
      if (fbclid) {
        let timestamp = Date.now();
        result["fbc"] = `fb.1.${timestamp}.${fbclid}`;
      }

      let gclid = referrer.searchParams.get("gclid");
      if (gclid) {
        result["gclid"] = gclid;
      }

      let liclid = referrer.searchParams.get("li_giant");
      if (liclid) {
        result["li_giant"] = liclid;
      }
    }
    return result;
  }
}
