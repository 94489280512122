import Weather0 from "./Weather0.png";
import Weather1 from "./Weather1.png";
import Weather10 from "./Weather10.png";
import Weather2 from "./Weather2.png";
import Weather3 from "./Weather3.png";
import Weather4 from "./Weather4.png";
import Weather5 from "./Weather5.png";
import Weather6 from "./Weather6.png";
import Weather7 from "./Weather7.png";
import Weather8 from "./Weather8.png";
import Weather9 from "./Weather9.png";

export const BadToGood = [
  Weather0,
  Weather1,
  Weather2,
  Weather3,
  Weather4,
  Weather5,
  Weather6,
  Weather7,
  Weather8,
  Weather9,
  Weather10,
];

// reverse() mutates array in-place, so need to copy
export const GoodToBad = [...BadToGood].reverse();

export default { GoodToBad, BadToGood };
