// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "controllers";
import "@hotwired/turbo";
import "@hotwired/turbo-rails";

import "../helpers/configure_ahoy";
import "../helpers/sentry";
import "../helpers/datadog";
import "../helpers/segment-client";
import "../components";

// Have to polyfill scroll-behavior: smooth for Safari
// https://caniuse.com/?search=scroll-behavior
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

require("@rails/ujs").start();
