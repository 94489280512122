import Image from "@js/components/shared/Image";
import FeelingImages from "@js/images/feelings";
import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const HowIShowMyFeelingsScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    goodMood: {
      thingsIDo: "",
      lookAndSound: "",
      thinkAboutMyself: "",
      bodyFeels: "",
      actAroundOthers: "",
    },
    badMood: {
      thingsIDo: "",
      lookAndSound: "",
      thinkAboutMyself: "",
      bodyFeels: "",
      actAroundOthers: "",
    },
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };

  const [thingsIDoGoodMood, setThingsIDoGoodMood] = React.useState(
    initialState.goodMood.thingsIDo
  );
  const [lookAndSoundGoodMood, setLookAndSoundGoodMood] = React.useState(
    initialState.goodMood.lookAndSound
  );
  const [thinkAboutMyselfGoodMood, setThinkAboutMyselfGoodMood] =
    React.useState(initialState.goodMood.thinkAboutMyself);
  const [bodyFeelsGoodMood, setBodyFeelsGoodMood] = React.useState(
    initialState.goodMood.bodyFeels
  );
  const [actAroundOthersGoodMood, setActAroundOthersGoodMood] = React.useState(
    initialState.goodMood.actAroundOthers
  );
  const [thingsIDoBadMood, setThingsIDoBadMood] = React.useState(
    initialState.badMood.thingsIDo
  );
  const [lookAndSoundBadMood, setLookAndSoundBadMood] = React.useState(
    initialState.badMood.lookAndSound
  );
  const [thinkAboutMyselfBadMood, setThinkAboutMyselfBadMood] = React.useState(
    initialState.badMood.thinkAboutMyself
  );
  const [bodyFeelsBadMood, setBodyFeelsBadMood] = React.useState(
    initialState.badMood.bodyFeels
  );
  const [actAroundOthersBadMood, setActAroundOthersBadMood] = React.useState(
    initialState.badMood.actAroundOthers
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      goodMood: {
        thingsIDo: thingsIDoGoodMood,
        lookAndSound: lookAndSoundGoodMood,
        thinkAboutMyself: thinkAboutMyselfGoodMood,
        bodyFeels: bodyFeelsGoodMood,
        actAroundOthers: actAroundOthersGoodMood,
      },
      badMood: {
        thingsIDo: thingsIDoBadMood,
        lookAndSound: lookAndSoundBadMood,
        thinkAboutMyself: thinkAboutMyselfBadMood,
        bodyFeels: bodyFeelsBadMood,
        actAroundOthers: actAroundOthersBadMood,
      },
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>How I Show My Feelings</EE.Heading>
          <EE.HR />

          <div id="goodMoodFields">
            <EE.Subheading id="goodMood">
              When I am in a good mood...
            </EE.Subheading>

            <Image src={FeelingImages.happy} className="w-1/3 py-8 mx-auto" />
            <EE.QuestionLabel id="thingsIDoGoodMood">
              These are the things I usually do (activities, people I spend time
              with):
              <EE.TextArea
                value={thingsIDoGoodMood}
                onChange={(e) => setThingsIDoGoodMood(e.target.value)}
                aria-labelledby="goodMood thingsIDoGoodMood"
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel id="lookAndSoundGoodMood">
              This is how I look and sound to other people (eye contact or not,
              slumping or standing up, sad or happy voice):
              <EE.TextArea
                value={lookAndSoundGoodMood}
                onChange={(e) => setLookAndSoundGoodMood(e.target.value)}
                aria-labelledby="goodMood lookAndSoundGoodMood"
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel id="thinkAboutMyselfGoodMood">
              This is what I think about myself and my world:
              <EE.TextArea
                value={thinkAboutMyselfGoodMood}
                onChange={(e) => setThinkAboutMyselfGoodMood(e.target.value)}
                aria-labelledby="goodMood thinkAboutMyselfGoodMood"
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel id="bodyFeelsGoodMood">
              This is how my body feels (antsy, sleepy, relaxed, etc.):
              <EE.TextArea
                value={bodyFeelsGoodMood}
                onChange={(e) => setBodyFeelsGoodMood(e.target.value)}
                aria-labelledby="goodMood bodyFeelsGoodMood"
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel id="actAroundOthersGoodMood">
              This is how I act around others:
              <EE.TextArea
                value={actAroundOthersGoodMood}
                onChange={(e) => setActAroundOthersGoodMood(e.target.value)}
                aria-labelledby="goodMood actAroundOthersGoodMood"
              />
            </EE.QuestionLabel>
          </div>
          <div id="badMoodFields">
            <EE.Subheading id="badMood">
              When I am in a bad mood...
            </EE.Subheading>
            <Image src={FeelingImages.sad} className="w-1/3 py-8 mx-auto" />
            <EE.QuestionLabel id="thingsIDoBadMood">
              These are the things I usually do (activities, people I spend time
              with):
              <EE.TextArea
                value={thingsIDoBadMood}
                onChange={(e) => setThingsIDoBadMood(e.target.value)}
                aria-labelledby="badMood thingsIDoBadMood"
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel id="lookAndSoundBadMood">
              This is how I look and sound to other people (eye contact or not,
              slumping or standing up, sad or happy voice):
              <EE.TextArea
                value={lookAndSoundBadMood}
                onChange={(e) => setLookAndSoundBadMood(e.target.value)}
                aria-labelledby="badMood lookAndSoundBadMood"
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel id="thinkAboutMyselfBadMood">
              This is what I think about myself and my world:
              <EE.TextArea
                value={thinkAboutMyselfBadMood}
                onChange={(e) => setThinkAboutMyselfBadMood(e.target.value)}
                aria-labelledby="badMood thinkAboutMyselfBadMood"
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel id="bodyFeelsBadMood">
              This is how my body feels (antsy, sleepy, relaxed, etc.):
              <EE.TextArea
                value={bodyFeelsBadMood}
                onChange={(e) => setBodyFeelsBadMood(e.target.value)}
                aria-labelledby="badMood bodyFeelsBadMood"
              />
            </EE.QuestionLabel>
            <EE.QuestionLabel id="actAroundOthersBadMood">
              This is how I act around others:
              <EE.TextArea
                value={actAroundOthersBadMood}
                onChange={(e) => setActAroundOthersBadMood(e.target.value)}
                aria-labelledby="badMood actAroundOthersBadMood"
              />
            </EE.QuestionLabel>
          </div>
          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default HowIShowMyFeelingsScreens;
