import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useWhoIAm } from "./Context";

const FavoriteBox = (props) => {
  const { title, contents, icon, bgColor, iconColor } = props;

  const boxClasses = `rounded-2.5xl flex py-7 px-4 justify-between items-center bg-${bgColor}`;
  const iconClasses = `text-4xl material-icons text-${iconColor}`;
  return (
    <div className={boxClasses}>
      <div>
        <div>{title}</div>
        <p>{contents}</p>
      </div>
      <div className={iconClasses}>{icon}</div>
    </div>
  );
};

const ShortInfoBox = (props) => {
  const { title, contents } = props;
  return (
    <div className="pb-6">
      <div className="font-sans text-base font-bold leading-7 md:text-lg">
        {title}
      </div>
      <div className="font-sans text-base leading-7 md:text-lg">{contents}</div>
    </div>
  );
};

const LongInfoBox = (props) => {
  const { title, contents, extraContentsClasses } = props;
  const contentsClasses = `font-sans text-sm leading-6 md:text-base ${extraContentsClasses}`;
  return (
    <div className="pb-6">
      <div className="font-sans text-2xl font-medium leading-9 md:text-3xl md:leading-11">
        {title}
      </div>
      <div className={contentsClasses}>{contents}</div>
    </div>
  );
};

const Summary = () => {
  const {
    name,
    birthday,
    graduatingClass,
    ethnicity,
    placesIHaveLived1,
    placesIHaveLived2,
    placesIHaveLived3,
    placesIHaveLived4,
    placesIHaveLived5,
    placesIHaveLived6,
    supportSystem,
    hobbies1,
    hobbies2,
    hobbies3,
    hobbies4,
    hobbies5,
    hobbies6,
    favoriteMovieOrShow,
    favoriteColor,
    favoriteBookOrMagazine,
    favoriteFood,
    favoriteClassOrSubject,
    favoriteMusicGenre,
    pets,
    goodFriend,
    freeTime,
    haveNever,
    assignedExerciseId,
    completedExercise,
  } = useWhoIAm();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      name,
      birthday,
      graduatingClass,
      ethnicity,
      placesIHaveLived1,
      placesIHaveLived2,
      placesIHaveLived3,
      placesIHaveLived4,
      placesIHaveLived5,
      placesIHaveLived6,
      supportSystem,
      hobbies1,
      hobbies2,
      hobbies3,
      hobbies4,
      hobbies5,
      hobbies6,
      favoriteMovieOrShow,
      favoriteColor,
      favoriteBookOrMagazine,
      favoriteFood,
      favoriteClassOrSubject,
      favoriteMusicGenre,
      pets,
      goodFriend,
      freeTime,
      haveNever,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  const allHobbies = [
    hobbies1,
    hobbies2,
    hobbies3,
    hobbies4,
    hobbies5,
    hobbies6,
  ]
    .filter((val) => val)
    .join(", ");
  const allPlacesLived = [
    placesIHaveLived1,
    placesIHaveLived2,
    placesIHaveLived3,
    placesIHaveLived4,
    placesIHaveLived5,
    placesIHaveLived6,
  ]
    .filter((val) => val)
    .join("; ");

  return (
    <EE.SubmitableForm submitExercise={submitExercise}>
      <h1 className="bg-blue-400 rounded-t-2.5xl text-gray-800 text-3xl md:text-4xl leading-12 md:leading-14 font-sans font-semibold px-6 pb-6 pt-12 mb-8 md:px-12 md:pt-19 md:pb-8">
        {name}
      </h1>
      <div className="px-6 pb-4 md:px-8">
        <ShortInfoBox title="Born" contents={birthday} />
        <ShortInfoBox title="Graduating class" contents={graduatingClass} />
        <ShortInfoBox title="Ethnicity/Race" contents={ethnicity} />
        <ShortInfoBox title="Places I've lived" contents={allPlacesLived} />
        <LongInfoBox
          title="My support system is..."
          contents={supportSystem}
          extraContentsClasses="italic"
        />
        <LongInfoBox title="My hobbies/sports are..." contents={allHobbies} />
        <div className="pb-6">
          <div className="pb-6 font-sans text-2xl font-medium leading-9 md:text-3xl md:leading-11">
            A few of my favorites are...
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <FavoriteBox
              title="Movie/Tv Show"
              contents={favoriteMovieOrShow}
              bgColor="yellow-200"
              iconColor="yellow-300"
              icon="videocam"
            />
            <FavoriteBox
              title="Color"
              contents={favoriteColor}
              bgColor="blue-300"
              iconColor="blue-500"
              icon="color_lens"
            />
            <FavoriteBox
              title="Book/Magazine"
              contents={favoriteBookOrMagazine}
              bgColor="green-300"
              iconColor="green-700"
              icon="menu_book"
            />
            <FavoriteBox
              title="Class/Subject"
              contents={favoriteClassOrSubject}
              bgColor="yellow-200"
              iconColor="yellow-300"
              icon="edit"
            />
            <FavoriteBox
              title="Music Genre"
              contents={favoriteMusicGenre}
              bgColor="blue-300"
              iconColor="blue-500"
              icon="audiotrack"
            />
            <FavoriteBox
              title="Food"
              contents={favoriteFood}
              bgColor="green-300"
              iconColor="green-700"
              icon="restaurant"
            />
          </div>
        </div>
        <LongInfoBox title="My pets are..." contents={pets} />
        <LongInfoBox
          title="What makes me a good friend:"
          contents={goodFriend}
        />
        <LongInfoBox title="Where I spend my free time:" contents={freeTime} />
        <LongInfoBox title="I have never..." contents={haveNever} />
        <EE.ButtonGroup>
          <EE.ResumableExerciseActions
            submitButtonText="I'm done"
            setExerciseComplete={setExerciseComplete}
            showSaveForLaterButton={true}
          />
        </EE.ButtonGroup>
      </div>
    </EE.SubmitableForm>
  );
};

export default Summary;
