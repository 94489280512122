import { Controller } from "@hotwired/stimulus";

// TODO: Give this a generic name
export default class extends Controller {
  static targets = [
    "form",
    "modal",
    "titleField",
    "groupField",
    "dateField",
    "notesField",
    "priorityField",
  ];

  connect() {
    this.formTarget.addEventListener("ajax:success", (e) => {
      $(this.modalTarget).modal("hide");
      this.refresh();
    });
  }

  // TODO: The list of fields should come from data attribute as well
  edit(event) {
    event.preventDefault();
    this.setFormTargetMethod("patch");
    this.formTarget.action = event.target.dataset["path"];
    this.titleFieldTarget.value = event.target.dataset["title"];
    this.groupFieldTarget.value = event.target.dataset["group"];
    this.priorityFieldTarget.value = event.target.dataset["priority"];
    this.dateFieldTarget.value = event.target.dataset["date"];
    this.notesFieldTarget.value = event.target.dataset["notes"] || "";

    $(this.modalTarget).modal("show");
  }

  new(event) {
    event.preventDefault();
    this.setFormTargetMethod("post");
    this.formTarget.action = event.target.dataset["path"];
    this.titleFieldTarget.value = "";
    this.groupFieldTarget.value = "";
    this.dateFieldTarget.value = "";
    this.notesFieldTarget.value = "";
    this.priorityFieldTarget.value = "";

    $(this.modalTarget).modal("show");
  }

  refresh() {
    Rails.ajax({
      url: this.data.get("refreshUrl"),
      type: "get",
      dataType: "script",
    });
  }

  /**
   * Sets the method of the form target to the given value.
   *
   * @param {string} method
   *
   * @example
   * this.setFormTargetMethod("patch"); // Sets the form target method to PATCH
   *
   * @returns {void}
   */
  setFormTargetMethod(method) {
    this.formTarget.querySelector("[name=_method]").value = method;
  }
}
