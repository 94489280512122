import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["loaded", "loading", "selectable"];
  NAVIGATION_INDICATOR_DELAY = 250;

  initialize() {
    this.defaultTab = String(this.element.dataset.defaultTab);

    this.selectableTargets.forEach((selectable) => {
      selectable.classList.remove("active");
      if (selectable.dataset.navigationTabParam === this.defaultTab) {
        selectable.classList.add("active");
      }
    });
  }

  showLoading() {
    const loadingElement = this.loadingTarget;
    const loadedTarget = this.loadedTarget;

    setTimeout(() => {
      loadingElement.classList.remove("hidden");
      loadedTarget.classList.add("hidden");
    }, this.NAVIGATION_INDICATOR_DELAY);
  }

  setSelected(event) {
    const url = new URL(window.location);
    url.searchParams.set("tab", event.params["tab"]);
    window.history.pushState({}, "", url);

    this.selectableTargets.forEach((selectable) => {
      selectable.classList.remove("active");
      if (selectable === event.target) {
        selectable.classList.add("active");
      }
    });
  }
}
