import { Controller } from "@hotwired/stimulus";
const SELECTED_CLASSES = ["font-bold", "border-b-4", "border-blue-700"];

export default class extends Controller {
  static targets = ["button", "content", "empty", "filter"];
  static values = { selected: String };

  connect() {
    this.reset();

    if (this.hasSelectedValue) {
      const matchingFilter = this.filterTargets.find(
        (el) => el.dataset.tab === this.selectedValue
      );
      if (matchingFilter) {
        this.applyFilter(this.selectedValue);
      }
    }
  }

  filter(event) {
    this.applyFilter(event.currentTarget.dataset.tab);
  }

  applyFilter(tab) {
    this.setSelected(tab);

    if (tab === "all") return this.reset();

    let contentsFound = false;
    this.contentTargets.forEach((el) => {
      if (el.dataset.tab === tab) {
        el.parentNode.style.display = "block";
        contentsFound = true;
      } else {
        el.parentNode.style.display = "none";
      }
    });

    this.emptyTargets.forEach((el) => {
      el.parentNode.style.display = "none";
    });

    if (!contentsFound) {
      this.emptyTargets.forEach((el) => {
        if (el.dataset.tab === tab) {
          el.parentNode.style.display = "block";
        }
      });
    }
  }

  setSelected(tab) {
    this.filterTargets.forEach((el) => {
      el.classList.remove(...SELECTED_CLASSES);
    });

    this.filterTargets
      .find((el) => el.dataset.tab === tab)
      .classList.add(...SELECTED_CLASSES);
  }

  reset() {
    this.contentTargets.forEach((el) => {
      el.parentNode.style.display = "block";
    });

    this.emptyTargets.forEach((el) => {
      el.parentNode.style.display = "none";
    });
  }
}
