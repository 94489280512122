import ChildAndCaregiverPainting from "@js/images/exercises/ChildAndCaregiverPainting.jpg";
import DadAndChildScooting from "@js/images/exercises/DadAndChildScooting.jpg";
import GirlJumpRoping from "@js/images/exercises/GirlJumpRoping.jpg";
import React, { useState } from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const SchedulingTimeForFunScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    day: "",
    plannedActivity: "",
    beforeThermometerValue: 5,
    activityIDid: "",
    afterThermometerValue: 5,
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };

  const [day, setDay] = React.useState(initialState.day);
  const [plannedActivity, setPlannedActivity] = React.useState(
    initialState.plannedActivity
  );
  const [beforeThermometerValue, setBeforeThermometerValue] = React.useState(
    initialState.beforeThermometerValue
  );
  const [activityIDid, setActivityIDid] = React.useState(
    initialState.activityIDid
  );
  const [afterThermometerValue, setAfterThermometerValue] = React.useState(
    initialState.afterThermometerValue
  );
  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      day,
      plannedActivity,
      beforeThermometerValue,
      activityIDid,
      afterThermometerValue,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Scheduling Time for Fun</EE.Heading>
          <EE.HR />
          <EE.Caption>
            Write down one fun activity you can do every day. Then write your
            Feelings Thermometer rating before and after each activity.
          </EE.Caption>

          <EE.QuestionLabel>
            Day of the week:
            <EE.TextInput
              value={day}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDay(e.target.value);
              }}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            Plan:
            <EE.TextArea
              value={plannedActivity}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                setPlannedActivity(e.target.value);
              }}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel className="mt-8" id="felt-before-label">
            How I felt before
            <EE.BasicThermometer
              value={beforeThermometerValue}
              setValue={setBeforeThermometerValue}
              ariaLabelledByForHandle="felt-before-label"
            />
          </EE.QuestionLabel>
          <EE.QuestionLabel>
            Activity I Did:
            <EE.TextInput
              value={activityIDid}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setActivityIDid(e.target.value);
              }}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel className="mt-8" id="felt-after-label">
            How I felt after
            <EE.BasicThermometer
              value={afterThermometerValue}
              setValue={setAfterThermometerValue}
              ariaLabelledByForHandle="felt-after-label"
            />
          </EE.QuestionLabel>

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default SchedulingTimeForFunScreens;
