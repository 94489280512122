import { Controller } from "@hotwired/stimulus";

const DEFAULT_ERROR_MESSAGE = "Something went wrong!";

export default class extends Controller {
  static targets = ["form", "errors"];
  static values = {
    url: String,
    checkValidity: Boolean,
  };

  connect() {
    this.eventSource().addEventListener("ajax:success", (e) => {
      this.refresh();
    });

    this.eventSource().addEventListener("ajax:error", (e) => {
      this.showError(e);
    });
  }

  eventSource() {
    if (this.hasFormTarget) {
      return this.formTarget;
    } else {
      return this.element;
    }
  }

  refresh() {
    if (
      this.checkValidityValue &&
      this.hasFormTarget &&
      !this.formTarget.reportValidity()
    ) {
      return;
    }

    Rails.ajax({
      url: this.urlValue ?? this.data.get("url") ?? this.element.dataset.url,
      type: "get",
      dataType: "script",
    });
  }

  showError(e) {
    const [data] = e.detail;
    const errors = data.errors ?? [DEFAULT_ERROR_MESSAGE];

    // OK to clear innerHTML as long as the children don't have event listeners
    if (this.hasErrorsTarget) {
      this.errorsTarget.innerHTML = "";
      this.errorsTarget.appendChild(this.errorMessageElementsFor(errors));
    } else {
      alert(errors.join("\r\n"));
    }
  }

  errorMessageElementsFor(errors) {
    const errorList = document.createElement("ul");
    errorList.classList = "alert alert-danger list-style-none p-4";
    errorList.setAttribute("role", "alert");

    const errorListItems = errors.map((error) => {
      const listItem = document.createElement("li");
      listItem.innerHTML = error;
      return listItem;
    });

    errorListItems.forEach((errorListItem) =>
      errorList.appendChild(errorListItem)
    );

    return errorList;
  }
}
