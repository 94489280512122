import { Controller } from "@hotwired/stimulus";
import { ONE_SECOND } from "@js/helpers/constants";
import throttle from "lodash/throttle";

export default class extends Controller {
  FADE_TIMEOUT = ONE_SECOND / 2;
  static targets = ["back", "question", "answers"];

  initialize() {
    this.showNextQuestion = throttle(this.showNextQuestion, ONE_SECOND);
    this.showPreviousQuestion = throttle(this.showPreviousQuestion, ONE_SECOND);
  }

  showNextQuestion(event) {
    if (event.key && event.key !== "Enter") return;
    if (this.questions.showNext()) return;

    event.stopImmediatePropagation();
  }

  showPreviousQuestion() {
    this.questions.showPrevious();
  }

  get questions() {
    return new Questions({
      elements: this.questionTargets,
      backButton: this.backTarget,
      answersSets: this.answersTargets,
    });
  }
}

class Questions {
  FADE_TIMEOUT = ONE_SECOND / 2;

  constructor({ elements, backButton, answersSets }) {
    this.elements = elements;
    this.answersSets = answersSets;
    this.backButton = new BackButton(backButton);
  }

  showNext() {
    if (this.hasRespondedToCurrentQuestion()) {
      this.transitionTo(this.currentIndex + 1);
      return true;
    }
  }

  showPrevious() {
    this.transitionTo(this.currentIndex - 1);
  }

  transitionTo(questionIndex) {
    this.hideAll();
    this.show(questionIndex);
    this.setBackButtonVisibility();
  }

  hasRespondedToCurrentQuestion() {
    const question = document.querySelector(
      `[data-question-index="${this.currentIndex}"]`
    );
    const answers = [...question.getElementsByTagName("input")];

    return answers.some((answer) => answer.checked);
  }

  hideAll() {
    this.elements.forEach((element) => {
      element.classList.remove(
        "fade-in",
        "assessment-assignment__question--current"
      );
      element.classList.add("fade-out");
      setTimeout(() => {
        element.classList.add("absolute");
        element.classList.remove("relative");
      }, this.FADE_TIMEOUT);
    });
    setTimeout(() => {
      this.answersSets.forEach((answers) => {
        answers.classList.remove("fixed");
      });
    }, this.FADE_TIMEOUT);
  }

  show(questionIndex) {
    const question = document.querySelector(
      `[data-question-index="${questionIndex}"]`
    );
    question.classList.remove("fade-out", "hidden");
    question.classList.add("assessment-assignment__question--current");
    setTimeout(() => {
      question.classList.add("fade-in", "relative");
      question.classList.remove("absolute");
    }, this.FADE_TIMEOUT);
    const answers = document.querySelector(
      `[data-answers-index="${questionIndex}"]`
    );
    if (answers != null) {
      setTimeout(() => {
        answers.classList.add("fixed");
      }, this.FADE_TIMEOUT);
    }
  }

  setBackButtonVisibility() {
    this.isFirstQuestion() ? this.backButton.hide() : this.backButton.show();
  }

  isFirstQuestion() {
    return this.currentIndex === 0;
  }

  get currentIndex() {
    return parseInt(
      document.querySelector(".assessment-assignment__question--current")
        .dataset.questionIndex
    );
  }
}

class BackButton {
  constructor(element) {
    this.element = element;
  }

  show() {
    this.element.classList.remove("invisible");
  }

  hide() {
    this.element.classList.add("invisible");
  }
}
