import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useMySelfInventory } from "./Context";

const Summary = () => {
  const {
    strengths,
    values,
    selfEsteem,
    stillGrowing,
    assignedExerciseId,
    completedExercise,
  } = useMySelfInventory();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      strengths,
      values,
      selfEsteem,
      stillGrowing,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.SubmitableForm submitExercise={submitExercise}>
      <div className="flex flex-row md:mt-12">
        <span className="text-yellow-300 material-icons">star</span>
        <div className="ml-4">
          <div className="font-bold">MY STRENGTHS</div>
          <div>{strengths}</div>
        </div>
      </div>

      <div className="flex flex-row mt-6">
        <span className="text-red-400 material-icons">favorite</span>
        <div className="ml-4">
          <div className="font-bold">MY VALUES</div>
          <div>{values}</div>
        </div>
      </div>

      <div className="flex flex-row mt-6">
        <span className="text-purple-400 material-icons">accessibility</span>
        <div className="ml-4">
          <div className="font-bold">MY DEFINITION OF SELF-ESTEEM</div>
          <div>{selfEsteem}</div>
        </div>
      </div>

      <div className="flex flex-row mt-6">
        <span className="text-green-300 material-icons">spa</span>
        <div className="ml-4">
          <div className="font-bold">WHERE I'M STILL GROWING</div>
          <div>{stillGrowing}</div>
        </div>
      </div>

      <EE.ButtonGroup>
        <EE.ResumableExerciseActions
          submitButtonText="I'm done"
          setExerciseComplete={setExerciseComplete}
        />
      </EE.ButtonGroup>
    </EE.SubmitableForm>
  );
};

export default Summary;
