import WeatherImages from "@js/images/weather";
import React from "react";

import { useThermometer } from "../../shared/Context";
import {
  ExerciseElements as EE,
  ThermometerElementsProps,
} from "../../shared/Elements";

const Title = () => {
  return <EE.Title>Feelings thermometer</EE.Title>;
};
const IntroductionText = () => {
  const { patientFirstName } = useThermometer();

  return (
    <ul>
      <EE.PaddedListItem>
        The Feelings Thermometer helps us understand {patientFirstName}
        's mood in connection with different situations or events.
      </EE.PaddedListItem>
      <EE.PaddedListItem>
        Using the Feelings Thermometer will help {patientFirstName} understand
        that feelings can have different intensities and build self-awareness.
      </EE.PaddedListItem>
      <EE.PaddedListItem>
        Over time, the thermometer will help us support {patientFirstName} in
        developing a coping plan to manage these feelings and learn what to do
        to feel better.
      </EE.PaddedListItem>
    </ul>
  );
};

const InstructionsText = () => {
  const { patientFirstName } = useThermometer();

  return (
    <ul>
      <EE.PaddedListItem>
        The Feelings Thermometer is a scale that represents {patientFirstName}
        's mood, from feeling very down to feeling great and everything in
        between. Select the image along the scale that best matches{" "}
        {patientFirstName}'s mood. Keep in mind that the thermometer is used to
        capture a wide range of feelings... not just the extremes!
      </EE.PaddedListItem>
      <EE.PaddedListItem>
        You can have {patientFirstName} enter a rating with you, or enter one on
        your own.
      </EE.PaddedListItem>
    </ul>
  );
};

const ImageMapping = WeatherImages.BadToGood;

const initialThermometerValue = 5;

const FeelingsThermometerElements: ThermometerElementsProps = {
  InstructionsText,
  IntroductionText,
  ImageMapping,
  Title,
  initialThermometerValue,
  minValueLabel: "Very Sad",
  maxValueLabel: "Very Happy",
  exerciseQuestion: "mood on the thermometer",
};

export default FeelingsThermometerElements;
