import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { frame: String };

  connect() {
    document.getElementById(this.frameValue)?.reload();
    this.element.remove();
  }
}
