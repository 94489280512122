/*
Purpose: This controller is intended for cases where we want to trigger a clicked state on a datetime slot in an availabilities grid,
when there's a value that is intended to specify a specific timeslot.

Example:
<div data-controller="toggle-clicked-state" data-toggle-clicked-state-for="<%=timestamp_matching_slot%>">
  <% Array(@slots[date]).each do |slot| %>
    <li data-toggle-clicked-state-target="slot" data-slot-identifier="<%= slot.datetime %>" data-trigger="click"> //DO SOMETHING ON CLICK
      <%= l(slot.datetime, format: :just_time_with_period) %>
    </li>
  <% end %>
</div>
*/

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["slot"];

  connect() {
    let selectedTime = this.data.get("for");

    this.slotTargets.forEach((slot) => {
      if (slot.dataset.slotIdentifier == selectedTime) {
        slot.click();
      }
    });
  }
}
