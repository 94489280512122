// import styles bundle
import "swiper/swiper-bundle.css";

import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";
import Swiper from "swiper";
import { A11y, FreeMode, Keyboard } from "swiper/modules";

export default class extends Controller {
  static targets = ["scroller"];
  static values = {
    id: String,
  };
  declare readonly scrollerTarget: HTMLElement;
  declare readonly idValue: String;

  connect() {
    let identifier = this.idValue;

    // https://swiperjs.com/swiper-api
    const scroller = new Swiper(this.scrollerTarget, {
      modules: [Keyboard, A11y, FreeMode],
      slidesPerView: "auto",
      spaceBetween: 16,
      a11y: {
        nextSlideMessage: "Next content card",
        lastSlideMessage: "This is the last content card",
        firstSlideMessage: "This is the first content card",
        containerMessage: `Here is content for ${identifier}`,
        slideLabelMessage: "Card {{index}} out of {{slidesLength}}",
      },
      keyboard: true,
      freeMode: true,
      slideClass: `card-swiper-slide`,
      preventClicks: false,
      preventClicksPropagation: false,
    });
    scroller.on("slideChangeTransitionEnd", function () {
      const properties = {
        cardCarouselIdValue: identifier,
        currentSlide: scroller.activeIndex + 1,
        totalSlides: scroller.slides.length,
        page: window.location.pathname,
      };
      ahoy.track("MemberCardCarousel:Scrolled", properties);
    });
  }
}
