import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const SessionSummaryScreen: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    sessionDate: "",
    therapistCoachName: "",
    weWorkedOn: "",
    theSkills: "",
    pleasePractice: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [sessionDate, setSessionDate] = React.useState(
    initialState.sessionDate
  );
  const [therapistCoachName, setTherapistCoachName] = React.useState(
    initialState.therapistCoachName
  );
  const [weWorkedOn, setWeWorkedOn] = React.useState(initialState.weWorkedOn);
  const [theSkills, setTheSkills] = React.useState(initialState.theSkills);
  const [pleasePractice, setPleasePractice] = React.useState(
    initialState.pleasePractice
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      sessionDate,
      therapistCoachName,
      weWorkedOn,
      theSkills,
      pleasePractice,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.HeadingCenterBlack>Session Summary</EE.HeadingCenterBlack>

          {EE.TextInputPromptSingle(
            "Date of Session:",
            sessionDate,
            setSessionDate
          )}

          {EE.TextInputPromptSingle(
            "Therapist or Coach Name:",
            therapistCoachName,
            setTherapistCoachName
          )}

          <br />
          <EE.HR />

          {EE.TextAreaPromptSingle(
            "Today, we worked on...",
            weWorkedOn,
            setWeWorkedOn
          )}

          {EE.TextAreaPromptSingle(
            "Here are the skills we learned/reviewed...",
            theSkills,
            setTheSkills
          )}

          {EE.TextAreaPromptSingle(
            "This week, please practice...",
            pleasePractice,
            setPleasePractice
          )}

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default SessionSummaryScreen;
