import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = { guestAccessActivateUrl: String };

  activate() {
    Rails.ajax({
      url: this.guestAccessActivateUrlValue,
      type: "post",
    });
  }
}
