import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const ParentObservationRecord: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const howdItGoDispositions = ["Great", "Fine", "Poorly"];

  const defaultState = {
    day: "",
    whatDidYourChildDo: "",
    whatDidYouDo: "",
    howdItGo: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [day, setDay] = React.useState(initialState.day);
  const [whatDidYourChildDo, setWhatDidYourChildDo] = React.useState(
    initialState.whatDidYourChildDo
  );
  const [whatDidYouDo, setWhatDidYouDo] = React.useState(
    initialState.whatDidYouDo
  );
  const [howdItGo, setHowdItGo] = React.useState(initialState.howdItGo);
  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      day,
      whatDidYourChildDo,
      whatDidYouDo,
      howdItGo,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Parent Observation Record</EE.Heading>
          <EE.HR />
          <EE.Caption>
            Please write down what your child did, then write what you did in
            the next column, and the results in the last column. Use the other
            side of the page to take any notes you may have.
          </EE.Caption>

          <EE.QuestionLabel>
            <EE.Bold>Day</EE.Bold>
            <EE.TextInput
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDay(e.target.value)
              }
              value={day}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Bold>What did your child do?</EE.Bold>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWhatDidYourChildDo(e.target.value)
              }
              value={whatDidYourChildDo}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Bold>What did you do?</EE.Bold>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWhatDidYouDo(e.target.value)
              }
              value={whatDidYouDo}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Bold>How'd it go?</EE.Bold>
            <div className="inline-block ml-4">
              {howdItGoDispositions.map((disposition) => {
                return (
                  <label
                    key={`radio-${disposition}`}
                    className="inline-flex items-center ml-2"
                  >
                    <input
                      type="radio"
                      className="form-radio"
                      name={howdItGo}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setHowdItGo(disposition);
                      }}
                      checked={howdItGo === disposition}
                    />
                    <span className="ml-2">{disposition}</span>
                  </label>
                );
              })}
            </div>
          </EE.QuestionLabel>
          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default ParentObservationRecord;
