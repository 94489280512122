import React from "react";

import { ChannelInfoProps, StafferImage } from "./DeprecatedMemberChatApp";

const ChatSidebarElement = (props: ChatSidebarProps) => {
  const { channelInfo, currentChannelId } = props;

  const {
    channel: { chatType },
    unread,
    lastMessage,
  } = channelInfo;
  const { createdAt, message } = lastMessage ?? {};

  const channelShowUrl = (channelInfo: ChannelInfoProps) =>
    `/accounts/${channelInfo.channel.accountId}/chats/${channelInfo.channel.id}`;

  const isSelected = () => currentChannelId === channelInfo.channel.id;

  const titles = {
    coach:
      channelInfo.brightlineParticipants.length > 1
        ? "Your Coaches"
        : "Your Coach",
  };

  const descriptions = {
    coach: {
      main: "Message your coach for:",
      list: [
        "Guidance on trying out new skills",
        "Questions about Brightline’s care and coaching programs",
        "Help with your assignments",
      ],
    },
    member_support: {
      main: "Message our member support team for:",
      list: [
        "Scheduling",
        "Billing",
        "Paperwork",
        "Eligibility and benefits",
        "Other administrative topics",
      ],
    },
    care_guide: {
      main: "Message a care guide for:",
      list: [
        "Guidance on coordinating care",
        "Questions about Brightline's internal and external resources",
        "Support with service recommendations",
      ],
    },
  };

  const displayTitle = () => {
    const title = titles[chatType];

    if (title) {
      return (
        <div
          className={`font-bold ${unread ? "text-gray-800" : "text-gray-700"}`}
        >
          <span className="px-2">•</span>
          <span className="text-sm">{title}</span>
        </div>
      );
    }
  };

  const displayDescription = () => {
    const description = descriptions[chatType];

    if (description) {
      return (
        <div className="font-sans text-sm italic text-gray-700">
          {description["main"]}

          <ul className="pt-2 pl-6 list-disc list-outside">
            {description["list"].map((el: string) => (
              <li key={el}>{el}</li>
            ))}
          </ul>
        </div>
      );
    }
  };

  const lastMessageSentToday = () => {
    const today = new Date();
    const lastMessageSent = new Date(createdAt);
    return today.getDate() === lastMessageSent.getDate();
  };

  const dateString = () => {
    if (!createdAt) {
      return "";
    }

    const dateString = new Date(createdAt);

    if (lastMessageSentToday()) {
      return dateString.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
      });
    } else {
      return dateString.toLocaleDateString("en-US");
    }
  };

  const selectedClasses = isSelected() ? " bg-gray-100" : "";

  return (
    <a
      href={channelShowUrl(channelInfo)}
      data-turbolinks={false}
      data-turbo={false}
    >
      <div
        className={`border-b border-gray-300 px-6 py-6 cursor-pointer ${selectedClasses}`}
      >
        <div className="flex">
          <div>
            {channelInfo.brightlineParticipants.map((bp) => (
              <div key={bp.id} className="relative w-12 h-12 mb-3">
                <StafferImage brightlineParticipant={bp} />
              </div>
            ))}
          </div>
          <div className="flex-grow pl-3 truncate">
            <div className="flex items-center">
              <div
                className={
                  unread ? "font-sans font-bold" : "text-base text-gray-700"
                }
              >
                {channelInfo.brightlineParticipants
                  .map((bp) => bp.nickname)
                  .join(" & ")}
              </div>
              {displayTitle()}
              {unread && (
                <div
                  className="w-3 h-3 ml-2 rounded-full bg-red-500"
                  data-testid="unread-badge"
                />
              )}
              <div className="flex-grow text-right">
                <span className="text-sm text-gray-700 opacity-60">
                  {dateString()}
                </span>
              </div>
            </div>
            <div
              className={`pt-1 text-sm truncate text-gray-700 ${
                unread ? "font-sans font-bold" : "font-sans"
              }`}
            >
              {message}
            </div>
            <div className="pt-3.5 whitespace-normal">
              {displayDescription()}
            </div>
          </div>
          <div className="sm:hidden">
            <span className="text-gray-700 material-icons">navigate_next</span>
          </div>
        </div>
      </div>
    </a>
  );
};

export default ChatSidebarElement;

interface ChatSidebarProps {
  channelInfo: ChannelInfoProps;
  currentChannelId: string;
}
