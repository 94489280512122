import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const BucketFillingAndBucketDippingScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    fillFamilyMembersBucket: "",
    fillFriendsBucket: "",
    fillTeachersBucket: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [fillFamilyMembersBucket, setFillFamilyMembersBucket] = React.useState(
    initialState.fillFamilyMembersBucket
  );
  const [fillFriendsBucket, setFillFriendsBucket] = React.useState(
    initialState.fillFriendsBucket
  );
  const [fillTeachersBucket, setFillTeachersBucket] = React.useState(
    initialState.fillTeachersBucket
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      fillFamilyMembersBucket,
      fillFriendsBucket,
      fillTeachersBucket,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Bucket-filling and Bucket-dipping</EE.Heading>
          <EE.HR />
          <EE.Paragraph className="my-4">
            We all carry around an invisible bucket with us every day. Your
            invisible bucket holds your good feelings and thoughts about
            yourself. When you are feeling happy and proud, your bucket is full.
            But when you are sad and lonely, your bucket is empty. The good news
            is, other people can fill your bucket and you can fill anyone else’s
            bucket, too!
          </EE.Paragraph>
          <EE.Paragraph className="my-4">
            There are so many ways to be a bucket filler! You can fill someone’s
            bucket by saying kind words, smiling at another person, or helping
            someone out. Your bucket also becomes filled when you are helping
            others, because it makes you feel good too!
          </EE.Paragraph>
          <EE.Paragraph className="my-4">
            While we want to be bucket-fillers, we also have to be aware of
            bucket-dippers. Bucket-dippers are people who take out good feelings
            from our buckets, because their bucket is empty. They become a
            bucket-dipper when they say mean words, do mean things, or ignore
            someone.
          </EE.Paragraph>
          <EE.Paragraph className="my-4">
            Let’s talk about how we can become even better bucket-fillers for
            those we care about!
          </EE.Paragraph>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>
                One way I can fill a family member’s invisible bucket is by...
              </EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFillFamilyMembersBucket(e.target.value)
              }
              value={fillFamilyMembersBucket}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>
                One way I can fill a friend’s invisible bucket is by...
              </EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFillFriendsBucket(e.target.value)
              }
              value={fillFriendsBucket}
            />
          </EE.QuestionLabel>

          <EE.QuestionLabel>
            <EE.Paragraph>
              <EE.Bold>
                One way I can fill my teacher’s invisible bucket is by...
              </EE.Bold>
            </EE.Paragraph>
            <EE.TextArea
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFillTeachersBucket(e.target.value)
              }
              value={fillTeachersBucket}
            />
          </EE.QuestionLabel>

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default BucketFillingAndBucketDippingScreens;
