import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["option"];
  static values = {
    className: { type: String, default: "button-secondary-active" },
  };

  declare optionTargets: [HTMLInputElement];
  declare classNameValue: string;

  selectRadioOption(event) {
    this.optionTargets.forEach((element) => {
      element.classList.toggle(
        this.classNameValue,
        event.currentTarget == element
      );
    });
  }
}
