import StressToolsIcon from "@js/images/icons/StressTools.svg";
import React from "react";
import { Route } from "react-router-dom";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import Calm from "./Calm";
import { StressManagementToolsProvider } from "./Context";
import Panic from "./Panic";
import Summary from "./Summary";
import TryItOut from "./TryItOut";
import Worry from "./Worry";

const Screens: React.FC<ExerciseScreenProps> = (props) => {
  const title = "Stress Management Tools";
  const numSteps = 3;
  let currentStep = 1;

  const intro = (
    <EE.GenericExerciseIntroPage
      imageSrc={StressToolsIcon}
      imageAltText="A toolbox full of tools."
      title={title}
      introText="We all feel stressed sometimes. Here are a couple ways to keep it from getting the best of you."
      nextPage="/panic"
    />
  );

  return (
    <StressManagementToolsProvider {...props}>
      <EE.WrapperWithIntro intro={intro}>
        <EE.ExerciseNavigation title={title} />

        <Route path="/panic">
          <Panic
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/worry"
          />
        </Route>

        <Route path="/worry">
          <Worry
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/calm"
          />
        </Route>

        <Route path="/calm">
          <Calm
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/summary"
          />
        </Route>

        <Route path="/summary">
          <Summary nextPage="/try_it_out" />
        </Route>

        <Route path="/try_it_out">
          <TryItOut />
        </Route>
      </EE.WrapperWithIntro>
    </StressManagementToolsProvider>
  );
};

export default Screens;
