import { Controller } from "@hotwired/stimulus";
import { useHotkeys } from "stimulus-use";

export default class extends Controller {
  static targets = ["modal"];

  modalTargetDisconnected(target) {
    document.body.classList.remove("overflow-y-hidden");
    if (this.trap) {
      this.trap.deactivate();
    }
  }

  modalTargetConnected(target) {
    if (this.visible) {
      document.body.classList.add("overflow-y-hidden");

      this.trap = focusTrap.createFocusTrap(this.modalTarget, {
        fallbackFocus: this.modalTarget,
      });
      this.trap.activate();
    }
  }

  connect() {
    this.visible = false;
    useHotkeys(this, {
      hotkeys: {
        "command+k": {
          handler: this.toggleSearch,
        },
        "escape": {
          handler: this.hide,
        },
        "down": {
          handler: this.moveFocusDown,
        },
        "up": {
          handler: this.moveFocusUp,
        },
      },
      filter: (e) => {
        if (e.metaKey && e.key === "k") {
          // Allow search hotkey to always be triggered, even with inputs focused
          return true;
        }

        // Otherwise only trigger if the modal is up
        return this.visible;
      },
    });
  }

  toggleSearch(e) {
    if (this.visible) {
      this.hide(e);
    } else {
      this.show(e);
    }
  }

  show(e) {
    if (this.visible) {
      return;
    }

    e.preventDefault();

    this.visible = true;
    this.previouslyFocusedElement = document.activeElement;

    this.modalTarget.classList.remove("hidden");
    this.modalTarget.classList.add("block");
    document.body.classList.add("overflow-y-hidden");

    this.focusFirstElement();
  }

  focusFirstElement() {
    const implicitFocusTarget = this.modalTarget.querySelector(
      "img, a, button, href, input, select, textarea"
    );

    const focusTarget = implicitFocusTarget;

    if (focusTarget) {
      focusTarget.focus();
    }
  }

  moveFocusDown(e) {
    e.preventDefault();

    const nextElement = this.getNextFocusElement(1);
    nextElement?.focus();
  }

  moveFocusUp(e) {
    e.preventDefault();

    const nextElement = this.getNextFocusElement(-1);
    nextElement?.focus();
  }

  getNextFocusElement(direction) {
    const elements = [
      ...this.modalTarget.querySelectorAll("input, a[href]"),
    ].filter((item) => parseInt(item.tabIndex) >= 0);
    const index = this.mod(
      elements.indexOf(document.activeElement) + direction,
      elements.length
    );

    return elements[index] || elements[0];
  }

  // modulo function
  // Used in this instance for wrapping array indexes around
  // It wraps around to a positive constrained (0 >= n <= m-1) index
  // e.g. Given m = 3
  // when n == 4 it returns 1
  // when n == 3 it returns 0
  // when n == -1 it returns 2
  mod(n, m) {
    return ((n % m) + m) % m;
  }

  hide(e) {
    if (!this.visible) {
      return;
    }

    e.preventDefault();

    this.visible = false;

    this.modalTarget.classList.add("hidden");
    this.modalTarget.classList.remove("block");
    document.body.classList.remove("overflow-y-hidden");
    if (this.trap) {
      this.trap.deactivate();
    }

    $(this.modalTarget).modal("hide");

    this.previouslyFocusedElement?.focus();
    this.previouslyFocusedElement = null;
  }
}
