import WhoIAmIcon from "@js/images/icons/WhoIAm.svg";
import React from "react";
import { Route, useLocation } from "react-router-dom";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import AmazingAt from "./Basics";
import Basics from "./Basics";
import { WhoIAmProvider } from "./Context";
import Favorites from "./Favorites";
import FreeTime from "./FreeTime";
import GoodFriend from "./GoodFriend";
import HaveNever from "./HaveNever";
import Hobbies from "./Hobbies";
import Lived from "./Lived";
import Pets from "./Pets";
import Summary from "./Summary";
import SupportSystem from "./SupportSystem";

const Screens: React.FC<ExerciseScreenProps> = (props) => {
  const title = "Who I am";
  const numSteps = 10;
  let currentStep = 1;

  return (
    <WhoIAmProvider {...props}>
      <EE.Wrapper>
        <EE.ExerciseNavigation title={title} />

        <Route exact path="/">
          <EE.GenericExerciseIntroPage
            title={title}
            imageSrc={WhoIAmIcon}
            imageAltText="A person made of many different color patches."
            introText="Want to better understand others? Spend some time getting to know yourself."
            nextPage="/basics"
          />
        </Route>

        <Route path="/basics">
          <Basics
            numSteps={numSteps}
            currentStep={currentStep++}
            nextPage="/lived"
          />
        </Route>

        <Route path="/lived">
          <Lived
            numSteps={numSteps}
            currentStep={currentStep++}
            nextPage="/support_system"
          />
        </Route>

        <Route path="/support_system">
          <SupportSystem
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/hobbies"
          />
        </Route>
        <Route path="/hobbies">
          <Hobbies
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/favorites"
          />
        </Route>

        <Route path="/favorites">
          <Favorites
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/pets"
          />
        </Route>

        <Route path="/pets">
          <Pets
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/good_friend"
          />
        </Route>

        <Route path="/good_friend">
          <GoodFriend
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/free_time"
          />
        </Route>

        <Route path="/free_time">
          <FreeTime
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/have_never"
          />
        </Route>

        <Route path="/have_never">
          <HaveNever
            currentStep={currentStep++}
            numSteps={numSteps}
            nextPage="/summary"
          />
        </Route>

        <Route path="/summary">
          <Summary />
        </Route>
      </EE.Wrapper>
    </WhoIAmProvider>
  );
};

export default Screens;
