import React from "react";

import { ExerciseScreenProps } from "../shared/Elements";

const BodyMapContext = React.createContext(undefined);

const BodyMapProvider: React.FC<ExerciseScreenProps> = (props) => {
  const defaultState = {
    initialThermometerValue: 5,
    finalThermometerValue: 5,
    thoughts: "",
    blobs: [],
  };

  const {
    assignedExerciseId,
    patientFirstName,
    careLink,
    chatLink,
    completedExercise,
    readOnly,
  } = props;
  const initialState = { ...defaultState, ...completedExercise?.data };

  const [initialThermometerValue, setInitialThermometerValue] = React.useState(
    initialState.initialThermometerValue
  );
  const [finalThermometerValue, setFinalThermometerValue] = React.useState(
    initialState.finalThermometerValue
  );
  const [thoughts, setThoughts] = React.useState<string>(initialState.thoughts);
  const [blobs, setBlobs] = React.useState(initialState.blobs);

  const value = {
    initialThermometerValue,
    finalThermometerValue,
    thoughts,
    blobs,
    setInitialThermometerValue,
    setFinalThermometerValue,
    setThoughts,
    setBlobs,
    assignedExerciseId,
    patientFirstName,
    careLink,
    chatLink,
    completedExercise,
    readOnly,
  };

  return <BodyMapContext.Provider value={value} {...props} />;
};

function useBodyMap() {
  const context = React.useContext(BodyMapContext);
  if (!context) {
    throw new Error("useBodyMap must be used within a BodyMapProvider");
  }
  return context;
}

export { BodyMapProvider, BodyMapContext, useBodyMap };
