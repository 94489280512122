import Image from "@js/components/shared/Image";
import WhatILoveAboutYouIcon from "@js/images/exercises/WhatILoveAboutYou.png";
import React from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const WhatILoveAboutYouScreen: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    love1: "",
    love2: "",
    love3: "",
    love4: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };
  const [love1, setlove1] = React.useState(initialState.love1);
  const [love2, setlove2] = React.useState(initialState.love1);
  const [love3, setlove3] = React.useState(initialState.love1);
  const [love4, setlove4] = React.useState(initialState.love1);

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      love1,
      love2,
      love3,
      love4,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <Image src={WhatILoveAboutYouIcon} className="mx-auto" />

          <EE.HeadingCenterBlack>What I Love About You</EE.HeadingCenterBlack>

          {EE.TextInputPromptSingle("Thing 1", love1, setlove1)}

          {EE.TextInputPromptSingle("Thing 2", love2, setlove2)}

          {EE.TextInputPromptSingle("Thing 3", love3, setlove3)}

          {EE.TextInputPromptSingle("Thing 4", love4, setlove4)}

          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default WhatILoveAboutYouScreen;
