import React from "react";

import { ExerciseScreenProps } from "../shared/Elements";

const MySelfInventoryContext = React.createContext(undefined);

const MySelfInventoryProvider: React.FC<ExerciseScreenProps> = (props) => {
  const defaultState = {
    strengths: "",
    values: "",
    selfEsteem: "",
    stillGrowing: "",
  };

  const {
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  } = props;

  const initialState = { ...defaultState, ...completedExercise?.data };

  const [strengths, setStrengths] = React.useState<string>(
    initialState.strengths
  );
  const [values, setValues] = React.useState<string>(initialState.values);
  const [selfEsteem, setSelfEsteem] = React.useState<string>(
    initialState.selfEsteem
  );
  const [stillGrowing, setStillGrowing] = React.useState<string>(
    initialState.stillGrowing
  );

  const value = {
    strengths,
    setStrengths,
    values,
    setValues,
    selfEsteem,
    setSelfEsteem,
    stillGrowing,
    setStillGrowing,
    assignedExerciseId,
    patientFirstName,
    chatLink,
    completedExercise,
    readOnly,
  };

  return <MySelfInventoryContext.Provider value={value} {...props} />;
};

function useMySelfInventory() {
  const context = React.useContext(MySelfInventoryContext);
  if (!context) {
    throw new Error(
      "useMySelfInventory must be used within a MySelfInventoryProvider"
    );
  }
  return context;
}

export { MySelfInventoryProvider, MySelfInventoryContext, useMySelfInventory };
