import { Controller } from "@hotwired/stimulus";

// This allows setting a scroll position for a target element based on persisted value
export default class extends Controller {
  static targets = ["scrollable"];

  initialize() {
    this.scrollPositions = {};
  }

  scrollableTargetConnected(target) {
    target.scrollTop = this.scrollPositions[target.id];
  }

  persist(_event) {
    this.scrollableTargets.forEach((element) => {
      this.scrollPositions[element.id] = element.scrollTop;
    });
  }
}
