import React, { useState } from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const Thermometer = (props: {
  thermometerValue: number;
  setThermometerValue: Function;
  ariaLabelledByForHandle: string;
}) => {
  return (
    <div className="py-4">
      <EE.ThermometerSlider
        thermometerValue={props.thermometerValue}
        onChange={props.setThermometerValue}
        ariaLabelledByForHandle={props.ariaLabelledByForHandle}
      />
      <EE.ExerciseAnchors>
        <EE.ExerciseAnchor className="text-left">Very Bad</EE.ExerciseAnchor>
        <EE.ExerciseAnchor className="text-right">Very Good</EE.ExerciseAnchor>
      </EE.ExerciseAnchors>
    </div>
  );
};

const LearningToRelaxScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    beforeRelaxingThermometerValue: 5,
    afterRelaxingThermometerValue: 5,
    beforeRelaxingFeelPhysically: "",
    afterRelaxingFeelPhysically: "",
    favoritePartOfRelaxing: [
      "Breathing out Stress & Breathing in Calm",
      "Tensing & Relaxing Muscles",
      "Imagining a Calm, Relaxing Place",
    ],
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };

  const [beforeRelaxingThermometerValue, setBeforeRelaxingThermometerValue] =
    useState(initialState.beforeRelaxingThermometerValue);
  const [afterRelaxingThermometerValue, setAfterRelaxingThermometerValue] =
    useState(initialState.afterRelaxingThermometerValue);
  const [beforeRelaxingFeelPhysically, setBeforeRelaxingFeelPhysically] =
    useState(initialState.beforeRelaxingFeelPhysically);
  const [afterRelaxingFeelPhysically, setAfterRelaxingFeelPhysically] =
    useState(initialState.afterRelaxingFeelPhysically);
  const [favoritePartOfRelaxing, setFavoritePartOfRelaxing] = useState(
    initialState.favoritePartOfRelaxing
  );

  const swap = (
    array: string[],
    setterFunction: React.Dispatch<React.SetStateAction<string[]>>,
    fromIndex: number,
    toIndex: number
  ) => {
    var newArray = [...array];
    const oldValue = newArray[toIndex];
    newArray[toIndex] = newArray[fromIndex];
    newArray[fromIndex] = oldValue;
    const result = setterFunction(newArray);
  };

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      beforeRelaxingThermometerValue,
      afterRelaxingThermometerValue,
      beforeRelaxingFeelPhysically,
      afterRelaxingFeelPhysically,
      favoritePartOfRelaxing,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Learning to Relax</EE.Heading>
          <EE.HR />
          <EE.Subheading>How Do You Feel BEFORE Relaxing?</EE.Subheading>
          <EE.Paragraph id="before-relaxing">
            This is how I feel <EE.Bold>BEFORE</EE.Bold> relaxing (when I'm
            thinking about a stressful situation):
          </EE.Paragraph>
          <Thermometer
            thermometerValue={beforeRelaxingThermometerValue}
            setThermometerValue={setBeforeRelaxingThermometerValue}
            ariaLabelledByForHandle="before-relaxing"
          />
          <EE.QuestionLabel>
            This is how I feel physically:
            <EE.TextInput
              value={beforeRelaxingFeelPhysically}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setBeforeRelaxingFeelPhysically(e.target.value);
              }}
            />
          </EE.QuestionLabel>
          <EE.Subheading>How do you Feel AFTER Relaxing?</EE.Subheading>
          <EE.Paragraph id="after-relaxing">
            This is how I feel <EE.Bold>AFTER</EE.Bold> relaxing (when I'm
            thinking about a stressful situation):
          </EE.Paragraph>
          <Thermometer
            thermometerValue={afterRelaxingThermometerValue}
            setThermometerValue={setAfterRelaxingThermometerValue}
            ariaLabelledByForHandle="after-relaxing"
          />
          <EE.QuestionLabel>
            This is how I feel physically:
            <EE.TextInput
              value={afterRelaxingFeelPhysically}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAfterRelaxingFeelPhysically(e.target.value);
              }}
            />
          </EE.QuestionLabel>
          <EE.Subheading>What's Your Favorite Part of Relaxing?</EE.Subheading>
          <EE.Paragraph>
            Which part of relaxing do you like the best? Rank them: #1, #2, and
            #3!
          </EE.Paragraph>
          {favoritePartOfRelaxing.map((favorite, index) => {
            return (
              <div className="flex w-full" key={`favorite-${index}`}>
                <div className="flex w-1/6 md:1/12">
                  <div className="w-1/3">
                    {index > 0 && (
                      <button
                        className="underline cursor-pointer text-grape hover:text-opacity-75"
                        onClick={(e) => {
                          e.preventDefault();
                          swap(
                            favoritePartOfRelaxing,
                            setFavoritePartOfRelaxing,
                            index,
                            index - 1
                          );
                        }}
                        title="Move up"
                      >
                        <span
                          className="text-gray-700 material-icons"
                          aria-hidden="true"
                        >
                          arrow_upward
                        </span>
                      </button>
                    )}{" "}
                  </div>
                  <div className="w-1/3">
                    {index < 2 && (
                      <button
                        className="underline cursor-pointer text-grape hover:text-opacity-75"
                        onClick={(e) => {
                          e.preventDefault();
                          swap(
                            favoritePartOfRelaxing,
                            setFavoritePartOfRelaxing,
                            index,
                            index + 1
                          );
                        }}
                        title="Move down"
                      >
                        <span
                          className="text-gray-700 material-icons"
                          aria-hidden="true"
                        >
                          arrow_downward
                        </span>
                      </button>
                    )}
                  </div>
                  <div className="w-1/3 text-right">{index + 1}. </div>
                </div>
                <div className="w-5/6 pl-4 md:w-11/12">{favorite} </div>
              </div>
            );
          })}
          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default LearningToRelaxScreens;
