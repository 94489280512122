import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";

export default class extends Controller {
  static targets = ["contentToTrack"];

  connect() {
    // Use the https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API to track when an element comes into view
    const intersectionObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.trackContentViewed(
              entry.target.dataset.eventName,
              JSON.parse(entry.target.dataset.properties)
            );
          }
          observer.unobserve(entry.target);
        });
      },
      {
        threshold: 1, // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#threshold
      }
    );

    this.contentToTrackTargets.forEach((content) => {
      intersectionObserver.observe(content);
    });
  }

  trackContentViewed(eventName, properties) {
    ahoy.track(eventName, properties);
  }
}
