import React from "react";

import { ExerciseElements as EE } from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";
import { useAllAboutMe } from "./Context";

const Summary = () => {
  const {
    description,
    deepDescription,
    amazingAt,
    surprising,
    hereBecause,
    sumItUp,
    assignedExerciseId,
    completedExercise,
  } = useAllAboutMe();

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      description,
      deepDescription,
      amazingAt,
      surprising,
      hereBecause,
      sumItUp,
    },
    assignedExerciseId,
    completedExercise?.id
  );

  return (
    <EE.SubmitableForm submitExercise={submitExercise}>
      <div className="md:mt-12">I am a {description}</div>

      <div className="mt-6">But really I'm a {deepDescription}</div>

      <div className="mt-6">I'm amazing at {amazingAt}</div>

      <div className="mt-6">
        You'll probably be surprised and delighted by {surprising}
      </div>

      <div className="mt-6">I am here because {hereBecause}</div>

      <div className="mt-6">To sum it up, I am {sumItUp}</div>

      <EE.ButtonGroup>
        <EE.ResumableExerciseActions
          submitButtonText="I'm done"
          setExerciseComplete={setExerciseComplete}
        />
      </EE.ButtonGroup>
    </EE.SubmitableForm>
  );
};

export default Summary;
