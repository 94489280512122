import { Controller } from "@hotwired/stimulus";

/*
Purpose: Allows setting the value of one input to the value of another.
         The initial use case for this was to regenerate a Turbo modal
         when one of the inputs changed.

Targets:
- destination(s): The input whose value should be updated when the target element changes

Params:
- destination: The name of the destination input to update

Example:

<div data-controller="input-reflection">
  <form>
    <select data-action="change->input-reflection#updateValue" data-input-reflection-destination-param="region"></select>
  </form>

  <form>
    <input type="hidden" name="region" data-input-reflection-target="destination">
  </form>
</div>
 */
export default class extends Controller {
  static targets = ["destination"];

  updateValue(event) {
    const value = event.target.value;

    this.destinationTargets.forEach((destinationTarget) => {
      if (destinationTarget.name === event.params.destination) {
        destinationTarget.value = value;
      }
    });
  }
}
