// Adds the contents of `template` to the end of `container`
//
import { Controller } from "@hotwired/stimulus";

export default class AddableController extends Controller {
  declare readonly templateTarget: HTMLTemplateElement;
  declare readonly containerTarget: HTMLElement;

  static targets = ["template", "container"];

  add(event) {
    event.preventDefault();
    const addableContent = this.templateTarget.content.cloneNode(true);
    this.containerTarget.append(addableContent);
  }
}
