import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "turboFrame"];

  navigate(event) {
    const url = this.selectTarget.value;

    if (url) {
      this.visitUrl(url);
    }
  }

  visitUrl(url) {
    if (this.hasTurboFrameTarget) {
      this.turboFrameTarget.src = url;
    } else {
      Turbo.visit(url);
    }
  }
}
