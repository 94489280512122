import React, { useState } from "react";

import {
  ExerciseElements as EE,
  ExerciseScreenProps,
} from "../shared/Elements";
import { useSubmitExercise } from "../shared/Hooks";

const FiveStepsScreens: React.FC<ExerciseScreenProps> = (
  props: ExerciseScreenProps
) => {
  const defaultState = {
    problem: "",
    solutions: "",
    goodThingsCouldHappen: "",
    badThingsCouldHappen: "",
    proposedSolution: "",
  };

  const initialState = { ...defaultState, ...props.completedExercise?.data };

  const [problem, setProblem] = useState(initialState.problem);
  const [solutions, setSolutions] = useState(initialState.solutions);
  const [goodThingsCouldHappen, setGoodThingsCouldHappen] = useState(
    initialState.goodThingsCouldHappen
  );
  const [badThingsCouldHappen, setBadThingsCouldHappen] = useState(
    initialState.badThingsCouldHappen
  );
  const [proposedSolution, setProposedSolution] = useState(
    initialState.proposedSolution
  );

  const { submitExercise, setExerciseComplete } = useSubmitExercise(
    {
      problem,
      solutions,
      goodThingsCouldHappen,
      badThingsCouldHappen,
      proposedSolution,
    },
    props.assignedExerciseId,
    props.completedExercise?.id
  );

  return (
    <>
      <EE.Wrapper>
        <EE.Form onSubmit={submitExercise}>
          <EE.Heading>Five S-T-E-P-S To Problem Solving</EE.Heading>
          <EE.HR />
          <div className="flex">
            <EE.BigOldLetter>S</EE.BigOldLetter>
            <EE.InputGroup>
              <EE.QuestionLabel htmlFor="problem">
                Say what the problem is
              </EE.QuestionLabel>
              <EE.TextArea
                id="problem"
                placeholder="Describe the problem"
                value={problem}
                onChange={(e) => setProblem(e.target.value)}
                readOnly={props.readOnly}
              />
            </EE.InputGroup>
          </div>
          <div className="flex">
            <EE.BigOldLetter>T</EE.BigOldLetter>
            <EE.InputGroup>
              <EE.QuestionLabel htmlFor="solutions">
                Think of solutions
              </EE.QuestionLabel>
              <EE.TextArea
                id="solutions"
                placeholder={"1.\n2.\n3.\n4.\n"}
                value={solutions}
                onChange={(e) => setSolutions(e.target.value)}
                readOnly={props.readOnly}
              />
            </EE.InputGroup>
          </div>
          <div className="flex">
            <EE.BigOldLetter>E</EE.BigOldLetter>
            <EE.InputGroup>
              <EE.QuestionLabel>
                Examine each solution you listed above. What good &amp; bad
                things might happen if you did each one?
              </EE.QuestionLabel>
              <div className="block md:flex">
                <div className="w-full md:w-1/2 md:pr-2">
                  <EE.QuestionLabel htmlFor="good-things-could-happen">
                    Good
                  </EE.QuestionLabel>
                  <EE.TextArea
                    id="good-things-could-happen"
                    placeholder={"1.\n2.\n3.\n4.\n"}
                    value={goodThingsCouldHappen}
                    onChange={(e) => setGoodThingsCouldHappen(e.target.value)}
                    readOnly={props.readOnly}
                  />
                </div>
                <div className="w-full md:w-1/2 md:pl-2">
                  <EE.QuestionLabel htmlFor="bad-things-could-happen">
                    Bad
                  </EE.QuestionLabel>
                  <EE.TextArea
                    id="bad-things-could-happen"
                    placeholder={"1.\n2.\n3.\n4.\n"}
                    value={badThingsCouldHappen}
                    onChange={(e) => setBadThingsCouldHappen(e.target.value)}
                    readOnly={props.readOnly}
                  />
                </div>
              </div>
            </EE.InputGroup>
          </div>
          <div className="flex">
            <EE.BigOldLetter>P</EE.BigOldLetter>
            <EE.InputGroup>
              <EE.QuestionLabel
                htmlFor="proposed-solution"
                className="block mt-8"
              >
                Pick one and try it out. Which one are you going to try?
              </EE.QuestionLabel>
              <EE.TextArea
                id="proposed-solution"
                value={proposedSolution}
                onChange={(e) => setProposedSolution(e.target.value)}
                readOnly={props.readOnly}
              />
            </EE.InputGroup>
          </div>
          <div className="flex">
            <EE.BigOldLetter>S</EE.BigOldLetter>
            <EE.InputGroup>
              <EE.QuestionLabel
                htmlFor="proposed-solution"
                className="block mt-8"
              >
                See if it worked. If it worked, great! If it did not work, then
                go back to your list of solutions and try another one.
              </EE.QuestionLabel>
            </EE.InputGroup>
          </div>
          <EE.ResumableExerciseActions
            setExerciseComplete={setExerciseComplete}
          />
        </EE.Form>
      </EE.Wrapper>
    </>
  );
};

export default FiveStepsScreens;
